import $i18n from 'panda-i18n';
import locale from '@/locales';
import * as formilyCore from '@formily/core';
import * as formilyReact from '@formily/react';
import './validator';
// 国际化初始化，必须首位执行
$i18n.init({
    locale,
    componentName: 'CnForm',
});
export { ArrayBase as CnFormArrayBase } from './array-base';
export * from './component-map';
export * from './schema-field';
export * from './cn-form';
export * from './cn-quick-dialog-form';
export * from './cn-form-field';
export * from './cn-form-tab';
export * from './cn-array-table';
export * from './cn-anchor-form';
export * from './cn-form-layout';
export { createCnFormStepInstance } from './cn-form-step';
export { formilyCore, formilyReact };
