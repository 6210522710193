const allNeedCalChar = [
    ...'abcdefghijklmnopqrstuvwxyz'.split(''),
    ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    '`',
    '~',
    '@',
    '#',
    '$',
    '%',
    '-',
    '+',
    '=',
    '!',
    '"',
    '&',
    '',
    '*',
    '(',
    ')',
    '_',
    '\\',
    '|',
    "'",
    ';',
    ':',
    '?',
    '/',
    '.',
    '>',
    '<',
    '¥',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    ' ',
];
export default allNeedCalChar;
