import * as React from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import cloneDeep from 'lodash/cloneDeep';
import NextNumberPicker from './number-picker';
import './range-number-picker.scss';
export class CnRangeNumberPicker extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isFocus: false,
        };
        this.handleBlur = () => {
            this.setState({ isFocus: false });
        };
        this.handleFocus = () => {
            this.setState({ isFocus: true });
        };
        this.onFirstChange = (value) => {
            const { value: oldValue, onChange } = this.props;
            const secondNumber = Array.isArray(oldValue) ? oldValue[1] : null;
            onChange && onChange([value, secondNumber]);
        };
        this.onSecondChange = (value) => {
            const { value: oldValue, onChange } = this.props;
            const firstNumber = Array.isArray(oldValue) ? oldValue[0] : null;
            onChange && onChange([firstNumber, value]);
        };
    }
    render() {
        const { isFocus } = this.state;
        const { value = [], state, readOnly, className, style, firstNumberPickerProps, secondNumberPickerProps, 
        // other
        onChange, ...otherProps } = this.props;
        const classes = cx({
            'cn-ui-range-number-picker': true,
            'cn-ui-range-number-picker-error': state === 'error',
            'cn-ui-range-number-picker-focus': isFocus,
            'cn-ui-range-number-picker-disabled': otherProps.disabled,
            'cn-ui-range-number-picker-readonly': !!readOnly,
            [className]: !!className,
        });
        // 组装前后输入框的 props
        const firstProps = cloneDeep(firstNumberPickerProps || {});
        const secondProps = cloneDeep(secondNumberPickerProps || {});
        if (Array.isArray(value)) {
            firstProps.value = value[0];
        }
        if (Array.isArray(value)) {
            secondProps.value = value[1];
        }
        // 预览状态展示
        if (readOnly) {
            const firstBigNumberValue = new BigNumber(firstProps.value);
            let firstDisplayValue = firstBigNumberValue.toString();
            if (!firstBigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    firstDisplayValue = firstBigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    firstDisplayValue = otherProps.format(firstDisplayValue);
                }
            }
            else {
                firstDisplayValue = '-';
            }
            const secondBigNumberValue = new BigNumber(secondProps.value);
            let secondDisplayValue = secondBigNumberValue.toString();
            if (!secondBigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    secondDisplayValue = secondBigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    secondDisplayValue = otherProps.format(secondDisplayValue);
                }
            }
            else {
                secondDisplayValue = '-';
            }
            return (React.createElement("div", { "data-name": "CnRangeNumberPicker", className: classes },
                React.createElement("div", null, firstDisplayValue),
                React.createElement("span", { className: "split-char" }, "~"),
                React.createElement("div", null, secondDisplayValue)));
        }
        // 编辑状态展示
        return (React.createElement("div", { "data-name": "CnRangeNumberPicker", className: classes, style: style, onBlur: this.handleBlur, onFocus: this.handleFocus },
            React.createElement(NextNumberPicker, { prefix: "cn-next-", onChange: this.onFirstChange, ...otherProps, ...firstProps }),
            React.createElement("span", { className: "split-char" }, "~"),
            React.createElement(NextNumberPicker, { prefix: "cn-next-", onChange: this.onSecondChange, ...otherProps, ...secondProps })));
    }
}
CnRangeNumberPicker.displayName = 'CnRangeNumberPicker';
