import React, { forwardRef } from 'react';
import { isEnableScrollbar, isEnableStyle } from './utils';
import Scrollbar from './scroll-bar';
export const CnScrollBar = forwardRef((props, ref) => {
    const { suppressScrollX, suppressScrollY, as = 'div', style, className, children, ...otherProps } = props;
    const currentStyle = {
        overflowX: isEnableStyle(suppressScrollX),
        overflowY: isEnableStyle(suppressScrollY),
        ...style,
    };
    const Wrapper = as;
    if (!isEnableScrollbar()) {
        return (React.createElement(Wrapper, { style: currentStyle, className: className, ref: ref, ...props }, children));
    }
    return (React.createElement(Scrollbar, { style: currentStyle, innerRef: ref, suppressScrollX: suppressScrollX, suppressScrollY: suppressScrollY, Wrapper: Wrapper, className: className, ...otherProps }, children));
});
