// 第三方依赖
import pick from 'lodash/pick';
import { useSizeChange, } from '@/components/cn-utils';
import { useControllableValue } from 'ahooks';
// 当前目录的相对依赖
import { useControlColumnsValue } from './use-control-columns-value';
import { useControlValue } from './use-control-value';
import { useUnitValue } from './use-unit-value';
export function useDelegateHub(props) {
    var _a, _b, _c;
    // const [localColumns, setLocalColumns] = React.useState(props.columns);
    // const preColumns = React.useRef();
    const { sortCustom } = props;
    const [dataSource, setDataSource] = useUnitValue(props, {
        defaultValue: [],
        valuePropName: 'dataSource',
    });
    const [primaryKey, setPrimaryKey] = useUnitValue(props, {
        defaultValue: 'id',
        valuePropName: 'primaryKey',
    });
    const [sorts, setSorts] = useControlValue((sortCustom || {}), {
        defaultValuePropName: (props === null || props === void 0 ? void 0 : props.defaultSorts) || [],
        valuePropName: 'sorts',
        changePropName: 'onChangeSorts',
    });
    const [loading, setLoading] = useControlValue(props, {
        defaultValuePropName: false,
        valuePropName: 'loading',
        changePropName: 'loadingChange',
    });
    // const [size, setSize] = useControlValue<SizeType>(props as unknown as Obj, {
    //   defaultValuePropName: props?.defaultSize ?? 'medium',
    //   valuePropName: 'size',
    //   changePropName: 'toolbar.sizeChange',
    // });
    const { size, setSize } = useSizeChange(Object.assign({}, props, ((_a = props === null || props === void 0 ? void 0 : props.toolbar) === null || _a === void 0 ? void 0 : _a.sizeChange)
        ? { sizeChange: props.toolbar.sizeChange }
        : {}));
    const [fullScreen, setFullScreen] = useControlValue(props, {
        defaultValuePropName: (_b = props === null || props === void 0 ? void 0 : props.defaultIsFullScreen) !== null && _b !== void 0 ? _b : false,
        valuePropName: 'isFullScreen',
        changePropName: 'toolbar.screenChange',
    });
    const [zebra, setZebra] = useControlValue(props, {
        defaultValuePropName: (_c = props === null || props === void 0 ? void 0 : props.defaultIsZebra) !== null && _c !== void 0 ? _c : false,
        valuePropName: 'isZebra',
        changePropName: 'toolbar.zebraChange',
    });
    const [openKeys, setOpenKeys] = useControllableValue(pick(props.rowDetail, ['defaultOpenKeys', 'openKeys', 'onChangeOpenKeys']), {
        defaultValue: [],
        valuePropName: 'openKeys',
        trigger: 'onChangeOpenKeys',
    });
    const [columns, setColumns] = useControlColumnsValue(props);
    return {
        dataSource,
        setDataSource,
        primaryKey,
        setPrimaryKey,
        sorts,
        setSorts,
        loading,
        setLoading,
        size,
        setSize,
        fullScreen,
        setFullScreen,
        zebra,
        setZebra,
        columns,
        setColumns,
        openKeys,
        setOpenKeys,
    };
}
