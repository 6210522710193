import classnames from 'classnames';
// 定义 stylegen 函数，接收 TableProps 类型的参数
export const stylegen = (props) => {
    // 从 props 中解构 hasBorder 和 style
    const { hasBorder = true, style } = props;
    // 返回一个新的样式对象，包含原样式和根据 hasBorder 设置的边框样式
    return Object.assign({}, style, {
        '--cell-border-vertical': hasBorder ? '1px solid var(--border-color)' : 0,
        '--header-cell-border-vertical': hasBorder
            ? 'var(--header-cell-border)'
            : 0,
    });
};
export const rowPropsgen = (props, selected) => {
    const { rowProps, primaryKey } = props;
    return (record, rowIndex) => {
        const customRowProps = rowProps && rowProps(record, rowIndex);
        return {
            ...customRowProps,
            className: classnames(customRowProps === null || customRowProps === void 0 ? void 0 : customRowProps.className, {
                'cn-table-row-selected': Array.isArray(selected) && selected.includes(record[primaryKey]),
            }),
        };
    };
};
// 定义 classNamegen 函数，接收 TableProps 类型的参数
export const classNamegen = (props) => {
    // 从 props 中解构 className 和 size，默认 size 为 'small'
    const { className, size = 'small' } = props;
    // 使用 classnames 函数生成类名字符串，根据 size 设置相应的类名
    return classnames(className, {
        'cn-table-small': size === 'small',
        'cn-table-medium': size === 'medium',
        'cn-table-large': size === 'large',
    });
};
