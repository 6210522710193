import React, { Component, Children, cloneElement } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Animate, Icon } from '@/components/fusion';
import { func, obj } from '@fusion/lib/util';
import Item from './item';
import SelectabelItem from './selectable-item';
import PopupItem from './popup-item';
import { getChildSelected } from './util';
const { Expand } = Animate;
const { bindCtx } = func;
/**
 * Menu.SubMenu
 * @order 1
 */
export default class SubMenu extends Component {
    constructor(props) {
        super(props);
        bindCtx(this, [
            'handleMouseEnter',
            'handleMouseLeave',
            'handleClick',
            'handleOpen',
            'afterLeave',
        ]);
    }
    componentDidMount() {
        this.itemNode = findDOMNode(this);
    }
    afterLeave() {
        const { focused, root } = this.props;
        const { focusable } = root.props;
        if (focusable && focused) {
            this.itemNode.focus();
        }
    }
    getOpen() {
        const { _key, root } = this.props;
        const { openKeys } = root.state;
        return openKeys.indexOf(_key) > -1;
    }
    handleMouseEnter(e) {
        this.handleOpen(true);
        this.props.onMouseEnter && this.props.onMouseEnter(e);
    }
    handleMouseLeave(e) {
        this.handleOpen(false);
        this.props.onMouseLeave && this.props.onMouseLeave(e);
    }
    handleClick(e) {
        const { root, selectable } = this.props;
        const { selectMode } = root.props;
        if (selectMode && selectable) {
            e.stopPropagation();
        }
        const open = this.getOpen();
        this.handleOpen(!open);
    }
    handleOpen(open, triggerType, e) {
        const { _key, root } = this.props;
        root.handleOpen(_key, open, triggerType, e);
    }
    passParentToChildren(children) {
        const { mode, root } = this.props;
        return Children.map(children, (child) => {
            // to fix https://github.com/alibaba-fusion/next/issues/952
            if (typeof child !== 'function' && typeof child !== 'object') {
                return child;
            }
            return cloneElement(child, {
                parent: this,
                parentMode: mode || root.props.mode,
            });
        });
    }
    renderInline() {
        const { _key, level, inlineLevel, root, className, selectable: selectableFromProps, label, children, noIcon, subMenuContentClassName, triggerType: propsTriggerType, parentMode, } = this.props;
        const { prefix, selectMode, triggerType: rootTriggerType, inlineArrowDirection, expandAnimation, rtl, } = root.props;
        const triggerType = propsTriggerType || rootTriggerType;
        const open = this.getOpen();
        const { selectedKeys, _k2n } = root.state;
        const isChildSelected = getChildSelected({
            _key,
            _k2n,
            selectMode,
            selectedKeys,
        });
        const others = obj.pickOthers(Object.keys(SubMenu.propTypes), this.props);
        const liProps = {
            className: cx({
                [`${prefix}menu-sub-menu-wrapper`]: true,
                [className]: !!className,
            }),
        };
        const itemProps = {
            'aria-expanded': open,
            _key,
            level,
            role: 'listitem',
            inlineLevel,
            root,
            type: 'submenu',
            component: 'div',
            parentMode,
            className: cx({
                [`${prefix}opened`]: open,
                [`${prefix}child-selected`]: isChildSelected,
            }),
        };
        if (typeof label === 'string') {
            itemProps.title = label;
        }
        const arrorProps = {
            type: inlineArrowDirection === 'right' ? 'arrow-right' : 'arrow-down',
            className: cx({
                [`${prefix}menu-icon-arrow`]: true,
                [`${prefix}menu-icon-arrow-down`]: inlineArrowDirection === 'down',
                [`${prefix}menu-icon-arrow-right`]: inlineArrowDirection === 'right',
                [`${prefix}open`]: open,
            }),
        };
        const selectable = !!selectMode && selectableFromProps;
        const NewItem = selectable ? SelectabelItem : Item;
        if (triggerType === 'hover') {
            liProps.onMouseEnter = this.handleMouseEnter;
            liProps.onMouseLeave = this.handleMouseLeave;
        }
        else if (selectable) {
            arrorProps.onClick = this.handleClick;
        }
        else {
            itemProps.onClick = this.handleClick;
        }
        const newSubMenuContentClassName = cx({
            [`${prefix}menu-sub-menu`]: true,
            [subMenuContentClassName]: !!subMenuContentClassName,
        });
        let roleMenu = 'menu';
        let roleItem = 'menuitem';
        if ('selectMode' in root.props) {
            roleMenu = 'listbox';
            roleItem = 'option';
        }
        const subMenu = open ? (React.createElement("ul", { role: roleMenu, dir: rtl ? 'rtl' : undefined, className: newSubMenuContentClassName }, this.passParentToChildren(children))) : null;
        return (React.createElement("li", { role: roleItem, ...others, ...liProps },
            React.createElement(NewItem, { ...itemProps },
                React.createElement("span", { className: `${prefix}menu-item-text` }, label),
                noIcon ? null : React.createElement(Icon, { ...arrorProps })),
            expandAnimation ? (React.createElement(Expand, { animationAppear: false, afterLeave: this.afterLeave }, subMenu)) : (subMenu)));
    }
    renderPopup() {
        const { children, subMenuContentClassName, noIcon, ...others } = this.props;
        const { root } = this.props;
        const { prefix, popupClassName, popupStyle, rtl } = root.props;
        const newClassName = cx({
            [`${prefix}menu`]: true,
            [`${prefix}ver`]: true,
            [popupClassName]: !!popupClassName,
            [subMenuContentClassName]: !!subMenuContentClassName,
        });
        others.rtl = rtl;
        return (React.createElement(PopupItem, { ...others, noIcon: noIcon, hasSubMenu: true },
            React.createElement("ul", { role: "menu", dir: rtl ? 'rtl' : undefined, className: newClassName, style: popupStyle }, this.passParentToChildren(children))));
    }
    render() {
        const { mode, root } = this.props;
        const newMode = mode || root.props.mode;
        return newMode === 'popup' ? this.renderPopup() : this.renderInline();
    }
}
SubMenu.menuChildType = 'submenu';
SubMenu.propTypes = {
    _key: PropTypes.string,
    root: PropTypes.object,
    level: PropTypes.number,
    inlineLevel: PropTypes.number,
    groupIndent: PropTypes.number,
    /**
     * 标签内容
     */
    label: PropTypes.node,
    /**
     * 是否可选，该属性仅在设置 Menu 组件 selectMode 属性后生效
     */
    selectable: PropTypes.bool,
    /**
     * 子菜单打开方式，如果设置会覆盖 Menu 上的同名属性
     * @default Menu 的 mode 属性值
     */
    mode: PropTypes.oneOf(['inline', 'popup']),
    /**
     * 是否需要提示当前项可展开的 icon，默认是有的
     */
    noIcon: PropTypes.bool,
    /**
     * 菜单项或下一级子菜单
     */
    children: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    subMenuContentClassName: PropTypes.string,
    triggerType: PropTypes.oneOf(['click', 'hover']),
    align: PropTypes.oneOf(['outside', 'follow']),
    parentMode: PropTypes.oneOf(['inline', 'popup']),
    parent: PropTypes.any,
};
SubMenu.defaultProps = {
    groupIndent: 0,
    noIcon: false,
    selectable: false,
};
