import $i18n from 'panda-i18n';
import React, { useEffect, useRef, useState } from 'react';
import { events } from '@/components/cn-utils';
import { CnBadge } from '@/components/cn-badge';
import { CnBox } from '@/components/cn-box';
import { CnLoading } from '@/components/cn-loading';
import { CnBalloon } from '../cn-balloon';
import { CnIcon } from '../cn-icon';
import { JobContainer } from './components/job-container';
import { cssPrefix, HIDE_EXPORT_LOADING, HIDE_IMPORT_LOADING, HIDE_PANEL, MARK_READ, MARK_UNREAD, RUNNING_TIP_KEY, SHOW_EXPORT_LOADING, SHOW_IMPORT_LOADING, SHOW_PANEL, SHOW_RUNNING_TIP, } from './constant';
import './index.scss';
import classNames from 'classnames';
export const CnAsyncJobs = (props) => {
    const { importProps, exportProps, className, style } = props;
    const ref = useRef();
    const [unread, setUnread] = useState(false);
    const [visible, setVisible] = useState(false);
    const [runningTip, setRunningTip] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const importPropsRef = useRef(importProps);
    useEffect(() => {
        importPropsRef.current = importProps;
    }, [importProps]);
    const exportPropsRef = useRef(exportProps);
    useEffect(() => {
        exportPropsRef.current = exportProps;
    }, [exportProps]);
    useEffect(() => {
        const cb1 = () => setImportLoading(true);
        events.on(SHOW_IMPORT_LOADING, cb1);
        const cb2 = () => setImportLoading(false);
        events.on(HIDE_IMPORT_LOADING, cb2);
        const cb3 = () => setExportLoading(true);
        events.on(SHOW_EXPORT_LOADING, cb3);
        const cb4 = () => setExportLoading(false);
        events.on(HIDE_EXPORT_LOADING, cb4);
        const cb5 = () => setUnread(true);
        events.on(MARK_UNREAD, cb5);
        const cb6 = () => setUnread(false);
        events.on(MARK_READ, cb6);
        const cb7 = () => {
            if (window.localStorage.getItem(RUNNING_TIP_KEY))
                return;
            window.localStorage.setItem(RUNNING_TIP_KEY, '1');
            setRunningTip(true);
        };
        events.on(SHOW_RUNNING_TIP, cb7);
        const cb8 = (options) => {
            var _a;
            setVisible(true);
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setTab((options === null || options === void 0 ? void 0 : options.type) === 'import' ? 'import' : 'export');
        };
        const cb9 = () => setVisible(false);
        events.on(SHOW_PANEL, cb8);
        events.on(HIDE_PANEL, cb9);
        return () => {
            events.off(SHOW_IMPORT_LOADING, cb1);
            events.off(HIDE_IMPORT_LOADING, cb2);
            events.off(SHOW_EXPORT_LOADING, cb3);
            events.off(HIDE_EXPORT_LOADING, cb4);
            events.off(MARK_UNREAD, cb5);
            events.off(MARK_READ, cb6);
            events.off(SHOW_RUNNING_TIP, cb7);
            events.off(SHOW_PANEL, cb8);
            events.off(HIDE_PANEL, cb9);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(className, `${cssPrefix}trigger`), style: style, onClick: () => setVisible(true) },
            React.createElement(CnBadge, { dot: unread },
                React.createElement(CnBalloon, { visible: runningTip, closable: false, trigger: React.createElement(CnLoading, { visible: importLoading || exportLoading, size: "medium", className: `${cssPrefix}trigger-loading` },
                        React.createElement(CnIcon, { type: "task-r-color", size: 24 })) },
                    React.createElement(CnBox, { direction: "row", spacing: 9, align: "center" },
                        React.createElement("span", null, $i18n.get({
                            id: 'TheImportAndExportTaskIs_391802359',
                            dm: '导入导出任务后台运行中',
                            ns: 'CnAsyncJobsPro',
                        })),
                        React.createElement("span", { className: `${cssPrefix}running-tip-know`, onClick: () => setRunningTip(false) }, $i18n.get({
                            id: 'IKnow',
                            dm: '我知道了',
                            ns: 'CnAsyncJobsPro',
                        })))))),
        React.createElement(JobContainer, { ref: ref, visible: visible, setVisible: setVisible, importProps: importPropsRef.current, exportProps: exportPropsRef.current })));
};
