/**
 * 保存查询习惯
 */
import { useEffect, useRef, } from 'react';
import storage from '../storage';
import { useAsyncReducer, SaveSelectedReducers, SaveSelectedActionTypes } from '../store';
import isEqual from 'lodash/isEqual';
export const useSaveSelected = ({ enableSaveSelected, values, storageKey, onGetStorage, onSetStorage, filterContext, }) => {
    const isInitStorageRef = useRef(true);
    const { currentSelectedValuesRef } = filterContext;
    const storageRef = useRef(storage({
        storageKey,
        type: 'selected',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    const [state, dispatch] = useAsyncReducer(SaveSelectedReducers, {
        options: [],
        selected: undefined,
        overlayVisible: false,
    });
    const { options } = state;
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        storageRef.current
            .getData()
            .then((data) => {
            isInitStorageRef.current = false;
            if (data) {
                dispatch({
                    type: SaveSelectedActionTypes.SetOptions,
                    payload: data,
                });
            }
        })
            .catch(() => { });
    }, [enableSaveSelected]);
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(options).catch(() => { });
    }, [options, enableSaveSelected]);
    useEffect(() => {
        if (!enableSaveSelected)
            return;
        if (currentSelectedValuesRef.current &&
            Object.keys({
                ...currentSelectedValuesRef.current,
                ...values,
            }).some((key) => {
                var _a;
                return !isEqual((_a = currentSelectedValuesRef.current) === null || _a === void 0 ? void 0 : _a[key], values === null || values === void 0 ? void 0 : values[key]);
            })) {
            currentSelectedValuesRef.current = null;
            dispatch({
                type: SaveSelectedActionTypes.SetSelected,
                payload: undefined,
            });
        }
    }, [values, enableSaveSelected]);
    return [state, dispatch];
};
