export const responsiveSize = [320, 480, 720, 990, 1200, 1500];
export const sizeColumns = [{
        xs: 2,
        small: 1,
        medium: 1,
        large: 1,
    }, {
        xs: 3,
        small: 2,
        medium: 1,
        large: 1,
    }, {
        xs: 4,
        small: 3,
        medium: 2,
        large: 1,
    }, {
        xs: 5,
        small: 4,
        medium: 3,
        large: 2,
    }, {
        xs: 6,
        small: 4,
        medium: 3,
        large: 2,
    }, {
        xs: 7,
        small: 5,
        medium: 4,
        large: 3,
    }];
