import React, { useContext } from 'react';
import classNames from 'classnames';
import { ShellStateCtx } from './context';
export default function Content(props) {
    const { children } = props;
    const { sideBarVisible, isSideBarFold } = useContext(ShellStateCtx);
    return (React.createElement("div", { className: classNames('cn-ui-shell-content', {
            stack: sideBarVisible && isSideBarFold,
        }) }, children));
}
