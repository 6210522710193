import $i18n from 'panda-i18n';
export default (value, rule) => {
    if (rule.whitespace) {
        if (/^\s+$/.test(value)) {
            return $i18n.get({
                id: 'CannotBeAPureBlankString_1539454662',
                dm: '不能为纯空白字符串',
                ns: 'CnForm',
            });
        }
    }
    return true;
};
