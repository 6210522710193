import React, { memo } from 'react';
import { computeRatio, updateScrollPosition } from './utils';
import './thumb-bar.scss';
function ThumbBar({ visible, isGlobal, trackStyle, thumbStyle, minThumbSize, start, gap, horizontal, pin, trackRef, boxSize, update, }) {
    const { CW, CH, PT, PR, PB, PL, SW, SH } = boxSize;
    const [sizeKey, offsetSize, scrollSize] = horizontal ? ['width', CW, SW] : ['height', CH, SH];
    function getContainerBox() {
        var _a, _b;
        const targetNode = (_b = (_a = trackRef.current) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.parentNode;
        return targetNode === document.body ? document.documentElement : targetNode;
    }
    function handleThumbBarClick(e) {
        const containerBox = getContainerBox();
        const { scrollLeft, scrollTop } = containerBox;
        const scrollPosition = horizontal ? scrollLeft : scrollTop;
        const rect = e.target.getBoundingClientRect();
        const clickPosition = horizontal
            ? (e.clientX - rect.left) / rect.width
            : (e.clientY - rect.top) / rect.height;
        const scrollRatio = scrollPosition / scrollSize;
        const position = clickPosition > scrollRatio
            ? Math.min(scrollSize, scrollPosition + offsetSize)
            : Math.max(0, scrollPosition - offsetSize);
        updateScrollPosition(containerBox, position, horizontal);
    }
    function handleStart(e) {
        e.stopPropagation();
        const { scrollLeft, scrollTop } = getContainerBox();
        update({
            pinX: horizontal,
            pinY: !horizontal,
            lastST: scrollTop,
            lastSL: scrollLeft,
            startX: e.clientX,
            startY: e.clientY,
        });
    }
    const style = {
        ...(isGlobal
            ? { [sizeKey]: gap > 0 ? `calc(100% - ${gap}px)` : undefined }
            : {
                [sizeKey]: offsetSize - gap,
                ...(horizontal
                    ? {
                        bottom: -PB,
                        left: -PL + start,
                    }
                    : {
                        top: PT - gap + start,
                        right: -PR,
                        transform: 'translateY(-100%)',
                    }),
            }),
        ...(trackStyle && trackStyle(horizontal)),
    };
    return (React.createElement("div", { className: `ms-track${horizontal ? ' ms-x' : ' ms-y'}${pin ? ' ms-active' : visible ? ' ms-track-show' : ''}`, onClick: handleThumbBarClick, ref: trackRef, style: style },
        React.createElement("div", { className: "ms-thumb", onMouseDown: handleStart, onClick: (e) => e.stopPropagation(), style: {
                [sizeKey]: computeRatio(scrollSize, offsetSize, gap, minThumbSize).thumbSize,
                ...(thumbStyle && thumbStyle(horizontal)),
            } })));
}
export default memo(ThumbBar);
