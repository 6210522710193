import React from 'react';
import { Classes } from './styles';
const DefaultLoadingIcon = React.memo(() => (React.createElement("svg", { style: { margin: 'auto', display: 'block', width: 40, height: 40 }, viewBox: '0 0 100 100', preserveAspectRatio: 'xMidYMid' },
    React.createElement("circle", { cx: '50', cy: '50', r: '40', fill: 'none', stroke: '#23a7fa', strokeDasharray: '188 64', strokeLinecap: 'round', strokeWidth: '10' },
        React.createElement("animateTransform", { attributeName: 'transform', dur: '1.5s', keyTimes: '0;1', repeatCount: 'indefinite', type: 'rotate', values: '0 50 50;360 50 50' })))));
function DefaultLoadingContentWrapper({ children, visible, }) {
    return (React.createElement("div", { className: 'cn-loading-content-wrapper', style: { filter: visible ? 'blur(1px)' : 'none' } }, children));
}
export default function Loading({ visible, children, LoadingContentWrapper = DefaultLoadingContentWrapper, LoadingIcon = DefaultLoadingIcon, }) {
    return (React.createElement("div", { className: Classes.loadingWrapper },
        visible && (React.createElement("div", { className: Classes.loadingIndicatorWrapper },
            React.createElement("div", { className: Classes.loadingIndicator },
                React.createElement(LoadingIcon, null)))),
        React.createElement(LoadingContentWrapper, { visible: visible }, children)));
}
