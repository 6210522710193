import * as React from 'react';
import classNames from 'classnames';
import './sub-area-card.scss';
export const CnCardSubAreaCard = ({ title, className, action, children, ...otherProps }) => {
    return (React.createElement("div", { "data-name": "CnCardSubAreaCard", className: classNames(['cn-ui-card-sub-area-card', className]), ...otherProps },
        (title || action) && (React.createElement("div", { className: "cn-ui-card-sub-area-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-area-card-title" },
                React.createElement("div", { className: "cn-ui-card-sub-area-card-title-header" }, title),
                React.createElement("div", { className: "cn-ui-card-sub-area-card-title-footer" }, action)))),
        children && (React.createElement("div", { className: "cn-ui-card-sub-area-card-footer" }, children))));
};
CnCardSubAreaCard.displayName = 'CnCardSubAreaCard';
