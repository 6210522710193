import * as React from 'react';
import cx from 'classnames';
import { Timeline as NextTimeline } from '@fusion/lib';
import "./index.scss";
const { Item: NextTimelineItem } = NextTimeline;
export const CnTimeline = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    return (React.createElement(NextTimeline, { "data-name": "CnTimeline", className: cx('cn-ui-timeline', className), ref: ref, ...others }));
});
CnTimeline.displayName = 'CnTimeline';
/**
 * @deprecated
 * 建议使用 CnTimelineItem
 */
CnTimeline.Item = NextTimelineItem;
/**
 * @deprecated
 * 建议使用 CnTimelineItem
 */
export const TimelineItem = NextTimelineItem;
export const CnTimelineItem = NextTimelineItem;
CnTimelineItem.displayName = 'CnTimelineItem';
