import { createTitle } from './title';
import { createText } from './text';
import './index.scss';
export const CnTypography = {
    Caption: createText('Caption', 'cn-ui-typography-caption'),
    BodyText1: createText('BodyText1', 'cn-ui-typography-body-text-1'),
    BodyText2: createText('BodyText2', 'cn-ui-typography-body-text-2'),
    H1: createTitle('h1'),
    H2: createTitle('h2'),
    H3: createTitle('h3'),
    H4: createTitle('h4'),
    H5: createTitle('h5'),
    H6: createTitle('h6'),
};
