import isEmpty from 'lodash/isEmpty';
export function handleMerge(props, columns) {
    const { remote } = props;
    if ('columns' in props && !isEmpty(props.columns)) {
        return columns;
    }
    const coverColumns = remote === null || remote === void 0 ? void 0 : remote.columns;
    if (Array.isArray(coverColumns)) {
        coverColumns.forEach((item) => {
            const key = (item === null || item === void 0 ? void 0 : item.dataIndex) || (item === null || item === void 0 ? void 0 : item.key);
            columns.forEach((colItem, index) => {
                const colKey = (colItem === null || colItem === void 0 ? void 0 : colItem.dataIndex) || (colItem === null || colItem === void 0 ? void 0 : colItem.key);
                if (key === colKey) {
                    // @ts-ignore
                    columns[index] = {
                        ...(colItem || {}),
                        ...(item || {}), // 覆盖能力
                    };
                }
            });
        });
    }
    return columns;
}
