import * as React from 'react';
import { useParentForm } from '@formily/react';
import { Dialog } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { CnDragTransfer } from '@/components/cn-drag-transfer';
import { defaultDragZone } from '../../const';
import { useFilterRefsContext } from '../../context';
export default ({ enableSaveSelected, dataSource, updateDataSource, setOverlayVisible, configInited, configValue, overlayVisible, gridProps, saveSelectSpan, $i18n, }) => {
    const [dragDataSource, setDragDataSource] = React.useState({});
    const filterContext = useFilterRefsContext();
    const form = useParentForm();
    React.useEffect(() => {
        const newDefault = [];
        const newMore = [];
        if (enableSaveSelected) {
            newDefault.push({
                label: $i18n.get({
                    id: 'QueryCustomaryArea',
                    dm: '查询习惯区',
                    ns: 'CnFilter',
                }),
                span: saveSelectSpan || 1,
                disabled: true,
                className: 'cn-ui-filter-config-modal-query',
            });
        }
        dataSource.forEach((i) => {
            if (i.visible) {
                newDefault.push(i);
            }
            else {
                newMore.push(i);
            }
        });
        setDragDataSource({
            default: newDefault,
            more: newMore,
        });
    }, [dataSource, enableSaveSelected, saveSelectSpan]);
    const handleConfigOk = () => {
        sendLog({
            id: 'cn-ui.cn-filter.clickConfigOkButton',
        });
        let newDataSource = [];
        newDataSource = defaultDragZone.reduce((p, { key: k }) => {
            var _a;
            return ((_a = dragDataSource === null || dragDataSource === void 0 ? void 0 : dragDataSource[k]) === null || _a === void 0 ? void 0 : _a.length)
                ? p.concat(dragDataSource[k].map((i) => ({
                    ...i,
                    visible: k === 'default',
                })))
                : p;
        }, []);
        newDataSource = newDataSource.filter((i) => !i.disabled);
        updateDataSource(newDataSource);
        setOverlayVisible(false);
    };
    return (React.createElement(Dialog, { v2: true, width: "80%", className: "cn-ui-filter-config-modal", visible: overlayVisible, onClose: () => setOverlayVisible(false), title: $i18n.get({
            id: 'configPanelTitle',
            dm: '筛选设置',
        }), closeMode: ['close', 'esc', 'mask'], onOk: handleConfigOk, onCancel: () => setOverlayVisible(false), footerActions: ['ok', 'cancel'] },
        React.createElement("div", { className: "cn-ui-filter-config-modal-info" },
            React.createElement(CnIcon, { type: "icon-info-r-fill", className: "info-icon" }),
            $i18n.get({
                id: 'OptionsBlocksInTheAreasWhere_84475068',
                dm: '已添加筛选项、未添加筛选项区域内选项块可以相互拖拽，或通过添加和移除图标按钮操作，未添加筛选项将不会出现在筛选器内',
                ns: 'CnFilter',
            })),
        React.createElement(CnDragTransfer, { dataSource: dragDataSource, onChange: (v) => {
                sendLog({
                    id: 'cn-ui.cn-filter.changeConfigItem',
                });
                setDragDataSource(v);
            }, gridProps: gridProps, dragZone: defaultDragZone })));
};
