import * as React from 'react';
import NextBadge from '@fusion/lib/badge';
export const CnBadge = (props) => {
    return React.createElement(NextBadge, { "data-name": "CnBadge", ...props });
};
CnBadge.displayName = 'CnBadge';
/**
 * @deprecated  请使用 CnBadge 替换
 */
export const Badge = CnBadge;
