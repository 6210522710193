import React from 'react';
import PropTypes from 'prop-types';
export default function withContext(Radio) {
    var _a;
    return _a = class WrappedComp extends React.Component {
            render() {
                return React.createElement(Radio, { ...this.props, context: this.context });
            }
        },
        _a.displayName = 'Radio',
        _a.contextTypes = {
            onChange: PropTypes.func,
            __group__: PropTypes.bool,
            selectedValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
            disabled: PropTypes.bool,
        },
        _a;
}
