import React, { Children, useMemo } from 'react';
import classNames from 'classnames';
import { Tab } from '@/components/fusion';
export function CnTabItem(props) {
    const { children, 
    // @ts-ignore 内部属性
    smartPadding, ...otherProps } = props;
    /**
     * 默认情况下增加内边距，以下白名单除外
     * 1. 只有一个子元素且子元素是 CnTab
     */
    const className = useMemo(() => {
        if (!smartPadding)
            return undefined;
        const temp1Count = Children.count(children);
        if (temp1Count !== 1)
            return undefined;
        let noPadding = false;
        Children.forEach(children, (child, i) => {
            var _a;
            if (i !== 0 || !React.isValidElement(child))
                return;
            // @ts-ignore 内部逻辑
            const temp1 = (_a = child.type) === null || _a === void 0 ? void 0 : _a.displayName;
            noPadding = temp1Count === 1 && temp1 === 'CnTab';
            // 下面逻辑为了兼容 lowcode
            if (temp1 === 'CnTabItem') {
                const temp2Count = Children.count(child.props.children);
                if (temp2Count === 0) {
                    noPadding = true;
                    return;
                }
                if (temp2Count > 1) {
                    noPadding = false;
                    return;
                }
                Children.forEach(child.props.children, (subChild, subI) => {
                    var _a;
                    if (subI === 0 && React.isValidElement(subChild)) {
                        // @ts-ignore 内部逻辑
                        noPadding = ((_a = subChild.type) === null || _a === void 0 ? void 0 : _a.displayName) === 'CnTab';
                    }
                });
            }
            // end
        });
        return classNames({ 'cn-ui-tab-item-padding': !noPadding });
    }, [smartPadding, children]);
    if (smartPadding) {
        return (React.createElement(Tab.Item, { ...otherProps },
            React.createElement("div", { className: className }, children)));
    }
    return React.createElement(Tab.Item, { ...otherProps }, children);
}
CnTabItem.displayName = 'CnTabItem';
CnTabItem.defaultProps = {
    maxTitleLength: 20,
};
