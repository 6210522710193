import { connect, mapProps } from '@formily/react';
import { mapSize, mapStatus } from '@/form/__builtins__';
import { Checkbox as UiCheckbox, Input as UiInput, NumberPicker as UiNumberPicker, Select as UiSelect, TimePicker2 as UiTimePicker2, DatePicker2 as UiDatePicker2, Radio as UiRadio, Transfer as UiTransfer, Range as UiRange, } from '@fusion/lib';
import { isVoidField } from '@formily/core';
const UiCheckboxGroup = UiCheckbox.Group;
export const Checkbox = connect(UiCheckbox, mapProps({
    value: 'checked',
    onInput: 'onChange',
}, mapSize));
export const CheckboxGroup = connect(UiCheckboxGroup, mapProps({
    dataSource: true,
}, mapSize));
const patchDataSource = (dataSource = []) => {
    const removeEmptyChildren = (data) => {
        const result = { ...data };
        if (!result.children || result.children.length === 0) {
            delete result.children;
        }
        else {
            result.children = result.children.map(removeEmptyChildren);
        }
        return result;
    };
    return dataSource.map(removeEmptyChildren);
};
export const Select = connect(UiSelect, mapProps((props, field) => {
    var _a;
    if (isVoidField(field)) {
        return props;
    }
    return {
        ...props,
        dataSource: patchDataSource((_a = props.dataSource) !== null && _a !== void 0 ? _a : field === null || field === void 0 ? void 0 : field.dataSource),
    };
}, mapSize, mapStatus));
const UiTextArea = UiInput.TextArea;
export const Input = connect(UiInput, mapProps(mapSize, mapStatus));
export const TextArea = connect(UiTextArea, mapProps(mapSize, mapStatus));
export const NumberPicker = connect(UiNumberPicker, mapProps(mapSize, mapStatus));
const UiRadioGroup = UiRadio.Group;
export const Radio = connect(UiRadio, mapProps({
    value: 'checked',
    onInput: 'onChange',
}, mapSize));
export const RadioGroup = connect(UiRadioGroup, mapProps({
    dataSource: true,
}, mapSize));
export const TimePicker2 = connect(UiTimePicker2, mapProps(mapSize, mapStatus));
export const RangeTimePicker = connect(UiTimePicker2.RangePicker, mapProps(mapSize, mapStatus));
export const DatePicker2 = connect(UiDatePicker2, mapProps(mapSize, mapStatus));
export const RangeDatePicker = connect(UiDatePicker2.RangePicker, mapProps(mapSize, mapStatus));
export const Transfer = connect(UiTransfer, mapProps(mapSize, mapStatus, {
    dataSource: true,
}));
export const Range = connect(UiRange, mapProps(mapSize, mapStatus));
