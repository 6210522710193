import React from 'react';
import { CnButton } from '@/components/cn-button';
import { useI18n } from 'panda-i18n';
export function CnDrawerFooter(props) {
    const { footer, onOk, onCancel, okProps, cancelProps } = props;
    const $i18n = useI18n();
    if (footer === false)
        return null;
    const { children: okChildren = $i18n.get({ id: 'Confirmation', dm: '确认' }), visible: okVisible = true, ...restOkProps } = okProps || {};
    const { children: cancelChildren = $i18n.get({ id: 'Cancel', dm: '取消' }), visible: cancelVisible = true, ...restCancelProps } = cancelProps || {};
    const hasFooter = footer || cancelVisible || okVisible;
    if (!hasFooter || footer === false)
        return null;
    return (React.createElement("div", { className: "cn-ui-drawer-footer" },
        typeof footer === 'boolean' ? null : footer,
        cancelVisible ? (React.createElement(CnButton, { className: "cn-ui-drawer-btn", onClick: onCancel, size: "medium", ...restCancelProps }, cancelChildren)) : null,
        okVisible ? (React.createElement(CnButton, { type: "primary", onClick: onOk, size: "medium", ...restOkProps }, okChildren)) : null));
}
