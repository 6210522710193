// 第三方依赖
import * as React from 'react';
import isFunction from 'lodash/isFunction';
// 其他组件
import { Dropdown } from '@/components/cn-base-table/view/dep/dropdown';
import { copyText } from '@/components/cn-base-table';
export function expandCell(props) {
    const { cellOverflowEllipsis, cellProps, cellCopyEnable } = props || {};
    if (cellOverflowEllipsis || cellProps) {
        return (rowIndex, colIndex, key, rowData) => {
            const sourceCellProps = isFunction(cellProps)
                ? // @ts-ignore
                    cellProps(rowIndex, colIndex, key, rowData)
                : {};
            const tipContent = isFunction(cellOverflowEllipsis)
                ? cellOverflowEllipsis(rowIndex, colIndex, key, rowData)
                : // @ts-ignore
                    rowData === null || rowData === void 0 ? void 0 : rowData[key];
            const cellOverflowEllipsisProps = cellOverflowEllipsis
                ? {
                    onMouseEnter(event) {
                        var _a, _b;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter(event));
                        // @ts-ignore
                        const { width } = isFunction((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect)
                            ? // @ts-ignore
                                (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()
                            : {};
                        tipContent &&
                            Dropdown.show(event, React.createElement("div", null, tipContent), {
                                hasMask: false,
                                offset: [0, (width !== null && width !== void 0 ? width : 0) / 2],
                                zIndex: 2000,
                                transform({ isTopHalf = true }) {
                                    return `translate(-50%, ${isTopHalf ? 0 : '-100'}%`;
                                },
                            });
                    },
                    onMouseLeave(event) {
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave(event));
                        Dropdown.hide();
                    },
                    className: 'cn-table-cell-ellipsis',
                }
                : {};
            const copyProps = cellCopyEnable
                ? {
                    onDoubleClick(event) {
                        var _a;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick(event));
                        // @ts-ignore
                        copyText((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.innerText, undefined, undefined);
                    },
                }
                : {};
            return Object.assign({}, cellOverflowEllipsisProps, sourceCellProps, copyProps);
        };
    }
}
