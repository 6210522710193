import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CnIcon } from '@/components/cn-icon';
import { CnBox } from '@/components/cn-box';
import { downloadFile } from '@/components/cn-utils';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { classMap, iconMap, cssPrefix } from './constant';
import { useStatusMap } from '../../hooks/use-status-map';
import JobItemRight from '../job-item-right';
import './index.scss';
export default function JobItem(props) {
    const { type, data, className } = props;
    const { jobName, createTime, status, errorDownloadUrl } = data;
    const statusMap = useStatusMap();
    return (React.createElement("div", { className: classNames(cssPrefix, classMap[status], className) },
        React.createElement("div", { className: `${cssPrefix}-content` },
            React.createElement(CnEllipsis, { className: `${cssPrefix}-content-title`, ellipsisPosition: "middle" }, jobName),
            React.createElement(CnBox, { className: `${cssPrefix}-content-bottom`, direction: "row", align: "center", spacing: 4 },
                React.createElement(CnIcon, { size: 16, type: iconMap[status] }),
                React.createElement("div", { className: `${cssPrefix}-content-status` }, statusMap[status]),
                React.createElement("div", { className: `${cssPrefix}-content-create-time` }, dayjs(createTime).format('YYYY-MM-DD HH:mm')),
                errorDownloadUrl ? (React.createElement("div", { className: `${cssPrefix}-content-failed-record`, onClick: () => downloadFile(errorDownloadUrl) }, $i18n.get({
                    id: 'FailureRecord',
                    dm: '失败记录',
                    ns: 'CnAsyncJobsPro',
                }))) : null)),
        React.createElement("div", { className: `${cssPrefix}-right` },
            React.createElement(JobItemRight, { type: type, data: data }))));
}
