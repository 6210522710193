import * as React from 'react';
import cx from 'classnames';
import { observer } from '@formily/react';
import './index.scss';
export const CnFormSingleCol = observer((props) => {
    const { className, style, children } = props;
    const containerRef = React.useRef();
    const resizeObserverRef = React.useRef();
    const [maxWidth, setMaxWidth] = React.useState('initial');
    React.useEffect(() => {
        resizeObserverRef.current = new ResizeObserver((enties) => {
            for (const oGrid of enties) {
                const width = oGrid.target.clientWidth;
                let targetMaxWidth = 'initial';
                if (width > 894 && width <= 1494) {
                    targetMaxWidth = 600;
                }
                else if (width > 1494) {
                    targetMaxWidth = 800;
                }
                setMaxWidth(targetMaxWidth);
            }
        });
        resizeObserverRef.current.observe(containerRef.current);
        return () => {
            resizeObserverRef.current.unobserve(containerRef.current);
        };
    }, []);
    const classes = cx({
        'cn-ui-form-single-col-wrapper': true,
        [className]: !!className,
    });
    return (React.createElement("div", { className: classes, style: style, ref: containerRef },
        React.createElement("div", { className: "cn-ui-form-single-col", style: {
                maxWidth,
            } }, children)));
});
