import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Drawer } from '@/components/fusion';
import { CnCard } from '@/components/cn-card';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { PandaConfigProvider } from 'panda-i18n';
import { CnDrawerFooter } from './cn-drawer-footer';
import './index.scss';
function CnDrawer(props) {
    const { children, size = 'small', footer, onOk, onCancel, okProps, cancelProps, $i18n, className, embeddable, noCard, transparencyMask, popupContainer, ...rest } = props;
    const wrapperRef = React.useRef();
    const hasCard = useCallback(() => {
        var _a;
        if (noCard) {
            return true;
        }
        if (embeddable) {
            return true;
        }
        let types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map((item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    }, [children, embeddable]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: wrapperRef, style: { display: 'none' } }),
        React.createElement(Drawer, { "data-name": "CnDrawer", wrapperClassName: classnames({
                'cn-ui-drawer-transparencyMask': transparencyMask,
            }), popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : (() => {
                var _a;
                return ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.closest('#cn-ui-page-scroll-container')) ||
                    document.body;
            }), ...rest, className: classnames({
                'cn-ui-drawer': true,
                [`cn-ui-drawer-${size}`]: true,
                [className]: !!className,
            }) },
            React.createElement(PandaConfigProvider, null,
                React.createElement(CnScrollBar, { className: "cn-ui-drawer-wrapper" },
                    React.createElement("div", { className: classnames({
                            'cn-ui-drawer-content': true,
                            'cn-ui-drawer-content-has-footer': footer,
                        }) }, hasCard() ? children : React.createElement(CnCard, null, children)),
                    React.createElement(CnDrawerFooter, { footer: footer, onOk: onOk, okProps: okProps, onCancel: onCancel, cancelProps: cancelProps }))))));
}
CnDrawer.displayName = 'CnDrawer';
export default CnDrawer;
