import * as React from 'react';
import { List as NextList } from '@/components/fusion';
import { basicsPlugin } from './plugin/basics';
import './index.scss';
import { Plugin, HocBaseComponents, pluginManager, } from '@/components/cn-utils';
export const List = React.forwardRef((props, ref) => {
    const plugin = React.useMemo(() => {
        const plugin = new Plugin('CnList', pluginManager);
        plugin.setBasicsPlugin(Object.values(basicsPlugin));
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnList')
            .map((item) => item.method));
        return plugin;
    }, []);
    const plugins = plugin.getPlugin();
    return HocBaseComponents(NextList, { props, plugins, ref });
});
List.defaultProps = {
    itemLayout: 'horizontal',
};
