export function addEventListener(target, eventType, cb, option) {
    /* eslint camelcase: 2 */
    const callback = cb;
    if (target.addEventListener) {
        target.addEventListener(eventType, callback, option);
    }
    return {
        remove: () => {
            if (target.removeEventListener) {
                target.removeEventListener(eventType, callback);
            }
        },
    };
}
export function getClientSize() {
    const width = document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;
    return {
        width,
        height,
    };
}
export const errorImage = 'https://img.alicdn.com/imgextra/i3/O1CN01QhCHhZ25FDbjoqott_!!6000000007496-2-tps-194-195.png';
