import React, { useEffect, useState } from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnMenuButton, CnMenuButtonItem } from '@/components/cn-menu-button';
import keyBy from 'lodash/keyBy';
import { langList as allLangList, defaultLangList, pandaEvent, useI18n, } from 'panda-i18n';
import './index.scss';
const allLangMap = keyBy(allLangList, 'value');
export const CnI18nSelect = ({ hasIcon, langList, lang, triggerType = 'hover', beforeChange, onChange, }) => {
    var _a;
    // get panda-i18n instance
    const $i18n = useI18n();
    // current lang state
    const [currentLang, setCurrentLang] = useState(lang || $i18n.getLang());
    // user controlled lang
    useEffect(() => {
        lang && setCurrentLang(lang);
    }, [lang]);
    // get user usable lang list
    const usedLangList = (langList === null || langList === void 0 ? void 0 : langList.map((_lang) => allLangMap[_lang] || {
        value: _lang,
        label: _lang,
    })) || defaultLangList;
    // lister lang changed then change the current lang
    useEffect(() => {
        const changeLangHandler = pandaEvent.on('CHANGE_LANG', (_lang) => {
            if (!lang) {
                setCurrentLang(_lang);
            }
        });
        return () => pandaEvent.off(changeLangHandler);
    }, []);
    return (React.createElement(CnMenuButton, { popupTriggerType: triggerType, popupProps: {
            offset: [0, 20],
        }, type: "normal", size: "small", label: React.createElement("div", { className: "cn-i18n-select-label" },
            hasIcon && React.createElement(CnIcon, { size: "small", type: "icon-international1" }),
            React.createElement("span", { "data-i18n-ignore": true }, (_a = allLangMap[currentLang]) === null || _a === void 0 ? void 0 : _a.label)) }, usedLangList.map((langObj) => {
        return (React.createElement(CnMenuButtonItem, { onClick: async () => {
                if (beforeChange && typeof beforeChange === 'function') {
                    try {
                        const result = await beforeChange(langObj.value);
                        if (result === false) {
                            return;
                        }
                    }
                    catch (err) {
                        return;
                    }
                }
                $i18n.setLang(langObj.value);
                if (onChange) {
                    onChange(langObj.value);
                }
                else {
                    location.reload();
                }
            }, key: langObj.value },
            React.createElement("span", { "data-i18n-ignore": true }, langObj.label)));
    })));
    //
    // return (
    //   <Dropdown
    //     trigger={
    //       <span className="cn-i18n-select" aria-haspopup="true">
    //         {allLangMap[currentLang]?.alias ||
    //           currentLang.substring(0, 1).toUpperCase() +
    //             currentLang.substring(1, 2).toLowerCase()}
    //       </span>
    //     }
    //     offset={[0, 20]}
    //     triggerType={triggerType}
    //   >
    //     <Menu className="cn-i18n-select-menu" selectedKeys={$i18n.getLang()}>
    //       {usedLangList.map((langObj) => {
    //         return (
    //           <Menu.Item
    //             onClick={async () => {
    //               if (beforeChange && typeof beforeChange === 'function') {
    //                 try {
    //                   const result = await beforeChange(langObj.value);
    //                   if (result === false) {
    //                     return
    //                   }
    //                 } catch (err) {
    //                   return
    //                 }
    //               }
    //               $i18n.setLang(langObj.value);
    //               if (onChange) {
    //                 onChange(langObj.value);
    //               } else {
    //                 location.reload();
    //               }
    //             }}
    //             key={langObj.value}
    //           >
    //             {langObj.label}
    //           </Menu.Item>
    //         );
    //       })}
    //     </Menu>
    //   </Dropdown>
    // );
};
CnI18nSelect.defaultProps = {
    hasIcon: true,
};
