import * as React from 'react';
import { Range as NextRange } from '@/components/fusion';
import { CnReadOnly } from '@/components/cn-read-only';
const NextRangeWithPreview = NextRange;
export const CnRange = React.forwardRef((props, ref) => {
    const { readOnly, readOnlyProps, ...otherProps } = props;
    const renderPreview = (value) => {
        return (React.createElement(CnReadOnly, { value: value, type: "string", ...readOnlyProps }));
    };
    return (React.createElement(NextRangeWithPreview, { "data-name": "CnRange", ref: ref, isPreview: readOnly, renderPreview: renderPreview, ...otherProps }));
});
CnRange.displayName = 'CnRange';
