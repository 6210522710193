export const cssPrefix = 'cn-ui-async-jobs-pro-';
export const RUNNING_TIP_KEY = 'CnAsyncJobsPro.RunningTip';
/** 标记有未读任务 */
export const MARK_UNREAD = 'CnAsyncJobsPro.markUnread';
/** 标记任务已读 */
export const MARK_READ = 'CnAsyncJobsPro.markRead';
/** 导入任务是否进行中 */
export const SHOW_IMPORT_LOADING = 'CnAsyncJobsPro.showImportLoading';
/** 导入任务是否处理结束 */
export const HIDE_IMPORT_LOADING = 'CnAsyncJobsPro.hideImportLoading';
/** 导出任务是否进行中 */
export const SHOW_EXPORT_LOADING = 'CnAsyncJobsPro.showExportLoading';
/** 导出任务是否处理结束 */
export const HIDE_EXPORT_LOADING = 'CnAsyncJobsPro.hideExportLoading';
/** 显示任务运行中引导 */
export const SHOW_RUNNING_TIP = 'CnAsyncJobsPro.showRunningTip';
/** 显示任务面板 */
export const SHOW_PANEL = 'CnAsyncJobsPro.showPanel';
/** 隐藏任务面板 */
export const HIDE_PANEL = 'CnAsyncJobsPro.hidePanel';
/** 轮询导出任务 */
export const LOOPING_EXPORT_JOB = 'CnAsyncJobsPro.loopingExportJob';
/** 轮询导入任务 */
export const LOOPING_IMPORT_JOB = 'CnAsyncJobsPro.loopingImportJob';
