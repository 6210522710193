// 导入组件名称
import { componentName } from './const';
// 导入视图组件和类型
import View from './view';
// 导入本地化文件
import locale from '@/locales';
// 导入国际化高阶组件
import { withI18n } from 'panda-i18n';
// 导出视图组件和类型
export * from './view';
export * from './type';
// 使用国际化高阶组件包装视图组件
const Table = withI18n(View, {
    componentName,
    locale,
});
// 设置组件显示名称
Table.displayName = componentName;
// 导出默认组件
export default Table;
