import { connect, mapProps } from '@formily/react';
import { CnDatePicker2 as UiCnDatePicker2, CnMonthPicker2 as UiCnMonthPicker2, CnYearPicker2 as UiCnYearPicker2, CnWeekPicker2 as UiCnWeekPicker2, CnQuarterPicker2 as UiCnQuarterPicker2, CnRangeDatePicker2 as UiCnRangeDatePicker2, CnDatePickerPro as UiCnDatePickerPro, CnMonthPickerPro as UiCnMonthPickerPro, CnYearPickerPro as UiCnYearPickerPro, CnWeekPickerPro as UiCnWeekPickerPro, CnQuarterPickerPro as UiCnQuarterPickerPro, CnRangeDatePickerPro as UiCnRangeDatePickerPro, } from '@/components/cn-date-picker-pro';
import { mapSize, mapStatus } from '@/form/__builtins__';
/**
 * @deprecated 请使用 CnDatePickerPro
 */
export const CnDatePicker2 = connect(UiCnDatePicker2, mapProps(mapSize, mapStatus));
/**
* @deprecated 请使用 CnMonthPickerPro
*/
export const CnMonthPicker2 = connect(UiCnMonthPicker2, mapProps(mapSize, mapStatus));
/**
* @deprecated 请使用 CnYearPickerPro
*/
export const CnYearPicker2 = connect(UiCnYearPicker2, mapProps(mapSize, mapStatus));
/**
 * @deprecated 请使用 CnWeekPickerPro
 */
export const CnWeekPicker2 = connect(UiCnWeekPicker2, mapProps(mapSize, mapStatus));
/**
 * @deprecated 请使用 CnQuarterPickerPro
 */
export const CnQuarterPicker2 = connect(UiCnQuarterPicker2, mapProps(mapSize, mapStatus));
/**
 * @deprecated 请使用 CnRangeDatePickerPro
 */
export const CnRangeDatePicker2 = connect(UiCnRangeDatePicker2, mapProps(mapSize, mapStatus));
export const CnDatePickerPro = connect(UiCnDatePickerPro, mapProps(mapSize, mapStatus));
export const CnMonthPickerPro = connect(UiCnMonthPickerPro, mapProps(mapSize, mapStatus));
export const CnYearPickerPro = connect(UiCnYearPickerPro, mapProps(mapSize, mapStatus));
export const CnWeekPickerPro = connect(UiCnWeekPickerPro, mapProps(mapSize, mapStatus));
export const CnQuarterPickerPro = connect(UiCnQuarterPickerPro, mapProps(mapSize, mapStatus));
export const CnRangeDatePickerPro = connect(UiCnRangeDatePickerPro, mapProps(mapSize, mapStatus));
