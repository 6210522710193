import * as React from 'react';
import cx from 'classnames';
import { handleFormat } from './util';
export class CnCurrencyAmountNumberPickerReadOnly extends React.Component {
    render() {
        const { amount, currency, precision, format, hasHighlight, className } = this.props;
        const hasValue = amount || amount === 0;
        const readOnlyCls = cx({
            'cn-ui-currency-amount-number-picker-readonly': true,
            'amount-light': !!hasHighlight,
        }, className);
        if (!hasValue) {
            return React.createElement("div", { className: "cn-ui-currency-amount-number-picker-readonly" }, "-");
        }
        return (React.createElement("div", { className: readOnlyCls },
            React.createElement("div", { className: "amount-readonly" }, handleFormat(amount, precision, format)),
            currency && React.createElement("div", { className: "currency-readonly" }, currency)));
    }
}
