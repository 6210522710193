/**
 * 限制一个数值在一个区间内
 * @param {number} min - 区间下限
 * @param {number} x - 待限制的数值
 * @param {number} max - 区间上限
 * @returns {number} - 限制后的数值
 */
export function clamp(min, x, max) {
    return Math.max(min, Math.min(max, x));
}
/**
 * 在元素的祖先节点中查找第一个满足条件的节点
 * @param {HTMLElement} el - 待查找的元素
 * @param {Function} match - 匹配函数，返回 true 表示找到目标节点
 * @returns {HTMLElement} - 第一个满足条件的祖先节点，若未找到则返回 null
 */
export function findTarget(el, match) {
    let current = el;
    while (current) {
        if (match(current)) {
            break;
        }
        current = current.parentElement;
    }
    return current;
}
