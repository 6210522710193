import * as React from 'react';
import cx from 'classnames';
export const Text = (props) => {
    const { prefix, className, component, strong, underline, delete: deleteProp, code, mark, children, ...others } = props;
    const Tag = component;
    let displayChildren = children;
    if (strong) {
        displayChildren = React.createElement("strong", null, children);
    }
    if (underline) {
        displayChildren = React.createElement("u", null, children);
    }
    if (deleteProp) {
        displayChildren = React.createElement("del", null, children);
    }
    if (code) {
        displayChildren = React.createElement("code", null, children);
    }
    if (mark) {
        displayChildren = React.createElement("mark", null, children);
    }
    return (React.createElement(Tag, { "data-name": "CnTypography", ...others, className: `${className || ''} cn-ui-typography` }, displayChildren));
};
Text.defaultProps = {
    delete: false,
    mark: false,
    underline: false,
    strong: false,
    code: false,
    component: 'div',
};
export const createText = (displayName, className) => {
    class Title extends React.Component {
        render() {
            const { className: propsClassName, ...others } = this.props;
            const classes = cx(className, propsClassName);
            return React.createElement(Text, { ...others, className: classes });
        }
    }
    Title.displayName = displayName;
    return Title;
};
