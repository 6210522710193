import $i18n from 'panda-i18n';
import { observer, RecursionField, useField, useFieldSchema, } from '@formily/react';
import { CnIcon } from '@/components/cn-icon';
import React, { useCallback, useRef } from 'react';
import { isArr, isBool } from '@formily/shared';
import { Table as FusionTable } from '@fusion/lib';
import Table from 'rc-table';
import { ArrayBase } from '@/form/array-base';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import isNil from 'lodash/isNil';
import { CnArrayTablePagination } from './cn-array-table-pagination';
import { isAdditionComponent, isColumnComponent, isOperationsComponent, } from './utils';
import './cn-array-table.scss';
import { CnTooltip } from '@/components/cn-tooltip';
const useAddition = () => {
    const schema = useFieldSchema();
    return schema.mapProperties((schema, key) => {
        return React.createElement(RecursionField, { schema: schema, name: key });
    });
};
const omit = (props, keys) => {
    return Object.keys(props)
        .filter((key) => !(keys === null || keys === void 0 ? void 0 : keys.includes(key)))
        .reduce((buf, key) => {
        buf[key] = props[key];
        return buf;
    }, {});
};
const useArrayTableSources = () => {
    const arrayField = useField();
    const schema = useFieldSchema();
    const parseSources = (schema) => {
        var _a, _b, _c;
        if (isColumnComponent(schema) ||
            isOperationsComponent(schema) ||
            isAdditionComponent(schema)) {
            if (!((_a = schema['x-component-props']) === null || _a === void 0 ? void 0 : _a.dataIndex) && !schema.name)
                return [];
            const name = ((_b = schema['x-component-props']) === null || _b === void 0 ? void 0 : _b.dataIndex) || schema.name;
            const field = arrayField.query(arrayField.address.concat(name)).take();
            const columnProps = ((_c = field === null || field === void 0 ? void 0 : field.component) === null || _c === void 0 ? void 0 : _c[1]) || schema['x-component-props'] || {};
            const display = (field === null || field === void 0 ? void 0 : field.display) || schema['x-display'];
            return [
                {
                    name,
                    display,
                    field,
                    schema,
                    columnProps,
                },
            ];
        }
        else if (schema.properties) {
            return schema.reduceProperties((buf, schema) => {
                return buf.concat(parseSources(schema));
            }, []);
        }
    };
    const parseArrayItems = (schema) => {
        if (!schema)
            return [];
        const sources = [];
        const items = isArr(schema) ? schema : [schema];
        return items.reduce((columns, schema) => {
            const item = parseSources(schema);
            if (item) {
                return columns.concat(item);
            }
            return columns;
        }, sources);
    };
    return parseArrayItems(schema.items);
};
const getValidatorHasRequired = (validatorSchema) => {
    // 兼容 [{required: true}]
    if (Array.isArray(validatorSchema)) {
        const hasItem = validatorSchema.find((item) => item.required);
        return !!hasItem;
    }
    // 兼容 {required: true}
    if (validatorSchema && validatorSchema.required) {
        return true;
    }
    return false;
};
const findPathIndex = (value, record) => {
    if (!value || !value.length)
        return;
    const traverse = (v, r) => {
        var _a;
        for (let i = 0; i < v.length; i++) {
            if (v[i] === r) {
                return i;
            }
            else if ((_a = v[i].children) === null || _a === void 0 ? void 0 : _a.length) {
                const path = traverse(v[i].children, r);
                if (!isNil(path)) {
                    return `${i}.children.${path}`;
                }
            }
        }
    };
    return traverse(value, record);
};
const useArrayTableColumns = (field, sources, isRcTable) => {
    return sources.reduce((buf, { name, columnProps, schema, display }, key) => {
        if (display !== 'visible')
            return buf;
        if (!isColumnComponent(schema))
            return buf;
        const tableColumnTitleRender = () => {
            var _a, _b;
            const itemSchema = (_a = Object.values((schema === null || schema === void 0 ? void 0 : schema.properties) || {})[0]) === null || _a === void 0 ? void 0 : _a.toJSON();
            return (React.createElement("div", { className: "cn-ui-formily-array-table-column-title" },
                ((itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema.required) ||
                    getValidatorHasRequired(itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema['x-validator'])) && (React.createElement("span", { className: "cn-ui-formily-array-table-column-title-header" }, "*")),
                React.createElement("span", { className: "cn-ui-formily-array-table-column-title-header-body" }, columnProps === null || columnProps === void 0 ? void 0 : columnProps.title),
                ((_b = itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema['x-decorator-props']) === null || _b === void 0 ? void 0 : _b.tip) && (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { className: "cn-ui-formily-array-table-column-title-footer", type: "help", size: "small" }) }, itemSchema['x-decorator-props'].tip))));
        };
        const column = {
            ...columnProps,
            key,
            title: tableColumnTitleRender(),
            dataIndex: name,
        };
        if (isRcTable) {
            column.render = (value, record) => {
                var _a;
                const index = (_a = field.value) === null || _a === void 0 ? void 0 : _a.indexOf(record);
                const findPath = findPathIndex(field.value, record);
                const children = (React.createElement(ArrayBase.Item, { key: index, index: index, record: () => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(RecursionField, { schema: schema, name: findPath, onlyRenderProperties: true })));
                return children;
            };
        }
        else {
            column.cell = (value, _, record) => {
                var _a;
                const index = (_a = field.value) === null || _a === void 0 ? void 0 : _a.indexOf(record);
                const children = (React.createElement(ArrayBase.Item, { key: index, index: index, record: () => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(RecursionField, { schema: schema, name: index })));
                return children;
            };
        }
        return buf.concat(column);
    }, []);
};
const SortableRow = SortableElement((props) => React.createElement("tr", { ...props }));
const SortableBody = SortableContainer((props) => React.createElement("tbody", { ...props }));
const RowComp = (props) => {
    return React.createElement(SortableRow, { index: props['data-row-key'] || 0, ...props });
};
export const CnArrayTable = observer((props) => {
    var _a;
    const ref = useRef();
    const field = useField();
    const dataSource = Array.isArray(field.value) ? field.value.slice() : [];
    const sources = useArrayTableSources();
    const isRcTable = (_a = props.isRcTable) !== null && _a !== void 0 ? _a : true;
    const columns = useArrayTableColumns(field, sources, isRcTable);
    const pagination = isBool(props.pagination) ? {} : props.pagination;
    const addition = useAddition();
    const prefixCls = 'cn-ui-formily-array-table';
    const defaultRowKey = (record) => {
        // return dataSource.indexOf(record);
        return findPathIndex(dataSource, record);
    };
    const addTdStyles = (node) => {
        const helper = document.body.querySelector(`.${prefixCls}-sort-helper`);
        if (helper) {
            const tds = node.querySelectorAll('td');
            requestAnimationFrame(() => {
                helper.querySelectorAll('td').forEach((td, index) => {
                    if (tds[index]) {
                        td.style.width = getComputedStyle(tds[index]).width;
                    }
                });
            });
        }
    };
    const WrapperComp = useCallback((props) => (React.createElement(SortableBody, { useDragHandle: true, lockAxis: "y", helperClass: `${prefixCls}-sort-helper`, helperContainer: () => {
            var _a;
            return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.querySelector('tbody');
        }, onSortStart: ({ node }) => {
            addTdStyles(node);
        }, onSortEnd: ({ oldIndex, newIndex }) => {
            field.move(oldIndex, newIndex);
        }, ...props })), []);
    const renderTableComp = (dataSource) => {
        if (isRcTable) {
            return (React.createElement(Table, { prefixCls: prefixCls, rowKey: defaultRowKey, emptyText: () => (React.createElement("div", { className: `${prefixCls}-empty` }, $i18n.get({
                    id: 'NoData',
                    dm: '无数据',
                    ns: 'CnForm',
                }))), ...props, columns: columns, data: dataSource, components: {
                    body: {
                        wrapper: WrapperComp,
                        row: RowComp,
                    },
                } }));
        }
        return (React.createElement(FusionTable, { emptyContent: $i18n.get({
                id: 'NoData',
                dm: '无数据',
                ns: 'CnForm',
            }), size: "small", ...omit(props, ['value', 'onChange', 'pagination']), columns: columns, dataSource: dataSource }));
    };
    return (React.createElement(CnArrayTablePagination, { "data-name": "CnArrayTable", ...pagination, dataSource: dataSource }, (dataSource, pager) => (React.createElement("div", { ref: ref, className: "cn-ui-array-table" },
        React.createElement(ArrayBase, null,
            renderTableComp(dataSource),
            React.createElement("div", { style: { marginTop: 5, marginBottom: 5 } }, pager),
            sources.map((column, key) => {
                // 专门用来承接对Column的状态管理
                if (!isColumnComponent(column.schema))
                    return;
                return React.createElement(RecursionField, {
                    name: column.name,
                    schema: column.schema,
                    onlyRenderSelf: true,
                    key,
                });
            }),
            addition)))));
});
CnArrayTable.displayName = 'CnArrayTable';
CnArrayTable.Column = Table.Column;
CnArrayTable.ColumnGroup = Table.ColumnGroup;
CnArrayTable.Summary = Table.Summary;
