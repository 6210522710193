import { connect, mapProps } from '@formily/react';
import { CnCheckbox as UiCnCheckbox, CnCheckboxGroup as UiCnCheckboxGroup, } from '@/components/cn-checkbox';
import { mapSize, mapStatus } from '@/form/__builtins__';
export const CnCheckbox = connect(UiCnCheckbox, mapProps(mapSize, {
    value: 'checked',
    onInput: 'onChange',
}));
export const CnCheckboxGroup = connect(UiCnCheckboxGroup, mapProps(mapSize, mapStatus, {
    dataSource: true,
}));
