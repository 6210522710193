import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import CnDynamicFormView from './view';
import { componentName } from './const';
export * from './view';
const CnDynamicForm = withI18n(CnDynamicFormView, {
    componentName,
    locale,
});
CnDynamicForm.displayName = componentName;
export { CnDynamicForm };
