import * as React from 'react';
import $i18n from 'panda-i18n';
import { useControllableValue } from 'ahooks';
import { useCnRequest, useNextLocale } from '@/components/cn-utils';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import { FormTree } from '@/components/cn-async-tree/tree';
import { CnCheckbox } from '@/components/cn-checkbox';
import NextTransfer from './transfer/transfer';
import { getAllCheckedKeys, filterChildKey, filterParentKey, } from '../cn-tree/view/util';
import { getCheckAllValues } from '@/components/cn-tree-select/utils';
import cx from 'classnames';
import './index.scss';
export const CnTransfer = React.forwardRef((props, ref) => {
    var _a, _b;
    const { className, requestConfig, isTree, treeCheckedStrategy, ...otherProps } = props;
    const locale = useNextLocale('Transfer');
    const [selected, setSelected] = useControllableValue(props);
    const [treeDataSource, setTreeDataSource] = React.useState([]);
    const [treeChanged, setTreeChanged] = React.useState(1);
    const transferRef = React.useRef(null);
    const treeRef = React.useRef(null);
    const insertTransferProps = {};
    const coverProps = {};
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    const { run, data, mutate } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sendRequest: run,
            ...((_a = transferRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    }, [transferRef]);
    insertTransferProps.dataSource = data || [];
    // onSearch 自动包装
    // 仅在 showSearch 且远程请求情况启用
    const enableOnSearch = otherProps.showSearch && !otherProps.onSearch && isRemoteDataSource;
    if (enableOnSearch) {
        insertTransferProps.onSearch = (inputValue) => {
            run({
                [requestConfig.searchKey || 'key']: inputValue,
            });
        };
        insertTransferProps.filter = () => true;
    }
    const makeDisabled = (dataSource = [], selected = []) => {
        var _a, _b;
        if (!((_b = (_a = treeRef === null || treeRef === void 0 ? void 0 : treeRef.current) === null || _a === void 0 ? void 0 : _a.datasetRef) === null || _b === void 0 ? void 0 : _b.current))
            return dataSource;
        const { _k2n, _p2n, _v2n } = treeRef.current.datasetRef.current;
        const keys = treeRef.current.getKeysByValue(selected);
        const allCheckedKeys = getAllCheckedKeys(keys, _k2n, _p2n);
        const allCheckedValues = treeRef.current.getValueByKeys(allCheckedKeys);
        const loop = (ds) => {
            return ds.map((i) => {
                const newItem = {
                    ...i,
                    disabled: allCheckedValues.includes(i.value),
                };
                if (i.children) {
                    newItem.children = loop(i.children);
                }
                return newItem;
            });
        };
        const newDs = loop(dataSource);
        return loop(newDs);
    };
    const originTreeDataSource = (_a = otherProps.dataSource) !== null && _a !== void 0 ? _a : insertTransferProps.dataSource;
    React.useEffect(() => {
        if (!isTree)
            return;
        setTreeDataSource(originTreeDataSource);
        setTreeChanged((s) => (s + 1) % 32);
    }, [originTreeDataSource, selected]);
    React.useEffect(() => {
        if (!isTree)
            return;
        // 树渲染完之后才有正确的dataset
        setTreeDataSource(makeDisabled(originTreeDataSource, selected));
    }, [treeChanged]);
    const transferDataSource = React.useMemo(() => {
        var _a, _b;
        if (!isTree)
            return [];
        if (!((_b = (_a = treeRef === null || treeRef === void 0 ? void 0 : treeRef.current) === null || _a === void 0 ? void 0 : _a.datasetRef) === null || _b === void 0 ? void 0 : _b.current))
            return [];
        let rst = [];
        const flatten = (list = []) => {
            list.forEach((item) => {
                rst.push(item);
                flatten(item.children);
            });
        };
        flatten(originTreeDataSource);
        const { _k2n, _p2n, _v2n } = treeRef.current.datasetRef.current;
        const allKeys = treeRef.current.getKeysByValue(rst.map((i) => i.value));
        rst = rst.filter((i) => {
            let newKeys;
            let newValues;
            switch (treeCheckedStrategy) {
                case 'parent':
                    // newKeys = filterChildKey(allKeys, _k2n, _p2n);
                    // newValues = treeRef.current.getValueByKeys(newKeys);
                    // return newValues.includes(i.value);
                    return true;
                case 'child':
                    newKeys = filterParentKey(allKeys, _k2n);
                    newValues = treeRef.current.getValueByKeys(newKeys);
                    return newValues.includes(i.value);
                default:
                    return true;
            }
        });
        return rst;
    }, [originTreeDataSource, treeDataSource]);
    // 获取处理后的全选值
    const getRealCheckAllValues = () => {
        var _a;
        if (!treeRef.current)
            return [];
        let checkAllValues = getCheckAllValues((_a = treeRef.current) === null || _a === void 0 ? void 0 : _a.getTreeChildren());
        let keys = treeRef.current.getKeysByValue(checkAllValues);
        const { _k2n, _p2n, _v2n } = treeRef.current.datasetRef.current;
        keys = getAllCheckedKeys(keys, _k2n, _p2n);
        keys = filterChildKey(keys, _k2n, _p2n);
        checkAllValues = treeRef.current.getValueByKeys(keys);
        return [...checkAllValues];
    };
    const handleClickTreeCheckAll = (checked, onChange, position) => {
        if (!treeRef.current) {
            return;
        }
        if (checked) {
            onChange(position, getRealCheckAllValues());
        }
        else {
            onChange(position, []);
        }
    };
    const renderTreeFooter = ({ position, showCheckAll, disabled, onChange, value, }) => {
        // 仅仅在 checkStrategy === parent 时无法确定 transfer dataSource,因此需要单独处理全选功能
        if (!showCheckAll || treeCheckedStrategy !== 'parent')
            return null;
        if (position !== 'left')
            return null;
        const allChecked = value &&
            !!value.length &&
            isEqual(sortBy(value), sortBy(getRealCheckAllValues()));
        return (React.createElement(CnCheckbox, { checked: allChecked, onChange: (checked) => {
                handleClickTreeCheckAll(checked, onChange, position);
            }, label: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnTreeSelect' }) }));
    };
    let treeProps = {};
    if (isTree) {
        coverProps.dataSource = transferDataSource;
        coverProps.footerRender = renderTreeFooter;
        const showSearch = otherProps.showSearch;
        if (showSearch && (showSearch[0] || typeof showSearch === 'boolean')) {
            coverProps.showSearch = isArray(showSearch)
                ? [false, showSearch[1]]
                : [false, true];
            treeProps.showSearch = true;
        }
    }
    const renderContent = ({ position, onChange, value }) => {
        if (!isTree)
            return null;
        if (position !== 'left')
            return null;
        return (React.createElement("div", { className: "cn-ui-transfer-tree" },
            React.createElement(FormTree, { ref: treeRef, checkable: true, value: value, onChange: (v) => {
                    onChange(position, v);
                }, dataSource: treeDataSource, checkedStrategy: treeCheckedStrategy, ...treeProps })));
    };
    return (React.createElement(NextTransfer, { className: cx(className, 'cn-ui-transfer'), ref: transferRef, "data-name": "CnTransfer", locale: locale, ...insertTransferProps, ...omit(otherProps, ['$i18n']), ...coverProps, value: selected, onChange: setSelected }, (_b = otherProps.children) !== null && _b !== void 0 ? _b : ((props) => renderContent(props))));
});
CnTransfer.displayName = 'CnTransfer';
CnTransfer.defaultProps = {
    requestConfig: {},
    treeCheckedStrategy: 'all',
};
