import { cssPrefix as _cssPrefix } from '../../constant';
export const cssPrefix = `${_cssPrefix}item`;
export const classMap = {
    EXECUTING: `${cssPrefix}-running`,
    SUCCEEDED: `${cssPrefix}-success`,
    FAILED: `${cssPrefix}-failed`,
    SOME: `${cssPrefix}-some`,
};
export const iconMap = {
    EXECUTING: 'pie-chart-fill',
    SUCCEEDED: 'complete-r-fill',
    FAILED: 'error-r-fill',
    SOME: 'exclamation-t-fill',
};
