import * as React from 'react';
import { withI18n } from 'panda-i18n';
import { Switch as NextSwitch } from '@fusion/lib';
import cx from 'classnames';
import locale from '@/locales';
import './index.scss';
export const CnSwitch = withI18n(React.forwardRef((props, ref) => {
    const { className, readOnly, disabled, $i18n, ...others } = props;
    return (React.createElement(NextSwitch, { "data-name": "CnSwitch", ref: ref, className: cx({
            'cn-ui-switch': true,
            'cn-ui-switch-readonly': !!readOnly,
        }, className), disabled: readOnly || disabled, ...others }));
}), {
    locale,
    componentName: 'CnSwitch',
    forwardRef: true,
});
CnSwitch.displayName = 'CnSwitch';
