import * as React from 'react';
import { AnchorLink } from './anchor-link';
import './index.scss';
/**
 * 将原本的嵌套 dataSource 转换为扁平
 * 并直接将 focus 状态等计算好附加上
 * @param menuList 目标扁平列表，直接做修改
 * @param props 组件属性
 * @param level 锚点嵌套等级
 */
const getMenu = (props, level) => {
    const drill = (menuList, props, level, padding = 0) => {
        const { onSelect, dataSource, value } = props;
        dataSource.forEach((item) => {
            const isFocus = value === item.htmlId;
            menuList.push({
                level,
                label: item.label,
                htmlId: item.htmlId,
                icon: item.icon,
                padding,
                isFocus,
                onSelect,
            });
            if (Array.isArray(item.children)) {
                drill(menuList, { ...props, dataSource: item.children }, level + 1, padding + (item.icon ? 20 : 0));
            }
        });
    };
    const menu = [];
    drill(menu, props, level);
    return menu;
};
export const AnchorList = (props) => {
    const { showRoot } = props;
    const [menuList, setMenuList] = React.useState(getMenu(props, 1));
    const firstFocusIdx = menuList.findIndex((item) => item.isFocus);
    React.useEffect(() => {
        setMenuList(getMenu(props, 1));
    }, [props.dataSource, props.value]);
    return (React.createElement("div", { className: "cn-ui-anchor-menu" },
        React.createElement("div", { className: "cn-ui-anchor-menu-header", style: {
                top: (firstFocusIdx + (showRoot ? 1 : 0)) * (18 + 16) + 16,
                display: firstFocusIdx === -1 ? 'none' : 'block',
            } }),
        React.createElement("div", { className: "cn-ui-anchor-menu-footer" },
            showRoot && React.createElement("div", { className: "cn-ui-anchor-menu-root" }, "\u76EE\u5F55"),
            menuList.map((item) => (React.createElement(AnchorLink, { key: `${item.label}-${item.htmlId}`, ...item }))))));
};
