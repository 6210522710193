import { useCallback } from 'react';
import { getFileIndex } from '../../../utils';
export const useRemoveFile = (props) => {
    const { setValue, onChangeRef, onRemoveRef } = props;
    return useCallback((_file) => {
        var _a, _b;
        let value = [];
        let file = null;
        setValue((prev) => {
            const fileIndex = getFileIndex(_file, prev);
            file = prev[fileIndex];
            value = prev.filter((_, index) => fileIndex !== index);
            return value;
        });
        (_a = onChangeRef.current) === null || _a === void 0 ? void 0 : _a.call(onChangeRef, value, file);
        (_b = onRemoveRef === null || onRemoveRef === void 0 ? void 0 : onRemoveRef.current) === null || _b === void 0 ? void 0 : _b.call(onRemoveRef, file);
    }, [setValue, onRemoveRef, onChangeRef]);
};
