import $i18n from 'panda-i18n';
import React from 'react';
import { CnCheckbox } from '@/components/cn-checkbox';
import uniqBy from 'lodash/uniqBy';
import isNil from 'lodash/isNil';
import { rowSelectionSymbol } from '../global';
import { flattenDeepChildren } from '@/components/cn-utils';
import { arrayUtils, getFunctionVal, isPlainObject, mergeCellProps, safeGetRowKey, } from '../utils';
export const stateKey = 'multiSelect';
export function getSelectedByPipeline(pipeline) {
    var _a;
    return ((_a = pipeline.getStateAtKey(stateKey)) === null || _a === void 0 ? void 0 : _a.lastKey) || [];
}
// @ts-ignore
const defaultIsDisabled = (row) => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.disabled) !== null && _a !== void 0 ? _a : false; };
export function columnsMultiSelectPipeline(opts = {}) {
    return function multiSelectStep(pipeline) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const primaryKey = pipeline.ensurePrimaryKey(stateKey);
        const dataSource = flattenDeepChildren(pipeline.getDataSource());
        // @ts-ignore
        const isDisabled = (_a = opts.isDisabled) !== null && _a !== void 0 ? _a : defaultIsDisabled;
        const clickArea = (_b = opts.clickArea) !== null && _b !== void 0 ? _b : 'checkbox';
        let selectedRowKeys = (_f = (_d = (_c = opts.selectedRowKeys) !== null && _c !== void 0 ? _c : opts.value) !== null && _d !== void 0 ? _d : (_e = pipeline.getStateAtKey(stateKey)) === null || _e === void 0 ? void 0 : _e.selectedRowKeys) !== null && _f !== void 0 ? _f : opts.defaultValue;
        selectedRowKeys = Array.isArray(selectedRowKeys) ? selectedRowKeys : [];
        const selectedRowRecords = (_h = (_g = (dataSource || [])).filter) === null || _h === void 0 ? void 0 : _h.call(_g, (row, rowIndex) => selectedRowKeys.includes(safeGetRowKey(primaryKey, row, rowIndex)));
        const lastKey = (_m = (_l = (_j = opts.lastKey) !== null && _j !== void 0 ? _j : (_k = pipeline.getStateAtKey(stateKey)) === null || _k === void 0 ? void 0 : _k.lastKey) !== null && _l !== void 0 ? _l : opts.defaultLastKey) !== null && _m !== void 0 ? _m : '';
        const onChange = (nextValue, key, keys, action) => {
            var _a, _b, _c, _d, _e, _f;
            if (['check', 'uncheck'].includes(action) &&
                opts.onSelect instanceof Function) {
                opts.onSelect(action === 'check', key, nextValue);
            }
            if (['check-all', 'uncheck-all'].includes(action) &&
                opts.onSelectAll instanceof Function) {
                opts.onSelectAll(action === 'check-all', nextValue);
            }
            // 获取当前选中的记录
            const selectedRecords = (_b = (_a = pipeline.getStateAtKey(stateKey)) === null || _a === void 0 ? void 0 : _a.selectedRecords) !== null && _b !== void 0 ? _b : [];
            // 获取下一次选中的记录
            const nextRecords = (_d = (_c = (dataSource || [])).filter) === null || _d === void 0 ? void 0 : _d.call(_c, (row, rowIndex) => nextValue.includes(safeGetRowKey(primaryKey, row, rowIndex)));
            let nextSelectedRecords = [];
            if (opts.crossPageEmpty) {
                // 是否跨页保存信息
                (_e = opts.onChange) === null || _e === void 0 ? void 0 : _e.call(opts, nextValue, nextRecords, key, keys, action); // 调用onChange回调
            }
            else {
                // 用全量的nextValue在当前的dataSource中找到records加入存储
                // 因为每次都是全量的nextValue 所以当前页数据会和存储数据出现重复
                const fullSelectedRecords = nextRecords === null || nextRecords === void 0 ? void 0 : nextRecords.concat(selectedRecords); // 全量选中的记录
                // 根据primaryKey去重保持数据唯一
                const uniqSelectedRecords = uniqBy(fullSelectedRecords, primaryKey); // 去重后的选中记录
                // 全量数据根据nextValue过滤保持数据一致性
                nextSelectedRecords = nextValue
                    .map((value) => {
                    return uniqSelectedRecords === null || uniqSelectedRecords === void 0 ? void 0 : uniqSelectedRecords.find((item) => !isNil(item) && safeGetRowKey(primaryKey, item, null) === value);
                })
                    .filter((item) => !isNil(item)); // 下一次选中的记录
                // takeNextSelectedRecords(fullSelectedRecords, primaryKey);
                (_f = opts.onChange) === null || _f === void 0 ? void 0 : _f.call(opts, nextValue, nextSelectedRecords, key, keys, action); // 调用onChange回调
            }
            pipeline.setStateAtKey(stateKey, {
                selectedRowKeys: nextValue,
                lastKey: key,
                selectedRecords: nextSelectedRecords !== null && nextSelectedRecords !== void 0 ? nextSelectedRecords : [],
            }, { keys, action });
        };
        /** dataSource 中包含的所有 keys */
        const fullKeySet = new Set();
        /** 所有有效的 keys（disable 状态为 false） */
        const allKeys = [];
        (dataSource || []).forEach((row, rowIndex) => {
            const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            fullKeySet.add(rowKey);
            // const selectedRowRecords = (dataSource || []).filter?.((row, rowIndex) =>
            //   selectedRowKeys.includes(safeGetRowKey(primaryKey, row, rowIndex)),
            // );
            // 在 allKeys 中排除被禁用的 key
            if (!isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords)) {
                allKeys.push(rowKey);
            }
        });
        const set = new Set(selectedRowKeys);
        const isAllChecked = allKeys.length > 0 && allKeys.every((key) => set.has(key));
        const isAnyChecked = allKeys.some((key) => set.has(key));
        const defaultCheckboxColumnTitle = (React.createElement(CnCheckbox, { style: {
                verticalAlign: 'middle',
            }, checked: isAllChecked, indeterminate: !isAllChecked && isAnyChecked, onChange: (_) => {
                if (isAllChecked) {
                    onChange(arrayUtils.diff(selectedRowKeys, allKeys), '', allKeys, 'uncheck-all');
                }
                else {
                    // merge 是为了防止丢失选中的key
                    onChange(arrayUtils.merge(selectedRowKeys, allKeys), '', allKeys, 'check-all');
                }
            } }));
        const checkboxColumn = {
            name: $i18n.get({ id: 'SelectOrNot', dm: '是否选中', ns: 'CnBaseTable' }),
            title: defaultCheckboxColumnTitle,
            width: 50,
            lock: true,
            attach: false,
            // @ts-ignore
            sizeFixed: true,
            symbol: rowSelectionSymbol,
            align: 'center',
            ...((opts === null || opts === void 0 ? void 0 : opts.columnProps) || {}),
            getCellProps(value, row, rowIndex) {
                var _a;
                const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
                const configProps = getFunctionVal((_a = opts === null || opts === void 0 ? void 0 : opts.columnProps) === null || _a === void 0 ? void 0 : _a.getCellProps, [value, row, rowIndex], {});
                if (fullKeySet.has(rowKey) && clickArea === 'cell') {
                    const prevChecked = set.has(rowKey);
                    // const selectedRowRecords = (dataSource || []).filter?.(
                    //   (row, rowIndex) =>
                    //     selectedRowKeys.includes(
                    //       safeGetRowKey(primaryKey, row, rowIndex),
                    //     ),
                    // );
                    const disabled = isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                    return mergeCellProps(isPlainObject(configProps) ? configProps : {}, {
                        style: { cursor: disabled ? 'not-allowed' : 'pointer' },
                        onClick: disabled
                            ? undefined
                            : (e) => {
                                if (opts.stopClickEventPropagation) {
                                    e.stopPropagation();
                                }
                                onCheckboxChange(prevChecked, rowKey, e.shiftKey);
                            },
                    });
                }
                return mergeCellProps(configProps, {
                    style: {
                        padding: 0,
                    },
                });
            },
            render(_, row, rowIndex) {
                const key = safeGetRowKey(primaryKey, row, rowIndex);
                const checked = set.has(key);
                return (React.createElement(CnCheckbox, { checked: checked, style: {
                        verticalAlign: 'middle',
                    }, disabled: (function () {
                        return isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                    })(), onChange: clickArea === 'checkbox'
                        ? (arg1, arg2) => {
                            var _a;
                            // 这里要同时兼容 antd 和 fusion 的用法
                            // fusion: arg2?.nativeEvent
                            // antd: arg1.nativeEvent
                            const nativeEvent = (_a = arg2 === null || arg2 === void 0 ? void 0 : arg2.nativeEvent) !== null && _a !== void 0 ? _a : arg1.nativeEvent;
                            if (nativeEvent) {
                                if (opts.stopClickEventPropagation) {
                                    nativeEvent.stopPropagation();
                                }
                                onCheckboxChange(checked, key, nativeEvent.shiftKey);
                            }
                        }
                        : undefined }));
            },
        };
        const nextColumns = pipeline.getColumns().slice();
        const placement = (_o = opts.placement) !== null && _o !== void 0 ? _o : 'start';
        if (placement === 'start') {
            nextColumns.unshift(checkboxColumn);
        }
        else {
            checkboxColumn.lock = 'right';
            nextColumns.push(checkboxColumn);
        }
        pipeline.columns(nextColumns);
        // @ts-ignore
        pipeline.appendRowPropsGetter((row, rowIndex) => {
            var _a;
            const rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            if (!fullKeySet.has(rowKey)) {
                // rowKey 不在 fullKeySet 中说明这一行是在 multiSelect 之后才生成的，multiSelect 不对之后生成的行进行处理
                return;
            }
            const style = {};
            let className;
            let onClick;
            const checked = set.has(rowKey);
            if (((_a = opts.highlightRowWhenSelected) !== null && _a !== void 0 ? _a : true) && checked) {
                className = 'highlight-cell';
            }
            if (clickArea === 'row') {
                const disabled = isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                if (!disabled) {
                    style.cursor = 'pointer';
                    onClick = (e) => {
                        if (opts.stopClickEventPropagation) {
                            e.stopPropagation();
                        }
                        onCheckboxChange(checked, rowKey, e.shiftKey);
                    };
                }
            }
            // @ts-ignore
            return { className, style, onClick };
        });
        return pipeline;
        function onCheckboxChange(prevChecked, key, batch) {
            let batchKeys = [key];
            if (batch && lastKey) {
                const lastIdx = allKeys.indexOf(lastKey);
                const cntIdx = allKeys.indexOf(key);
                const [start, end] = lastIdx < cntIdx ? [lastIdx, cntIdx] : [cntIdx, lastIdx];
                batchKeys = allKeys.slice(start, end + 1);
            }
            if (prevChecked) {
                // @ts-ignore
                onChange(arrayUtils.diff(selectedRowKeys, batchKeys), key, batchKeys, 'uncheck');
            }
            else {
                // @ts-ignore
                onChange(arrayUtils.merge(selectedRowKeys, batchKeys), key, batchKeys, 'check');
            }
        }
    };
}
