import * as React from 'react';
/**
 * 列配置上下文
 * @export
 * @const {React.Context<IColumnConfig>}
 */
export const ColumnsStateContext = React.createContext({
    columns: [],
    originColumns: [],
    fixLeftColumns: [],
    fixRightColumns: [],
    noFixColumns: [],
    columnKeys: [],
    checkedColumnKeys: [],
});
