export function isEnableScrollbar() {
    return typeof navigator !== 'undefined';
}
export function updateRef(innerRef, scrollBoxElement) {
    if (!innerRef) {
        return;
    }
    if (typeof innerRef === 'function') {
        innerRef(scrollBoxElement);
        return;
    }
    // @ts-ignore
    innerRef.current = scrollBoxElement;
}
export function getGapSize(trackGap, showBothBar) {
    if (Array.isArray(trackGap)) {
        const [startX, endX, startY, endY] = trackGap;
        return [startX, startX + endX, startY, startY + endY];
    }
    const endGap = showBothBar ? trackGap : 0;
    return [0, endGap, 0, endGap];
}
export function handleExtractSize(target) {
    const { clientWidth, scrollWidth, clientHeight, scrollHeight } = target;
    const { paddingTop, paddingRight, paddingBottom, paddingLeft } = window.getComputedStyle(target);
    return {
        CW: clientWidth,
        SW: scrollWidth,
        CH: clientHeight,
        SH: scrollHeight,
        PT: parseInt(paddingTop, 10),
        PR: parseInt(paddingRight, 10),
        PB: parseInt(paddingBottom, 10),
        PL: parseInt(paddingLeft, 10),
    };
}
export function isEnableStyle(disabled) {
    return disabled ? 'hidden' : 'auto';
}
export function updateElementStyle(element, obj) {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in obj) {
        element.style[key] = `${obj[key]}px`;
    }
}
export function computeRatio(scrollSize, clientSize, gapSize, minThumbSize = 20) {
    const boxSize = clientSize - gapSize;
    const realThumbSize = (boxSize / scrollSize) * boxSize;
    const thumbSize = Math.max(minThumbSize, realThumbSize);
    const remaining = boxSize - thumbSize;
    const distance = scrollSize - clientSize;
    return {
        thumbSize,
        ratio: remaining / distance,
    };
}
export function updateScrollPosition(element, position, horizontal) {
    if (!element) {
        return;
    }
    if (horizontal) {
        element.scrollLeft = position;
        return;
    }
    element.scrollTop = position;
}
export function updateScrollElementStyle(containerElement, horizontalElement, verticalElement, gapX, gapY, minThumbSize) {
    if (!containerElement) {
        return;
    }
    const { scrollTop, scrollLeft, scrollWidth, scrollHeight, clientWidth, clientHeight } = containerElement;
    if (horizontalElement) {
        updateElementStyle(horizontalElement.firstChild, {
            left: scrollLeft * computeRatio(scrollWidth, clientWidth, gapX, minThumbSize).ratio,
        });
    }
    if (verticalElement) {
        updateElementStyle(verticalElement.firstChild, {
            top: scrollTop * computeRatio(scrollHeight, clientHeight, gapY, minThumbSize).ratio,
        });
    }
}
