import { findTarget } from './utils';
export function getInitNextWidth(e, leafColumns) {
    var _a;
    const tableDom = findTarget(e.target, (el) => el.tagName === 'TABLE');
    if (((_a = tableDom === null || tableDom === void 0 ? void 0 : tableDom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const nextWidth = {};
        // @ts-ignore
        Array.from(tableDom === null || tableDom === void 0 ? void 0 : tableDom.children).forEach((_dom) => {
            var _a;
            if ((_dom === null || _dom === void 0 ? void 0 : _dom.tagName) === 'COLGROUP' && ((_a = _dom === null || _dom === void 0 ? void 0 : _dom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                Array.from(_dom === null || _dom === void 0 ? void 0 : _dom.children).forEach((colDom, index) => {
                    var _a, _b, _c;
                    if (colDom) {
                        const matchColum = leafColumns[index];
                        if (matchColum) {
                            const colCode = (_a = matchColum === null || matchColum === void 0 ? void 0 : matchColum.code) !== null && _a !== void 0 ? _a : (_b = matchColum === null || matchColum === void 0 ? void 0 : matchColum.range) === null || _b === void 0 ? void 0 : _b.startIndex;
                            nextWidth[colCode] =
                                colDom.clientWidth > 0
                                    ? colDom.clientWidth
                                    : // chrome 浏览器有的版本
                                        parseInt((_c = colDom === null || colDom === void 0 ? void 0 : colDom.style) === null || _c === void 0 ? void 0 : _c.width, 10);
                        }
                    }
                });
            }
        });
        return nextWidth;
    }
    return {};
}
