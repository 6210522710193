import cx from 'classnames';
import * as React from 'react';
import { usePageFooterHeight } from './hooks/use-page-footer-height';
import { useImproveMargin } from './hooks/use-improve-margin';
import './index.scss';
export const CnPageFooter = ({ className, style, leftSlot, middleSlot, middleSecondarySlot, rightSlot, ...otherProps }) => {
    const classes = cx('cn-ui-page-footer', className);
    const ref = React.useRef(null);
    usePageFooterHeight({ ref });
    useImproveMargin({ ref });
    return (React.createElement("div", { className: classes, ...otherProps, ref: ref },
        React.createElement("div", { className: "cn-ui-page-footer-header" }, leftSlot),
        React.createElement("div", { className: "cn-ui-page-footer-sub-header" }, middleSecondarySlot),
        React.createElement("div", { className: "cn-ui-page-footer-body" }, middleSlot),
        React.createElement("div", { className: "cn-ui-page-footer-footer" }, rightSlot)));
};
CnPageFooter.displayName = 'CnPageFooter';
