import React from 'react';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
export default function columnsTitlePipeline() {
    return function (pipeline) {
        const columns = pipeline.getColumns();
        return pipeline.columns(columns.map(handleTitleTooltips));
    };
}
function handleTitleTooltips(column) {
    const { title, tip, align } = column;
    if (tip) {
        const textDom = (React.createElement(CnIcon, { style: { marginLeft: '2px' }, type: "help-color", size: "medium" }));
        let justifyContentProp;
        if (align === 'center') {
            justifyContentProp = 'center';
        }
        else if (align === 'right') {
            justifyContentProp = 'flex-end';
        }
        else {
            justifyContentProp = 'flex-start';
        }
        column.title = (React.createElement("div", { style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: justifyContentProp,
            } },
            title,
            React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, tip)));
    }
    return column;
}
