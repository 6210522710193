import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { polyfill } from 'react-lifecycles-compat';
import UIState from '../mixin-ui-state';
import { ConfigProvider } from '@fusion/lib';
import { CnIcon } from '@/components/cn-icon';
import { obj, func } from '@fusion/lib/util';
import withContext from './with-context';
const { makeChain, noop } = func;
/**
 * Radio
 * @order 1
 */
class Radio extends UIState {
    constructor(props) {
        super(props);
        const { context } = props;
        let checked;
        if (context.__group__) {
            checked = context.selectedValue === props.value;
        }
        else if ('checked' in props) {
            checked = props.checked;
        }
        else {
            checked = props.defaultChecked;
        }
        this.state = { checked };
        this.onChange = this.onChange.bind(this);
    }
    static getDerivedStateFromProps(nextProps) {
        const { context: nextContext } = nextProps;
        if (nextContext.__group__ && 'selectedValue' in nextContext) {
            return {
                checked: nextContext.selectedValue === nextProps.value,
            };
        }
        else if ('checked' in nextProps) {
            return {
                checked: nextProps.checked,
            };
        }
        return null;
    }
    get disabled() {
        const { props } = this;
        const { context } = props;
        const disabled = props.disabled || (context.__group__ && 'disabled' in context && context.disabled);
        return disabled;
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { shallowEqual } = obj;
        return (!shallowEqual(this.props, nextProps) ||
            !shallowEqual(this.state, nextState) ||
            !shallowEqual(this.context, nextContext));
    }
    componentDidUpdate() {
        // when disabled, reset UIState
        if (this.disabled) {
            // only class has an impact, no effect on visual
            this.resetUIState();
        }
    }
    onChange(e) {
        const checked = e.target.checked;
        const { context, value } = this.props;
        if (context.__group__) {
            context.onChange(value, e);
        }
        else if (this.state.checked !== checked) {
            if (!('checked' in this.props)) {
                this.setState({
                    checked: checked,
                });
            }
            this.props.onChange(checked, e);
        }
    }
    render() {
        /* eslint-disable no-unused-vars */
        const { id, className, children, style, label, onMouseEnter, onMouseLeave, tabIndex, rtl, name, isPreview, renderPreview, value, context, description, icon, ...otherProps } = this.props;
        const checked = !!this.state.checked;
        const disabled = this.disabled;
        const prefix = context.prefix || this.props.prefix;
        const others = obj.pickOthers(Radio.propTypes, otherProps);
        const othersData = obj.pickAttrsWith(others, 'data-');
        if (isPreview) {
            const previewCls = classnames(className, `${prefix}form-preview`);
            if ('renderPreview' in this.props) {
                return (React.createElement("div", { id: id, dir: rtl ? 'rtl' : 'ltr', ...others, className: previewCls }, renderPreview(checked, this.props)));
            }
            return (React.createElement("p", { id: id, dir: rtl ? 'rtl' : 'ltr', ...others, className: previewCls }, checked && (children || label || value)));
        }
        let input = (React.createElement("input", { ...obj.pickOthers(othersData, others), name: name, id: id, tabIndex: tabIndex, disabled: disabled, checked: checked, type: "radio", onChange: this.onChange, "aria-checked": checked, className: `${prefix}radio-input` }));
        // disabled do not hove focus state
        if (!disabled) {
            input = this.getStateElement(input);
        }
        const cls = classnames({
            [`${prefix}radio`]: true,
            checked,
            disabled,
            [this.getStateClassName()]: true,
        });
        const clsInner = classnames({
            [`${prefix}radio-inner`]: true,
            press: checked,
            unpress: !checked,
        });
        const clsWrapper = classnames({
            [`${prefix}radio-wrapper`]: true,
            [`${prefix}complex-radio-wrapper`]: true,
            [className]: !!className,
            checked,
            disabled,
            [this.getStateClassName()]: true,
        });
        const iconRender = (icon) => {
            if (typeof icon === 'string') {
                return React.createElement(CnIcon, { type: icon });
            }
            return icon;
        };
        const title = label !== null && label !== void 0 ? label : children;
        return (React.createElement("label", { ...othersData, dir: rtl ? 'rtl' : 'ltr', style: style, "aria-checked": checked, "aria-disabled": disabled, className: clsWrapper, onMouseEnter: disabled ? onMouseEnter : makeChain(this._onUIMouseEnter, onMouseEnter), onMouseLeave: disabled ? onMouseLeave : makeChain(this._onUIMouseLeave, onMouseLeave) },
            React.createElement("span", { className: cls },
                React.createElement("span", { className: clsInner }),
                input),
            React.createElement("div", { className: "cn-next-complex-radio-rightcontent" },
                React.createElement("div", { className: "cn-next-complex-radio-header" },
                    title && React.createElement("div", { className: "cn-next-complex-radio-title" }, title),
                    icon &&
                        React.createElement("div", { className: "cn-next-complex-radio-icon" }, iconRender(icon))),
                description && React.createElement("div", { className: "cn-next-complex-radio-description" }, description))));
    }
}
Radio.displayName = 'Radio';
Radio.propTypes = {
    ...ConfigProvider.propTypes,
    /**
     * 自定义类名
     */
    className: PropTypes.string,
    /**
     * 组件input的id
     */
    id: PropTypes.string,
    /**
     * 自定义内敛样式
     */
    style: PropTypes.object,
    /**
     * 设置radio是否选中
     */
    checked: PropTypes.bool,
    /**
     * 设置radio是否默认选中
     */
    defaultChecked: PropTypes.bool,
    /**
     * 通过属性配置label
     */
    label: PropTypes.node,
    /**
     * 状态变化时触发的事件
     * @param {Boolean} checked 是否选中
     * @param {Event} e Dom 事件对象
     */
    onChange: PropTypes.func,
    /**
     * 鼠标进入enter事件
     * @param {Event} e Dom 事件对象
     */
    onMouseEnter: PropTypes.func,
    /**
     * 鼠标离开事件
     * @param {Event} e Dom 事件对象
     */
    onMouseLeave: PropTypes.func,
    /**
     * radio是否被禁用
     */
    disabled: PropTypes.bool,
    /**
     * radio 的value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    /**
     * name
     */
    name: PropTypes.string,
    /**
     * 是否为预览态
     */
    isPreview: PropTypes.bool,
    /**
     * 预览态模式下渲染的内容
     * @param {Boolean} checked 是否选中
     * @param {Object} props 所有传入的参数
     * @returns {reactNode} Element 渲染内容
     */
    renderPreview: PropTypes.func,
    /**
     * 描述信息
     */
    description: PropTypes.string,
    /**
     * 右侧图标，string 格式会被识别为icon
     */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};
Radio.defaultProps = {
    onChange: noop,
    onMouseLeave: noop,
    onMouseEnter: noop,
    tabIndex: 0,
    prefix: 'cn-next-',
    isPreview: false,
};
Radio.contextTypes = {
    onChange: PropTypes.func,
    __group__: PropTypes.bool,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
};
export default ConfigProvider.config(withContext(polyfill(Radio)));
