import * as React from 'react';
import { Collapse as UiCollapse } from '@fusion/lib';
import cx from 'classnames';
import './index.scss';
export const CnCollapse = React.forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (React.createElement(UiCollapse, { className: cx({
            'cn-ui-collapse': true,
            [className]: true,
        }), "data-name": "CnCollapse", ref: ref, ...otherProps }));
});
CnCollapse.displayName = 'CnCollapse';
export const CnCollapsePanel = UiCollapse.Panel;
CnCollapsePanel.displayName = 'CnCollapsePanel';
/**
 * @deprecated
 * 推荐使用 CnCollapsePanel
 */
CnCollapse.Panel = CnCollapsePanel;
