// 第三方依赖
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
export function normalizeProps(props) {
    var _a, _b, _c;
    normalRemote(props);
    if (!isEmpty((_a = props === null || props === void 0 ? void 0 : props.toolbar) === null || _a === void 0 ? void 0 : _a.batchArea)) {
        props.toolbar.batchArea = normalizeDisabledAndHidden(props.toolbar.batchArea);
    }
    if (!isEmpty((_b = props === null || props === void 0 ? void 0 : props.toolbar) === null || _b === void 0 ? void 0 : _b.toolArea)) {
        props.toolbar.toolArea = normalizeDisabledAndHidden(props.toolbar.toolArea);
    }
    if (!isEmpty((_c = props === null || props === void 0 ? void 0 : props.operateColumn) === null || _c === void 0 ? void 0 : _c.buttons)) {
        props.operateColumn.buttons = normalizeDisabledAndHidden(props.operateColumn.buttons);
    }
    return props;
}
function normalizeDisabledAndHidden(areList) {
    if (!Array.isArray(areList))
        return [];
    const normalizeAreList = areList.map((item) => {
        if (isFunction(item === null || item === void 0 ? void 0 : item.hidden) && !isFunction(item === null || item === void 0 ? void 0 : item.visible)) {
            item.visible = (...args) => {
                return !item.hidden(...args);
            };
        }
        if (isFunction(item === null || item === void 0 ? void 0 : item.disabled) && !isFunction(item === null || item === void 0 ? void 0 : item.isDisabled)) {
            item.isDisabled = item.disabled;
        }
        return item;
    });
    return normalizeAreList;
}
function normalRemote(props) {
    if (isPlainObject(props === null || props === void 0 ? void 0 : props.remote)) {
        if (props.remote.service && !props.remote.fetch) {
            props.remote.fetch = props.remote.service;
        }
        if ('manual' in props.remote && !('immediate' in props.remote)) {
            props.remote.immediate = !props.remote.manual;
        }
        if (isFunction(props.remote.onBefore) &&
            !isFunction(props.remote.paramsTransform)) {
            props.remote.paramsTransform = props.remote.onBefore;
        }
        if (isFunction(props.remote.onError) &&
            !isFunction(props.remote.failCallback)) {
            props.remote.failCallback = props.remote.onError;
        }
        if (isFunction(props.remote.onSuccess) &&
            !isFunction(props.remote.responseTransform)) {
            props.remote.responseTransform = props.remote.onSuccess;
        }
    }
}
