import { useCallback } from 'react';
import { getFileIndex } from '../../../utils';
export const useOnProgress = (props) => {
    const { setValue } = props;
    return useCallback((event, file) => {
        setValue((prev) => {
            const fileIndex = getFileIndex(file, prev);
            return prev.map((item, index) => {
                if (index !== fileIndex)
                    return item;
                return { ...item, percent: event.percent, status: 'uploading' };
            });
        });
    }, [setValue]);
};
