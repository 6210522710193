import * as React from 'react';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import padStart from 'lodash/padStart';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';
import { CnTooltip } from '@/components/cn-tooltip';
export const notEmptyOrNil = (value) => {
    // 是空的返回 false，不是空返回 true
    if (isNil(value)) {
        return false;
    }
    if (isEqual(value, {})) {
        return false;
    }
    if (value === '') {
        return false;
    }
    return true;
};
// 接受用户数值，将其转换为逗号链接的工号数组
export const transformValueToArray = (value, valuePaddingZero, valueCommaJoin) => {
    if (valueCommaJoin && typeof value === 'string') {
        value = value.split(',');
    }
    const arr = transformValueToArrayOld(value);
    return valuePaddingZero ? arr.map((vo) => workNoPadZero(vo)) : arr;
};
const transformValueToArrayOld = (value) => {
    if (isArray(value)) {
        return value
            .filter((item) => {
            if (isObject(item)) {
                return notEmptyOrNil(item === null || item === void 0 ? void 0 : item.workNo);
            }
            if (notEmptyOrNil(item)) {
                return true;
            }
            return false;
        })
            .map((item) => {
            if (isObject(item)) {
                return toString(item === null || item === void 0 ? void 0 : item.workNo);
            }
            return toString(item);
        });
    }
    if (notEmptyOrNil(value)) {
        if (isObject(value)) {
            return [toString(value === null || value === void 0 ? void 0 : value.workNo)];
        }
        return [toString(value)];
    }
    return [];
};
/** 第一位是数字则补0 */
export const workNoPadZero = (vo) => typeof +vo[0] === 'number' ? padStart(vo, 6, '0') : vo;
// 接受用户数值，将其转换为逗号链接的工号，如 111 或 111,123
export const transformValue = (value) => {
    if (isArray(value)) {
        return value
            .map((item) => {
            if (isObject(item)) {
                return item === null || item === void 0 ? void 0 : item.workNo;
            }
            return item;
        })
            .filter(notEmptyOrNil)
            .join(',');
    }
    if (notEmptyOrNil(value)) {
        return toString(value);
    }
    return '';
};
// 离职人员的图像
export const LizhiImg = () => {
    return (React.createElement("img", { className: "employee-info-footer", src: "//img.alicdn.com/imgextra/i3/O1CN01CSI1wX1R49PxGwBiy_!!6000000002057-55-tps-48-15.svg" }));
};
// 状态异常人员的图像
export const YiChangImg = () => {
    return (React.createElement("img", { className: "employee-info-footer", src: "//img.alicdn.com/imgextra/i2/O1CN01AjrD4K1Yj697Ffst7_!!6000000003094-55-tps-48-15.svg" }));
};
export const renderEmployeeItem = (employee, actions) => {
    const { personalPhotoUrl = '', name = '', nickName = '', workNo = '', department = '', deptName = '', workStatus, available, } = employee;
    let statusLabel = (React.createElement(CnTooltip, { trigger: React.createElement("div", { className: "employee-info-footer" },
            React.createElement("div", { className: "ellipsis" }, department || deptName)) }, department || deptName));
    if (available === 'F') {
        statusLabel = React.createElement(YiChangImg, null);
    }
    if (workStatus === false) {
        statusLabel = React.createElement(LizhiImg, null);
    }
    return (React.createElement("div", { className: "cn-ui-employee-select-item", key: workNo },
        React.createElement("div", { className: "cn-ui-employee-select-item-header" },
            React.createElement("img", { className: "employee-img", src: `https://work.alibaba-inc.com/photo/${workNo}.50x50.jpg`, style: {
                    objectFit: 'cover',
                } })),
        React.createElement("div", { className: "cn-ui-employee-select-item-body" },
            React.createElement("div", { className: "employee-info-header" }, `${name}${nickName ? `(${nickName})` : ''}  ${workNo}`),
            statusLabel),
        typeof actions === 'function' && (React.createElement("div", { className: "cn-ui-employee-select-item-footer" }, actions()))));
};
