export const useShowPreview = (params) => {
    const { file, onPreview } = params;
    if (typeof params.showPreview === 'function') {
        return params.showPreview(file);
    }
    if (!params.showPreview)
        return false;
    const isUploading = ['idle', 'uploading'].includes(file.status);
    if (isUploading)
        return false;
    return (onPreview || file.url) && file.allowPreview !== false;
};
