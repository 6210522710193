import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import Input from '@fusion/lib/input';
export default class TreeNodeInput extends Component {
    componentDidMount() {
        const inputWrapperNode = findDOMNode(this);
        inputWrapperNode.querySelector('input').focus();
    }
    render() {
        const { prefix, ...others } = this.props;
        return (React.createElement(Input, { size: "small", className: `${prefix}tree-node-input`, ...others }));
    }
}
TreeNodeInput.propTypes = {
    prefix: PropTypes.string,
};
