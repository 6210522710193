import * as React from 'react';
import cx from 'classnames';
import { Tag as NextTag } from '@fusion/lib';
import './index.scss';
const { Group: NextTagGroup, Closeable: NextTagCloseable, Selectable: NextTagSelectable, } = NextTag;
export const CnTag = React.forwardRef(({ className, status, ...rest }, ref) => {
    const classes = cx('cn-ui-tag', {
        'cn-ui-tag-status': !!status,
        [`cn-ui-tag-status-${status}`]: !!status,
        [className]: !!className,
    });
    return (React.createElement(NextTag, { "data-name": "CnTag", ref: ref, className: classes, ...rest }));
});
CnTag.displayName = 'CnTag';
/**
 * @deprecated
 * 建议使用 TagGroup
 */
CnTag.Group = NextTagGroup;
export const CnTagGroup = React.forwardRef((props, ref) => {
    return React.createElement(NextTagGroup, { "data-name": "CnTagGroup", ref: ref, ...props });
});
CnTagGroup.displayName = 'CnTagGroup';
/**
 * @deprecated
 * 建议使用 TagCloseable
 */
CnTag.Closeable = NextTagCloseable;
export const CnTagCloseable = React.forwardRef((props, ref) => {
    return React.createElement(NextTagCloseable, { "data-name": "CnTagCloseable", ref: ref, ...props });
});
CnTagCloseable.displayName = 'CnTagCloseable';
/**
 * @deprecated
 * 建议使用 TagSelectable
 */
CnTag.Selectable = NextTagSelectable;
export const CnTagSelectable = React.forwardRef((props, ref) => {
    return React.createElement(NextTagSelectable, { "data-name": "CnTagSelectable", ref: ref, ...props });
});
CnTagSelectable.displayName = 'CnTagSelectable';
