import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import compact from 'lodash/compact';
import { cloneElementStyle, getPropsField } from './utils';
import './index.scss';
const DEFAULT_COLS = 4;
export const CnFormGrid = ({ style, gridItemStyle, columnGap, rowGap, slot, cols: propsCols, area, className, children, responsive: propResponsive, onColumnChange, }) => {
    const responsive = !propsCols ? true : propResponsive;
    const [cols, setCols] = useState(propsCols);
    const gridRef = useRef();
    const resizeObserverRef = useRef();
    const observerStatus = useRef({ setted: false });
    useEffect(() => {
        var _a;
        if (responsive && !observerStatus.current.setted) {
            resizeObserverRef.current = new ResizeObserver((enties) => {
                for (const oGrid of enties) {
                    const width = oGrid.target.clientWidth;
                    let targetCols = DEFAULT_COLS;
                    if (width <= 428) {
                        targetCols = 1;
                    }
                    else if (width > 428 && width <= 894) {
                        targetCols = 2;
                    }
                    else if (width > 894 && width <= 1494) {
                        targetCols = 4;
                    }
                    else if (width > 1494) {
                        targetCols = 6;
                    }
                    if (targetCols !== cols) {
                        onColumnChange(targetCols);
                    }
                    setCols(targetCols);
                }
            });
            resizeObserverRef.current.observe(gridRef.current);
            observerStatus.current.setted = true;
        }
        else if (!responsive) {
            (_a = resizeObserverRef === null || resizeObserverRef === void 0 ? void 0 : resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(gridRef.current);
            observerStatus.current.setted = false;
        }
    }, [responsive, cols]);
    useEffect(() => {
        if (!responsive) {
            setCols(propsCols);
        }
    }, [propsCols]);
    return (React.createElement("div", { "data-name": "CnFormGrid", style: {
            gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr) [col-start])`,
            gridColumnGap: columnGap,
            gridRowGap: rowGap,
            ...style,
            visibility: cols === undefined ? 'hidden' : 'visible',
        }, ref: gridRef, className: cn('cn-ui-grid', className, responsive && 'responsive') },
        React.Children.map(compact(React.Children.toArray(children)), (item, index) => {
            const { colSpan, rowSpan, hidden } = item.props;
            const areaIndexData = [].concat(area === null || area === void 0 ? void 0 : area[index]);
            const gridItemRow = rowSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[1]) || 1;
            const gridItemColumn = colSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[0]) || 1;
            const originItemStyle = getPropsField(item, 'style');
            return cloneElementStyle(item, {
                ...(originItemStyle || {}),
                ...gridItemStyle,
                display: hidden ? 'none' : (originItemStyle === null || originItemStyle === void 0 ? void 0 : originItemStyle.display) || null,
                gridRow: `span ${gridItemRow}`,
                gridColumn: `span ${
                // eslint-disable-next-line no-nested-ternary
                gridItemColumn === 'full'
                    ? cols
                    : gridItemColumn > cols
                        ? cols
                        : gridItemColumn}`,
            });
        }),
        slot));
};
CnFormGrid.defaultProps = {
    columnGap: 16,
    rowGap: 0,
    responsive: false,
    onColumnChange: () => { },
};
CnFormGrid.displayName = 'Grid';
