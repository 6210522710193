import { Classes } from '../styles';
// 表格 DOM 结构
// div.cn-table-wrapper
// └── div.cn-loading-wrapper
//     ├── div.cn-loading-indicator-wrapper
//     │   └── div.cn-loading-indicator
//     │
//     └── div.cn-loading-content-wrapper
//         ├── div.cn-table
//         │   │
//         │   ├── div.cn-table-header
//         │   │  └── table
//         │   │      ├── colgroup
//         │   │      └── thead  注意这里会出现自定义内容，可能存在嵌套表格
//         │   │
//         │   ├── div.cn-table-body
//         │   │   ├── div.cn-virtual-blank.top
//         │   │   ├── table
//         │   │   │   ├── colgroup
//         │   │   │   └── tbody 注意这里会出现自定义内容，可能存在嵌套表格
//         │   │   └── div.cn-virtual-blank.bottom
//         │   │
//         │   ├── div.cn-table-footer
//         │   │  └── table
//         │   │      ├── colgroup
//         │   │      └── tfoot  注意这里会出现自定义内容，可能存在嵌套表格
//         │   │
//         │   ├── div.cn-lock-shadow-mask
//         │   │   └── div.cn-left-lock-shadow
//         │   └── div.cn-lock-shadow-mask
//         │       └── div.cn-right-lock-shadow
//         │
//         └── div.cn-sticky-scroll
//             └── div.cn-sticky-scroll-item
//
// 在「可能存在嵌套表格」的情况下，我们可以采用以下的方式来避免「querySelector 不小心获取到了的嵌套表格上的元素」：
//  artTable.querySelector(':scope > .cn-lock-shadow-mask .cn-left-lock-shadow')
// 表格 DOM 结构辅助工具
export class TableDOMHelper {
    constructor(artTableWrapper) {
        this.artTableWrapper = artTableWrapper;
        this.artTable = artTableWrapper.querySelector(`.${Classes.artTable}`);
        this.tableHeader = this.artTable.querySelector(`:scope > .${Classes.tableHeader}`);
        this.tableBody = this.artTable.querySelector(`:scope > .${Classes.tableBody}`);
        this.tableFooter = this.artTable.querySelector(`:scope > .${Classes.tableFooter}`);
        const stickyScrollSelector = `.${Classes.artTable} + .${Classes.stickyScroll}`;
        this.stickyScroll =
            artTableWrapper.querySelector(stickyScrollSelector);
        this.stickyScrollItem = this.stickyScroll.querySelector(`.${Classes.stickyScrollItem}`);
    }
    getVirtualTop() {
        return this.tableBody.querySelector(`.${Classes.virtualBlank}.top`);
    }
    getTableRows() {
        const htmlTable = this.getTableBodyHtmlTable();
        return htmlTable.querySelectorAll(`:scope > tbody > .${Classes.tableRow}`);
    }
    getTableBodyHtmlTable() {
        return this.artTable.querySelector(`.${Classes.tableBody} table`);
    }
    getLeftLockShadow() {
        const selector = `:scope > .${Classes.lockShadowMask} .${Classes.leftLockShadow}`;
        return this.artTable.querySelector(selector);
    }
    getRightLockShadow() {
        const selector = `:scope > .${Classes.lockShadowMask} .${Classes.rightLockShadow}`;
        return this.artTable.querySelector(selector);
    }
    getLoadingIndicator() {
        return this.artTableWrapper.querySelector(`.${Classes.loadingIndicator}`);
    }
}
