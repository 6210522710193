import React, { useContext } from 'react';
import $i18n from 'panda-i18n';
import { Checkbox } from '@/components/fusion';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { leftFixMaxNum, rightFixMaxNum } from './const';
import { CnDialog } from '@/components/cn-dialog';
import { ColumnItem } from './column-item';
import { ColumnsStateContext } from './context';
import ColumnsFixLeft from './columns-fix-left';
import ColumnsFixRight from './columns-fix-right';
const LeftColumns = () => {
    const { fixLeftColumns } = useContext(ColumnsStateContext);
    if (fixLeftColumns.length > 0) {
        return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__left" },
            React.createElement(ColumnsFixLeft, null)));
    }
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__left" },
        React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheLeftUpTo_236572884',
            dm: '固定在左侧(最多固定{leftFixMaxNum}个)',
            ns: 'CnTable',
        }, { leftFixMaxNum })),
        React.createElement("div", { className: "cn-table-column-setting-wrapper-null" }, $i18n.get({
            id: 'ThereIsNoFixedItemFor_2234810',
            dm: '暂无固定项，请从「不固定」池选择固定',
            ns: 'CnTable',
        }))));
};
const RightColumns = () => {
    const { fixRightColumns } = useContext(ColumnsStateContext);
    if ((fixRightColumns === null || fixRightColumns === void 0 ? void 0 : fixRightColumns.length) > 0) {
        return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__right" },
            React.createElement(ColumnsFixRight, null)));
    }
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__right" },
        React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheRightUpTo_937691714',
            dm: '固定在右侧(最多固定{rightFixMaxNum}个)',
            ns: 'CnTable',
        }, { rightFixMaxNum })),
        React.createElement("div", { className: "cn-table-column-setting-wrapper-null" }, $i18n.get({
            id: 'ThereIsNoFixedItemFor_2234810',
            dm: '暂无固定项，请从「不固定」池选择固定',
            ns: 'CnTable',
        }))));
};
const MiddleColumns = () => {
    const { noFixColumns } = useContext(ColumnsStateContext);
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__no_fix" },
        React.createElement(CnScrollBar, null, noFixColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, noFixColumns.map((dataItem, index) => (React.createElement(ColumnItem, { showIndex: true, position: "center", dataItem: dataItem, key: dataItem.dataIndex, index: index }))))) : null)));
};
export function ColumnsSettingDialog(props) {
    const { visible, setVisible } = props;
    const { checkedColumnKeys, checkedAll, setColumns, updateColumns, cancelUpdateColumns, } = useContext(ColumnsStateContext);
    const cancel = () => {
        cancelUpdateColumns();
        setVisible(false);
    };
    return (React.createElement(CnDialog, { visible: visible, size: "large", className: "cn-table-column-setting-dialog", title: $i18n.get({ id: 'ColumnSettings', dm: '列设置', ns: 'CnTable' }), footer: React.createElement("div", { className: "cn-table-column-setting-dialog-checkall" },
            React.createElement(Checkbox, { checked: checkedAll, onChange: (checked) => {
                    setColumns((prev) => {
                        return prev.map((item) => ({ ...item, hidden: !checked }));
                    });
                }, indeterminate: checkedColumnKeys.length && !checkedAll }),
            $i18n.get({ id: 'AllSelection', dm: '全选', ns: 'CnTable' })), onClose: cancel, onCancel: cancel, onOk: () => {
            updateColumns();
            setVisible(false);
        }, okProps: {
            children: $i18n.get({ id: 'Confirmation', dm: '确认', ns: 'CnTable' }),
        } },
        React.createElement("div", { className: "cn-table-column-setting-dialog" },
            React.createElement("div", { className: "cn-table-column-setting-dialog__fix" },
                React.createElement(LeftColumns, null),
                React.createElement(RightColumns, null)),
            React.createElement(MiddleColumns, null))));
}
