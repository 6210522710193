/**
 * 获取表格列的key值
 * @param {ICnTableColumn[]} columns - 表格列数组
 * @returns {string[]} - key值数组
 */
export default function getColumnsKey(columns) {
    return columns
        .reduce((result, item) => {
        var _a, _b, _c;
        // 如果该列被隐藏，则不加入key值数组
        if (item.hidden)
            return result;
        // 如果该列的dataIndex存在，则将其加入key值数组
        // 否则，如果该列的key存在，则将其加入key值数组
        // 否则，如果该列的code存在，则将其加入key值数组
        // 否则，将空字符串加入key值数组
        return [...result, (_c = (_b = (_a = item.dataIndex) !== null && _a !== void 0 ? _a : item.key) !== null && _b !== void 0 ? _b : item.code) !== null && _c !== void 0 ? _c : ''];
    }, [])
        .filter(Boolean);
}
