import $i18n from 'panda-i18n';
import isNumber from 'lodash/isNumber';
import BigNumber from 'bignumber.js';
export default (value, rule) => {
    if (isNumber(value) &&
        new BigNumber(value).isGreaterThan(rule.exclusiveMinimum)) {
        return true;
    }
    return ($i18n.get({
        id: 'TheValueMustBeGreater',
        dm: '数值必须大于',
        ns: 'CnForm',
    }) + rule.exclusiveMinimum);
};
