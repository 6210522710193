import * as React from 'react';
const wrapAsync = (dispatch) => {
    return (action) => {
        if (typeof action === 'function') {
            return action(dispatch);
        }
        else {
            return dispatch(action);
        }
    };
};
export function useAsyncReducer(reducer, initializerArg, initializer) {
    const [state, dispatch] = React.useReducer(reducer, initializerArg, initializer);
    const wrappedDispatch = React.useMemo(() => wrapAsync(dispatch), [dispatch]);
    return [state, wrappedDispatch];
}
