import { isDef, isLeafNode, makeRecursiveMapper } from '../utils';
export function resetColumnsWidthByTableWidthPipeline(tableWidth, totalWidthCalByColumnRef, midRef) {
    return function (pipeline) {
        var _a, _b, _c, _d;
        // console.log('object target', midRef?.current?.artTableWrapperRef?.current?.clientWidth);
        tableWidth =
            (_d = (_c = (_b = (_a = midRef === null || midRef === void 0 ? void 0 : midRef.current) === null || _a === void 0 ? void 0 : _a.artTableWrapperRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.clientWidth) !== null && _d !== void 0 ? _d : tableWidth.current;
        const columns = pipeline.getColumns();
        let totalWidthCalByColumn = 0;
        makeRecursiveMapper((col, range) => {
            var _a;
            if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                totalWidthCalByColumn += (_a = col === null || col === void 0 ? void 0 : col.width) !== null && _a !== void 0 ? _a : 0;
            }
            return col;
        })(columns);
        totalWidthCalByColumnRef.current = totalWidthCalByColumn;
        if (tableWidth && totalWidthCalByColumn < tableWidth) {
            traverseAndReCalWidth(columns, tableWidth - totalWidthCalByColumn - 7.5);
            // 重新计算，后重置
            totalWidthCalByColumnRef.current = tableWidth;
        }
        return pipeline.columns(columns);
    };
}
export const traverseAndReCalWidth = function (columns, addWidth) {
    const noFixedColNum = columns.filter((col) => !col.sizeFixed).length;
    if (noFixedColNum === 0) {
        return;
    }
    const avgAddWidth = Math.floor(addWidth / noFixedColNum);
    for (const col of columns) {
        if (!col.sizeFixed && isDef(col.width)) {
            if (isLeafNode(col)) {
                // @ts-ignore
                col.width += avgAddWidth;
            }
            else {
                traverseAndReCalWidth(col.children, avgAddWidth);
            }
        }
    }
};
