import * as React from 'react';
import classnames from 'classnames';
import useDrag from 'ahooks/lib/useDrag';
import useDrop from 'ahooks/lib/useDrop';
import { ResponsiveGrid } from '@/components/fusion';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import { useControlValue } from './hook';
import { CnIcon } from '@/components/cn-icon';
import './style/index.scss';
const { Cell } = ResponsiveGrid;
const defaultDragZone = [
    {
        key: 'default',
        title: 'Default zone',
        zoneStyle: {
            backgroundColor: '#E8EDF9',
        },
    },
    {
        key: 'more',
        title: 'More zone',
        zoneStyle: {
            backgroundColor: '#F8F8F8',
        },
    },
];
const CnDragTransfer = React.forwardRef((props, ref) => {
    const { className, dragZone, defaultDataSource, gridProps, } = props;
    const { columns } = gridProps;
    const [dataSource, setDataSource] = useControlValue(props, {
        valuePropName: 'dataSource',
        changePropName: 'onChange',
        defaultValue: defaultDataSource !== null && defaultDataSource !== void 0 ? defaultDataSource : {},
    });
    const [, setDragingZone] = React.useState('');
    const [, setDropingZone] = React.useState('');
    const changeSort = (fromKey, fromIndex, toKey, toIndex) => {
        const fromList = Array.isArray(dataSource[fromKey])
            ? dataSource[fromKey]
            : [];
        const toList = Array.isArray(dataSource[toKey]) ? dataSource[toKey] : [];
        let finalAppendIndex = parseInt(toIndex);
        if (fromKey === toKey) {
            finalAppendIndex =
                parseInt(fromIndex) > parseInt(toIndex)
                    ? parseInt(toIndex)
                    : parseInt(toIndex) - 1; // 因为先 去掉了一个，影响了整体长度，所以要去掉
        }
        const append = fromList.splice(parseInt(fromIndex), 1);
        !isNil(append[0]) && toList.splice(finalAppendIndex, 0, append[0]);
        setDataSource({
            ...dataSource,
            [fromKey]: fromList,
            [toKey]: toList,
        }, {
            fromKey,
            fromIndex,
            toKey,
            toIndex,
        });
        setDragingZone('');
        setDropingZone('');
    };
    const changeZone = (nowZoneIndex, nowItemIndex, changeTo) => {
        var _a, _b, _c;
        const fromKey = (_a = dragZone[nowZoneIndex]) === null || _a === void 0 ? void 0 : _a.key;
        const toKey = (_b = dragZone[nowZoneIndex + changeTo]) === null || _b === void 0 ? void 0 : _b.key;
        const toIndex = changeTo > 0 ? 0 : (_c = dataSource[toKey]) === null || _c === void 0 ? void 0 : _c.length;
        changeSort(fromKey, `${nowItemIndex}`, toKey, `${toIndex}`);
    };
    return (React.createElement("div", { className: classnames('cn-ui-drag-transfer', className), ref: ref }, map(dragZone, (zoneConfig, zoneIndex) => {
        const dataList = Array.isArray(dataSource === null || dataSource === void 0 ? void 0 : dataSource[zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key])
            ? dataSource === null || dataSource === void 0 ? void 0 : dataSource[zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key]
            : [];
        return (React.createElement("div", { key: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key },
            React.createElement("div", { style: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.titleStyle, className: "cn-ui-drag-transfer-title" }, isFunction(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title) ? zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title() : zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title),
            React.createElement(DropItem, { gridProps: gridProps, style: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.zoneStyle, className: "cn-ui-drag-transfer-zone", onChange: ((fromKey, fromIndex, toIndex) => {
                    changeSort(fromKey, fromIndex, zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, toIndex);
                }) }, dataList.length > 0
                ? dataList.map((item, index) => {
                    var _a, _b;
                    const colSpan = Math.min(Number(item.span) || 1, Number(columns));
                    return (React.createElement(Cell, { colSpan: colSpan, key: (_a = item.value) !== null && _a !== void 0 ? _a : index },
                        React.createElement(DragItem, { key: (_b = item.value) !== null && _b !== void 0 ? _b : index, sign: index, label: item.label, disabled: item.disabled, tansferKey: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, className: item.className, content: dragZone.length > 1 && (React.createElement("div", { className: "cn-ui-drag-transfer-adjust" },
                                zoneIndex !== dragZone.length - 1 && (React.createElement(CnIcon, { type: "icon-arrow-down1", size: "small", onClick: () => {
                                        changeZone(zoneIndex, index, 1);
                                    } })),
                                zoneIndex !== 0 && (React.createElement(CnIcon, { type: "icon-arrow-up1", size: "small", onClick: () => {
                                        changeZone(zoneIndex, index, -1);
                                    } })))), onChange: ((fromKey, fromIndex, toIndex) => {
                                changeSort(fromKey, fromIndex, zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, toIndex);
                            }), onDragStart: () => {
                                setDragingZone(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key);
                            }, onDragEnd: () => {
                                setDragingZone('');
                            }, onDragOver: () => {
                                setDropingZone(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key);
                            }, onDrop: () => {
                                setDropingZone('');
                            } })));
                })
                : null)));
    })));
});
function DropItem(props) {
    const { children, style, className, onChange, gridProps, } = props;
    const dropRef = React.useRef(null);
    useDrop(dropRef, {
        onText: (data) => {
            var _a, _b;
            const fromKey = (_a = data === null || data === void 0 ? void 0 : data.split) === null || _a === void 0 ? void 0 : _a.call(data, '_')[0];
            const fromIndex = (_b = data === null || data === void 0 ? void 0 : data.split) === null || _b === void 0 ? void 0 : _b.call(data, '_')[1];
            onChange instanceof Function &&
                onChange(fromKey, fromIndex, `${React.Children.toArray(children).length}`);
        },
    });
    return (React.createElement("div", { ref: dropRef, style: style, className: className },
        React.createElement(ResponsiveGrid, { ...gridProps }, children !== null && children !== void 0 ? children : '')));
}
function DragItem(props) {
    const { label, sign, onChange, tansferKey, onDragStart, onDragEnd, onDragOver, onDrop, content, disabled, className, } = props;
    const dragRef = React.useRef(null);
    const dropRef = React.useRef(null);
    const [dragging, setDragging] = React.useState(false);
    const [align, setAlign] = React.useState('');
    useDrag(`${tansferKey}_${sign}`, dragRef, {
        onDragStart: (event) => {
            if (disabled)
                return;
            event.dataTransfer.setData('text', `${tansferKey}_${sign}`);
            // requestAnimationFrame 能保证，拖拽出来的和原来的不一样
            requestAnimationFrame(() => setDragging(true));
            onDragStart instanceof Function && onDragStart(event);
        },
        onDragEnd: (event) => {
            if (disabled)
                return;
            setDragging(false);
            onDragEnd instanceof Function && onDragEnd(event);
        },
    });
    useDrop(dropRef, {
        onText: (data) => {
            var _a, _b;
            const toIndex = sign - (align === 'left' ? 0 : -1);
            if (`${data}` !== `${tansferKey}_${toIndex}`) {
                const fromKey = (_a = data === null || data === void 0 ? void 0 : data.split) === null || _a === void 0 ? void 0 : _a.call(data, '_')[0];
                const fromIndex = (_b = data === null || data === void 0 ? void 0 : data.split) === null || _b === void 0 ? void 0 : _b.call(data, '_')[1];
                onChange instanceof Function &&
                    onChange(fromKey, fromIndex, `${toIndex}`);
            }
            setAlign('');
        },
        onDragLeave: () => setAlign(''),
        onDrop(event) {
            var _a;
            if (disabled)
                return;
            onDrop instanceof Function && onDrop(event);
            (_a = event === null || event === void 0 ? void 0 : event.stopPropagation) === null || _a === void 0 ? void 0 : _a.call(event);
        },
        onDragOver(event) {
            if (disabled)
                return;
            onDragOver instanceof Function && onDragOver(event);
            if (!dragging) {
                const { offsetX } = event;
                const { width } = event.target.getBoundingClientRect();
                if (offsetX > 0 && offsetX < width / 2) {
                    // 靠左
                    setAlign('left');
                }
                else if (offsetX >= width / 2 && offsetX < width) {
                    // 靠右
                    setAlign('right');
                }
            }
        },
    });
    return (React.createElement("div", { className: classnames('cn-ui-drag-item', {
            [className]: !!className,
        }), ref: dropRef },
        align === 'left' && (React.createElement("div", { className: "cn-ui-drag-line", style: { left: -1 } })),
        React.createElement("div", { ref: dragRef, className: classnames('cn-ui-drag-item-content', {
                disabled,
            }) },
            label,
            !disabled &&
                React.createElement("div", { className: "cn-ui-drag-item-content-icons" },
                    content,
                    React.createElement(CnIcon, { type: "icon-hamburger", className: "cn-ui-drag-item-content-icons-drag" })),
            dragging && React.createElement("span", { className: "cn-ui-drag-item-null" })),
        align === 'right' && (React.createElement("div", { className: "cn-ui-drag-line", style: { right: -1 } }))));
}
CnDragTransfer.defaultProps = {
    className: '',
    dragZone: defaultDragZone,
    gridProps: {
        columns: 3,
    },
};
export default CnDragTransfer;
