import { useCallback } from 'react';
import { useRemoveFile } from './use-remove-file';
export const useOnError = (props) => {
    const { setValue, onChangeRef, onErrorRef } = props;
    const removeFile = useRemoveFile({ setValue, onChangeRef });
    return useCallback((error, response, file) => {
        removeFile(file);
        if (onErrorRef.current) {
            onErrorRef.current(error, response, file);
        }
    }, [onErrorRef, removeFile]);
};
