import * as React from 'react';
import cx from 'classnames';
import { Rating as NextRating } from '@fusion/lib';
export const CnRating = React.forwardRef(({ className, ...rest }, ref) => {
    const classes = cx('cn-ui-rating', {
        [className]: !!className,
    });
    return (React.createElement(NextRating, { "data-name": "CnTag", ref: ref, className: classes, ...rest }));
});
CnRating.displayName = 'CnRating';
CnRating.defaultProps = {
    count: 5,
    allowHalf: false,
    defaultValue: 0,
    allowClear: true,
};
