import { connect, mapProps } from '@formily/react';
import { CnComplexCheckbox as UiCnComplexCheckbox, CnComplexCheckboxGroup as UiCnComplexCheckboxGroup, } from '@/components/cn-complex-checkbox';
import { mapSize, mapStatus } from '@/form/__builtins__';
export const CnComplexCheckbox = connect(UiCnComplexCheckbox, mapProps(mapSize, {
    value: 'checked',
    onInput: 'onChange',
}));
export const CnComplexCheckboxGroup = connect(UiCnComplexCheckboxGroup, mapProps(mapSize, mapStatus, {
    dataSource: true,
}));
