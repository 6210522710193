import useLatest from 'ahooks/lib/useLatest';
import { useCnRequest } from '@cainiaofe/cn-ui-utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import useInitPolling from './use-init-polling';
const getProgress = (list) => {
    const countProgress = list.reduce((total, item) => total + (item.progress || 0), 0);
    return Math.round(countProgress / list.length);
};
const getJobStatus = (list) => {
    try {
        return list.some((item) => item.status === 'EXECUTING')
            ? 'running'
            : 'finished';
    }
    catch {
        return 'running';
    }
};
/**
 * 计算本次处理结束的任务
 * 本地轮询结果和上次轮询结果进行对比
 */
const getProcessedJobs = (_prevList, _list) => {
    const list = _list.filter((item) => item.status !== 'EXECUTING');
    const _prevIdList = _prevList.map((item) => item.jobId);
    const prevIdList = _prevList
        .filter((item) => item.status === 'EXECUTING')
        .map((item) => item.jobId);
    return list.filter(({ jobId, status }) => {
        if (!_prevIdList.length)
            return false;
        if (_prevIdList.length && !_prevIdList.includes(jobId))
            return true;
        return status !== 'EXECUTING' && prevIdList.includes(jobId);
    });
};
export default function useJobsPolling(props) {
    const { onSuccess, onError, pollingInterval: _pollingInterval = 5000, pollingRequestConfig, } = props;
    const pollingInterval = _pollingInterval < 3000 ? 3000 : _pollingInterval;
    const [jobResult, setJobResult] = useState([]);
    const jobAbortController = useRef();
    const taskId = useRef();
    const prevJobResult = useRef([]);
    const onErrorRef = useLatest(onError);
    const onSuccessRef = useLatest(onSuccess);
    const pollingRequestConfigRef = useLatest(pollingRequestConfig);
    const stopTaskLoop = useCallback(() => {
        var _a;
        (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        if (taskId.current) {
            clearTimeout(taskId.current);
            taskId.current = null;
            prevJobResult.current = [];
        }
    }, []);
    const { runAsync } = useCnRequest({
        ...pollingRequestConfig,
        manual: true,
    });
    const _startTask = useCallback(async () => {
        var _a;
        jobAbortController.current = new AbortController();
        const _pollingService = pollingRequestConfigRef.current;
        if (!_pollingService)
            return undefined;
        try {
            const data = await runAsync();
            const result = data.tableData || [];
            setJobResult((prev) => {
                prevJobResult.current = prev;
                return result;
            });
            (_a = onSuccessRef.current) === null || _a === void 0 ? void 0 : _a.call(onSuccessRef, data);
            return result;
        }
        catch (err) {
            if ((err === null || err === void 0 ? void 0 : err.message) === 'canceled')
                return [];
            setJobResult([]);
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
        }
        return [];
    }, [onErrorRef, onSuccessRef, pollingRequestConfigRef, runAsync]);
    const startTaskLoop = useCallback(async () => {
        stopTaskLoop();
        const result = await _startTask();
        if (getJobStatus(result) === 'running') {
            taskId.current = setTimeout(startTaskLoop, pollingInterval);
        }
    }, [_startTask, stopTaskLoop, pollingInterval]);
    useInitPolling({ stopTaskLoop, startTaskLoop });
    const result = useMemo(() => {
        const processedJobs = getProcessedJobs(prevJobResult.current, jobResult);
        return {
            startTaskLoop,
            stopTaskLoop,
            jobResult,
            progress: getProgress(jobResult),
            hasUnreadJob: Boolean(processedJobs.length),
            loading: jobResult.some((job) => job.status === 'EXECUTING'),
            processedJobs,
        };
    }, [jobResult, startTaskLoop, stopTaskLoop]);
    return result;
}
