import React from 'react';
import $i18n from 'panda-i18n';
import { CnIcon } from '@/components/cn-icon';
import { RESULT_TYPE_MAP } from './constant';
export const getIcon = (_icon, type, _size) => {
    const icon = _icon || RESULT_TYPE_MAP($i18n)[type].icon;
    const size = _size === 'normal' ? 72 : 60;
    if (!icon)
        return null;
    if (icon.startsWith('http') || icon.startsWith('//')) {
        return (React.createElement("div", { className: "cn-result-img", style: { backgroundImage: `url(${icon})` } }));
    }
    return (React.createElement("div", { className: "cn-result-icon" },
        React.createElement(CnIcon, { size: size, type: icon })));
};
