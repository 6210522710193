import * as React from 'react';
import ReactDOM from 'react-dom';
import * as op from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import useUpdate from 'ahooks/lib/useUpdate';
import styled from 'styled-components';
import { isFunction, safeRenderHeader, isLeafNode, makeRecursiveMapper, collectNodes, safeCellPaddingWidth, } from '../../utils';
import { columnResizeChangeArmsReport } from '@/components/cn-table/view/arms';
import { DragLine } from './drag-line';
import { clamp } from './utils';
import { ColAbility } from './types';
import { getInitNextWidth } from './get-init-next-width';
const ResizeHandle = styled.span `
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  cursor: col-resize;
  z-index: 1001;
`;
export function columnsResizePipeline(resizeOption) {
    const { columnResize, widthsRef, dragLineRef, ability, update, size } = resizeOption;
    // 允许拖拽
    const allowResize = ability === ColAbility.widthAndAsyncResize ||
        ability === ColAbility.widthAndResize;
    // 是否可异步拖拽
    const allowColAsyncDrag = ability === ColAbility.widthAndAsyncResize;
    const { minWidth = 4, maxWidth = Infinity, disableUserSelectWhenResizing = true, } = columnResize || {};
    const asyncDragColHandler = (val) => {
        var _a, _b;
        isFunction((_a = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _a === void 0 ? void 0 : _a.setOffset) &&
            ((_b = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _b === void 0 ? void 0 : _b.setOffset(val));
    };
    const onChange = (...args) => {
        if (!(columnResize === null || columnResize === void 0 ? void 0 : columnResize.widths)) {
            widthsRef.current = args[0];
            update();
        }
        if ((columnResize === null || columnResize === void 0 ? void 0 : columnResize.onChange) instanceof Function) {
            columnResize.onChange(...args);
        }
        columnResizeChangeArmsReport();
    };
    const widths = widthsRef.current;
    const startResize = (nowColumn, startIndex, allLeafColumns, e) => {
        var _a;
        // 记录鼠标开始位置
        const startX = e.clientX;
        // 异步拖拽记录
        let asyncRecord = null;
        // 当前列的 code
        const nowColCode = (_a = nowColumn === null || nowColumn === void 0 ? void 0 : nowColumn.code) !== null && _a !== void 0 ? _a : startIndex;
        const nowWidths = {
            ...(getInitNextWidth(e, allLeafColumns) || {}),
            ...(widthsRef.current || {}),
        }; // 获取初始宽度
        const nextWidths = cloneDeep(nowWidths); // 下一个宽度
        const nextWidths$ = fromEvent(window, 'mousemove').pipe(
        // 监听鼠标移动事件
        op.takeUntil(fromEvent(window, 'mouseup')), // 监听鼠标抬起事件
        op.map((e) => {
            // 处理鼠标移动事件
            const movingX = e.clientX; // 获取鼠标移动位置
            const changeWidth = movingX - startX; // 计算宽度变化量
            // 如果当前列是叶子节点
            if (isLeafNode(nowColumn)) {
                // 计算下一个宽度
                nextWidths[nowColCode] = clamp(minWidth, nowWidths[nowColCode] + changeWidth, maxWidth);
            }
            else {
                // 如果当前列有孩子节点
                const leafColumns = collectNodes(
                // 获取所有叶子节点
                nowColumn.children, 'leaf-only');
                const avgChangeWidth = changeWidth / leafColumns.length; // 计算平均宽度变化量
                makeRecursiveMapper((col, range) => {
                    var _a;
                    // 遍历所有节点
                    if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                        // 如果是叶子节点
                        const colCode = (_a = col === null || col === void 0 ? void 0 : col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex; // 获取节点 code
                        nextWidths[colCode] = clamp(
                        // 计算下一个宽度
                        minWidth, nowWidths[colCode] + avgChangeWidth, maxWidth);
                    }
                    return col;
                })(nowColumn.children, startIndex);
            }
            return [nextWidths, nowColCode, nextWidths[nowColCode], movingX]; // 返回下一个宽度、当前列 code、当前列宽度、鼠标移动位置
        }));
        let prevUserSelect = ''; // 保存之前的 user-select 样式
        let docElemStyle;
        if (disableUserSelectWhenResizing) {
            // 如果需要禁用 user-select 样式
            docElemStyle = document.documentElement.style;
            prevUserSelect = docElemStyle.userSelect; // 保存之前的 user-select 样式
            docElemStyle.userSelect = 'none'; // 禁用 user-select 样式
            docElemStyle.cursor = 'col-resize'; // 设置鼠标样式
        }
        nextWidths$.subscribe({
            // 监听下一个宽度
            next: (args) => {
                if (allowColAsyncDrag) {
                    // 如果允许异步拖拽
                    asyncRecord = args; // 保存结果
                    asyncDragColHandler(args[3]); // 设置异步拖拽位置
                }
                else {
                    onChange(...args); // 更新宽度
                }
            },
            complete() {
                if (allowColAsyncDrag && asyncRecord) {
                    // 如果允许异步拖拽且有异步拖拽记录
                    onChange(...asyncRecord); // 更新宽度
                    asyncDragColHandler(0); // 重置异步拖拽位置
                }
                if (disableUserSelectWhenResizing) {
                    // 如果需要禁用 user-select 样式
                    docElemStyle.userSelect = prevUserSelect; // 恢复之前的 user-select 样式
                    docElemStyle.cursor = 'unset'; // 重置鼠标样式
                }
            },
        });
    };
    const innerTransform = (columns) => {
        // let totalWidthCalByColumn = 0;
        const leafColumns = [];
        const traverseAndGetData = makeRecursiveMapper(
        // @ts-ignore
        (col, range) => {
            var _a, _b, _c;
            if (!col) {
                return col;
            }
            const prevTitle = safeRenderHeader(col);
            const code = col.code || range.startIndex;
            const resizable = 'resizable' in col ? col === null || col === void 0 ? void 0 : col.resizable : true;
            const colWidth = (_b = (_a = widths[code]) !== null && _a !== void 0 ? _a : col.width) !== null && _b !== void 0 ? _b : safeCellPaddingWidth(size);
            if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                // totalWidthCalByColumn += colWidth;
                leafColumns.push({
                    ...(col || {}),
                    range,
                });
            }
            const resColumn = resizable && allowResize
                ? {
                    ...col,
                    width: colWidth,
                    title: (React.createElement(React.Fragment, null,
                        prevTitle,
                        React.createElement(ResizeHandle, { className: "resize-handle", onMouseDown: (e) => startResize(col, range === null || range === void 0 ? void 0 : range.startIndex, leafColumns, e) }))),
                    headerCellProps: {
                        ...col.headerCellProps,
                        style: {
                            ...(_c = col.headerCellProps) === null || _c === void 0 ? void 0 : _c.style,
                            overflow: 'visible',
                            position: 'relative',
                        },
                    },
                }
                : {
                    ...col,
                    width: colWidth,
                };
            return resColumn;
        });
        // @ts-ignore
        const resColumns = traverseAndGetData(columns);
        return resColumns;
    };
    return (pipeline) => {
        const columns = innerTransform(pipeline.getColumns());
        return pipeline.columns(columns);
    };
}
// @ts-ignore
export function AsyncDragLine({ resize, tableRef }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const update = useUpdate();
    if (resize === null || resize === void 0 ? void 0 : resize.dragLineUpdateRef) {
        resize.dragLineUpdateRef.current = update;
    }
    return (resize === null || resize === void 0 ? void 0 : resize.ability) === ColAbility.widthAndAsyncResize &&
        ((_b = (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.domHelper) === null || _b === void 0 ? void 0 : _b.artTableWrapper)
        ? ReactDOM.createPortal(React.createElement(DragLine, { ref: resize === null || resize === void 0 ? void 0 : resize.dragLineRef, tableClientX: ((_e = (_d = (_c = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _c === void 0 ? void 0 : _c.domHelper) === null || _d === void 0 ? void 0 : _d.artTableWrapper) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect) instanceof Function
                ? (_h = (_g = (_f = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _f === void 0 ? void 0 : _f.domHelper) === null || _g === void 0 ? void 0 : _g.artTableWrapper) === null || _h === void 0 ? void 0 : _h.getBoundingClientRect().x
                : 0 }), (_k = (_j = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _j === void 0 ? void 0 : _j.domHelper) === null || _k === void 0 ? void 0 : _k.artTableWrapper)
        : null;
}
export { useAutoWidth } from './use-auto-width';
export { useColumnsResize } from './use-columns-resize';
export * from './types';
