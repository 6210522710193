import * as React from 'react';
import NextAffix from '@fusion/lib/affix';
export const CnAffix = (props) => {
    return React.createElement(NextAffix, { "data-name": "CnAffix", ...props });
};
CnAffix.displayName = 'CnAffix';
/**
 * @deprecated 请使用 CnAffix 替换
 */
export const Affix = CnAffix;
