import React from 'react';
import classNames from 'classnames';
import { PandaConfigProvider } from 'panda-i18n';
import { useSizeChange } from '@/components/cn-utils';
import { CnPageContent } from './page-content';
export function CnPageView(props) {
    const { className, ...restProps } = props;
    const { size } = useSizeChange(props);
    return (React.createElement(PandaConfigProvider, null,
        React.createElement("div", { className: classNames('cn-ui-page', `cn-ui-page-${size}`, className), "data-name": "CnPage" },
            React.createElement(CnPageContent, { ...restProps }),
            React.createElement("div", { className: "cn-ui-page-footer-placeholder" }))));
}
