'use strict';
import $i18n from 'panda-i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import Preview from './image-preview';
import cx from 'classnames';
import { CnBox } from '@/components/cn-box';
import { getClientSize, errorImage } from './utils';
import { safeCallFunction } from '@/components/cn-utils';
const initialPosition = {
    x: 0,
    y: 0,
};
export class CnImageViewer extends React.Component {
    constructor(props) {
        super(props);
        this.shouldExpandWidth = (target) => {
            if (!target)
                return false;
            const { naturalWidth, naturalHeight } = target;
            const { width: clientWidth, height: clientHeight } = getClientSize();
            return (this.props.shouldPreviewExpand &&
                (naturalWidth / clientWidth) * clientHeight >= naturalHeight);
        };
        this.onOpen = (value, e) => {
            this.setState({
                visible: true,
                chooseUrl: value,
                shouldExpandWidth: this.shouldExpandWidth(e === null || e === void 0 ? void 0 : e.target),
            });
        };
        this.onError = (e) => {
            const { onError, errorUrl } = this.props;
            e.target.src = errorUrl;
            onError && onError(e);
        };
        this.renderImg = (value, key) => {
            const { width, height } = this.props;
            if (value) {
                return (React.createElement("img", { id: `cn-ui-image-viewer-image-${key}`, key: key, onError: (e) => {
                        this.onError(e);
                    }, onClick: (e) => this.onOpen(value, e), className: 'cn-ui-image-viewer-img-item', src: value, alt: "", style: { width, height } }));
            }
            else {
                return (React.createElement("div", { id: `cn-ui-image-viewer-${key}`, key: key, alt: "", className: 'cn-ui-image-viewer-no-image-display', style: { width, height } }, $i18n.get({ id: 'NoPicture', dm: '无图片', ns: 'CnImageViewer' })));
            }
        };
        this.onSwitch = (direction, current = '') => {
            const { src } = this.state;
            const len = Array.isArray(src) && src.length;
            const currentIndex = src.indexOf(current);
            if (len && len > 1 && currentIndex > -1) {
                switch (direction) {
                    case 'next': {
                        const newIndex = (currentIndex + 1) % len;
                        const shouldExpandWidth = this.shouldExpandWidth(document.getElementById(`cn-ui-image-viewer-image-${newIndex}`));
                        this.setState({
                            chooseUrl: src[newIndex],
                            shouldExpandWidth,
                        });
                        return;
                    }
                    case 'prev': {
                        const newIndex = (currentIndex - 1 + len) % len;
                        const shouldExpandWidth = this.shouldExpandWidth(document.getElementById(`cn-ui-image-viewer-image-${newIndex}`));
                        this.setState({
                            chooseUrl: src[newIndex],
                            shouldExpandWidth,
                        });
                    }
                    default:
                }
            }
        };
        this.onClose = () => {
            this.setState({
                visible: false,
            });
            safeCallFunction(this.props.onClose);
        };
        this.state = {
            src: Array.isArray(props.src) ? props.src : [props.src],
            chooseUrl: '',
            visible: false,
            rotate: 0,
            isMoving: false,
            scale: 1,
            position: initialPosition,
            shouldExpandWidth: false,
        };
    }
    render() {
        const { className, style, shouldPreviewExpand, wrapperClassName, errorUrl, onError, hasDownload, hideImageBox, } = this.props;
        const { src, chooseUrl, visible, shouldExpandWidth } = this.state;
        const classes = cx('cn-ui-image-viewer-image-player', className);
        return (React.createElement(React.Fragment, null,
            hideImageBox ? null : (React.createElement(CnBox, { direction: "row", spacing: 20, "data-name": "CnImageViewer", style: style, className: classes }, src.map((item, index) => {
                return this.renderImg(item, index);
            }))),
            React.createElement(Preview, { ref: (ref) => {
                    this.previewRef = ref;
                }, wrapperClassName: wrapperClassName, showSwitch: src.length > 1, visible: visible, chooseUrl: chooseUrl, onSwitch: this.onSwitch, onError: onError, onClose: this.onClose, shouldPreviewExpand: shouldPreviewExpand, shouldExpandWidth: shouldExpandWidth, errorUrl: errorUrl, hasDownload: hasDownload })));
    }
}
CnImageViewer.displayName = 'CnImageViewer';
CnImageViewer.defaultProps = {
    wrapperClassName: '',
    width: '100px',
    height: '100px',
    src: '',
    errorUrl: errorImage,
    shouldPreviewExpand: false,
    onError: () => { },
    onClose: () => { },
    hasDownload: true,
    hideImageBox: false,
};
CnImageViewer.getDerivedStateFromProps = (nextProps, prevState) => {
    const changeState = {};
    if (Array.isArray(nextProps.src) && nextProps.src !== prevState.src) {
        changeState.src = nextProps.src;
        changeState.chooseUrl = '';
    }
    if (!Array.isArray(nextProps.src) && nextProps.src !== prevState.src[0]) {
        changeState.src = [nextProps.src];
        changeState.chooseUrl = '';
    }
    if (Object.keys(changeState).length > 0) {
        return changeState;
    }
    return null;
};
CnImageViewer.open = (config) => {
    return open(config);
};
const open = (config) => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    let imageViewerInstance;
    let imageViewerRef;
    ReactDOM.render(React.createElement(CnImageViewer, { ...config, ref: (ref) => {
            imageViewerRef = ref;
        }, hideImageBox: true, onClose: () => {
            var _a;
            typeof config.onClose === 'function' && config.onClose();
            ReactDOM.unmountComponentAtNode(container);
            (_a = container.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(container);
        } }), container, () => {
        imageViewerInstance = imageViewerRef;
        let value = config === null || config === void 0 ? void 0 : config.value;
        if (!value && typeof (config === null || config === void 0 ? void 0 : config.src) === 'string') {
            value = config.src;
        }
        imageViewerInstance === null || imageViewerInstance === void 0 ? void 0 : imageViewerInstance.onOpen(value);
    });
    return {
        hide: () => {
            var _a;
            (_a = imageViewerInstance === null || imageViewerInstance === void 0 ? void 0 : imageViewerInstance.previewRef) === null || _a === void 0 ? void 0 : _a.onClose();
        },
    };
};
