import { useEffect, useMemo } from 'react';
import { events } from '@/components/cn-utils';
import { setBreadcrumbDataSource } from '../utils/breadcrumb-data-source';
/**
 * 通过菜单数据计算出面包屑数据源
 */
export const useBreadcrumbDataSource = (props) => {
    const { selectedKey, headerMenu, sideBarMenu } = props;
    // 把菜单打平成 Map 结构
    const menuDataMap = useMemo(() => {
        const temp = {};
        const calcSidebarMenuDataMap = (list = [], parent) => {
            for (const item of list) {
                const { children, key } = item;
                temp[key] = {
                    parentKey: (parent === null || parent === void 0 ? void 0 : parent.key) || item.parentKey,
                    key,
                    text: item.text,
                    path: item.path,
                    type: item.type,
                };
                calcSidebarMenuDataMap(children, item);
            }
        };
        const calcHeaderMenuDataMap = (list = [], parent) => {
            for (const item of list) {
                const { children, key } = item;
                temp[key] = {
                    parentKey: (parent === null || parent === void 0 ? void 0 : parent.key) || item.parentKey,
                    key,
                    text: item.text,
                    path: item.path,
                    type: item.type,
                };
                calcHeaderMenuDataMap(children, item);
            }
        };
        try {
            calcSidebarMenuDataMap(sideBarMenu);
            calcHeaderMenuDataMap(headerMenu);
        }
        catch { }
        return temp;
    }, [headerMenu, sideBarMenu]);
    // 通过菜单数据计算出面包屑数据源
    const breadcrumbDataSource = useMemo(() => {
        if (!selectedKey)
            return [];
        const findMenu = (dataSource) => {
            const { parentKey } = dataSource[0];
            const findTemp = menuDataMap[parentKey];
            if (!findTemp)
                return dataSource;
            return findMenu([findTemp, ...dataSource]);
        };
        try {
            const findTemp = menuDataMap[selectedKey];
            if (!findTemp)
                return [];
            const tempMenu = findMenu([findTemp]);
            const dataSource = tempMenu
                .filter((item) => item.type !== 'blank')
                .map((item) => ({
                link: item.path,
                children: item.text,
            }));
            return dataSource;
        }
        catch { }
        return [];
    }, [menuDataMap, selectedKey]);
    useEffect(() => {
        setBreadcrumbDataSource(breadcrumbDataSource);
        events.emit('CnShell.menuSelected', breadcrumbDataSource);
    }, [breadcrumbDataSource]);
    return breadcrumbDataSource;
};
