import $i18n from 'panda-i18n';
import isNumber from 'lodash/isNumber';
import BigNumber from 'bignumber.js';
export default (value, rule) => {
    if (isNumber(value) && new BigNumber(value).isLessThanOrEqualTo(rule.max)) {
        return true;
    }
    return ($i18n.get({
        id: 'TheValueCannotBeGreater',
        dm: '数值不能大于',
        ns: 'CnForm',
    }) + rule.max);
};
