// 导入classnames库
import classnames from 'classnames';
// 导入isFunction函数
import { isFunction } from './index';
// 导入isPlainObject和isString函数
import { isPlainObject } from './common';
// 重新排序列函数
export function reOrderColumns(columns) {
    // 定义左侧、普通和右侧列数组
    const left = [];
    const normal = [];
    const right = [];
    // 如果没有列，返回空数组
    if (!columns)
        return [];
    // 遍历列
    columns.forEach((col) => {
        const { lock } = col;
        // 如果锁定在左侧或者锁定为true
        if (lock === true || lock === 'left') {
            left.push(col);
        }
        else if (lock === 'right') {
            // 如果锁定在右侧
            right.push(col);
        }
        else {
            // 否则为普通列
            normal.push(col);
        }
    });
    // 返回重新排序后的列数组
    return [...left, ...normal, ...right];
}
// 规范化列函数
export function normalizeColumns(columns) {
    // 定义返回数组
    const ret = [];
    // 如果没有列，返回空数组
    if (!columns)
        return ret;
    // 遍历列
    columns.forEach((col) => {
        var _a;
        // 如果列不是普通对象，跳过
        if (!isPlainObject(col)) {
            return;
        }
        // 如果列有子列，递归规范化子列
        if (Array.isArray(col.children) && col.children.length > 0) {
            col.children = normalizeColumns(col.children);
        }
        // 如果列有key或dataIndex，且没有code，将code设置为key或dataIndex
        if ((col.key || col.dataIndex) && col.code == null) {
            col.code = (_a = col.key) !== null && _a !== void 0 ? _a : col.dataIndex;
        }
        // 如果列有sortable属性，将其添加到features中
        if ('sortable' in col) {
            col.features = {
                ...col.features,
                sortable: col.sortable,
            };
        }
        // 兼容fusion api
        if ((col === null || col === void 0 ? void 0 : col.cell) instanceof Function) {
            const sourceRender = col.cell;
            col.render = (value, row, rowIndex) => sourceRender(value, rowIndex, row);
        }
        // 如果列有bold或ellipsis属性
        if ('bold' in col || 'ellipsis' in col) {
            const sourceCellProps = col.cellProps;
            col.cellProps = (...args) => {
                const customProps = isFunction(sourceCellProps)
                    ? sourceCellProps(...args) || {}
                    : {};
                // 返回自定义属性和类名
                return {
                    ...customProps,
                    className: classnames(customProps === null || customProps === void 0 ? void 0 : customProps.className, {
                        'cn-table-cell--bold': col.bold,
                        'cn-table-cell--ellipsis': col.ellipsis,
                    }),
                };
            };
        }
    });
    // 返回规范化后的列数组
    return columns;
}
// 扁平映射函数
export function flatMap(array, callback) {
    // 定义结果数组
    const result = [];
    // 遍历数组
    array.forEach((value, index) => {
        // 将回调函数的结果添加到结果数组中
        result.push(...callback(value, index, array));
    });
    // 返回结果数组
    return result;
}
