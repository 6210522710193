import { useEffect, useMemo, useState } from 'react';
import { events } from '@/components/cn-utils';
import { getBreadcrumbDataSource } from '@/components/cn-shell/utils/breadcrumb-data-source';
/**
 * 计算面包屑数据源
 */
export const useBreadcrumbDataSource = (props) => {
    const { cnBreadcrumbProps, cnBreadcrumbDataSource } = props;
    const { useStandard = false, dataSource: dataSourceProps } = cnBreadcrumbProps;
    const [dataSource, setDataSource] = useState(getBreadcrumbDataSource());
    useEffect(() => {
        if (useStandard) {
            events.on('CnShell.menuSelected', (data) => {
                setDataSource(data);
            });
        }
    }, [useStandard]);
    const tempResult = useMemo(() => {
        if (useStandard && (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)) {
            return dataSource.filter((item) => Boolean(item.link));
        }
        else if (dataSourceProps === null || dataSourceProps === void 0 ? void 0 : dataSourceProps.length) {
            return dataSourceProps;
        }
        else if (cnBreadcrumbDataSource === null || cnBreadcrumbDataSource === void 0 ? void 0 : cnBreadcrumbDataSource.length) {
            return cnBreadcrumbDataSource;
        }
        return [];
    }, [cnBreadcrumbDataSource, dataSource, dataSourceProps, useStandard]);
    return useMemo(() => {
        const temp = [...tempResult];
        if (temp.length) {
            temp[temp.length - 1] = {
                ...temp[temp.length - 1],
                link: undefined,
            };
        }
        return temp;
    }, [tempResult]);
};
