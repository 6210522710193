import React, { useContext } from 'react';
import classNames from 'classnames';
import { Header } from './header';
import { SideBar } from './side-bar';
import Content from './content';
import { ShellStateCtx } from './context';
export const CnShellContainer = (props) => {
    const { children } = props;
    const { headerVisible, headerProps, sideBarVisible, theme } = useContext(ShellStateCtx);
    return (React.createElement("div", { "data-name": "CnShell", className: classNames('cn-ui-shell', {
            [`cn-ui-shell-${theme}`]: theme,
            'hide-sidebar': !sideBarVisible,
            'hide-top-bar': !headerVisible,
        }) },
        headerVisible ? React.createElement(Header, { ...headerProps }) : null,
        React.createElement("div", { className: "cn-ui-shell-body" },
            sideBarVisible ? React.createElement(SideBar, null) : null,
            React.createElement(Content, null, children))));
};
