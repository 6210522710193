import * as React from 'react';
import './index.scss';
export class DragLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startClientX: 0,
        };
    }
    setOffset(val) {
        this.setState({
            startClientX: val,
        });
    }
    reset() {
        this.setState({
            startClientX: 0,
        });
    }
    render() {
        const { startClientX } = this.state;
        const { tableClientX } = this.props;
        return startClientX > 0 ? (React.createElement("div", { className: "cn-ui-table-resize-drag-line-container", style: { transform: `translateX(${startClientX - tableClientX}px)` } },
            React.createElement("div", { className: "cn-ui-table-resize-drag-line" }))) : null;
    }
}
