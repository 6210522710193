import { createSchemaField } from '@formily/react';
import { componentMap } from '@/form/component-map';
export const createScope = (scope) => {
    if (scope) {
        return createSchemaField({
            components: componentMap,
            scope,
        });
    }
    else {
        return createSchemaField({
            components: componentMap,
        });
    }
};
export const SchemaField = createScope();
