import $i18n from 'panda-i18n';
import React, { useCallback, useMemo, useState } from 'react';
import useLatest from 'ahooks/lib/useLatest';
import classNames from 'classnames';
import { CnDialog } from '@/components/cn-dialog';
import { events, friendlyTips } from '@/components/cn-utils';
import { useCnRequest } from '@cainiaofe/cn-ui-utils';
import { CnMessage } from '@/components/cn-message';
import { CnOSSUpload } from '../cn-oss-upload';
import { CnButton } from '@/components/cn-button';
import { cssPrefix } from './constant';
import ImportButton from './import-button';
import LoopDialog from './loop-dialog';
import { LOOPING_IMPORT_JOB } from '../cn-async-jobs-pro/constant';
export const CnOSSImportView = (props) => {
    const { children, style, className, buttonProps, buttonText, autoImport, uploadMsgSlot, uploadProps: cnUploadProps, createService, createRequestConfig: _createRequestConfig, hideLoop: hideLoopDialog, pollingService, pollingRequestConfig: _pollingRequestConfig, pollingInterval, showBgProcessBtn, onCreateSuccess, onSuccess, onSome, onError, accept, } = props;
    const [jobId, setJobId] = useState();
    const [jobCreateLoading, setJobCreateLoading] = useState(false);
    const [ossData, setOssData] = useState();
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [loopDialogVisible, setLoopDialogVisible] = useState(false);
    const onCreateSuccessRef = useLatest(onCreateSuccess);
    const onErrorRef = useLatest(onError);
    const createRequestConfig = useMemo(() => {
        const main = () => {
            if (_createRequestConfig) {
                return _createRequestConfig;
            }
            if (typeof createService === 'function') {
                return {
                    service: (params) => {
                        if (typeof params === 'string') {
                            return createService(params);
                        }
                        return createService(params.key);
                    },
                };
            }
            return createService;
        };
        return { ...main(), manual: true };
    }, [createService, _createRequestConfig]);
    const pollingRequestConfig = useMemo(() => {
        const main = () => {
            if (_pollingRequestConfig) {
                return _pollingRequestConfig;
            }
            if (typeof pollingService === 'function') {
                return {
                    service: pollingService,
                };
            }
            return pollingService;
        };
        return { ...main(), manual: true };
    }, [pollingService, _pollingRequestConfig]);
    const openUploadDialog = useCallback(() => {
        setUploadDialogVisible(true);
    }, []);
    const _onCreateJobSuccess = useCallback((job) => {
        if (onCreateSuccessRef.current) {
            onCreateSuccessRef.current(job);
        }
        else if (hideLoopDialog) {
            CnMessage.success($i18n.get({
                id: 'TaskCreationSuccessful',
                dm: '任务创建成功',
                ns: 'CnOssImport',
            }));
        }
    }, [hideLoopDialog, onCreateSuccessRef]);
    const { runAsync } = useCnRequest(createRequestConfig);
    /**
     * 创建导入任务
     */
    const _createJob = useCallback(async (_ossData) => {
        if (!_ossData)
            return;
        setJobCreateLoading(true);
        try {
            const result = await runAsync({ key: _ossData.key });
            if (result === null || result === void 0 ? void 0 : result.jobId) {
                _onCreateJobSuccess(result);
                events.emit(LOOPING_IMPORT_JOB);
                if (hideLoopDialog) {
                    setUploadDialogVisible(false);
                    setOssData(undefined);
                }
                else {
                    setLoopDialogVisible(true);
                    setJobId(result.jobId);
                }
            }
            else {
                throw new Error($i18n.get({
                    id: 'TheCreatedInterfaceDoesNotReturn_287404140',
                    dm: '创建接口未返回jobId，请检查导入任务是否创建成功',
                    ns: 'CnOssImport',
                }));
            }
        }
        catch (err) {
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else {
                friendlyTips({
                    component: 'CnImport',
                    message: $i18n.get({
                        id: 'CreateImportTaskException',
                        dm: '创建导入任务异常',
                        ns: 'CnOssImport',
                    }),
                });
            }
            setJobCreateLoading(false);
        }
    }, [runAsync, _onCreateJobSuccess, hideLoopDialog, onErrorRef]);
    /**
     * 创建导入任务
     */
    const createJob = useCallback(() => {
        _createJob(ossData);
    }, [_createJob, ossData]);
    /**
     * 导入文件上传成功后，判断是否创建导出任务
     */
    const onUpload = useCallback(async (fileList) => {
        var _a;
        const file = fileList[0];
        if (!file)
            return;
        setOssData(file);
        (_a = cnUploadProps === null || cnUploadProps === void 0 ? void 0 : cnUploadProps.onChange) === null || _a === void 0 ? void 0 : _a.call(cnUploadProps, fileList, file);
        if (autoImport)
            _createJob(file);
    }, [_createJob, autoImport, cnUploadProps]);
    return (React.createElement("div", { "data-name": "CnOSSImport", className: classNames(`${cssPrefix}wrapper`, className), style: style },
        React.createElement(ImportButton, { buttonProps: buttonProps, buttonText: buttonText, onClick: openUploadDialog }, children),
        React.createElement(CnDialog, { className: `${cssPrefix}dialog`, title: $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' }), visible: uploadDialogVisible, onOk: createJob, okProps: { visible: false }, cancelProps: { visible: false }, onClose: () => setUploadDialogVisible(false), afterClose: () => {
                setJobCreateLoading(false);
                setOssData(undefined);
            }, cache: false, footer: React.createElement(React.Fragment, null,
                React.createElement(CnButton, { onClick: () => setUploadDialogVisible(false), size: "medium" }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnOssImport' })),
                autoImport ? null : (React.createElement(CnButton, { style: { marginLeft: 8 }, disabled: !ossData, loading: jobCreateLoading, type: "primary", onClick: createJob, size: "medium" }, $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' })))) },
            React.createElement("div", { className: `${cssPrefix}content-wrapper` },
                uploadMsgSlot ? (React.createElement("div", { className: `${cssPrefix}message-slot` },
                    React.createElement(CnMessage, { type: "notice" }, uploadMsgSlot))) : null,
                React.createElement(CnOSSUpload, { className: `${cssPrefix}upload`, shape: "dragger", ...cnUploadProps, accept: accept, multiple: false, limit: 1, onChange: onUpload }))),
        jobId ? (React.createElement(LoopDialog, { jobId: jobId, setJobId: setJobId, onRender: () => setUploadDialogVisible(false), visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingRequestConfig: pollingRequestConfig, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onSome: onSome, onError: onError })) : null));
};
CnOSSImportView.defaultProps = {
    accept: '.xls,.xlsx',
    pollingInterval: 5000,
};
