import * as React from 'react';
import { CnTree, CnTreeNode } from '@/components/cn-tree';
import { normalizeToArray } from '@/components/cn-tree/view/util';
import { valueToSelectKey } from '@fusion/lib/select/util';
import { flatDataSource } from '@/components/cn-tree-select/tree-select/tree-select';
import { useCnRequest } from '@/components/cn-utils';
import { findDeep } from '@/components/cn-tree-select/utils';
const Tree = React.forwardRef((props, ref) => {
    const isControlled = 'value' in props;
    const { value: propValue, defaultValue, children, dataSource, checkable, checkedStrategy, onChange, ...others } = props;
    const [value, setValue] = React.useState(normalizeToArray(isControlled ? propValue : defaultValue).map(v => {
        return valueToSelectKey(v);
    }));
    const [, forceUpdate] = React.useState(1);
    const datasetRef = React.useRef(flatDataSource(props));
    const { _k2n, _p2n, _v2n } = datasetRef.current;
    const treeProps = {};
    React.useEffect(() => {
        if (isControlled && value !== propValue) {
            setValue(normalizeToArray(propValue).map(v => {
                return valueToSelectKey(v);
            }));
        }
    }, [propValue]);
    React.useEffect(() => {
        datasetRef.current = flatDataSource(props);
        forceUpdate((s) => (s + 1) % 32);
    }, [dataSource, children]);
    const createNodesByData = (ds) => {
        const loop = (data, prefix = '0') => {
            const retainedNodes = [];
            data.forEach((item, index) => {
                var _a;
                const { children, ...others } = item;
                const pos = `${prefix}-${index}`;
                const key = (_a = _p2n[pos]) === null || _a === void 0 ? void 0 : _a.key;
                if (key) {
                    retainedNodes.push(React.createElement(CnTreeNode, { ...others, key: key }, children && children.length ? loop(children, pos) : null));
                }
            });
            return retainedNodes;
        };
        return loop(ds);
    };
    const getKeysByValue = (value) => {
        return value.reduce((ret, v) => {
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                ret.push(k);
            }
            return ret;
        }, []);
    };
    const getValueByKeys = (keys) => {
        return keys.map(k => _k2n[k].value);
    };
    const getData = (value) => {
        const ret = value.reduce((ret, v) => {
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                const { label, pos } = _k2n[k];
                const d = {
                    value: v,
                    label,
                    pos,
                };
                d.key = k;
                ret.push(d);
            }
            return ret;
        }, []);
        return ret;
    };
    const handleSelect = (selectedKeys) => {
        const value = getValueByKeys(selectedKeys);
        if (!isControlled) {
            setValue(value);
        }
        const data = getData(value);
        props.multiple ? onChange(value, data) : onChange(value[0], data[0]);
    };
    const handleCheck = (checkedKeys) => {
        const value = getValueByKeys(checkedKeys);
        if (!isControlled) {
            setValue(value);
        }
        onChange(value, getData(value));
    };
    const keys = getKeysByValue(value);
    if (checkable) {
        treeProps.checkable = checkable;
        treeProps.checkedStrategy = props.checkStrictly ? 'all' : checkedStrategy;
        treeProps.checkedKeys = keys;
        treeProps.onCheck = handleCheck;
    }
    else {
        treeProps.selectedKeys = keys;
        treeProps.onSelect = handleSelect;
    }
    return (React.createElement(CnTree, { ref: ref, ...others, ...treeProps }, (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) ? createNodesByData(dataSource) : children));
});
export const FormTree = React.forwardRef((props, ref) => {
    const { requestConfig, enableRemoteLazyLoad, ...others } = props;
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    const remoteLazyLoadItemRef = React.useRef();
    const treeProps = {};
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    const { runAsync, data, loading } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    React.useEffect(() => {
        if (!isRemoteDataSource)
            return;
        if (loading)
            return;
        setInnerDataSource((previousDataSource) => {
            var _a;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                const valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return [...previousDataSource];
            }
            return data;
        });
    }, [data]);
    if (isRemoteDataSource) {
        treeProps.dataSource = innerDataSource;
    }
    if (enableRemoteLazyLoad) {
        treeProps.loadData = ({ props: item }) => {
            remoteLazyLoadItemRef.current = item;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).catch(err => {
                console.log(err);
            });
        };
    }
    return (React.createElement(Tree, { ref: ref, ...others, ...treeProps }));
});
FormTree.defaultProps = {
    requestConfig: {},
};
