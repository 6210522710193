import $i18n from 'panda-i18n';
import isNumber from 'lodash/isNumber';
import BigNumber from 'bignumber.js';
export default (value, rule) => {
    if (isNumber(value) && new BigNumber(value).isGreaterThanOrEqualTo(rule.min)) {
        return true;
    }
    return ($i18n.get({
        id: 'TheValueCannotBeLess',
        dm: '数值不能小于',
        ns: 'CnForm',
    }) + rule.min);
};
