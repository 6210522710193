import * as React from 'react';
import { Button as NextButton } from '@fusion/lib';
import { useAsyncLoading, useSizeChange, HocBaseComponents, useCreatePluginInstance, } from '@/components/cn-utils';
import classNames from 'classnames';
const CnButtonOrigin = React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;
    const wrapLoading = useAsyncLoading(props);
    const { size } = useSizeChange(props);
    return (React.createElement(NextButton, { className: classNames('cn-ui-button', className), "data-hottag": "cn-ui.cn-button", "data-name": "CnButton", size: size, ...restProps, ...wrapLoading, ref: ref }));
});
export const CnButton = React.forwardRef((props, ref) => {
    var _a;
    const pluginIns = useCreatePluginInstance('CnButton', props === null || props === void 0 ? void 0 : props.usePlugin);
    const plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnButtonOrigin, { ...props, ref: ref });
    }
    return HocBaseComponents(CnButtonOrigin, { props, plugins, ref });
});
/**
 * @deprecated
 * 建议使用 SplitButtonGroup
 */
CnButton.Group = NextButton.Group;
CnButton.displayName = 'CnButton';
CnButton.defaultProps = {
    type: 'normal',
    // size: 'medium',
    icons: {},
    htmlType: 'button',
    component: 'button',
    loading: false,
    ghost: false,
    text: false,
    warning: false,
    disabled: false,
    onClick: function onClick() { },
};
const CnSplitButtonGroupOrigin = React.forwardRef((props, ref) => {
    const { className } = props;
    const { size } = useSizeChange(props);
    return (React.createElement(NextButton.Group, { ref: ref, className: classNames('cn-ui-button-group', className), size: size, ...props }));
});
export const CnSplitButtonGroup = React.forwardRef((props, ref) => {
    var _a;
    const pluginIns = useCreatePluginInstance('CnSplitButtonGroup', props === null || props === void 0 ? void 0 : props.usePlugin);
    const plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnSplitButtonGroupOrigin, { ...props, ref: ref });
    }
    return HocBaseComponents(CnSplitButtonGroupOrigin, { props, plugins, ref });
});
export const Button = CnButton;
export const SplitButtonGroup = NextButton.Group;
