/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { useRequest } from 'ahooks';
import isFunction from 'lodash/isFunction';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { Select as NextSelect } from '@/components/fusion';
import { CnLoading } from '@/components/cn-loading';
import { packRequest as request, loopDataSource } from '@/components/cn-utils';
import { CnReadOnly } from '@/components/cn-read-only';
import './index.scss';
const { AutoComplete: NextAutoComplete } = NextSelect;
function handleRequestService(requestConfig, remoteUrl) {
    const { service, searchKey = 'key', params: paramsProps, data: dataProps, searchFormat: searchFormatProps = (params) => params, formatParam, formatResult = (res) => {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    }, method = 'GET', } = requestConfig || {};
    if (isFunction(service)) {
        return async (searchConfig) => {
            var _a;
            return (_a = service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then((data) => {
                const dataSource = formatResult(data);
                return Array.isArray(dataSource) ? dataSource : [];
            });
        };
    }
    if (remoteUrl) {
        return async (searchConfig) => {
            const searchFormat = formatParam !== null && formatParam !== void 0 ? formatParam : searchFormatProps;
            const params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat({ [searchKey]: searchConfig, ...paramsProps })
                : paramsProps;
            const data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat({ [searchKey]: searchConfig, ...dataProps })
                : dataProps;
            const resultData = await request({
                ...requestConfig,
                params,
                data,
                url: remoteUrl,
            });
            const dataSource = formatResult(resultData);
            return Array.isArray(dataSource) ? dataSource : [];
        };
    }
    return undefined;
}
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: React.createElement("div", { className: "cn-async-select-error" }, "\u8BF7\u6C42\u6570\u636E\u5F02\u5E38"),
    };
}
function handleLoading(loading, data) {
    const ContentLoading = () => {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(CnLoading, { size: "medium" })));
    };
    const IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
function onSearchClear(run, onSearchClearProps, actionType) {
    onSearchClearProps(actionType, run);
}
export const AutoComplete = React.forwardRef((props, ref) => {
    var _a;
    const { remoteUrl: url, requestConfig, initRequestConfig, readOnly, readOnlyProps, onVisibleChange: onVisibleChangeProps, onFocus: onFocusProps, onSearchClear: onSearchClearProps = () => { }, popupContainer, $i18n, valueRender, fieldNames, ...otherProps } = props;
    // useRequest 相关配置
    const { service, searchFormat, url: ConfighUrl, ...otherConfig } = requestConfig || {};
    const remoteUrl = ConfighUrl || url;
    const insertSelectProps = {};
    const requestService = (_a = handleRequestService(requestConfig, remoteUrl)) !== null && _a !== void 0 ? _a : function () {
        return Promise.resolve([]);
    };
    const { run, error, data = [], loading, } = useRequest(requestService, {
        ready: !!(remoteUrl || service),
        ...otherConfig,
    });
    const onVisibleChange = (visible, type) => {
        if (isFunction(onVisibleChangeProps)) {
            onVisibleChangeProps(visible, type, run);
        }
    };
    const onFocus = (params) => {
        if (isFunction(onFocusProps)) {
            onFocusProps(params, run);
        }
    };
    // const onVisibleChange = (visible: boolean, type?: string) => {
    //   if (isFunction(onVisibleChangeProps)) {
    //     onVisibleChangeProps(visible, type, run);
    //   }
    // };
    insertSelectProps.dataSource = data;
    const errorInfo = handleErrorInfo(error);
    const loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    const enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = (inputValue) => {
            run(inputValue);
        };
    }
    insertSelectProps.isPreview = readOnly;
    insertSelectProps.renderPreview = (values) => {
        return (React.createElement(CnReadOnly, { type: "enum", value: values, ...{
                ...pick(props, [
                    'addonBefore',
                    'innerBefore',
                    'addonTextBefore',
                    'addonTextAfter',
                    'innerAfter',
                    'addonAfter',
                ]),
                ...readOnlyProps,
            } }));
    };
    let dataSource = 'dataSource' in props ? props.dataSource : data;
    if (fieldNames) {
        dataSource = loopDataSource(dataSource, fieldNames);
    }
    const onSearchClearCallBack = React.useMemo(() => {
        return onSearchClear.bind(null, run, onSearchClearProps);
    }, []);
    return (React.createElement(NextAutoComplete, { "data-name": "CnAsyncSelect", ref: ref, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), ...insertSelectProps, ...omit(otherProps, ['$i18n']), dataSource: dataSource, onVisibleChange: onVisibleChange, onFocus: onFocus, onSearchClear: onSearchClearCallBack }));
});
