import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import formatColumns from './utils/format-columns';
import getColumnsKey from './utils/get-columns-key';
import { ColumnsStateContext } from './context';
/**
 * 列配置上下文组件
 * @export
 * @param {{
 *   isDialog?: boolean;
 *   columns: ICnTableColumn[];
 *   setColumns: (columns: ICnTableColumn[], type?: string) => void;
 *   children: React.ReactNode;
 * }} props
 * @return {*}
 */
export function ColumnsSettingContextProvider(props) {
    const { isDialog, columns: originColumns, setColumns: setOriginColumns, } = props;
    /**
     * 格式化表格列配置
     * @type {[ICnTableColumn[], Dispatch<SetStateAction<ICnTableColumn[]>>]}
     */
    const [columns, setColumns] = useState(formatColumns(originColumns));
    /**
     * 已选中的列的键名
     * @type {string[]}
     */
    const checkedColumnKeys = useMemo(() => {
        return columns.reduce((result, item) => {
            if (item.hidden)
                return result;
            return [...result, item.dataIndex];
        }, []);
    }, [columns]);
    /**
     * 当原始表格列配置变更时，重新格式化表格列配置
     */
    useEffect(() => {
        setColumns(formatColumns(originColumns));
    }, [originColumns]);
    /**
     * 列的键名
     * @type {string[]}
     */
    const columnKeys = useMemo(() => {
        return getColumnsKey(originColumns);
    }, [originColumns]);
    /**
     * 固定在左侧的列
     * @type {ICnTableColumn[]}
     */
    const fixLeftColumns = useMemo(() => {
        return columns.filter((item) => item.lock === 'left' || item.lock === true);
    }, [columns]);
    /**
     * 固定在右侧的列
     * @type {ICnTableColumn[]}
     */
    const fixRightColumns = useMemo(() => {
        return columns.filter((item) => item.lock === 'right');
    }, [columns]);
    /**
     * 不固定的列
     * @type {ICnTableColumn[]}
     */
    const noFixColumns = useMemo(() => {
        return columns.filter((item) => !item.lock);
    }, [columns]);
    /**
     * 更新列配置
     */
    const updateColumns = useCallback(() => {
        setOriginColumns(columns, 'user');
    }, [columns, setOriginColumns]);
    /**
     * 取消列配置变更
     * 使用场景：当CnDialog关闭时，但dom未销毁
     */
    const cancelUpdateColumns = useCallback(() => {
        setColumns(formatColumns(originColumns));
    }, [setColumns, originColumns]);
    /**
     * 列配置上下文输出
     * @type {IColumnConfig}
     */
    const output = {
        isDialog,
        columns,
        setColumns,
        fixLeftColumns,
        fixRightColumns,
        noFixColumns,
        columnKeys,
        checkedColumnKeys,
        checkedAll: checkedColumnKeys.length === columns.length,
        updateColumns,
        cancelUpdateColumns,
    };
    return (React.createElement(ColumnsStateContext.Provider, { value: output }, props.children));
}
