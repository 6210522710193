import React, { forwardRef, useCallback, useState, useRef, useEffect, } from 'react';
import { Tag } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import moment from 'moment';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { sendLog } from '@/components/cn-utils';
import { isEmpty, childrenToDataSource, valueToDataSourceItem, defaultDateFormat, getNormalizedDisplayName, } from '../../utils';
import { useFilterRefsContext } from '../../context';
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
export const useItemCollection = (itemProps) => {
    const filterContext = useFilterRefsContext();
    const defaultChildRef = useRef(null);
    const fusionChildRef = useRef(null);
    const itemPropsRef = useRef(null);
    const setValueRender = (valueRender) => {
        if (typeof valueRender === 'function') {
            itemPropsRef.current = {
                renderSelected: (_key, val, label) => {
                    return valueRender(val, label);
                },
            };
        }
        const { update } = filterContext || {};
        update && update();
    };
    const setRef = (target, ref) => {
        if (target && !target._observeProps) {
            target._observeProps = true;
            target._props = target.props;
            Object.defineProperty(target, 'props', {
                get: () => {
                    return target._props;
                },
                set: (val) => {
                    const { update } = filterContext || {};
                    if (target._props !== val) {
                        update && update();
                    }
                    target._props = val;
                },
            });
        }
        ref.current = target;
    };
    const getCollectionInjectPorps = (displayName) => {
        const inject = {
            ref: (target) => {
                setRef(target, defaultChildRef);
            },
        };
        if (!formatMap.has(displayName) && !uninjectSet.has(displayName)) {
            inject.filterFusionRef = (target) => setRef(target, fusionChildRef);
            inject.setFilterValueRender = setValueRender;
            inject.setFilterRenderValue = setValueRender;
            inject.itemPropsRef = itemPropsRef;
        }
        return inject;
    };
    const setCollection = (name, displayName) => {
        filterContext.itemCollection[name] = {
            itemProps,
            defaultChildRef,
            fusionChildRef,
            itemPropsRef,
            displayName,
        };
    };
    return {
        getCollectionInjectPorps,
        setCollection,
    };
};
const formatWithDataSource = (key, value, dataSource, cache) => {
    let itemCache = cache.get(key);
    if (!itemCache) {
        itemCache = new Map();
        cache.set(key, itemCache);
    }
    const items = valueToDataSourceItem(value, dataSource);
    const valueItems = items.map((item) => {
        if (!item || !item.value) {
            // 在dataSource中未匹配的，尝试在cache中匹配
            const label = itemCache.get(item);
            if (label) {
                return {
                    label,
                    value: item,
                };
            }
        }
        return item;
    });
    // 更新缓存中label
    itemCache.clear();
    valueItems.forEach((item) => {
        if (item === null || item === void 0 ? void 0 : item.label) {
            itemCache.set(item.value, item.label);
        }
    });
    return valueItems
        .map((item) => item.label || item.value)
        .filter((val) => !isEmpty(val))
        .join(', ');
};
const defaultFormat = (key, value, child, cache) => {
    if (Array.isArray(value)) {
        return value
            .filter((val) => !isEmpty(val))
            .map((val) => defaultFormat(key, val, child, cache))
            .join(',');
    }
    return value;
};
const uninjectSet = new Set([
    'Base',
    'NumberPicker',
    'DatePicker2',
    'RangePicker2',
    'WeekPicker2',
    'MonthPicker2',
    'QuarterPicker2',
    'YearPicker2',
    'Range',
    'Rating',
    'Switch',
]);
const formatMap = new Map([
    [
        'CheckboxGroup',
        (key, value, child, cache) => {
            const { dataSource, children } = (child === null || child === void 0 ? void 0 : child.props) || {};
            let normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Checkbox', (element) => {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'RadioGroup',
        (key, value, child, cache) => {
            const { dataSource, children } = (child === null || child === void 0 ? void 0 : child.props) || {};
            let normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Radio', (element) => {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'CascaderSelect',
        (key, value, child, cache) => {
            const { dataSource } = (child === null || child === void 0 ? void 0 : child.props) || {};
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            return formatWithDataSource(key, value, dataSource, cache);
        },
    ],
    [
        'DatePicker',
        (_, value, child) => {
            var _a;
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            let format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = `${format} ${((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time}`;
            }
            return moment(value).format(format);
        },
    ],
    [
        'RangePicker',
        (_, value, child) => {
            var _a;
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            let format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = `${format} ${((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time}`;
            }
            if (typeof value === 'string')
                return value;
            return value
                .map((val) => (val ? moment(val).format(format) : ''))
                .join(' ~ ');
        },
    ],
    [
        'WeekPicker',
        (_, value, child) => {
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const format = props.format || defaultDateFormat.week;
            return moment(value).format(format);
        },
    ],
    [
        'MonthPicker',
        (_, value, child) => {
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const format = props.format || defaultDateFormat.month;
            return moment(value).format(format);
        },
    ],
    [
        'YearPicker',
        (_, value, child) => {
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const format = props.format || defaultDateFormat.year;
            return moment(value).format(format);
        },
    ],
    [
        'Picker',
        (_, value, child) => {
            var _a, _b;
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const { type } = props;
            const mode = (props === null || props === void 0 ? void 0 : props.mode) || 'date';
            if (type === 'range') {
                if (!(value === null || value === void 0 ? void 0 : value.length))
                    return '';
                let format = props.format ||
                    defaultDateFormat[mode] ||
                    defaultDateFormat.date;
                if (props.mode === 'date' && props.showTime) {
                    format = `${format} ${((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) ||
                        defaultDateFormat.time}`;
                }
                if (typeof value === 'string')
                    return value;
                return value
                    .map((val) => (val ? dayjs(val).format(format) : ''))
                    .join(' ~ ');
            }
            let format = props.format ||
                defaultDateFormat[mode] ||
                defaultDateFormat.date;
            if (mode === 'date' && props.showTime) {
                format = `${format} ${((_b = props.showTime) === null || _b === void 0 ? void 0 : _b.format) || defaultDateFormat.time}`;
            }
            return dayjs(value).format(format);
        },
    ],
    [
        'Select',
        (key, value, child, cache) => {
            const { dataSource, children } = (child === null || child === void 0 ? void 0 : child.props) || {};
            let normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Option', (element) => {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'TimePicker',
        (_, value, child) => {
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const format = props.format || defaultDateFormat.time;
            return moment(value).format(format);
        },
    ],
    [
        'TimePicker2',
        (_, value, child) => {
            if (!value)
                return '';
            const props = (child === null || child === void 0 ? void 0 : child.props) || {};
            const format = props.format || defaultDateFormat.time;
            return dayjs(value).format(format);
        },
    ],
    [
        'TreeSelect',
        (key, value, child, cache) => {
            const { dataSource, children } = (child === null || child === void 0 ? void 0 : child.props) || {};
            let normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'TreeNode', (element) => {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.label,
                    };
                });
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
]);
const formatRenderItem = (key, value, itemColloction, selectedCache) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const targetColloction = itemColloction[key];
    const { label, renderSelected } = (targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemProps) || {};
    if (renderSelected) {
        return renderSelected(key, value, label);
    }
    if ((_b = (_a = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemPropsRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.renderSelected) {
        return (_d = (_c = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemPropsRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.renderSelected(key, value, label);
    }
    const prefix = label ? `${label}: ` : '';
    const target = (((_e = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.fusionChildRef) === null || _e === void 0 ? void 0 : _e.current) ||
        ((_f = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.defaultChildRef) === null || _f === void 0 ? void 0 : _f.current));
    const formatFn = formatMap.get(getNormalizedDisplayName((((_g = target === null || target === void 0 ? void 0 : target._reactInternals) === null || _g === void 0 ? void 0 : _g.type) ||
        ((_h = target === null || target === void 0 ? void 0 : target._reactInternalFiber) === null || _h === void 0 ? void 0 : _h.type)))) || defaultFormat;
    return prefix + formatFn(key, value, target, selectedCache);
};
const TagItem = ({ item, handleClose }) => {
    const [isOverflow, setIsOverflow] = React.useState(false);
    const tagRef = React.useRef(null);
    const isStringText = typeof item.text === 'string';
    React.useEffect(() => {
        if (!isStringText || !tagRef || !tagRef.current)
            return;
        let tagBody;
        try {
            tagBody = tagRef.current
                .getInstance()
                ._reactInternalFiber.child.stateNode.getInstance()
                .tagNode.querySelector('.cn-next-tag-body');
        }
        catch (e) { }
        if (!tagBody)
            return;
        setIsOverflow(tagBody.scrollWidth > tagBody.offsetWidth);
    }, [item]);
    if (!item.text)
        return null;
    if (isStringText && isOverflow) {
        return (React.createElement(CnTooltip, { v2: true, trigger: React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: () => handleClose(item) }, item.text) }, item.text));
    }
    return (React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: () => handleClose(item) }, item.text));
};
export const FilterSelectedTags = forwardRef((props, ref) => {
    const { values, innerValues, onRemove, extend } = props;
    // 缓存上次计算的label，value不变时直接返回上次计算的有效label，当datasource变化时也能得到原先的label，以应对动态datasource的情况
    const selectedCacheRef = useRef(new Map());
    const [, update] = useState(0);
    const filterContext = useFilterRefsContext();
    const filterItemColloction = (filterContext === null || filterContext === void 0 ? void 0 : filterContext.itemCollection) || {};
    useEffect(() => {
        filterContext.update = () => update((i) => (i + 1) % 32);
        return () => {
            filterContext.update = () => undefined;
        };
    }, [filterContext]);
    filterContext.update = () => update((i) => (i + 1) % 32);
    Object.keys({
        ...values,
        ...innerValues,
    }).forEach((key) => {
        const targetValue = values === null || values === void 0 ? void 0 : values[key];
        const targetInnerValue = innerValues === null || innerValues === void 0 ? void 0 : innerValues[key];
        const value = [];
        if (Array.isArray(targetValue)) {
            value.push(...targetValue);
        }
        else {
            value.push(targetValue);
        }
        if (Array.isArray(targetInnerValue)) {
            value.push(...targetInnerValue);
        }
        else {
            value.push(targetInnerValue);
        }
        if (value.filter((val) => !isEmpty(val)).length === 0) {
            selectedCacheRef.current.delete(key);
        }
    });
    // cache innerValues text
    Object.entries(innerValues || {}).forEach(([key, value]) => {
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter((val) => !isEmpty(val)).length)) {
            return;
        }
        formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current);
    });
    const renderItems = Object.entries(values || {})
        .map(([key, value]) => {
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter((val) => !isEmpty(val)).length)) {
            return null;
        }
        const text = formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current);
        return {
            key,
            value,
            text,
        };
    })
        .filter(Boolean);
    const handleClose = useCallback((item) => {
        sendLog({
            id: 'cn-ui.cn-filter.deleteSelectedTag',
            name: 'CnFilter标签删除点击',
        });
        onRemove && onRemove(item.key);
        return true;
    }, [onRemove]);
    return (React.createElement(Tag.Group, { ref: ref, className: "cn-ui-filter-selected-tags" },
        extend,
        renderItems.map((item, idx) => (React.createElement(TagItem, { key: idx, item: item, handleClose: handleClose })))));
});
FilterSelectedTags.displayName = 'FilterSelectedTags';
export default FilterSelectedTags;
