import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
/**
 * 根据菜单 key 找根节点
 * @param key 要寻找的那个 key
 * @returns 根节点
 */
export const isKeyParent = (menu, childKey, parentKey) => {
    const keyParentMap = {};
    Array.isArray(menu) &&
        menu.forEach((menuItem) => {
            var _a;
            keyParentMap[menuItem.key] = [];
            keyParentMap[menuItem.key].push(menuItem.key);
            if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) {
                menuItem.children.forEach((subItem) => {
                    var _a;
                    keyParentMap[subItem.key] = [];
                    keyParentMap[subItem.key].push(subItem.key);
                    keyParentMap[menuItem.key].push(subItem.key);
                    if ((_a = subItem.children) === null || _a === void 0 ? void 0 : _a.length) {
                        subItem.children.forEach((thirdLevelItem) => {
                            keyParentMap[menuItem.key].push(thirdLevelItem.key);
                            keyParentMap[subItem.key].push(thirdLevelItem.key);
                        });
                    }
                });
            }
        });
    let flag = false;
    Object.keys(keyParentMap).forEach((menuKey) => {
        if (menuKey === parentKey && keyParentMap[menuKey].includes(childKey)) {
            flag = true;
        }
    });
    return flag;
};
/**
 * 找出当前菜单中匹配指定建值对的 key 与 parentKey
 */
export const findMenuItemByPair = (menu, { key, value, }) => {
    let findResult = '';
    let flag = false;
    const find = (list, parent = '') => {
        if (flag)
            return;
        list.forEach((item) => {
            var _a;
            if (flag)
                return true;
            if (item[key] === value && !((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length)) {
                findResult = `${parent},${item.key}`;
                flag = true;
                return;
            }
            if (item.children) {
                find(item.children, `${parent},${item.key}`);
            }
        });
    };
    find(menu);
    const ret = { currentKey: null, parentKeys: null };
    const keys = compact(findResult.split(','));
    if (keys === null || keys === void 0 ? void 0 : keys.length) {
        ret.currentKey = keys.pop();
        ret.parentKeys = keys;
    }
    return ret;
};
/**
 * 获取当前选中的分类和菜单
 */
export const getParentKeysBySelected = (menu, selectedMenuKey) => {
    const { currentKey, parentKeys } = findMenuItemByPair(menu, {
        key: 'key',
        value: selectedMenuKey,
    });
    if (currentKey && Array.isArray(parentKeys) && parentKeys.length > 0) {
        return uniq(parentKeys);
    }
    return [];
};
export const getMenuKeysWitchHasChildren = (menu) => {
    const rst = [];
    const find = (m) => {
        m.forEach((i) => {
            var _a;
            if (!((_a = i === null || i === void 0 ? void 0 : i.children) === null || _a === void 0 ? void 0 : _a.length))
                return;
            rst.push(i.key);
            find(i.children);
        });
    };
    find(menu);
    return rst;
};
