import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { CnInput } from '@/components/cn-input';
import { formatCardNumber, IMAGES, getCardTypeByValue } from './utils';
const numberReg = /^[0-9]+$/;
export const CnBankInput = (props) => {
    const { value, defaultValue, readOnly, placeholder, onChange, readOnlyRender, cardTypes, maxLength, className, ...others } = props;
    const initValue = typeof defaultValue !== 'undefined' ? defaultValue : value;
    const [curValue, setCurValue] = useState(initValue);
    useEffect(() => {
        if (typeof value !== 'undefined') {
            setCurValue(value);
        }
    }, [value]);
    // 获取银行卡类型和格式化之后的字段
    const [bankCode, cardNumber, cardType] = useMemo(() => {
        const resetValue = String(curValue).replace(/\s/g, '');
        const cType = getCardTypeByValue(resetValue, cardTypes);
        const formattedValue = formatCardNumber(resetValue, cardTypes);
        return [cType.bankCode.toLowerCase(), formattedValue, cType];
    }, [curValue, cardTypes]);
    function handleChange(v, e) {
        const resetValue = String(v).replace(/\s/g, '');
        // 如果存在非数字，直接return
        if (resetValue && !numberReg.test(resetValue)) {
            return;
        }
        // 最大长度
        if (maxLength && resetValue.length > maxLength) {
            return;
        }
        // 受控类型
        if (typeof value === 'undefined') {
            setCurValue(resetValue);
        }
        onChange && onChange(resetValue, e);
    }
    const cls = classnames('cn-ui-bank-input', {
        'cn-ui-bank-input-readonly': readOnly,
        [className]: !!className,
    });
    if (readOnly) {
        if (readOnlyRender) {
            return readOnlyRender({ ...props, cardType });
        }
        return (React.createElement("span", { className: cls },
            React.createElement("img", { className: "cn-ui-bank-input-badge", src: IMAGES[bankCode] }),
            React.createElement("span", { className: "cn-ui-bank-input-name" }, cardType === null || cardType === void 0 ? void 0 : cardType.bankName),
            React.createElement("span", { className: "cn-ui-bank-input-number" }, cardNumber)));
    }
    return (React.createElement("span", { className: cls, "data-name": "CnBankInput" },
        React.createElement(CnInput, { ...others, className: "cn-ui-bank-input-input", value: cardNumber, onChange: handleChange, innerBefore: React.createElement("img", { className: "cn-ui-bank-input-badge", src: IMAGES[bankCode] }) })));
};
CnBankInput.defaultProps = {
    cardTypes: [],
    maxLength: 20,
};
CnBankInput.displayName = 'CnBankInput';
