import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { useFieldSchema } from '@formily/react';
import { createScope } from '../schema-field';
const JSXSchemaField = createScope();
export const JSXField = ({ propsSchema, schema, setSchema, children, formInstance, }) => {
    const jsxFieldSchemaJsonRef = React.useRef();
    React.useEffect(() => {
        var _a, _b;
        (_b = (_a = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query('jsxField')) === null || _a === void 0 ? void 0 : _a.take()) === null || _b === void 0 ? void 0 : _b.setComponentProps({ schema });
    }, [formInstance, schema]);
    // 从 Markup SchemaField 中读取 schema
    const VoidJSXField = ({ schema: s, }) => {
        const jsxFieldSchema = useFieldSchema();
        jsxFieldSchemaJsonRef.current = jsxFieldSchema.toJSON();
        React.useEffect(() => {
            if (!isNil(propsSchema))
                return;
            let jsxFieldSchemaJson = jsxFieldSchema.toJSON();
            if (!jsxFieldSchemaJson || !jsxFieldSchemaJson.properties)
                return;
            jsxFieldSchemaJson = {
                type: 'object',
                properties: jsxFieldSchemaJson.properties,
            };
            if (!isEqual(s, jsxFieldSchemaJson)) {
                setSchema(jsxFieldSchemaJson);
            }
        }, [jsxFieldSchema, s, propsSchema]);
        return null;
    };
    // 如果不是 shcemaField 则自行控制
    if (!jsxFieldSchemaJsonRef.current || !jsxFieldSchemaJsonRef.current.properties) {
        return (React.createElement(React.Fragment, null, children));
    }
    return (React.createElement(JSXSchemaField, null,
        React.createElement(JSXSchemaField.Void, { name: "jsxField", "x-component": VoidJSXField, "x-component-props": {
                schema,
            } }, children)));
};
