import { getRequestService } from '@cainiaofe/cn-ui-utils';
export const preview = async (props) => {
    const { key, requestConfig } = props;
    const previewService = getRequestService(requestConfig);
    // 防止初始化错误
    // @ts-ignore 通过 js script 加载，暂无类型
    if (!window.aliyun) {
        throw new Error('加载失败');
    }
    const previewData = await previewService({ key });
    // webOffice初始化
    const dom = document.createElement('span');
    dom.style.width = '1px';
    dom.style.height = '1px';
    dom.style.opacity = '0';
    document.body.appendChild(dom);
    // @ts-ignore 通过 js script 加载，暂无类型
    const instance = window.aliyun.config({
        mount: dom,
        mode: 'simple',
        url: previewData.webofficeURL,
    });
    // 设置 token
    instance.setToken({
        token: previewData.accessToken,
        timeout: 30 * 60 * 1000,
    });
    await instance.ready();
    dom.remove();
    window.open(previewData.webofficeURL);
};
