import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Overlay } from '@/components/fusion';
import { func, obj, dom } from '@fusion/lib/util';
import Item from './item';
import SelectableItem from './selectable-item';
import { getChildSelected } from './util';
const { bindCtx } = func;
const { setStyle } = dom;
const { Popup } = Overlay;
/**
 * Menu.PopupItem
 * @order 2
 */
export default class PopupItem extends Component {
    constructor(props) {
        super(props);
        bindCtx(this, [
            'handleOpen',
            'handlePopupOpen',
            'handlePopupClose',
            'getPopup',
        ]);
    }
    getPopup(ref) {
        this.popup = ref;
    }
    getOpen() {
        const { _key, root } = this.props;
        const { openKeys } = root.state;
        return openKeys.indexOf(_key) > -1;
    }
    getPopupProps() {
        let { popupProps } = this.props.root.props;
        if (typeof popupProps === 'function') {
            popupProps = popupProps(this.props);
        }
        return popupProps;
    }
    handleOpen(open, triggerType, e) {
        const { _key, root } = this.props;
        root.handleOpen(_key, open, triggerType, e);
        const { popupProps } = this;
        popupProps.onVisibleChange &&
            popupProps.onVisibleChange(open, triggerType, e);
    }
    handlePopupOpen() {
        const { root, level, align, autoWidth } = this.props;
        const { popupAutoWidth: rootPopupAutoWidth, popupAlign: rootPopupAlign, direction, } = root.props;
        const popupAlign = align || rootPopupAlign;
        const popupAutoWidth = 'autoWidth' in this.props ? autoWidth : rootPopupAutoWidth;
        try {
            // avoid errors while dom removed and js executing
            const itemNode = findDOMNode(this);
            const menuNode = itemNode.parentNode;
            this.popupNode = this.popup
                .getInstance()
                .overlay.getInstance()
                .getContentNode();
            root.popupNodes.push(this.popupNode);
            if (popupAutoWidth) {
                const targetNode = direction === 'hoz' && level === 1 ? itemNode : menuNode;
                if (targetNode.offsetWidth > this.popupNode.offsetWidth) {
                    setStyle(this.popupNode, 'width', `${targetNode.offsetWidth}px`);
                }
            }
            if (popupAlign === 'outside' && !(direction === 'hoz' && level === 1)) {
                setStyle(this.popupNode, 'height', `${menuNode.offsetHeight}px`);
                this.popupNode.firstElementChild &&
                    setStyle(this.popupNode.firstElementChild, 'overflow-y', 'auto');
            }
            // removeClass(this.popupNode, `${prefix}hide`);
            const { popupProps } = this;
            popupProps.onOpen && popupProps.onOpen();
        }
        catch (error) {
            return null;
        }
    }
    handlePopupClose() {
        const { root } = this.props;
        const { popupNodes } = root;
        const index = popupNodes.indexOf(this.popupNode);
        index > -1 && popupNodes.splice(index, 1);
        const { popupProps } = this;
        popupProps.onClose && popupProps.onClose();
    }
    renderItem(selectable, children, others) {
        const { _key, root, level, inlineLevel, label, className } = this.props;
        const { prefix, selectMode } = root.props;
        const NewItem = selectable ? SelectableItem : Item;
        const open = this.getOpen();
        const { selectedKeys, _k2n } = root.state;
        const isChildSelected = getChildSelected({
            _key,
            _k2n,
            selectMode,
            selectedKeys,
        });
        const itemProps = {
            'aria-haspopup': true,
            'aria-expanded': open,
            _key,
            root,
            level,
            inlineLevel,
            type: 'submenu',
        };
        itemProps.className = cx({
            [`${prefix}opened`]: open,
            [`${prefix}child-selected`]: isChildSelected,
            [className]: !!className,
        });
        return (React.createElement(NewItem, { ...itemProps, ...others },
            React.createElement("span", { className: `${prefix}menu-item-text` }, label),
            children));
    }
    renderPopup(trigger, triggerType, positionProps, children) {
        const { root, level, selectable, className: propCls } = this.props;
        const { direction } = root.props;
        this.popupProps = this.getPopupProps();
        const open = this.getOpen();
        if (direction === 'hoz' && level === 1 && selectable) {
            positionProps.target = () => findDOMNode(this);
        }
        const { className: posCls, ...otherPostion } = positionProps;
        const className = cx(propCls, posCls);
        return (React.createElement(Popup, { ref: this.getPopup, ...otherPostion, ...this.popupProps, canCloseByEsc: false, trigger: trigger, triggerType: triggerType, visible: open, pinFollowBaseElementWhenFixed: true, onVisibleChange: this.handleOpen, onOpen: this.handlePopupOpen, onClose: this.handlePopupClose },
            React.createElement("div", { className: className }, children)));
    }
    render() {
        const { root, level, hasSubMenu, selectable: selectableFromProps, children, triggerType, align, noIcon, rtl, } = this.props;
        const others = obj.pickOthers(Object.keys(PopupItem.propTypes), this.props);
        const { prefix, selectMode, direction, popupAlign: rootPopupAlign, triggerType: rootTriggerType, } = root.props;
        const popupAlign = align || rootPopupAlign;
        const newTriggerType = triggerType || (hasSubMenu ? rootTriggerType : 'hover');
        const newChildren = Array.isArray(children) ? children[0] : children;
        // let newChildren = Array.isArray(children) ? children[0] : children;
        // newChildren = cloneElement(newChildren, {
        //     className: cx({
        //         [`${prefix}menu-popup-content`]: true,
        //         [newChildren.props.className]: !!newChildren.props.className,
        //         [`${prefix}hide`]: popupAutoWidth || popupAlign === 'outside'
        //     })
        // });
        const selectable = selectMode && selectableFromProps;
        const triggerIsIcon = selectable && newTriggerType === 'click';
        const open = this.getOpen();
        const positionProps = {};
        let arrowProps;
        if (direction === 'hoz' && level === 1) {
            positionProps.align = 'tl bl';
            positionProps.className = `${prefix}menu-spacing-tb`;
            arrowProps = {
                type: 'arrow-down',
                className: cx({
                    [`${prefix}menu-hoz-icon-arrow`]: true,
                    [`${prefix}open`]: open,
                }),
            };
        }
        else {
            if (popupAlign === 'outside') {
                positionProps.target = () => {
                    return findDOMNode(root);
                };
                positionProps.align = 'tl tr';
                positionProps.className = `${prefix}menu-spacing-lr ${prefix}menu-outside`;
            }
            else {
                if (triggerIsIcon) {
                    positionProps.target = () => {
                        return findDOMNode(this);
                    };
                }
                positionProps.align = 'tl tr';
                positionProps.className = `${prefix}menu-spacing-lr`;
            }
            arrowProps = {
                type: 'arrow-right',
                className: `${prefix}menu-icon-arrow ${prefix}menu-symbol-popupfold`,
            };
        }
        const arrow = React.createElement(Icon, { ...arrowProps });
        const trigger = triggerIsIcon
            ? arrow
            : this.renderItem(selectable, noIcon ? null : arrow, others);
        const popup = this.renderPopup(trigger, newTriggerType, positionProps, newChildren);
        return triggerIsIcon ? this.renderItem(selectable, popup, others) : popup;
    }
}
PopupItem.menuChildType = 'submenu';
PopupItem.propTypes = {
    _key: PropTypes.string,
    root: PropTypes.object,
    level: PropTypes.number,
    hasSubMenu: PropTypes.bool,
    noIcon: PropTypes.bool,
    rtl: PropTypes.bool,
    selectable: PropTypes.bool,
    /**
     * 标签内容
     */
    label: PropTypes.node,
    /**
     * 自定义弹层内容
     */
    children: PropTypes.node,
    className: PropTypes.string,
    /**
     * 子菜单打开的触发行为
     */
    triggerType: PropTypes.oneOf(['click', 'hover']),
    align: PropTypes.oneOf(['outside', 'follow']),
    autoWidth: PropTypes.bool,
};
PopupItem.defaultProps = {
    selectable: false,
    noIcon: false,
};
