import * as React from 'react';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import { useField } from '@formily/react';
export function CnReadOnlyContainer(props) {
    const { value, size, className, children, line, showFolder, cnTextEllipsisProps, 
    // 自定义渲染
    readOnlyRender, 
    // 其他展示属性
    addonBefore, innerBefore, addonTextBefore, addonTextAfter, innerAfter, addonAfter, ...otherDivProps } = props;
    const classes = cx({
        'cn-ui-read-only': true,
        [`cn-ui-read-only-size-${size}`]: true,
        [className]: !!className,
    });
    const field = useField();
    const handleClick = (e) => {
        if (typeof props.onClick === 'function') {
            props.onClick(e, field);
        }
    };
    if (readOnlyRender && isFunction(readOnlyRender)) {
        return (React.createElement("div", { className: classes, ...otherDivProps }, readOnlyRender(value)));
    }
    return (React.createElement("div", { className: classes, ...otherDivProps, onClick: handleClick },
        addonBefore || '',
        innerBefore || '',
        addonTextBefore || '',
        React.createElement(CnTextEllipsis, { line: line, hasTooltip: true, showFolder: showFolder, ...cnTextEllipsisProps }, children),
        addonTextAfter || '',
        innerAfter || '',
        addonAfter || ''));
}
