// 第三方依赖
import { useState, useCallback } from 'react';
import isFunction from 'lodash/isFunction';
import { getValueByPath } from '../utils';
import { useSecond } from './use-second';
export function useControlValue(props, opts) {
    var _a;
    const { defaultValuePropName, valuePropName = 'value', changePropName = 'onChange', } = opts;
    const [data, setData] = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName);
    const setHandler = useCallback((...args) => {
        const finalChange = getValueByPath(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            // 防止render期间导致 error引发白屏
            try {
                finalChange(...args);
            }
            catch (error) {
                console.error(error);
            }
        }
        !(valuePropName in (props || {})) && setData(...args);
    }, [props, valuePropName, changePropName]);
    useSecond(() => {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
