/**
 * 动态加载js文件
 */
function loadJS(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
        script.onload = () => resolve(true);
        script.onerror = (err) => reject(err);
    });
}
let hasLoaded = false;
export const loadWebOfficeSDK = async () => {
    if (hasLoaded)
        return true;
    const main = async () => {
        const sdkVersion = '1.1.16';
        const url = `https://g.alicdn.com/IMM/office-js/${sdkVersion}/aliyun-web-office-sdk.min.js`;
        hasLoaded = await loadJS(url);
    };
    await main();
};
