// 第三方依赖
import { useState, useCallback } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import { useSecond } from './use-second';
/**
 * useUnitValue hook用于处理表格中单元格的值，包括默认值和用户输入的值
 * @template T 泛型，表示单元格的值的类型
 * @param {Obj} props 表格单元格的props
 * @param {unitValueI} opts 配置项，包括默认值和值的属性名
 * @returns {[T, React.Dispatch<T>]} 返回一个数组，第一个元素是单元格的值，第二个元素是更新单元格值的函数
 */
export function useUnitValue(props, opts) {
    var _a;
    const { defaultValue, valuePropName = 'value' } = opts;
    const initialValue = (_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValue;
    const [data, setData] = useState(initialValue);
    /**
     * 检测是否为 proxy，如是，则转换为 plain object
     */
    const setDataProxy = (args) => {
        // 如果类型为 Proxy 或其他不为简单对象的值，则需要转换后再传递
        if (!isPlainObject(args)) {
            args = JSON.parse(JSON.stringify(args));
        }
        setData(args);
    };
    const setHandler = useCallback((args) => {
        !(valuePropName in (props || {})) && setDataProxy(args);
    }, [props, valuePropName]);
    useSecond(() => {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
