import React from 'react';
import { isVoidField } from '@formily/core';
import { connect, mapProps } from '@formily/react';
import { useFormLayout } from '@/form/cn-form-layout';
import { Form } from '@fusion/lib';
const UiFormItem = Form.Item;
const useFormItemLayout = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const layout = useFormLayout();
    return {
        ...props,
        colon: (_a = props.colon) !== null && _a !== void 0 ? _a : layout.colon,
        labelAlign: (_b = props.labelAlign) !== null && _b !== void 0 ? _b : layout.labelAlign,
        labelWidth: (_c = props.labelWidth) !== null && _c !== void 0 ? _c : layout.labelWidth,
        labelCol: ((_d = props.labelCol) !== null && _d !== void 0 ? _d : layout.labelCol),
        wrapperCol: ((_e = props.wrapperCol) !== null && _e !== void 0 ? _e : layout.wrapperCol),
        size: ((_f = props.size) !== null && _f !== void 0 ? _f : layout.size),
        asterisk: props.asterisk,
        labelTextAlign: (_g = props.labelTextAlign) !== null && _g !== void 0 ? _g : layout.labelTextAlign,
    };
};
export const BaseItem = (props) => {
    const { children, ...others } = props;
    const formLayout = useFormItemLayout(others);
    function getChildren(child) {
        if (props === null || props === void 0 ? void 0 : props.fullWidth) {
            // fullWidth 撑开
            return React.cloneElement(child, {
                style: {
                    width: '100%',
                },
            });
        }
        return children;
    }
    return (React.createElement(UiFormItem, { ...formLayout }, getChildren(children)));
};
// 适配
export const FormItem = connect(BaseItem, mapProps((props, field) => {
    if (isVoidField(field)) {
        return {
            label: field.title || props.label,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    const takeFeedbackStatus = () => {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    const takeMessage = () => {
        const split = (messages) => {
            return messages.reduce((buf, text, index) => {
                if (!text)
                    return buf;
                return index < messages.length - 1
                    ? buf.concat([text, ', '])
                    : buf.concat([text]);
            }, []);
        };
        if (field.validating)
            return;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                return [props.requiredMessage];
            }
            else {
                return split(field.selfErrors);
            }
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    const takeAsterisk = () => {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        label: props.label || field.title,
        validateState: takeFeedbackStatus(),
        help: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
    };
}));
FormItem.BaseItem = BaseItem;
export default FormItem;
