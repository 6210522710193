import React from 'react';
import { getFileType } from './utils';
import classNames from 'classnames';
const iconMap = {
    zip: 'https://img.alicdn.com/imgextra/i2/O1CN01KaehYF1k3DMzMqh2h_!!6000000004627-2-tps-56-64.png',
    mov: 'https://img.alicdn.com/imgextra/i2/O1CN016gJD6m1rXcywbAv0z_!!6000000005641-2-tps-56-70.png',
    music: 'https://img.alicdn.com/imgextra/i3/O1CN015BqbHc1FsHROf4buG_!!6000000000542-2-tps-56-70.png',
    png: 'https://img.alicdn.com/imgextra/i3/O1CN01jQeGjp1LsdRapQuz4_!!6000000001355-2-tps-56-64.png',
    pdf: 'https://img.alicdn.com/imgextra/i3/O1CN01Gsl3aK1YjYb8a7pW6_!!6000000003095-2-tps-56-64.png',
    ppt: 'https://img.alicdn.com/imgextra/i3/O1CN01wZ8dIw1j8seVO9l6e_!!6000000004504-2-tps-56-64.png',
    txt: 'https://img.alicdn.com/imgextra/i3/O1CN01kMAof71lT9Pk8hZgY_!!6000000004819-2-tps-56-64.png',
    xls: 'https://img.alicdn.com/imgextra/i1/O1CN01Ux7Atg1SZ7sjt3RKl_!!6000000002260-2-tps-56-64.png',
    html: 'https://img.alicdn.com/imgextra/i1/O1CN016vzefh1rtbzgKpacW_!!6000000005689-2-tps-56-64.png',
    doc: 'https://img.alicdn.com/imgextra/i4/O1CN01tE4SE01kZjUPN6F14_!!6000000004698-2-tps-56-64.png',
    unknown: 'https://img.alicdn.com/imgextra/i2/O1CN0106LaqJ1SxrGnSKeTV_!!6000000002314-2-tps-56-70.png',
};
const getIconUrl = (_fileType) => {
    let fileType = _fileType;
    if (fileType === 'docx')
        fileType = 'doc';
    if (fileType === 'htm')
        fileType = 'htm';
    if (fileType === 'eml' || fileType === 'msf' || fileType === 'oft') {
        return 'https://img.alicdn.com/imgextra/i4/O1CN018mjekU1XEa7AVjfip_!!6000000002892-55-tps-40-40.svg';
    }
    if (fileType === 'mp4') {
        fileType = 'music';
    }
    if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'gif') {
        fileType = 'png';
    }
    if (fileType === 'pptx') {
        fileType = 'ppt';
    }
    if (fileType === 'csv' || fileType === 'xlsx') {
        fileType = 'xls';
    }
    if (fileType === 'rar' ||
        fileType === '7z' ||
        fileType === 'gz' ||
        fileType === 'z') {
        fileType = 'zip';
    }
    return iconMap[fileType] || iconMap.unknown;
};
export const StaticFileIcon = ({ file, className }) => {
    const fileType = getFileType(file);
    const cdnUrl = getIconUrl(fileType);
    return (React.createElement("div", { className: classNames(className, 'cn-ui-upload-list-file-ext') },
        React.createElement("img", { src: cdnUrl })));
};
