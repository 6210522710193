// 第三方依赖
import * as React from 'react';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
export function useColumnsResize(props, columns, setColumns) {
    const { columnResize: columnResizeProps } = props;
    if (isNil(columnResizeProps) || columnResizeProps == false) {
        return columnResizeProps;
    }
    const columnResize = {};
    const onChange = React.useCallback(debounce((info, dataIndex, width) => {
        const columnsWidth = columns.map((el) => {
            if (el.dataIndex === dataIndex) {
                return {
                    ...el,
                    width,
                };
            }
            return el;
        });
        setColumns(columnsWidth, 'user');
    }, 550), [columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange, columns]);
    if (columnResizeProps === undefined)
        return columnResizeProps;
    columnResize.onChange = (...res) => {
        var _a;
        (_a = columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange) === null || _a === void 0 ? void 0 : _a.call(columnResizeProps, ...res);
        onChange(...res);
    };
    return {
        ...(typeof columnResizeProps === 'object' ? columnResizeProps : {}),
        // columnResizeProps,
        ...columnResize,
    };
}
