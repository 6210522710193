import { isEmpty } from './utils';
const DEFAULT_FILTER_SAVE_KEY = `FILTER_${`${global.location.origin}_${global.location.pathname}`}`;
const createStorage = (config) => {
    const { storageKey, type, getData, setData } = config || {};
    const getKey = (defaultKey) => {
        if (isEmpty(storageKey))
            return defaultKey;
        if (typeof storageKey === 'function') {
            const key = storageKey();
            return `${defaultKey}|${key}`;
        }
        return `${defaultKey}|${storageKey}`;
    };
    const FILTER_SAVE_KEY = getKey([DEFAULT_FILTER_SAVE_KEY, type].filter(Boolean).join('|'));
    const _getData = async () => {
        if (getData)
            return getData(FILTER_SAVE_KEY, type);
        const saveDataStr = localStorage.getItem(FILTER_SAVE_KEY) || '';
        let saveData = null;
        if (saveDataStr) {
            try {
                saveData = JSON.parse(saveDataStr);
            }
            catch (e) {
                console.error(e);
            }
        }
        return Promise.resolve(saveData);
    };
    const _setData = async (saveData) => {
        if (setData)
            return setData(FILTER_SAVE_KEY, saveData, type);
        let saveDataStr = '';
        try {
            saveDataStr = JSON.stringify(saveData);
        }
        catch (e) {
            console.error(e);
        }
        if (saveDataStr) {
            localStorage.setItem(FILTER_SAVE_KEY, saveDataStr);
        }
        return Promise.resolve();
    };
    return {
        getData: _getData,
        setData: _setData,
    };
};
export default createStorage;
