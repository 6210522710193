import { useRef, useState, useEffect } from 'react';
import { isArr, isValid } from '@formily/shared';
const calcBreakpointIndex = (breakpoints, width) => {
    for (let i = 0; i < breakpoints.length; i++) {
        if (width <= breakpoints[i]) {
            return i;
        }
    }
    return 0;
};
const calcFactor = (value, breakpointIndex) => {
    var _a;
    if (Array.isArray(value)) {
        if (breakpointIndex === -1)
            return value[0];
        return (_a = value[breakpointIndex]) !== null && _a !== void 0 ? _a : value[value.length - 1];
    }
    else {
        return value;
    }
};
const factor = (value, breakpointIndex) => (isValid(value) ? calcFactor(value, breakpointIndex) : value);
const calculateProps = (target, props) => {
    const { clientWidth } = target;
    const { breakpoints = [0], layout, labelAlign, wrapperAlign, labelCol, wrapperCol, ...otherProps } = props;
    const breakpointIndex = calcBreakpointIndex(breakpoints, clientWidth);
    return {
        layout: factor(layout, breakpointIndex),
        labelAlign: factor(labelAlign, breakpointIndex),
        wrapperAlign: factor(wrapperAlign, breakpointIndex),
        labelCol: factor(labelCol, breakpointIndex),
        wrapperCol: factor(wrapperCol, breakpointIndex),
        ...otherProps,
    };
};
export const useResponsiveFormLayout = (props) => {
    const ref = useRef(null);
    const { breakpoints } = props;
    if (!isArr(breakpoints)) {
        return { ref, props };
    }
    const [layoutProps, setLayout] = useState(props);
    const updateUI = () => {
        if (ref.current) {
            setLayout(calculateProps(ref.current, props));
        }
    };
    useEffect(() => {
        const observer = () => {
            updateUI();
        };
        const resizeObserver = new ResizeObserver(observer);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        updateUI();
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    return {
        ref,
        props: layoutProps,
    };
};
