import React from 'react';
import { Menu, Dropdown } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { setGlobalSize, useSizeChange } from '@/components/cn-utils';
import './index.scss';
export const CnCompactSelect = (props) => {
    const { triggerType = 'click', onChange, cookieOptions } = props;
    const { size } = useSizeChange(props);
    const usedLangList = [
        { value: 'small', label: '紧凑' },
        { value: 'medium', label: '宽松' },
    ];
    return (React.createElement(Dropdown, { trigger: React.createElement("span", { className: "cn-compact-select", "aria-haspopup": "true" }, size === 'small' ? (React.createElement(CnIcon, { type: "icon-task-list" })) : (React.createElement(CnIcon, { type: "icon-density-compact" }))), triggerType: triggerType },
        React.createElement(Menu, { className: "cn-compact-select-menu" }, usedLangList.map((langObj) => {
            return (React.createElement(Menu.Item, { onClick: () => {
                    setGlobalSize(langObj.value, cookieOptions);
                    onChange === null || onChange === void 0 ? void 0 : onChange(langObj.value);
                }, key: langObj.value }, langObj.label));
        }))));
};
export default CnCompactSelect;
