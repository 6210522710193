import $i18n from 'panda-i18n';
import isNumber from 'lodash/isNumber';
import BigNumber from 'bignumber.js';
export default (value, rule) => {
    if (isNumber(value) &&
        new BigNumber(value).isLessThan(rule.exclusiveMaximum)) {
        return true;
    }
    return ($i18n.get({ id: 'TheValueMustBeLess', dm: '数值必须小于', ns: 'CnForm' }) +
        rule.exclusiveMaximum);
};
