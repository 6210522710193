import * as React from 'react';
import { usePagination } from 'ahooks';
import { ResponsiveGrid } from '@/components/fusion';
import { CnGrid } from '@/components/cn-grid';
import Pagination from '@fusion/lib/pagination';
import isFunction from 'lodash/isFunction';
import cx from 'classnames';
import { useSize } from 'ahooks';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import assign from 'lodash/assign';
import { packRequest as request, } from '@/components/cn-utils';
import { responsiveSize, sizeColumns } from './const';
const { Cell } = ResponsiveGrid;
export const basicsPlugin = {
    // useControllablePagination(props, context) {
    //   const { onPageChange, pageProps = {}, dataSource } = props;
    //   const pageChangeEventList = React.useRef([onPageChange]);
    //   function onPageChangeEventList(...res) {
    //     pageChangeEventList.current.forEach((fn) => {
    //       safeCallFunction(fn, ...res);
    //     });
    //   }
    //   const [total, setTotal] = useControllableValue(pick(pageProps, ['total']), {
    //     valuePropName: 'total',
    //     defaultValue: dataSource?.length ?? 0,
    //   });
    //   const [current, setCurrent] = useControllableValue(
    //     {
    //       ...pick(pageProps, ['current', 'defaultCurrentPage']),
    //       onChange: (value) => onPageChangeEventList(value, 'current'),
    //     },
    //     {
    //       defaultValuePropName: 'defaultCurrentPage',
    //       valuePropName: 'current',
    //     },
    //   );
    //   const [pageSizeList, setPageSizeList] = useControllableValue({
    //     ...pick(pageProps, ['pageSizeList']),
    //     onChange: onPageChangeEventList,
    //   });
    //   const [pageSize, setPageSize] = useControllableValue(
    //     {
    //       ...pick(pageProps, ['pageSize', 'defaultPageSize']),
    //       onChange: (value) => onPageChangeEventList(value, 'pageSize'),
    //     },
    //     {
    //       valuePropName: 'pageSize',
    //       defaultValuePropName: 'defaultPageSize',
    //     },
    //   );
    //   return {
    //     props: {
    //       ...props,
    //       pageProps: {
    //         ...pageProps,
    //         total,
    //         current,
    //         pageSizeList,
    //         pageSize,
    //       },
    //     },
    //     context: {
    //       ...context,
    //       pageChangeEventList,
    //       setTotal,
    //       setPageSizeList,
    //       setPageSize,
    //       setCurrent,
    //     },
    //   };
    // },
    useHandleRequestService(props, context) {
        const { requestConfig = {}, pagging } = props;
        // const { setTotal, setPageSize, setCurrent } = context;
        const requestService = React.useMemo(() => {
            function takeRequestService(requestConfig) {
                const { service, method = 'get', formatParam = (params) => params, params: paramsProps, formatResult = (res) => {
                    var _a, _b;
                    const result = { list: [], total: 0 };
                    if (res.success) {
                        const list = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.list;
                        const total = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.total;
                        if (Array.isArray(list)) {
                            result.list = list;
                        }
                        if (!isNil(total)) {
                            result.total = total;
                        }
                    }
                    return result;
                }, url, } = requestConfig;
                if (isFunction(service)) {
                    return async (searchParams) => {
                        var _a, _b;
                        const paggingParams = pagging
                            ? searchParams
                            : omit(searchParams, ['current', 'pageSize']);
                        return (_b = (_a = service(paggingParams)) === null || _a === void 0 ? void 0 : _a.then) === null || _b === void 0 ? void 0 : _b.call(_a, formatResult);
                        // (result) => {
                        // const resulet = formatResult(result);
                        // setTotal(resulet.total ?? 0);
                        // setPageSize(searchParams?.pageSize ?? 10);
                        // setCurrent(searchParams?.current ?? 1);
                        //   return resulet;
                        // });
                    };
                }
                if (url) {
                    return async (searchParams) => {
                        const paggingParams = pagging
                            ? searchParams
                            : omit(searchParams, ['current', 'pageSize']);
                        const searchKey = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET' ? 'params' : 'data';
                        const params = {
                            [searchKey]: formatParam === null || formatParam === void 0 ? void 0 : formatParam(assign({}, paramsProps, paggingParams)),
                        };
                        return request({
                            ...requestConfig,
                            ...params,
                            url,
                        }).then(formatResult);
                        //   (result) => {
                        //   const resulet = formatResult(result);
                        //   return resulet;
                        // });
                        // const dataSource = formatResult(resultData);
                        // return Array.isArray(dataSource) ? dataSource : [];
                    };
                }
                return async () => ({ list: [], total: 0 });
            }
            return takeRequestService(requestConfig);
        }, [requestConfig]);
        return {
            props,
            context: {
                ...context,
                requestService,
            },
        };
    },
    useHandlePagination(props, context) {
        var _a, _b, _c;
        const { requestConfig = {}, pagging, pageProps, onPageChange } = props;
        const { requestService, pageChangeEventList } = context;
        const { run, runAsync, mutate, data, loading, pagination } = usePagination(requestService, {
            refreshDeps: Array.isArray(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.refreshDeps)
                ? requestConfig.refreshDeps
                : [],
            defaultCurrent: (_a = pageProps === null || pageProps === void 0 ? void 0 : pageProps.current) !== null && _a !== void 0 ? _a : 1,
            defaultPageSize: (_b = pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSize) !== null && _b !== void 0 ? _b : 10,
        });
        // const paginationChange = React.useCallback((value, type) => {
        //   switch (type) {
        //     case 'current':
        //       setCurrent(value, type); // setCu
        //       pagination.changeCurrent(value);
        //       break;
        //     case 'pageSize':
        //       setPageSize(value, type);
        //       pagination.changePageSize(value);
        //       break;
        //     default:
        //       break;
        //   }
        // }, []);
        const dataSource = Array.isArray(props.dataSource)
            ? props.dataSource
            : (_c = data === null || data === void 0 ? void 0 : data.list) !== null && _c !== void 0 ? _c : [];
        return {
            props: {
                ...props,
                loading,
                pageProps: {
                    ...pagination,
                    onChange: (value) => {
                        onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange({
                            ...pick(pagination, ['pageSize', 'total', 'totalPage']),
                            current: value,
                        });
                        pagination.changeCurrent(value);
                    },
                    onPageSizeChange: (value) => {
                        onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange({
                            ...pick(pagination, ['current', 'total', 'totalPage']),
                            pageSize: value,
                        });
                        pagination.onChange(1, value);
                    },
                    ...pageProps,
                },
                dataSource,
            },
            context: {
                ...context,
                run,
                runAsync,
                mutate,
                pagination,
                pageChangeEventList,
            },
            render(props, BaseComponents) {
                const { pageProps = {}, itemLayout } = props;
                if (itemLayout === 'horizontal') {
                    const { horizontalConfig = {}, children, dataSource, renderItem, ...otherProps } = props;
                    const { flex, flexWrap, flexColSpan } = horizontalConfig;
                    const { columns, rowGap = 16, columnGap = 16, size = 'medium' } = horizontalConfig;
                    const resizeRef = React.useRef(null);
                    const { width } = useSize(resizeRef) || {};
                    const innerColunms = React.useMemo(() => {
                        if (columns)
                            return columns;
                        let matched = sizeColumns[sizeColumns.length - 1][size];
                        if (!width)
                            return matched;
                        responsiveSize.some((v, idx) => {
                            if (width < v) {
                                matched = sizeColumns[idx][size];
                                return true;
                            }
                            return false;
                        });
                        return matched > 1 ? matched : 1;
                    }, [columns, width, size]);
                    const customContent = Array.isArray(dataSource) &&
                        dataSource.map((one, index) => {
                            let item = null;
                            if (renderItem) {
                                item = renderItem(one, index);
                            }
                            if (flex) {
                                return (React.createElement(CnGrid.CnCol, { key: index, span: flexColSpan }, item));
                            }
                            return (React.createElement(Cell, { key: index, colSpan: 1 }, item));
                        });
                    const newChildren = React.useMemo(() => {
                        if (!children)
                            return null;
                        return React.Children.map(children, (child, idx) => {
                            if (flex) {
                                return (React.createElement(CnGrid.CnCol, { key: idx, span: flexColSpan }, child));
                            }
                            return (React.createElement(Cell, { key: idx, colSpan: 1 }, child));
                        });
                    }, [children]);
                    return (React.createElement("div", { className: cx('cn-list', props.className), ref: resizeRef },
                        React.createElement(BaseComponents, { ...omit(otherProps, [
                                'itemLayout',
                                'pageProps',
                                '$i18n',
                                'requestConfig',
                                'pagging',
                            ]) }, flex ?
                            React.createElement(CnGrid.CnRow, { wrap: flexWrap },
                                customContent,
                                newChildren) :
                            React.createElement(ResponsiveGrid, { columns: innerColunms, gap: [Number(rowGap), Number(columnGap)] },
                                customContent,
                                newChildren))));
                }
                return (React.createElement("div", { className: cx('cn-list', props.className) },
                    React.createElement(BaseComponents, { ...omit(props, [
                            'horizontalConfig',
                            'pageProps',
                            '$i18n',
                            'requestConfig',
                            'pagging',
                        ]) }),
                    pagging && (React.createElement("div", null,
                        React.createElement(Pagination, { className: "cn-list-pagination", ...pageProps, type: "simple", size: "small" })))));
            },
        };
    },
    // useHandleRequest(props, context) {
    //   return { props, context };
    //   const { requestConfig, innerProps } = props;
    //   const { requestService } = context;
    //   const { service, searchFormat, url, ...otherConfig } = requestConfig || {};
    //   const {
    //     run,
    //     runAsync,
    //     mutate,
    //     error,
    //     data = [],
    //     loading,
    //   } = useRequest(requestService, {
    //     ready: !!(url || service),
    //     ...otherConfig,
    //   });
    //   const dataSource = Array.isArray(props.dataSource)
    //     ? props.dataSource
    //     : data;
    //   return {
    //     props: {
    //       ...props,
    //       loading,
    //       dataSource,
    //       ...innerProps,
    //     },
    //     context: {
    //       ...context,
    //       run,
    //       runAsync,
    //       error,
    //       mutate,
    //     },
    //   };
    // },
};
