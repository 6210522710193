import { createContext, useContext } from 'react';
export const FilterRefsContext = createContext({
    update: () => undefined,
    itemCollection: {},
    filterRef: null,
    overlayRef: null,
    currentSelectedValuesRef: null,
});
export const FilterPropsContext = createContext({});
export const useFilterRefsContext = () => useContext(FilterRefsContext);
export const useFilterPropsContext = () => useContext(FilterPropsContext);
