import { events } from '@fusion/lib/util';
import debounce from 'lodash/debounce';
let timeout;
/**
 * 用于监听当前页面到达哪一个锚点
 * @param dataSource 数据源
 * @param container 函数，返回监听容器
 * @param cb 锚点改变时触发
 * @param external 放置额外参数，例如 offsetY
 */
export const startListen = (dataSource, container, cb, external) => {
    updateNodePosition(dataSource, container, cb, external);
    timeout = window.setTimeout(() => {
        setEventHandlerForContainer(dataSource, container, cb, external);
    });
};
const setEventHandlerForContainer = (dataSource, container, cb, external) => {
    const affixContainer = container;
    if (affixContainer) {
        events.on(affixContainer, 'scroll', updateNodePosition.bind(null, dataSource, container, cb, external), false);
        events.on(affixContainer, 'resize', updateNodePosition.bind(null, dataSource, container, cb, external), false);
    }
};
/**
 * 解除 startListen 带来的监听功能
 */
export const removeListen = (dataSource, container) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    removeEventHandlerForContainer(dataSource, container);
};
const removeEventHandlerForContainer = (dataSource, container) => {
    const affixContainer = container;
    if (affixContainer) {
        events.off(affixContainer, 'scroll', updateNodePosition.bind(null, dataSource, container));
        events.off(affixContainer, 'resize', updateNodePosition.bind(null, dataSource, container));
    }
};
/**
 * 获取节点在容器中的偏移量
 */
export function getNodeOffset(el, container) {
    if (container === window) {
        return el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top;
    }
    return (el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) - (container === null || container === void 0 ? void 0 : container.getBoundingClientRect().top);
}
/**
 * 滚动时触发，判断所有在页面中的节点中的”第一个“，并通过 cb 返回此 htmlId
 */
export const updateNodePosition = debounce((dataSource, container, cb, external) => {
    const { offsetY } = external || {};
    const affixContainer = container;
    if (affixContainer) {
        let findEl;
        const predicateItem = (item) => {
            const el = (item === null || item === void 0 ? void 0 : item.htmlId) && document.getElementById(item.htmlId);
            if (el) {
                if (getNodeOffset(el, affixContainer) >= (offsetY || 0)) {
                    findEl = item.htmlId;
                    return true;
                }
            }
            return false;
        };
        dataSource.some((item) => {
            const status = predicateItem(item);
            if (!status && Array.isArray(item.children)) {
                item.children.some((subItem) => {
                    const subStatus = predicateItem(subItem);
                    if (!subStatus && Array.isArray(subItem.children)) {
                        subItem.children.some((thirdItem) => {
                            const thirdStatus = predicateItem(thirdItem);
                            return thirdStatus;
                        });
                    }
                    return subStatus;
                });
            }
            return !!findEl;
        });
        if (findEl) {
            cb && cb(findEl);
        }
    }
}, 100, {
    leading: true,
});
/**
 * 用于在特定容器内跳转到对应的锚点位置
 * 增加 offsetY，用于跳转的偏移，防止部分元素 fixed 遮挡锚点
 */
export const jumpToNode = (htmlId, container, offsetY = 0) => {
    var _a;
    const el = htmlId && ((_a = (container === window ? document : container)) !== null && _a !== void 0 ? _a : document).querySelector(`#${htmlId}`);
    if (el) {
        if (container) {
            const affixContainer = container;
            if (affixContainer === window) {
                window.scrollTo({
                    top: Math.max(0, ((el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) + window.pageYOffset) + offsetY),
                    behavior: 'smooth',
                });
            }
            else {
                affixContainer.scrollTo({
                    top: Math.max(0, ((el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) - (affixContainer === null || affixContainer === void 0 ? void 0 : affixContainer.getBoundingClientRect().top) + affixContainer.scrollTop) + offsetY),
                    behavior: 'smooth',
                });
            }
        }
    }
};
/**
 * 根据 target 获取实际 DOM 节点
 * @param target css selectors
 * @param container 查询容器
 * @returns 单个 DOM 节点
 */
export const findNode = (target, container = document) => {
    if (!target || typeof target !== 'string') {
        return target;
    }
    let rst = target;
    try {
        rst = container.querySelector(target);
    }
    catch (err) { }
    return rst;
};
/**
 * 根据 target 获取所有实际 DOM 节点
 * @param target css selectors
 * @param container 查询容器
 * @param ignore 去除的 selectors
 * @returns DOM 节点数组
 */
export const findAllNodes = (target, container = document, ignore) => {
    if (!target || typeof target !== 'string') {
        return null;
    }
    if (container === window) {
        container = document;
    }
    let rst;
    let selectors = target;
    if (ignore) {
        selectors = target.split(',').map((selector) => {
            return selector.trim() + ':not(' + ignore + ')';
        });
    }
    try {
        rst = container.querySelectorAll(selectors);
    }
    catch (err) {
        rst = null;
    }
    return rst || null;
};
const addNode = (obj, nest) => {
    const level = obj.level;
    let array = nest;
    let lastItem = array[array.length - 1];
    const lastItemLevel = lastItem ? lastItem.level : 0;
    let counter = level - lastItemLevel;
    while (counter > 0) {
        lastItem = array[array.length - 1];
        if (lastItem && level === lastItem.level) {
            break;
        }
        else if (lastItem && lastItem.children !== undefined) {
            array = lastItem.children;
        }
        counter--;
    }
    array.push(obj);
};
/**
 * 获取嵌套的 headings
 */
export const getNestHeadings = (headingsArray, headingsContainer, levelNodes, levelSelector) => {
    return [].reduce.call(headingsArray, (prev, curr) => {
        var _a, _b, _c, _d;
        let level = 0;
        let node = curr;
        while (node && node !== headingsContainer) {
            if ([].indexOf.call(levelNodes, node) > -1) {
                level++;
            }
            node = node.parentElement;
        }
        const obj = {
            htmlId: curr.id,
            children: [],
            level,
            label: curr.textContent.trim(),
            // icon 的获取方式，临时方案，后续需要优化
            icon: (_d = (_c = (_b = (_a = curr === null || curr === void 0 ? void 0 : curr.closest('.cn-ui-card-header')) === null || _a === void 0 ? void 0 : _a.querySelector('.cn-ui-icon use')) === null || _b === void 0 ? void 0 : _b.href) === null || _c === void 0 ? void 0 : _c.baseVal) === null || _d === void 0 ? void 0 : _d.replace(/\#/, ''),
        };
        addNode(obj, prev.nest);
        return prev;
    }, {
        nest: [],
    }).nest;
};
