import * as React from 'react';
import { Checkbox as NextCheckbox } from './checkbox';
import $i18n from 'panda-i18n';
import { CnReadOnly } from '@/components/cn-read-only';
import locale from '@/locales';
import './index.scss';
const renderPreview = (value, readOnlyProps) => {
    let showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnCheckbox' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnCheckbox' });
    }
    return (React.createElement(CnReadOnly, { value: showValue, valueSeparator: ", ", type: "enum", ...readOnlyProps }));
};
function withI18n(c, p) {
    $i18n.init(p);
    return c;
}
export const CnComplexCheckbox = withI18n(React.forwardRef((props, ref) => {
    const { readOnly, readOnlyProps, ...otherProps } = props;
    return (React.createElement(NextCheckbox, { "data-name": "CnComplexCheckbox", ref: ref, isPreview: readOnly, renderPreview: (values) => renderPreview(values, readOnlyProps), ...otherProps }));
}), {
    locale,
    componentName: 'CnCheckbox',
    forwardRef: true,
});
CnComplexCheckbox.displayName = 'CnCheckbox';
CnComplexCheckbox.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
