import $i18n from 'panda-i18n';
export const getUploadData = (ossData) => {
    if (!ossData)
        return undefined;
    return {
        // 兼容历史逻辑，1230 删除下线...ossData逻辑
        ...ossData,
        key: ossData.key,
        OSSAccessKeyId: ossData.ossAccessKeyId,
        policy: ossData.policy,
        signature: ossData.signature,
        success_action_status: 200,
    };
};
export const fileValidator = (file) => {
    if (!file) {
        const errorMsg = $i18n.get({
            id: 'InvalidUpload',
            dm: '无效上传',
            ns: 'CnOssUpload',
        });
        throw new Error(errorMsg);
    }
    // 校验文件名长度
    if (file.name.length > 190) {
        const errorMsg = $i18n.get({
            id: 'FileNameIsUpTo_1175914279',
            dm: '文件名最长190个字符',
            ns: 'CnOssUpload',
        });
        throw new Error(errorMsg);
    }
};
