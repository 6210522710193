import * as React from 'react';
import { CnButton, CnSplitButtonGroup } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import cx from 'classnames';
import { isInvisible } from '../../utils';
import { OptBtns } from '../opt-btns';
import { ConfigButton } from '../save-config';
import { useFilterRefsContext, useFilterPropsContext } from '../../context';
import '../../index.scss';
import { observer } from '@formily/reactive-react';
export const FilterItemBtns = observer(() => {
    const { $i18n, resetButtonUseIcon, size, buttonType, expandButtonUseIcon, enableConfig, saveConfigState, saveConfigDispatch, extendButtonsPosition, extendButtons, enableExpand, showAll, expand, hideChildren, handleToggleMore, } = useFilterPropsContext();
    const filterContext = useFilterRefsContext();
    const btns = [];
    btns.push({
        icon: 'refresh',
        onClick: () => {
            filterContext.formInstance.filterReset && filterContext.formInstance.filterReset();
        },
        title: $i18n.get({ id: 'reset', dm: '重置' }),
        useIcon: resetButtonUseIcon,
    });
    // hideChildren 中存在需要展示的话，显示展开按钮
    const showButton = enableExpand &&
        (showAll ||
            (hideChildren &&
                hideChildren.filter((child) => {
                    var _a, _b;
                    const item = (_b = (_a = filterContext.formInstance) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.call(_a, child.key).take();
                    return !(isInvisible(child) || (item === null || item === void 0 ? void 0 : item.display) === 'none' || (item === null || item === void 0 ? void 0 : item.display) === 'hidden');
                }).length > 0));
    const renderButtons = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(CnButton, { size: size, type: "primary", htmlType: "submit", onClick: (e) => {
                    filterContext.formInstance.filterSubmit && filterContext.formInstance.filterSubmit(e);
                }, className: `cn-ui-filter-search-btn cn-ui-filter-btn-${buttonType || size}` }, $i18n.get({
                id: 'search',
                dm: '查询',
                ns: 'CnFilter',
            })),
            React.createElement(OptBtns, { maxShowNum: 2, dataSource: btns, size: size })));
    };
    const renderExpandButton = () => {
        let expandButton = (React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { size: size, onClick: handleToggleMore, className: cx('cn-ui-filter-icon-btn', `cn-ui-filter-icon-btn-${size}`) },
                React.createElement("span", null,
                    React.createElement(CnIcon, { size: size, type: expand ? 'arrow-up' : 'arrow-down' }))) }, expand
            ? $i18n.get({ id: 'hide', dm: '更多' })
            : $i18n.get({ id: 'more', dm: '更多' })));
        if (!expandButtonUseIcon) {
            expandButton = (React.createElement(CnButton, { size: size, onClick: handleToggleMore, className: cx('cn-ui-filter-expand-btn-with-text') },
                React.createElement("span", { className: "cn-ui-filter-expand-btn-with-text-content" },
                    React.createElement(CnIcon, { size: size, className: "cn-ui-filter-expand-btn-with-text-icon", type: expand ? 'arrow-up' : 'arrow-down' }),
                    expand
                        ? $i18n.get({ id: 'hide', dm: '更多' })
                        : $i18n.get({ id: 'more', dm: '更多' }))));
        }
        const configButton = (React.createElement(ConfigButton, { enableConfig: enableConfig, store: saveConfigState, dispatch: saveConfigDispatch, size: size }));
        if (showButton && enableConfig) {
            return (React.createElement(CnSplitButtonGroup, { className: "cn-ui-filter-btn-group", size: size },
                expandButton,
                configButton));
        }
        if (showButton) {
            return expandButton;
        }
        if (enableConfig) {
            return configButton;
        }
        return null;
    };
    return (React.createElement("div", { className: "filter-item-btns" },
        React.createElement("div", { className: "cn-ui-filter-btns" },
            extendButtonsPosition === 'left' ? extendButtons : undefined,
            renderButtons(),
            extendButtonsPosition === 'right' ? extendButtons : undefined,
            renderExpandButton(),
            extendButtonsPosition === 'end' ? extendButtons : undefined)));
});
