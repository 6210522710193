/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export function isFunction(val) {
    return val instanceof Function;
}
export function isPlainObject(o) {
    return Object.prototype.toString.call(o) === '[object Object]';
}
export function isString(o) {
    return Object.prototype.toString.call(o) === '[object String]';
}
export function getValueByPath(source, path, defaultValue) {
    return path.reduce((ret, nextKey) => { var _a; return (_a = ret === null || ret === void 0 ? void 0 : ret[nextKey]) !== null && _a !== void 0 ? _a : defaultValue; }, source);
}
export const isDef = (val) => {
    return val !== undefined && val !== null;
};
export const noop = (val) => val;
/**
 * debounce
 * @param {*} fn
 * @param {*} ms
 */
export function debounce(func, wait, immediate) {
    let timeout;
    let result;
    const debounced = function () {
        //@ts-ignore
        const context = this;
        const args = arguments;
        if (timeout)
            clearTimeout(timeout);
        if (immediate) {
            // 如果已经执行过，不再执行
            const callNow = !timeout;
            timeout = setTimeout(() => {
                timeout = null;
            }, wait);
            if (callNow)
                result = func.apply(context, args);
        }
        else {
            timeout = setTimeout(() => {
                func.apply(context, args);
            }, wait);
        }
        return result;
    };
    debounced.cancel = function () {
        //@ts-ignore
        clearTimeout(timeout);
        timeout = null;
    };
    return debounced;
}
