import React from 'react';
import classnames from 'classnames';
// 弹出框容器组件
export function PopupContainer(props) {
    // 解构 props
    const { children, className, ...rest } = props;
    // 合并类名
    const classNames = classnames(className, 'cn-table-attach-container');
    // 返回组件
    return (React.createElement("div", { className: classNames, ...rest }, children));
}
