import * as React from 'react';
import NextMenuButton from './menu-button/index';
import { useSizeChange } from '@/components/cn-utils';
export const CnMenuButton = React.forwardRef((props, ref) => {
    const { size, setSize } = useSizeChange(props);
    return (React.createElement(NextMenuButton, { "data-name": "MenuButton", ...props, size: size, ref: ref }));
});
CnMenuButton.displayName = 'MenuButton';
/**
 * @deprecated
 * 建议使用 MenuButtonItem
 */
CnMenuButton.Item = NextMenuButton.Item;
export const CnMenuButtonItem = NextMenuButton.Item;
CnMenuButtonItem.displayName = 'CnMenuButtonItem';
export const MenuButtonItem = NextMenuButton.Item;
MenuButtonItem.displayName = 'MenuButtonItem';
/**
 * @deprecated
 * 建议使用 MenuButtonGroup
 */
CnMenuButton.Group = NextMenuButton.Group;
export const CnMenuButtonGroup = NextMenuButton.Group;
CnMenuButtonGroup.displayName = 'CnMenuButtonGroup';
export const MenuButtonGroup = NextMenuButton.Group;
MenuButtonGroup.displayName = 'MenuButtonGroup';
/**
 * @deprecated
 * 建议使用 MenuButtonGroup
 */
CnMenuButton.Divider = NextMenuButton.Divider;
export const CnMenuButtonDivider = NextMenuButton.Divider;
CnMenuButtonDivider.displayName = 'CnMenuButtonDivider';
export const MenuButtonDivider = NextMenuButton.Divider;
MenuButtonDivider.displayName = 'MenuButtonDivider';
