import $i18n from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { MenuContainer, MenuItem, MenuPopupItem } from './menu';
import './full-header-menu.scss';
const findFirstLevelActivatedByThirdLevelActiveKey = (menu, selectedKey) => {
    let findResult;
    menu.forEach((menuItem) => {
        if (menuItem.key === selectedKey) {
            findResult = menuItem.key;
        }
        if (Array.isArray(menuItem.children)) {
            menuItem.children.forEach((subItem) => {
                if (Array.isArray(subItem.children)) {
                    subItem.children.forEach((thirdItem) => {
                        if (thirdItem.key === selectedKey) {
                            findResult = menuItem.key;
                        }
                    });
                }
            });
        }
    });
    return findResult;
};
export const FullHeaderMenu = (props) => {
    const { menu, onSelect, hideExternalIcon, onExternalSelect, selectedKeys, fullMenuItemIconRender, } = props;
    const selectedKey = Array.isArray(selectedKeys)
        ? selectedKeys[0]
        : selectedKeys;
    const firstLevelActiveKey = React.useMemo(() => {
        return findFirstLevelActivatedByThirdLevelActiveKey(menu, selectedKey);
    }, [menu, selectedKey]);
    const [openKeys, setOpenKeys] = React.useState([]);
    const SubPanel = (props) => {
        const { dataSource } = props;
        const hasChildren = Array.isArray(dataSource === null || dataSource === void 0 ? void 0 : dataSource.children);
        if (!hasChildren) {
            return React.createElement("div", { className: "cn-ui-shell-full-header-menu" });
        }
        return (React.createElement("div", { className: "cn-ui-shell-full-header-menu" }, dataSource.children.map((subMenuItem) => (React.createElement("div", { className: "cn-ui-shell-full-header-menu-item", key: subMenuItem.key },
            Array.isArray(subMenuItem.children) &&
                subMenuItem.children.length > 0 ? (React.createElement("div", { className: "cn-ui-shell-full-header-menu-item-header" }, subMenuItem.text)) : (React.createElement("div", { onClick: () => {
                    onSelect && onSelect([subMenuItem.key], subMenuItem);
                    setOpenKeys([]);
                }, className: cx({
                    'cn-ui-shell-full-header-menu-item-header': true,
                    interact: true,
                    active: firstLevelActiveKey === subMenuItem.key,
                }) }, subMenuItem.text)),
            React.createElement("div", { className: "cn-ui-shell-full-header-menu-item-footer" }, Array.isArray(subMenuItem.children) &&
                subMenuItem.children.map((thirdMenuItem) => {
                    return (React.createElement("div", { key: thirdMenuItem.key, onClick: () => {
                            onSelect &&
                                onSelect([thirdMenuItem.key], thirdMenuItem);
                            setOpenKeys([]);
                        } },
                        React.createElement("div", { className: cx({
                                'cn-ui-shell-full-header-sub-menu-item': true,
                                active: selectedKeys === thirdMenuItem.key ||
                                    (selectedKeys === null || selectedKeys === void 0 ? void 0 : selectedKeys[0]) === thirdMenuItem.key,
                            }), key: thirdMenuItem.key },
                            React.createElement("div", null, thirdMenuItem.text),
                            React.createElement("div", { className: "cn-ui-shell-full-header-sub-menu-item-icon" },
                                fullMenuItemIconRender(thirdMenuItem),
                                hideExternalIcon ? null : (React.createElement(CnIcon, { className: "icon", type: "link-blank", onClick: (e) => {
                                        e.stopPropagation();
                                        onExternalSelect &&
                                            onExternalSelect([thirdMenuItem.key], thirdMenuItem);
                                        setOpenKeys([]);
                                    } }))))));
                })))))));
    };
    const Panel = (props) => {
        const { dataSource, ...others } = props;
        return (React.createElement("div", { className: "cn-ui-shell-full-header-menu-more", ...others }, Array.isArray(dataSource) &&
            dataSource.map((item) => {
                return (React.createElement("div", { className: "cn-ui-shell-full-header-menu-more-item" },
                    Array.isArray(item.dataSource.children) &&
                        item.dataSource.children.length > 0 ? (React.createElement("div", { className: cx({
                            'cn-ui-shell-full-header-menu-more-item-title': true,
                            active: firstLevelActiveKey === item.dataSource.key,
                        }) }, item.title)) : (React.createElement("div", { onClick: (e) => {
                            onSelect &&
                                onSelect([item.dataSource.key], item.dataSource);
                            setOpenKeys([]);
                        }, className: cx({
                            'cn-ui-shell-full-header-menu-more-item-title': true,
                            interact: true,
                            active: firstLevelActiveKey === item.dataSource.key,
                        }) }, item.title)),
                    React.createElement(SubPanel, { dataSource: item.dataSource })));
            })));
    };
    return (React.createElement(MenuContainer, { hozInLine: true, direction: "hoz", mode: "popup", className: "cn-ui-shell-full-header-menu-trigger", popupAutoWidth: true, triggerType: "hover", selectMode: "single", selectedKeys: selectedKeys, onSelect: (keys, item, extra) => {
            onSelect && onSelect(keys, item, extra);
        }, renderMore: (more) => {
            const newDs = more.map((item) => {
                var _a, _b, _c;
                const data = (_b = (_a = item.props) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.props;
                return {
                    title: (_c = item.props) === null || _c === void 0 ? void 0 : _c.title,
                    dataSource: data === null || data === void 0 ? void 0 : data.dataSource,
                };
            });
            return (React.createElement(MenuPopupItem, { noIcon: true, triggerType: "click", key: "0-more", label: $i18n.get({ id: 'More', dm: '更多', ns: 'CnShell' }) },
                React.createElement(Panel, { dataSource: newDs })));
        }, openKeys: openKeys, onOpen: setOpenKeys }, Array.isArray(menu) &&
        menu.map((menuItem) => {
            if (Array.isArray(menuItem.children) &&
                menuItem.children.length > 0) {
                return (React.createElement(MenuPopupItem, { key: menuItem.key, noIcon: true, title: menuItem.text, label: React.createElement("div", { key: menuItem.key, className: cx({
                            'cn-ui-shell-full-header-menu-trigger-item': true,
                            active: firstLevelActiveKey === menuItem.key,
                        }) },
                        React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-header" }, menuItem.text),
                        React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-footer" },
                            React.createElement(CnIcon, { type: "arrow-down", size: "small" }))) },
                    React.createElement(SubPanel, { dataSource: menuItem })));
            }
            return (React.createElement(MenuItem, { key: menuItem.key, title: menuItem.text, onClick: () => {
                    onSelect && onSelect([menuItem.key], menuItem);
                } },
                React.createElement("div", { key: menuItem.key, className: cx({
                        'cn-ui-shell-full-header-menu-trigger-item': true,
                        active: firstLevelActiveKey === menuItem.key,
                    }) },
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-header" }, menuItem.text))));
        })));
};
