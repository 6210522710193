import * as React from 'react';
import { Text } from './text';
export const createTitle = (Tag) => {
    /**
     * Typography.Title
     * @description 分为 H1, H2, H3, H4, H5, H6 不同的组件，表示不同层级，继承 Typography.Text API
     * @order 1
     */
    class Title extends React.Component {
        render() {
            const { className, ...others } = this.props;
            return (React.createElement(Text, { ...others, component: Tag, className: `cn-ui-typography-title ${className || ''}` }));
        }
    }
    Title.defaultProps = {
        prefix: 'cn-next-',
    };
    Title.displayName = Tag.toUpperCase();
    return Title;
};
