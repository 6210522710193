import * as React from 'react';
import { collectNodes, isLeafNode, layeredSort, mergeCellProps, safeGetValue, safeGetCellProps, safeRenderHeader, } from '../../utils';
import { smartCompare } from './smart-compare';
const stateKey = 'sort';
export default function sort(opts = {
    SortHeaderIconWidth: 16,
}) {
    return function sortStep(pipeline) {
        var _a, _b, _c;
        // 接收一个TablePipeline对象，返回一个TablePipeline对象
        const { orders = ['desc', 'asc', 'none'], // 排序顺序
        mode = 'multiple', // 排序模式
        SortHeaderCell, // 排序表头
        keepDataSource, // 是否保持dataSource不变
        highlightColumnWhenActive, // 排序激活时是否高亮这一列的单元格
        stopClickEventPropagation, // 是否对触发onChangeOpenKeys的click事件调用event.stopPropagation()
        clickArea = 'content', // 点击事件的响应区域
        SortHeaderIconWidth = 16, // 自定义排序表头操作符宽度
         } = opts; // 从opts中获取以上参数
        const inputSorts = // 获取排序字段列表
         (_c = (_b = (_a = opts.sorts) !== null && _a !== void 0 ? _a : pipeline.getStateAtKey(stateKey)) !== null && _b !== void 0 ? _b : opts.defaultSorts) !== null && _c !== void 0 ? _c : [];
        const activeSorts = inputSorts.filter((s) => s.order !== 'none'); // 获取当前排序字段列表
        // 单字段排序的情况下 sorts 中只有第一个排序字段才会生效
        const sorts = mode === 'multiple' ? activeSorts : activeSorts.slice(0, 1); // 根据排序模式获取排序字段列表
        const onChangeSortsInMultipleMode = (nextSorts) => {
            var _a;
            // 更新排序字段列表的回调函数
            (_a = opts.onChangeSorts) === null || _a === void 0 ? void 0 : _a.call(opts, nextSorts);
            pipeline.setStateAtKey(stateKey, nextSorts);
        };
        const onChangeSorts = mode === 'multiple'
            ? onChangeSortsInMultipleMode
            : (nextSorts) => {
                // 单字段排序的情况下，nextSorts 中只有最后一个排序字段才会生效
                const len = nextSorts.length;
                onChangeSortsInMultipleMode(nextSorts.slice(len - 1));
            };
        const sortOptions = {
            // 排序选项
            sorts,
            onChangeSorts,
            orders,
            mode,
            keepDataSource,
            highlightColumnWhenActive,
            stopClickEventPropagation,
            clickArea,
        };
        const sortMap = new Map(// 排序字段映射
        sorts.map((sort, index) => [sort.code, { index, ...sort }]));
        const dataSource = pipeline.getDataSource(); // 获取数据源
        const columns = pipeline.getColumns(); // 获取列信息
        pipeline.dataSource(processDataSource(dataSource)); // 处理数据源
        pipeline.columns(processColumns(columns)); // 处理列信息
        return pipeline; // 返回TablePipeline对象
        function processDataSource(dataSource) {
            // 处理数据源
            if (keepDataSource) {
                // 如果保持dataSource不变，则直接返回dataSource
                return dataSource;
            }
            const sortColumnsMap = new Map(// 排序列映射
            collectNodes(columns, 'leaf-only')
                .filter((col) => {
                var _a, _b;
                return ((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) !== false &&
                    ((_b = col.features) === null || _b === void 0 ? void 0 : _b.sortable) != null;
            })
                .map((col) => [col.code, col]));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return layeredSort(dataSource, (x, y) => {
                // 对dataSource进行排序
                for (const { code, order } of sorts) {
                    // 遍历排序字段列表
                    const column = sortColumnsMap.get(code); // 获取当前排序字段对应的列信息
                    // 如果 code 对应的 column 不可排序，我们跳过该 code
                    if (column == null) {
                        // 如果当前排序字段对应的列信息不存在，则跳过该排序字段
                        continue;
                    }
                    const { sortable } = column.features; // 获取当前排序字段对应的列信息的sortable属性
                    const compareFn = typeof sortable === 'function' ? sortable : smartCompare;
                    const xValue = safeGetValue(column, x, -1);
                    const yValue = safeGetValue(column, y, -1);
                    const cmp = compareFn(xValue, yValue, x, y);
                    if (cmp !== 0) {
                        return cmp * (order === 'asc' ? 1 : -1);
                    }
                }
                return 0;
            });
        }
        // 在「升序 - 降序 - 不排序」之间不断切换
        function toggle(code) {
            const sort = sortMap.get(code);
            if (sort == null) {
                onChangeSorts(sorts.concat([{ code, order: orders[0] }]));
            }
            else {
                const index = sorts.findIndex((s) => s.code === code);
                const nextSorts = sorts.slice(0, index + 1);
                const nextOrder = getNextOrder(sort.order);
                if (nextOrder === 'none') {
                    nextSorts.pop();
                }
                else {
                    nextSorts[index] = { ...nextSorts[index], order: nextOrder };
                }
                onChangeSorts(nextSorts);
            }
        }
        function processColumns(columns) {
            return columns.map(dfs);
            function dfs(col) {
                var _a;
                const result = { ...col };
                const sortable = col.code && (((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) || sortMap.has(col.code));
                const active = sortable && sortMap.has(col === null || col === void 0 ? void 0 : col.code);
                if (sortable) {
                    let sortIndex = -1;
                    let sortOrder = 'none';
                    if (active) {
                        const { order, index } = sortMap.get(col === null || col === void 0 ? void 0 : col.code) || {};
                        sortOrder = order;
                        sortIndex = index;
                        if (highlightColumnWhenActive) {
                            // @ts-ignore
                            result.headerCellProps = mergeCellProps(col.headerCellProps, {
                                style: { background: 'var(--header-highlight-bgcolor)' },
                            });
                            result.getCellProps = (value, row, rowIndex) => {
                                const prevCellProps = safeGetCellProps(col, row, rowIndex);
                                return mergeCellProps(prevCellProps, {
                                    style: { background: 'var(--highlight-bgcolor)' },
                                });
                            };
                        }
                    }
                    result.title = (React.createElement(SortHeaderCell, { clickArea: clickArea, onToggle: (e) => {
                            if (stopClickEventPropagation) {
                                e.stopPropagation();
                            }
                            toggle(col === null || col === void 0 ? void 0 : col.code);
                        }, sortOrder: sortOrder, column: col, sortIndex: sortIndex, 
                        // @ts-ignore
                        sortOptions: sortOptions }, safeRenderHeader(col)));
                    // @ts-ignore
                    if ((result === null || result === void 0 ? void 0 : result.width) > 0) {
                        // @ts-ignore
                        result.width += SortHeaderIconWidth + 2;
                    }
                }
                if (!isLeafNode(col)) {
                    result.children = col.children.map(dfs);
                }
                return result;
            }
        }
        function getNextOrder(order) {
            const idx = orders.indexOf(order);
            return orders[idx === orders.length - 1 ? 0 : idx + 1];
        }
    };
}
