import { observable } from '@formily/reactive';
export const slot = {
    widgetSlot: observable.shallow({}),
    searchSlot: observable.shallow({}),
};
export const registerWidgetSlot = (name, comp) => {
    slot.widgetSlot[name] = comp;
};
export const registerSearchSlot = (name, comp) => {
    slot.searchSlot[name] = comp;
};
