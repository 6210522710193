import { useCallback } from 'react';
import { useRemoveFile } from './use-remove-file';
export const useOnRemove = (props) => {
    const { setValue, onChangeRef, onRemoveRef, uploadRef } = props;
    const removeFile = useRemoveFile({ setValue, onChangeRef, onRemoveRef });
    return useCallback((file) => {
        var _a, _b;
        if (file.originFile)
            (_b = (_a = uploadRef.current) === null || _a === void 0 ? void 0 : _a.abort) === null || _b === void 0 ? void 0 : _b.call(_a, file.originFile);
        removeFile(file);
    }, [removeFile, uploadRef]);
};
