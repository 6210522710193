import $i18n from 'panda-i18n';
import { cnQuickDialogForm } from '@/form';
export async function renameQuickDialog(props) {
    const reanmeDefaultJobName = props === null || props === void 0 ? void 0 : props.defaultJobName;
    const renameTitle = (props === null || props === void 0 ? void 0 : props.title) ||
        $i18n.get({ id: 'ExportFileNaming', dm: '导出文件命名', ns: 'CnExport' });
    return new Promise((resolve, reject) => {
        cnQuickDialogForm({ title: renameTitle }, {
            type: 'object',
            properties: {
                jobName: {
                    'x-decorator': 'CnFormItem',
                    default: reanmeDefaultJobName,
                    'x-component': 'CnInput',
                    'x-component-props': {
                        trim: true,
                        autoFocus: true,
                        hasClear: true,
                        required: true,
                    },
                },
            },
        })
            .forConfirm(async (form, next) => {
            try {
                resolve(form.values.jobName);
            }
            catch (err) {
                reject(err);
            }
            next();
        })
            .open();
    });
}
