import * as React from 'react';
export const useImproveMargin = (props) => {
    const { ref } = props;
    React.useEffect(() => {
        var _a;
        const dom = ref.current;
        if (!dom)
            return;
        try {
            const list = (_a = dom.parentNode) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.cn-ui-card');
            const lastCard = list[list.length - 1];
            if (lastCard)
                lastCard.style.marginBottom = '0';
        }
        catch { }
    }, [ref]);
};
