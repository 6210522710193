import LRU from 'lru-cache';
import { getSubDeptByKey, getDeptByKey } from './service';
const lruOptions = {
    max: 10,
};
const lru = new LRU(lruOptions);
export const getSubDeptCacheByKey = async (params) => {
    const cacheResult = lru.get(`expand-${params.deptNo}`);
    if (cacheResult) {
        return cacheResult;
    }
    const expandResult = await getSubDeptByKey(params);
    lru.set(`expand-${params.deptNo}`, expandResult);
    return expandResult;
};
export const getDeptCacheByKey = async (params) => {
    const cacheResult = lru.get(`info-${params.deptNo}`);
    if (cacheResult) {
        return cacheResult;
    }
    const expandResult = await getDeptByKey(params);
    lru.set(`info-${params.deptNo}`, expandResult[0]);
    return expandResult[0];
};
