import { model, markRaw, action, toJS, observable, observe, define } from '@formily/reactive';
export const createCnFormStepInstance = (defaultCurrent = 0) => {
    const env = define({
        form: null,
        field: null,
        steps: [],
    }, {
        form: observable.ref,
        field: observable.ref,
        steps: observable.shallow,
    });
    let dispose = null;
    const currentChangeCallbacks = [];
    const _nextLoading = observable.ref(false);
    const setDisplay = action.bound((target) => {
        const currentStep = env.steps[target];
        env.steps.forEach(({ name }) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            env.form.query(`${env.field.address}.${name}`).take((field) => {
                if (name === currentStep.name) {
                    field.setDisplay('visible');
                }
                else {
                    field.setDisplay('hidden');
                }
            });
        });
    });
    const next = () => {
        if (formStep.allowNext) {
            setDisplay(formStep.current + 1);
            formStep.setCurrent(formStep.current + 1);
        }
    };
    const back = () => {
        if (formStep.allowBack) {
            setDisplay(formStep.current - 1);
            formStep.setCurrent(formStep.current - 1);
        }
    };
    const formStep = model({
        connect(steps, field) {
            env.steps = steps;
            env.form = field === null || field === void 0 ? void 0 : field.form;
            env.field = field;
        },
        current: defaultCurrent,
        setCurrent(key) {
            formStep.current = key;
            setDisplay(key);
        },
        onCurrentChange(cb) {
            if (typeof cb === 'function') {
                currentChangeCallbacks.push(cb);
            }
            return dispose || (() => { });
        },
        get allowNext() {
            return formStep.current < env.steps.length - 1;
        },
        get nextLoading() {
            return _nextLoading.value;
        },
        set nextLoading(value) {
            _nextLoading.value = value;
        },
        get allowBack() {
            return formStep.current > 0;
        },
        async next(fnc) {
            try {
                _nextLoading.value = true;
                await env.form.validate();
                if (fnc) {
                    const values = toJS(env.form.values);
                    const _next = () => {
                        _nextLoading.value = false;
                        next();
                    };
                    fnc(values, _next);
                    return;
                }
                next();
            }
            catch (e) {
                _nextLoading.value = false;
            }
        },
        async back() {
            back();
        },
        async submit(onSubmit) {
            var _a, _b;
            return (_b = (_a = env.form) === null || _a === void 0 ? void 0 : _a.submit) === null || _b === void 0 ? void 0 : _b.call(_a, onSubmit);
        },
    });
    const rawFormStep = markRaw(formStep);
    dispose = observe(rawFormStep, (v) => {
        if ((v === null || v === void 0 ? void 0 : v.key) === 'current') {
            currentChangeCallbacks.map((cb) => {
                return cb(v);
            });
        }
    });
    return rawFormStep;
};
