/* eslint-disable react/react-in-jsx-scope */
import { belongTo } from './utils';
/* eslint-disable import/no-mutable-exports */
export const rowDetailSymbol = Symbol('row-detail');
export const rowSelectionSymbol = Symbol('row-selection');
export const rowIndex = Symbol('row-index');
export const treeMetaSymbol = Symbol('treeMetaSymbol');
export const footerSymbol = Symbol('footerSymbol');
export let globalAsyncDragCol = false;
export let globalHeaderCopy = false;
export let globalSplit = '｜';
export let globalOpreateMoreIconSize = 12;
export const sysFixedColSign = [rowSelectionSymbol, rowIndex];
export const isSysFixedCol = belongTo(sysFixedColSign);
export function setGlobalAsyncDragCol(val) {
    globalAsyncDragCol = val;
}
export function setGlobalHeaderCopy(val) {
    globalHeaderCopy = val;
}
export function setOperateColumnSplit(val) {
    globalSplit = val;
}
