/**
 * 保存查询项配置
 */
import * as React from 'react';
import storage from '../storage';
import { useAsyncReducer, SaveConfigReducers, SaveConfigActionTypes, SaveConfigActions } from '../store';
export const useSaveConfig = ({ enableConfig, defaultConfigValue, configValue: propConfigValue, storageKey, children, onGetStorage, onSetStorage, }) => {
    const [state, dispatch] = useAsyncReducer(SaveConfigReducers, {
        configValue: defaultConfigValue || null,
        overlayVisible: false,
        configInited: false,
        itemsMeta: [],
        dataSource: [],
        arrangedChildren: [],
    });
    const { configValue, overlayVisible, configInited, itemsMeta, dataSource } = state;
    const isInitStorageRef = React.useRef(true);
    React.useEffect(() => {
        if (propConfigValue !== undefined && propConfigValue !== configValue) {
            dispatch({
                type: SaveConfigActionTypes.SetConfigValue,
                payload: propConfigValue,
            });
        }
    }, [configValue, propConfigValue]);
    const storageRef = React.useRef(storage({
        storageKey,
        type: 'config',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    React.useEffect(() => {
        if (!enableConfig)
            return;
        storageRef.current
            .getData()
            .then((data) => {
            isInitStorageRef.current = false;
            if (data) {
                dispatch({
                    type: SaveConfigActionTypes.SetConfigValue,
                    payload: data,
                });
            }
        })
            .catch(() => { }).finally(() => {
            dispatch({
                type: SaveConfigActionTypes.SetConfigInited,
                payload: true,
            });
        });
    }, [enableConfig]);
    React.useEffect(() => {
        if (!enableConfig)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(configValue).catch(() => { });
    }, [configValue, enableConfig]);
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateItemsMeta(state, {
            enableConfig, children,
        }));
    }, [enableConfig, children]);
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateDataSource(state, {
            enableConfig,
        }));
    }, [configValue, itemsMeta, enableConfig]);
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateArrangedChildren(state, {
            enableConfig,
            children,
        }));
    }, [children, dataSource, enableConfig]);
    return [state, dispatch];
};
