import React, { useRef } from 'react';
import { useSyncRef } from './hooks';
import useScrollbar from './use-scroll-bar';
import './scroll-bar.scss';
export default function ScrollBar({ className = '', onScroll, onMouseEnter, onMouseLeave, innerRef, children, suppressScrollX, suppressScrollY, suppressAutoHide, skin = 'white', trackGap, trackStyle, thumbStyle, minThumbSize, Wrapper, ...props }) {
    const scrollBoxRef = useRef(null);
    useSyncRef(innerRef, scrollBoxRef);
    const [horizontalBar, verticalBar, updateLayerNow, updateLayerThrottle, hideScrollbar] = useScrollbar(scrollBoxRef, {
        trackGap,
        trackStyle,
        thumbStyle,
        minThumbSize,
        suppressAutoHide,
    });
    function handleScroll(evt) {
        if (onScroll) {
            onScroll(evt);
        }
        updateLayerThrottle();
    }
    function handleMouseEnter(evt) {
        if (onMouseEnter) {
            onMouseEnter(evt);
        }
        updateLayerNow();
    }
    function handleMouseLeave(evt) {
        if (onMouseLeave) {
            onMouseLeave(evt);
        }
        hideScrollbar();
    }
    return (React.createElement(Wrapper, { className: `ms-container${className && ` ${className}`}`, ref: scrollBoxRef, onScroll: handleScroll, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, ...props },
        React.createElement("div", { className: `ms-track-box ms-theme-${skin}` },
            !suppressScrollX && horizontalBar,
            !suppressScrollY && verticalBar),
        children));
}
