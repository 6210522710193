/**
 * 组件内埋点能力
 * 埋点参考 https://yuque.antfin-inc.com/cn-xt/arch/ibqesg#T36ta
 * @param id
 * @param eventType
 * @param otherParams
 */
export function sendLog({ id, eventType = 'CLK', ...otherParams }) {
    try {
        const trace = window.coneArmsTrace;
        if (!trace || !trace.reportHottag)
            return;
        trace.reportHottag({
            hottag: id,
            eventType,
            ...otherParams,
        });
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
}
