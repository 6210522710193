export const isColumnComponent = (schema) => {
    var _a;
    return ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnArrayTableColumn')) > -1;
};
export const isOperationsComponent = (schema) => {
    var _a;
    return ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf('CnArrayTableOperations')) > -1;
};
export const isAdditionComponent = (schema) => {
    var _a;
    return ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf('CnArrayTableAddition')) > -1;
};
