import React, { forwardRef, cloneElement } from 'react';
import { Form } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import classnames from 'classnames';
import { format } from '@alifd/validate/lib/util';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import { useNextLocale } from '@/components/cn-utils';
import FilterContext from './filter-context';
import { getFieldInitName, getNormalizedDisplayName, getItemStyleInjectProps, } from './helper';
import { useItemCollection } from './filter-selected-tags';
import { getValueFormatterInjectProps } from './value-formatter';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
const FormItem = Form.Item;
export const CnFilterItem = forwardRef((props, ref) => {
    const { children, renderSelected, className, labelAlign, label, tips, tip, tipsProps, rules, errorMessageName, useLabelForErrorMessage, removeEmptyLabel, ...rest } = props;
    const { Validate: messages } = useNextLocale('Form') || {};
    const filterContext = React.useContext(FilterContext);
    const { setCollection, getCollectionInjectPorps } = useItemCollection({
        renderSelected,
        label,
    });
    let required = false;
    let requiredMessage;
    const requiredRule = find([].concat(rules), (v) => v === null || v === void 0 ? void 0 : v.required);
    if (requiredRule) {
        required = true;
        requiredMessage = requiredRule.message || requiredMessage;
    }
    // 对 [null, null] 这样的 value 值的必填做处理
    if (required || rest.required) {
        const { validator } = rest;
        rest.validator = (r, v, cb) => {
            if (Array.isArray(v) && isEmpty(compact(v))) {
                typeof cb === 'function' &&
                    cb(format(messages === null || messages === void 0 ? void 0 : messages.required, r.aliasName || r.field));
            }
            if (typeof validator === 'function') {
                validator(r, v, cb);
            }
            typeof cb === 'function' && cb();
        };
    }
    let customErrorMessageName;
    if (useLabelForErrorMessage && typeof label === 'string') {
        customErrorMessageName = errorMessageName || label;
    }
    let existName = false;
    const renderChildren = React.Children.map(children, (child, idx) => {
        const childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        const style = {
            width: '100%',
            ...childProps === null || childProps === void 0 ? void 0 : childProps.style,
        };
        const initName = getFieldInitName(props, childProps, idx);
        if (initName && !existName) {
            existName = true;
            const displayName = getNormalizedDisplayName(child === null || child === void 0 ? void 0 : child.type);
            setCollection(initName, displayName);
            const collectionInjectProps = getCollectionInjectPorps(displayName);
            const valueFromatterInjectProps = getValueFormatterInjectProps(displayName);
            const styleInjectProps = getItemStyleInjectProps(displayName, filterContext, childProps);
            return cloneElement(child, pickBy({
                style,
                rules,
                ...collectionInjectProps,
                ...valueFromatterInjectProps,
                ...styleInjectProps,
            }));
        }
        return cloneElement(child, {
            style,
        });
    });
    const renderLabel = () => {
        if (removeEmptyLabel && !label) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "label-text" },
                React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, label)),
            (tips || tip) && (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { outerClassName: "cn-ui-filter-item-label-help-icon", type: "help-color", size: 14 }), align: "t", ...tipsProps }, tips || tip))));
    };
    return (React.createElement(FormItem, { ref: ref, className: classnames('cn-ui-filter-item', !labelAlign || labelAlign === 'top' ? 'label-top' : 'label-left', className), label: renderLabel(), labelAlign: labelAlign, required: required, requiredMessage: requiredMessage, useLabelForErrorMessage: useLabelForErrorMessage, errorMessageName: customErrorMessageName, ...rest }, renderChildren));
});
export default CnFilterItem;
CnFilterItem.displayName = 'CnFilterItem';
