import { footerSymbol } from '../global';
/**
 * 生成表格底部数据源
 * @param {TablePipeline} pipeline - 表格管道
 * @param {footerCalRuleI} opts - 底部数据计算规则
 * @returns {Array} - 底部数据源
 */
export function getFooterDataSource(pipeline, opts) {
    if (!opts) {
        return [];
    }
    const primaryKey = pipeline.ensurePrimaryKey('footerRule');
    const columns = pipeline.getColumns();
    const footerData = {
        key: footerSymbol,
        [primaryKey]: Date.now(),
    };
    const dataSource = pipeline.getDataSource();
    dataSource.forEach((item) => {
        columns.forEach((col) => {
            const key = col === null || col === void 0 ? void 0 : col.code;
            if (key && opts[key] instanceof Function) {
                footerData[key] = opts[key](footerData[key], item[key], item);
            }
            else if (!footerData[key]) {
                footerData[key] = '-';
            }
        });
    });
    return [footerData];
}
