import * as React from 'react';
import $i18n from 'panda-i18n';
import isPlainObject from 'lodash/isPlainObject';
import { CnInput } from '@/components/cn-input';
import { CnIcon } from '@/components/cn-icon';
import { CnCheckbox } from '@/components/cn-checkbox';
import { useControlValue } from './hooks';
import { debounce, isDef, isFunction, isString } from './util';
import dfs from './dfs';
import getFilterList from './get-filter-list';
import './style/index.scss';
export default function ListFilter(props) {
    var _a, _b, _c, _d;
    const { list, labelKey, valueKey, className, style, searchStyle, searchTransform, column, } = props;
    const [search, setSearch] = React.useState('');
    const onChangeSearch = debounce((val) => {
        setSearch(val);
    }, 500, false);
    const allValue = React.useMemo(() => getAllValue(list, valueKey), [list]);
    // 过滤后的列表
    const filterList = React.useMemo(() => getFilterList(list, isFunction(searchTransform) ? searchTransform(search) : search, labelKey), [list, search, labelKey]);
    const [value, setValue] = useControlValue(props, {
        defaultValuePropName: 'defaultValue',
        valuePropName: 'value',
        changePropName: 'onChange',
        defaultValue: [],
    });
    const filterValue = React.useMemo(() => {
        // 使用 useMemo 避免重复计算
        return (
        // 过滤列表
        (filterList === null || filterList === void 0 ? void 0 : filterList.filter((item) => {
            // 判断当前项是否在已选中的值中
            return value.includes(item[valueKey]);
        })) || [] // 如果 filterList 不存在，返回空数组
        );
    }, [filterList, value, valueKey]); // 依赖项为 filterList、value 和 valueKey
    const [openKeys, setOpenKeys] = useControlValue(props, {
        defaultValuePropName: 'defaultOpenKeys',
        valuePropName: 'openKeys',
        changePropName: 'onChangeOpenKeys',
        defaultValue: [],
    });
    const valueMap = React.useMemo(() => {
        return Array.isArray(value)
            ? value.reduce((ret, next) => ({
                ...ret,
                [next]: true,
            }), {})
            : {};
    }, [value]);
    const openKeysMap = React.useMemo(() => {
        return Array.isArray(openKeys)
            ? openKeys.reduce((ret, next) => ({
                ...ret,
                [next]: true,
            }), {})
            : {};
    }, [openKeys]);
    const onChange = (changeValue, checked) => {
        const ret = Array.isArray(value) ? [...value] : [];
        if (checked && !valueMap[changeValue]) {
            ret.push(changeValue);
            setValue(ret);
        }
        else if (!checked && valueMap[changeValue]) {
            const valueSet = new Set(ret);
            valueSet.delete(changeValue);
            setValue(Array.from(valueSet));
        }
    };
    const onChangeOpenKeys = (changeOpenKey, isOpen) => {
        const ret = Array.isArray(openKeys) ? [...openKeys] : [];
        if (isOpen && !openKeysMap[changeOpenKey]) {
            ret.push(changeOpenKey);
            setOpenKeys(ret);
        }
        else if (!isOpen && openKeysMap[changeOpenKey]) {
            const valueSet = new Set(ret);
            valueSet.delete(changeOpenKey);
            setOpenKeys(Array.from(valueSet));
        }
    };
    const onCheckAll = (checked) => {
        setValue(checked ? [...allValue] : []);
    };
    const onCheckAllFilterValue = (checked) => {
        const filterValueList = filterList === null || filterList === void 0 ? void 0 : filterList.map((item) => item[valueKey]);
        if (checked) {
            setValue([...value, ...filterValueList]);
        }
        else {
            setValue(allValue.filter((item) => filterList.includes(item)));
        }
    };
    function renderItem(item, level, index) {
        var _a, _b, _c;
        if (isFunction(column === null || column === void 0 ? void 0 : column.render)) {
            const key = (_c = (_b = (_a = column.code) !== null && _a !== void 0 ? _a : column.key) !== null && _b !== void 0 ? _b : labelKey) !== null && _c !== void 0 ? _c : '';
            try {
                item[key] = JSON.parse(item[key]);
            }
            catch (error) { }
            return column.render(item[key], item, level === 1 ? index : -1);
        }
        if (isUndefOrNullString(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'])) {
            return $i18n.get({ id: 'Empty', dm: '空', ns: 'CnListFilter' });
        }
        return item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'];
    }
    // 渲染列表
    function renderList(list, level = 1) {
        var _a;
        // 如果列表不是数组，返回 null
        if (!Array.isArray(list)) {
            return null;
        }
        // 定义结果数组
        const result = [];
        // 遍历列表
        for (let i = 0; i < list.length; i++) {
            // 获取当前项
            const item = list[i] || { children: [] };
            // 获取当前项的值
            const ItemValue = isPlainObject(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                ? JSON.stringify(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                : item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'];
            // 获取当前项的子项
            const children = (item === null || item === void 0 ? void 0 : item.children) || [];
            // 定义展开/收起图标
            const CaretRightIconElement = children.length > 0 ? (React.createElement(CaretRightIcon
            // 如果当前项已展开，添加 cn-list-filter-opened 类名
            , { 
                // 如果当前项已展开，添加 cn-list-filter-opened 类名
                className: openKeysMap[ItemValue] && 'cn-list-filter-opened', 
                // 点击图标时，调用 onChangeOpenKeys 函数
                onClick: () => {
                    onChangeOpenKeys(ItemValue, !openKeysMap[ItemValue]);
                } })) : null;
            // 定义复选框
            const CheckboxElement = (React.createElement(CnCheckbox
            // 如果当前项已选中，设置 checked 为 true
            , { 
                // 如果当前项已选中，设置 checked 为 true
                checked: (_a = valueMap[ItemValue]) !== null && _a !== void 0 ? _a : false, 
                // 点击复选框时，调用 onChange 函数
                onChange: (checked) => {
                    onChange(ItemValue, checked);
                }, className: "cn-list-filter-checkbox" }));
            // 定义当前项的元素
            const ItemElement = renderItem(item, level, i);
            // 定义当前项的包裹元素
            const ItemWrapperElement = (React.createElement("div", { 
                // 如果当前项已选中，添加 cn-list-filter-selected 类名
                className: `cn-list-filter-item ${valueMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']]
                    ? 'cn-list-filter-selected'
                    : ''}`, 
                // 设置 key 为当前项的值
                key: ItemValue },
                CaretRightIconElement,
                CheckboxElement,
                ItemElement));
            // 将当前项的包裹元素添加到结果数组中
            result.push(ItemWrapperElement);
            // 如果当前项已展开
            if (openKeysMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']]) {
                // 定义子项的包裹元素
                const ChildrenWrapperElement = (React.createElement("div", { 
                    // 设置子项的左边距
                    style: { marginLeft: level * 16 }, className: "cn-list-filter-list" }, renderList(children, level + 1)));
                // 将子项的包裹元素添加到结果数组中
                result.push(ChildrenWrapperElement);
            }
        }
        // 返回结果数组
        return result;
    }
    return (React.createElement("div", { style: style, className: `cn-list-filter${className ? ` ${className !== null && className !== void 0 ? className : ''}` : ''}` },
        React.createElement(CnInput, { innerBefore: React.createElement(CnIcon, { type: "search", style: { margin: 4 } }), placeholder: $i18n.get({
                id: 'FilterCurrentPage',
                dm: '筛选当前页',
                ns: 'CnBaseTable',
            }), className: "cn-list-filter-search", onChange: onChangeSearch, style: searchStyle }),
        React.createElement("div", { className: "cn-list-filter-list cn-list-filter-wrapper" },
            !search ? (React.createElement("div", { className: `cn-list-filter-item ${allValue.length === (value === null || value === void 0 ? void 0 : value.length) ? 'cn-list-filter-selected' : ''}` },
                React.createElement(CnCheckbox, { checked: allValue.length === (value === null || value === void 0 ? void 0 : value.length), indeterminate: allValue.length > (value === null || value === void 0 ? void 0 : value.length) && (value === null || value === void 0 ? void 0 : value.length) > 0, className: "cn-list-filter-checkbox", onChange: onCheckAll }),
                $i18n.get({ id: 'SelectAll', dm: '全选(', ns: 'CnListFilter' }), (_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0,
                "/", (_b = allValue === null || allValue === void 0 ? void 0 : allValue.length) !== null && _b !== void 0 ? _b : 0,
                ")")) : (filterList === null || filterList === void 0 ? void 0 : filterList.length) ? (React.createElement("div", { className: `cn-list-filter-item ${allValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length)
                    ? 'cn-list-filter-selected'
                    : ''}` },
                React.createElement(CnCheckbox, { checked: filterValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length), indeterminate: filterValue.length < (filterList === null || filterList === void 0 ? void 0 : filterList.length) &&
                        filterValue.length > 0, className: "cn-list-filter-checkbox", onChange: onCheckAllFilterValue }),
                $i18n.get({
                    id: 'SelectAllSearchItems',
                    dm: '全选搜索项目(',
                    ns: 'CnListFilter',
                }), (_c = filterValue === null || filterValue === void 0 ? void 0 : filterValue.length) !== null && _c !== void 0 ? _c : 0,
                "/", (_d = filterList === null || filterList === void 0 ? void 0 : filterList.length) !== null && _d !== void 0 ? _d : 0,
                ")")) : null,
            renderList(filterList, 1))));
}
function getAllValue(list, valueKey) {
    const allValue = [];
    dfs(list, (item) => {
        if ((valueKey !== null && valueKey !== void 0 ? valueKey : 'value') in item) {
            allValue.push(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']);
        }
    });
    return allValue;
}
function CaretRightIcon(props) {
    return (React.createElement("svg", { focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 32 32", ...props },
        React.createElement("path", { d: "M12 8L22 16 12 24z" })));
}
/**
 * 判断是否为 undefined 或 null 或空字符串
 * @param {any} val - 待判断的值
 * @returns {boolean} - 是否为 undefined 或 null 或空字符串
 */
function isUndefOrNullString(val) {
    return !isDef(val) || (isString(val) && val.replace(/\s/g, '') === '');
}
