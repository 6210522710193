/**
 * 格式化输出值
 * @param v dayjs格式日期值
 * @param fmt 格式
 * @returns
 */
export const formatValue = (v, fmt) => {
    if (!v || !fmt)
        return v || undefined;
    const formatter = (i, idx) => {
        let _fmt = fmt;
        if (Array.isArray(fmt)) {
            _fmt = fmt[idx];
        }
        if (typeof _fmt === 'function') {
            return _fmt(i);
        }
        if (i && i.format) {
            return i.format(_fmt);
        }
        return null;
    };
    return Array.isArray(v) ? v.map((i, idx) => formatter(i, idx)) : formatter(v);
};
/**
 * 获取时间戳
 * @param v dayjs格式日期值
 * @param processTime 是否处理时间开始/结束
 * @param endOfDay 是否取时间结束
 * @returns
 */
export const getValueFromDayjs = (v, processTime, endOfDay) => {
    const fmt = () => {
        const getValue = (p, type) => {
            if (!p || !p.valueOf) {
                return null;
            }
            if (!processTime) {
                return p.valueOf();
            }
            if (type) {
                return p.endOf('date').valueOf();
            }
            return p.startOf('date').valueOf();
        };
        if (Array.isArray(endOfDay)) {
            return endOfDay.map((type) => ((i) => {
                return getValue(i, type);
            }));
        }
        return (i) => getValue(i, endOfDay);
    };
    return formatValue(v, fmt());
};
