import * as React from 'react';
import { calcColumnWidths } from '../../autoWidth';
import { makeRecursiveMapper, safeCellPaddingWidth } from '../../utils';
export function useAutoWidth(props) {
    const { columnResize, columnsAutoWidth, columnsAutoWidthOpt, size, dataSource, columns, allColumns, resize, } = props;
    // @ts-ignore
    const { widths } = columnResize || {};
    React.useMemo(() => {
        // 满足此类条件，则需要自动计算
        if (columnsAutoWidth &&
            !widths &&
            Array.isArray(allColumns) &&
            (allColumns === null || allColumns === void 0 ? void 0 : allColumns.length) > 0 &&
            resize // 开启了width能力
        ) {
            const needCalColumns = [];
            const fixWidths = {};
            // @ts-ignore
            makeRecursiveMapper((col, range) => {
                var _a;
                if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                    if (col.width > 0 || col.enableAutoWidth === false) {
                        // @ts-ignore
                        fixWidths[(_a = col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex] = col.width;
                    }
                    else {
                        needCalColumns.push(col);
                    }
                }
            })(allColumns);
            const calWidths = calcColumnWidths(needCalColumns, dataSource, {
                headerFontSize: 12,
                cellFontSize: 12,
                additionalWidth: safeCellPaddingWidth(size),
                ...(columnsAutoWidthOpt || {}),
            });
            resize.setWidths({
                ...calWidths,
                ...fixWidths,
            });
        }
    }, [
        dataSource,
        columns,
        widths,
        columnsAutoWidth,
        columnsAutoWidthOpt,
        size,
    ]);
}
