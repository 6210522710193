import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import iwIL from './iw-IL.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import msMY from './ms-MY.json';
import plPL from './pl-PL.json';
import ptBR from './pt-BR.json';
import ptPT from './pt-PT.json';
import ruRU from './ru-RU.json';
import trTR from './tr-TR.json';
import zhCN from './zh-CN.json';
import zhHK from './zh-HK.json';
import zhTW from './zh-TW.json';

export default {
  'en-US': enUS,
  'es-ES': esES,
  'fr-FR': frFR,
  'iw-IL': iwIL,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'ms-MY': msMY,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'tr-TR': trTR,
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'zh-TW': zhTW,
};
