import React, { useEffect, useState } from 'react';
import { Overlay } from '@fusion/lib';
import { CnIcon } from '@/components/cn-icon';
import isFunction from 'lodash/isFunction';
const { Popup } = Overlay;
const DEFAULT_AVATAR = 'https://img.alicdn.com/imgextra/i1/O1CN01JkTVma1u660f1TAPV_!!6000000005987-2-tps-112-112.png';
const User = ({ userSlot, ...others }) => {
    var _a;
    // api 兼容
    const [user, setUser] = useState();
    // popup 显示状态
    const [popupVisible, setPopupVisible] = useState(false);
    // 用户信息由外部传入
    useEffect(() => {
        setUser(others.user);
    }, [others.user]);
    const userInfo = (React.createElement("div", { className: "user-info" },
        React.createElement("img", { alt: "avatar", width: "18", height: "18", src: (_a = user === null || user === void 0 ? void 0 : user.img) !== null && _a !== void 0 ? _a : DEFAULT_AVATAR, className: "avatar" }),
        user && user.displayname && (React.createElement("span", { className: "displayname" },
            user.displayname,
            userSlot && React.createElement(CnIcon, { type: "triangle-down-fill", size: "small" })))));
    return (React.createElement(Popup, { v2: true, visible: popupVisible, onVisibleChange: setPopupVisible, placement: "br", trigger: userInfo, triggerType: "click", offset: [4, 14], className: "shell-user-popup", followTrigger: true },
        React.createElement("div", { className: "user-overlay" },
            React.isValidElement(userSlot) && userSlot,
            isFunction(userSlot) &&
                userSlot(() => {
                    setPopupVisible(false);
                }))));
};
User.defaultProps = {};
export default User;
