import { genId } from '../../utils';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
export const ActionTypes = {
    SetOptions: 'SetOptions',
    SetSelected: 'SetSelected',
    SetOverlayVisible: 'SetOverlayVisible',
    SetOverlayRef: 'SetOverlayRef',
};
export const Actions = {
    saveSetOptions: (state, params) => {
        const { options } = state;
        const { data, name } = params;
        return (dispatch) => {
            const value = genId(options.map((opt) => opt.value));
            dispatch({
                type: ActionTypes.SetSelected,
                payload: value,
            });
            const newOptions = filter(options, (i) => i.label !== name);
            const saveData = [
                {
                    label: name,
                    value,
                    data,
                },
                ...newOptions,
            ];
            dispatch({
                type: ActionTypes.SetOptions,
                payload: saveData,
            });
            return saveData;
        };
    },
    clearSelected: (state, params) => {
        const { options, selected } = state;
        const { values } = params;
        return (dispatch) => {
            const findSelected = options.find(i => i.value === selected);
            if (!findSelected || !findSelected.data)
                return;
            if (!isEqual(findSelected.data, values)) {
                dispatch({
                    type: ActionTypes.SetSelected,
                    payload: undefined,
                });
            }
        };
    },
};
