import * as React from 'react';
import { useSize } from 'ahooks';
import './index.scss';
export const CnPlaceholder = (props) => {
    const { children, ...otherProps } = props;
    const containerRef = React.useRef();
    const containerSize = useSize(() => containerRef.current);
    const placeholderRender = () => {
        // 小于 60 * 60
        if ((containerSize === null || containerSize === void 0 ? void 0 : containerSize.width) < 60 || (containerSize === null || containerSize === void 0 ? void 0 : containerSize.height) < 60) {
            return (React.createElement("div", { className: "cn-ui-placeholder" },
                React.createElement("img", { className: "cn-ui-placeholder-img", src: "//img.alicdn.com/imgextra/i4/O1CN01vuim9S24TaV8NgHo8_!!6000000007392-55-tps-20-20.svg", draggable: false })));
        }
        return (React.createElement("div", { className: "cn-ui-placeholder", ref: containerRef },
            React.createElement("img", { className: "cn-ui-placeholder-img", src: "//img.alicdn.com/imgextra/i1/O1CN01655VI01dXG2sAsDMB_!!6000000003745-55-tps-1400-780.svg", draggable: false }),
            React.createElement("div", { className: "cn-ui-placeholder-text-container" },
                React.createElement("div", { className: "cn-ui-placeholder-text" }, children || 'PLACEHOLDER'))));
    };
    return (React.createElement("div", { "data-name": "CnPlaceholder", className: "cn-ui-placeholder-wrapper", ...otherProps }, placeholderRender()));
};
