import React from 'react';
import PropTypes from 'prop-types';
export default function withContext(Checkbox) {
    var _a;
    return _a = class WrappedComp extends React.Component {
            render() {
                return React.createElement(Checkbox, { ...this.props, context: this.context });
            }
        },
        _a.displayName = 'Checkbox',
        _a.contextTypes = {
            onChange: PropTypes.func,
            __group__: PropTypes.bool,
            selectedValue: PropTypes.array,
            disabled: PropTypes.bool,
            prefix: PropTypes.string,
        },
        _a;
}
