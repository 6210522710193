export const ActionTypes = {
    SetConfigValue: 'SetConfigValue',
    SetConfigInited: 'SetConfigInited',
    SetItemsMeta: 'SetItemsMeta',
    SetDataSource: 'SetDataSource',
    SetArrangedChildren: 'SetArrangedChildren',
    SetOverlayVisible: 'SetOverlayVisible',
};
const getFilterItemMeta = (property) => {
    var _a, _b;
    // todo _cnfilter_ismorefilter
    const { key: name, value, _cnfilter_ismorefilter = false } = property;
    const { enableConfig, title: label } = value;
    const span = (_b = (_a = value === null || value === void 0 ? void 0 : value['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : value.colSpan;
    if (enableConfig === false)
        return null;
    if (typeof enableConfig === 'string') {
        return {
            label: enableConfig,
            value: enableConfig,
            _cnfilter_ismorefilter,
            span,
        };
    }
    if (typeof enableConfig === 'object') {
        return {
            ...enableConfig,
            _cnfilter_ismorefilter,
            span,
        };
    }
    if (name) {
        return {
            label: label,
            value: name,
            _cnfilter_ismorefilter,
            span,
        };
    }
    return null;
};
export const Actions = {
    updateItemsMeta: (state, params) => {
        const { enableConfig, children } = params;
        return (dispatch) => {
            let rst;
            if (!enableConfig) {
                rst = [];
            }
            else {
                rst = children.map((child) => getFilterItemMeta(child)).filter(Boolean);
            }
            dispatch({
                type: ActionTypes.SetItemsMeta,
                payload: rst,
            });
        };
    },
    updateDataSource: (state, params) => {
        const { configValue, itemsMeta } = state;
        const { enableConfig } = params;
        return (dispatch) => {
            let rst;
            if (!enableConfig) {
                rst = [];
            }
            else if (!configValue) {
                rst = itemsMeta.map((meta) => ({
                    ...meta,
                    visible: !meta._cnfilter_ismorefilter,
                }));
            }
            else {
                const metaInConfig = [];
                const metaNotInConfig = [];
                itemsMeta.forEach((meta) => {
                    const idx = configValue.findIndex((val) => val.name === (meta === null || meta === void 0 ? void 0 : meta.value));
                    if (idx < 0) {
                        metaNotInConfig.push({
                            ...meta,
                            visible: false,
                        });
                        return;
                    }
                    metaInConfig[idx] = {
                        ...meta,
                        visible: configValue[idx].visible,
                    };
                });
                rst = [...metaInConfig.filter(Boolean), ...metaNotInConfig];
            }
            dispatch({
                type: ActionTypes.SetDataSource,
                payload: rst,
            });
        };
    },
    updateArrangedChildren: (store, params) => {
        const { dataSource } = store;
        const { enableConfig, children } = params;
        return (dispatch) => {
            let rst;
            if (!enableConfig) {
                rst = children;
            }
            else {
                let lastIdx = dataSource.length;
                const elementOrderList = children.map((child) => {
                    var _a;
                    const meta = getFilterItemMeta(child);
                    let idx = !meta
                        ? -1
                        : dataSource.findIndex((dataSourceItem) => dataSourceItem.value === (meta === null || meta === void 0 ? void 0 : meta.value));
                    if (idx < 0) {
                        idx = lastIdx++;
                    }
                    return {
                        idx,
                        element: {
                            ...child,
                            value: {
                                ...child.value,
                                'x-visible': !!((_a = dataSource[idx]) === null || _a === void 0 ? void 0 : _a.visible),
                            },
                        },
                    };
                });
                elementOrderList.sort((a, b) => a.idx - b.idx);
                rst = elementOrderList.map((item) => item.element);
            }
            dispatch({
                type: ActionTypes.SetArrangedChildren,
                payload: rst,
            });
        };
    },
};
