import React from 'react';
import cx from 'classnames';
import { Icon } from '@/components/fusion';
const customCache = new Set();
/** createFromIconfontCN
 *  @description 通过自定义 iconfont源来使用使用svg格式的图片
 */
export function createFromIconfontCN(options) {
    const { scriptUrl, extraCommonProps = {} } = options;
    let hasExist = customCache.has(scriptUrl);
    if (document.querySelector(`script[data-namespace="${scriptUrl}"]`)) {
        hasExist = true;
    }
    /**
     * DOM API required.
     * Make sure in browser environment.
     * The Custom Icon will create a <script/>
     * that loads SVG symbols and insert the SVG Element into the document body.
     */
    if (typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        typeof document.createElement === 'function' &&
        typeof scriptUrl === 'string' &&
        scriptUrl.length &&
        !hasExist &&
        document.body) {
        const script = document.createElement('script');
        script.setAttribute('src', scriptUrl);
        script.setAttribute('data-namespace', scriptUrl);
        customCache.add(scriptUrl);
        document.body.appendChild(script);
    }
    const Iconfont = (props) => {
        const { type, size, children, className, outerClassName, prefix = 'cn-next-', ...others } = props;
        // component > children > type
        let content = null;
        if (props.type) {
            content = React.createElement("use", { xlinkHref: `#${type}` });
        }
        if (children) {
            content = children;
        }
        const classes = cx({
            [`${prefix}icon-remote`]: true,
        }, className);
        return (React.createElement(Icon, { "data-name": "CnIcon", size: size, className: outerClassName },
            React.createElement("svg", { className: classes, focusable: false, ...others, ...extraCommonProps }, content)));
    };
    Iconfont.displayName = 'Iconfont';
    return Iconfont;
}
