export function fileToObject(file, options) {
    return {
        ...options,
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        uid: file.uid,
        percent: 0,
        originFile: file,
    };
}
function getMatchKey(file) {
    if (file.uid)
        return 'uid';
    if (file.key)
        return 'key';
    return 'name';
}
export function getFileIndex(file, fileList) {
    const matchKey = getMatchKey(file);
    return fileList.findIndex((item) => item[matchKey] === file[matchKey]);
}
export function removeFileItem(file, fileList) {
    const matchKey = getMatchKey(file);
    const removed = fileList.filter((item) => item[matchKey] !== file[matchKey]);
    if (removed.length === fileList.length) {
        return null;
    }
    return removed;
}
