import React from 'react';
import locale from '@/locales';
import { withI18n } from 'panda-i18n';
import { ShellContext } from './context';
import { CnShellContainer } from './shell';
import './shell.scss';
export * from './utils/registerSlot';
export const CnShell = withI18n((props) => {
    const { children, ...restProps } = props;
    return (React.createElement(ShellContext, { shellProps: restProps },
        React.createElement(CnShellContainer, null, props.children)));
}, {
    locale,
    componentName: 'CnShell',
});
CnShell.defaultProps = {
    sideBarVisible: true,
    headerVisible: true,
    theme: 'normal',
};
