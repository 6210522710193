// 第三方依赖
import { useMemo, useRef } from 'react';
import flip from 'lodash/flip';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
// 当前目录的相对依赖
import { useControlValue } from './use-control-value';
/**
 * 用于处理表格行选择的 hook
 * @param {CnTableProps} props - 表格组件的 props
 * @returns {Object} - 返回一个对象，包含 hasSelect, storeSelectInfo, selectKeys, setSelectKeys 四个属性
 */
export function useSelect(props) {
    var _a;
    const { showSelect, rowSelection, primaryKey } = props;
    const { onSelectChange } = props;
    // 获取选中的行的key值
    const [selectKeys, setSourceSelectKeys] = useControlValue(
    // @ts-ignore
    rowSelection, {
        defaultValuePropName: (_a = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.defaultValue) !== null && _a !== void 0 ? _a : [],
        valuePropName: 'selectedRowKeys',
        changePropName: 'onChange', // 改变选中行的key值
    });
    // 保存每一次的selection 信息，存储给button的时候传递
    // 保存每一次的selection信息
    const storeSelectInfo = useRef([]);
    const hasSelect = useMemo(
    // 是否有选择
    () => !!(showSelect || rowSelection), // 如果showSelect或rowSelection存在，则返回true，否则返回false
    [showSelect, rowSelection]);
    /**
     * 设置选中的行
     * @param  {...any} argsArr - 选中的行的 key 值
     */
    const setSelectKeys = (...argsArr) => {
        // 设置选中的行
        storeSelectInfo.current = argsArr; // 保存每一次的selection信息
        if (showSelect && isFunction(onSelectChange)) {
            // 如果showSelect存在且onSelectChange是函数
            if (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue) {
                // 如果rowSelection.useDetailValue存在
                const onSelectChangeFlip = flip(onSelectChange); // 将onSelectChange反转
                onSelectChangeFlip.apply(null, argsArr.slice(0, 2)); // 将反转后的onSelectChange应用到argsArr的前两个元素上
            }
            else {
                onSelectChange.apply(null, argsArr); // 将onSelectChange应用到argsArr上
            }
        }
        setSourceSelectKeys(...argsArr); // 改变选中行的key值
    };
    const selectKeysProps = (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue // 如果rowSelection.useDetailValue存在
    )
        ? selectKeys === null || selectKeys === void 0 ? void 0 : selectKeys.map((item) => (isPlainObject(item) ? item[primaryKey] : item)) // 将selectKeys中的每个元素映射为item[primaryKey]或item
        : selectKeys; // 否则返回selectKeys
    return {
        hasSelect,
        storeSelectInfo,
        selectKeys: selectKeysProps,
        setSelectKeys,
    };
}
