// 导入 cx 函数，用于处理 className
import cx from 'classnames';
// 定义 composeEventHandler 函数，用于合并两个事件处理函数
export function composeEventHandler(handler1, handler2) {
    // 返回一个新的函数，依次执行 handler1 和 handler2
    return (...args) => {
        handler1(args);
        handler2(args);
    };
}
// 定义 mergeCellProps 函数，用于合并两个 cellProps 对象
export function mergeCellProps(base, extra) {
    // 如果 base 为空，直接返回 extra
    if (base == null) {
        return extra;
    }
    // 如果 extra 为空，直接返回 base
    if (extra == null) {
        return base;
    }
    // 创建一个新的对象，将 base 的属性复制到新对象中
    const result = Object.assign({}, base);
    // 遍历 extra 的属性
    for (const key of Object.keys(extra)) {
        const value = extra[key];
        const type = typeof value;
        // 如果 value 为 null，覆盖原来的值
        if (value === null) {
            result[key] = null;
        }
        else if (value === undefined) {
            // 如果 value 为 undefined，保留原来的值
        }
        else if (type === 'number' || type === 'string' || type === 'boolean') {
            // 如果 key 为 className，使用 cx 函数进行字符串拼接
            if (key === 'className') {
                result[key] = cx(result[key], value);
            }
            else {
                // 否则，直接覆盖原来的值
                result[key] = value;
            }
        }
        else if (type === 'function') {
            // 如果 value 为函数，使用 composeEventHandler 合并事件处理函数
            const prev = result[key];
            if (prev == null) {
                result[key] = value;
            }
            else {
                result[key] = composeEventHandler(prev, value);
            }
        }
        else if (type === 'object') {
            // 如果 value 为对象，合并两个对象的属性
            result[key] = Object.assign({}, result[key], value);
        }
        // 如果 value 为 bigint 或 symbol 类型，忽略该属性
    }
    // 返回合并后的对象
    return result;
}
