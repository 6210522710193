import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { cssPrefix } from './constant';
export default function ImportButton(props) {
    const { children, onClick, buttonProps, buttonText } = props;
    if (children) {
        return (React.createElement("span", { className: `${cssPrefix}button-wrapper`, onClick: onClick }, children));
    }
    return (React.createElement(CnButton, { ...buttonProps, iconSize: "medium", icons: {
            loading: React.createElement(CnIcon, { type: "import-server" }),
        }, onClick: onClick }, buttonText || $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' })));
}
