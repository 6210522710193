import * as React from 'react';
import { Radio as NextRadio } from '@fusion/lib';
import $i18n from 'panda-i18n';
import { HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils';
import { CnReadOnly } from '@/components/cn-read-only';
import locale from '@/locales';
const renderPreview = (value, readOnlyProps) => {
    let showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return React.createElement(CnReadOnly, { value: showValue, type: "enum", ...readOnlyProps });
};
function withI18n(c, p) {
    $i18n.init(p);
    return c;
}
export const CnRadioBase = withI18n(React.forwardRef((props, ref) => {
    const { readOnly, readOnlyProps, ...otherProps } = props;
    return (React.createElement(NextRadio, { "data-name": "CnRadio", ref: ref, isPreview: readOnly, renderPreview: (values) => renderPreview(values, readOnlyProps), ...otherProps }));
}), {
    locale,
    componentName: 'CnRadio',
    forwardRef: true,
});
export const CnRadio = React.forwardRef((props, ref) => {
    const plugin = React.useMemo(() => {
        const plugin = new Plugin('CnRadio', pluginManager);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnRadio')
            .map((item) => item.method));
        return plugin;
    }, []);
    const plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnRadioBase, { ...props, ref: ref });
    }
    return HocBaseComponents(CnRadioBase, { props, plugins, ref });
});
CnRadio.displayName = 'CnRadio';
CnRadio.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
