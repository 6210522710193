import * as React from 'react';
import ReactDOM from 'react-dom';
import useUpdate from 'ahooks/lib/useUpdate';
import { isDef, isPlainObject } from '../../utils';
import { useWillUpdate } from '../../hooks';
import { ColAbility } from './types';
import { globalAsyncDragCol } from '../../global';
import { operateCode } from '../operateColumnPipeline';
export function useNoWidthRule(widthRef) {
    const buttonWidthRef = React.useRef({});
    const [, update] = React.useState({});
    const calOpereateButtonItemWithNoRule = React.useCallback((props) => {
        const { render, buttonIndex, record, index, splitWidth } = props;
        if (buttonWidthRef.current[buttonIndex]) {
            return buttonWidthRef.current[buttonIndex];
        }
        const element = render instanceof Function ? render(record, index) : null;
        if (element) {
            buttonWidthRef.current[buttonIndex] = true;
        }
        const hasMeasureEl = document.querySelector('.cnc-table-measure');
        const measureEl = hasMeasureEl
            ? hasMeasureEl
            : document.createElement('div');
        if (!hasMeasureEl) {
            measureEl.style.width = 'fit-content';
            measureEl.className = 'cnc-table-measure';
            measureEl.style.cssText = `
          position: fixed;
          top: -10000px;
          left: -10000px;
          z-index: -100;
        `;
            document.body.appendChild(measureEl);
        }
        element &&
            ReactDOM.render(element, measureEl, () => {
                var _a;
                buttonWidthRef.current[buttonIndex] =
                    measureEl.offsetWidth + splitWidth;
                const buttonValues = Object.keys(buttonWidthRef.current).map((key) => buttonWidthRef.current[key]);
                const filterValues = buttonValues.filter((val) => parseInt(val, 10) > 0);
                if (filterValues.length === buttonValues.length) {
                    if (!widthRef.current) {
                        // @ts-ignore
                        widthRef.current = {};
                    }
                    widthRef.current[operateCode] =
                        // @ts-ignore
                        ((_a = widthRef.current[operateCode]) !== null && _a !== void 0 ? _a : 0) +
                            // @ts-ignore
                            filterValues.reduce((ret, val) => ret + val, 0);
                    if (document.querySelector('.cnc-table-measure')) {
                        document
                            .querySelector('.cnc-table-measure')
                            .parentElement.removeChild(document.querySelector('.cnc-table-measure'));
                        // document.body.removeChild(measureEl);
                    }
                    update({});
                }
            });
    }, []);
    return {
        calOpereateButtonItemWithNoRule,
    };
}
// 处理dragline能力
export function useAsyncDrag(allowColAsyncDrag, dragLineUpdateRef) {
    React.useEffect(() => {
        if (allowColAsyncDrag) {
            dragLineUpdateRef.current instanceof Function &&
                dragLineUpdateRef.current();
        }
    }, [allowColAsyncDrag]);
}
function useCalAbility(columnResize, dragLineUpdateRef) {
    let ability = ColAbility.justWidth;
    if (columnResize) {
        ability =
            columnResize.async || globalAsyncDragCol
                ? ColAbility.widthAndAsyncResize
                : ColAbility.widthAndResize;
    }
    useAsyncDrag(ability === ColAbility.widthAndAsyncResize, dragLineUpdateRef);
    return ability;
}
/**
 * 生成列宽度调整的相关参数
 * @param {TableProps} props - 表格组件的props
 * @returns {resizeOpt | undefined} - 返回列宽度调整的相关参数
 */
export function useColumnsResize(props) {
    const { columnResize, columnsAutoWidth, size } = props;
    if (!isDef(columnResize) && !columnsAutoWidth) {
        return;
    }
    const update = useUpdate();
    const dragLineUpdateRef = React.useRef();
    const ability = useCalAbility(columnResize, dragLineUpdateRef);
    // @ts-ignore
    const { widths, defaultWidths = {} } = columnResize || {};
    const widthsRef = React.useRef(widths || defaultWidths || {});
    const setWidths = React.useCallback((ws) => {
        widthsRef.current = ws;
    }, []);
    const setWidth = React.useCallback((key, val) => {
        if (!isPlainObject(widthsRef.current)) {
            widthsRef.current = {};
        }
        widthsRef.current[key] = val;
    }, []);
    /**
     * 生成没有规则的操作按钮的宽度
     * @returns {void}
     */
    const { calOpereateButtonItemWithNoRule } = useNoWidthRule(widthsRef);
    useWillUpdate(() => {
        setWidths(widths);
    }, [widths]);
    const dragLineRef = React.useRef();
    return {
        widthsRef,
        setWidth,
        setWidths,
        calOpereateButtonItemWithNoRule,
        size,
        dragLineRef,
        columnResize: columnResize,
        columnsAutoWidth,
        ability,
        update,
        dragLineUpdateRef,
    };
}
