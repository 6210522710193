import { useMemo, useRef } from 'react';
import useUpdate from 'ahooks/lib/useUpdate';
import { useWillUpdate } from './use-will-update';
/**
 * 用于缓存函数，避免重复渲染
 * @param fn
 * @returns
 */
function usePersistFn(fn) {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const persistFn = useRef(() => { });
    if (!persistFn.current) {
        persistFn.current = function () {
            const args = [];
            for (let _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return fnRef.current.apply(this, args);
        };
    }
    return persistFn.current;
}
/**
 * 用于处理受控组件的值
 * @param props controlProps 控制组件的props
 * @param opt controlOpt 控制组件的选项
 * @returns 返回一个数组，第一个元素是当前的值，第二个元素是设置值的函数
 */
export function useControllableValue(props, opt) {
    // 处理选项
    props = props || {};
    const { defaultValue: fallbackDefaultValue, // 默认值
    valuePropName = 'value', // 值的属性名
    defaultValuePropName = 'defaultValue', // 默认值的属性名
    triggerName = 'onChange', // 触发器的属性名
     } = (opt || {});
    // 获取值和默认值
    const value = props[valuePropName];
    const defaultValue = props[defaultValuePropName];
    // 初始化值
    const initialValue = useMemo(() => {
        var _a;
        return (_a = value !== null && value !== void 0 ? value : defaultValue) !== null && _a !== void 0 ? _a : fallbackDefaultValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // 强制更新
    const forceUpdate = useUpdate();
    // 值的引用
    const valueRef = useRef(initialValue);
    // 设置值的函数
    const setState = usePersistFn((val, ...rest) => {
        const onChange = props[triggerName];
        if (!(valuePropName in props)) {
            valueRef.current = val;
            forceUpdate();
        }
        onChange instanceof Function && onChange(val, ...rest);
    });
    // 监听值的变化
    useWillUpdate(() => {
        valueRef.current = value;
    }, [value]);
    return [valueRef.current, setState];
}
