import isFunction from 'lodash/isFunction';
import flattenDeep from 'lodash/flattenDeep';
import cloneDeep from 'lodash/cloneDeep';
const monitorTextRule = '\u{206F}\u{206F}(.*)\u{206F}\u{206F}(.*)\u{206F}\u{206F}';
/**
 * 获取字符串字节长
 */
export function getByteLen(val) {
    var _a;
    if (new RegExp(monitorTextRule, 'u').test(val)) {
        try {
            const replaceResult = (_a = [
                ...val.matchAll(new RegExp(`^${monitorTextRule}$`, 'gu')),
            ]) === null || _a === void 0 ? void 0 : _a[0];
            if (Array.isArray(replaceResult) && replaceResult.length > 2) {
                val = replaceResult[1];
            }
        }
        catch (error) { }
    }
    let len = 0;
    for (let i = 0; i < val.length; i++) {
        const a = val.charCodeAt(i);
        if (a >= 0 && a <= 128) {
            len += 1;
        }
        else {
            len += 2;
        }
    }
    return len;
}
/**
 * 截取字符串，中文占2字节，西文占1字节
 * 例如 subCharStr('测试aaa一段很bbb长字符ccc串测试一段很长字符串', 15)
 * 返回 '测试aaa一段很bb...'
 */
export function subCharStr(str, n) {
    var _a;
    if (typeof str !== 'string')
        return str;
    let matchMonitorMode = false;
    let monitorId = null;
    if (new RegExp(monitorTextRule, 'u').test(str)) {
        matchMonitorMode = true;
        try {
            const replaceResult = (_a = [
                ...str.matchAll(new RegExp(`^${monitorTextRule}$`, 'gu')),
            ]) === null || _a === void 0 ? void 0 : _a[0];
            if (Array.isArray(replaceResult) && replaceResult.length > 2) {
                str = replaceResult[1];
                monitorId = replaceResult[2];
            }
        }
        catch (error) { }
    }
    let inoutStr = ''; // 新字符串
    let inputLength = 0; // 记录长度
    for (let i = 0, k = str.length; i < k; i++) {
        const countCode = str.charCodeAt(i);
        if (countCode >= 0 && countCode <= 128) {
            inputLength++;
        }
        else {
            inputLength += 2;
        }
        if (inputLength > n)
            break;
        inoutStr += str[i];
    }
    if (inoutStr.length < str.length)
        inoutStr += '...';
    if (matchMonitorMode && monitorId) {
        return `\u206F\u206F${inoutStr}\u206F\u206F${monitorId}\u206F\u206F`;
    }
    return inoutStr;
}
export function safeCallFunction(fun, ...rest) {
    var _a;
    if (isFunction(fun)) {
        return (_a = fun(...rest)) !== null && _a !== void 0 ? _a : true;
    }
    return undefined;
}
export function flattenDeepChildren(collection) {
    if (!Array.isArray(collection)) {
        return [];
    }
    const getTail = (item) => {
        if ((item === null || item === void 0 ? void 0 : item.children) && (item === null || item === void 0 ? void 0 : item.children.length) > 0) {
            return [item, item === null || item === void 0 ? void 0 : item.children.map((m) => getTail(m))];
        }
        else {
            return [item];
        }
    };
    const cloneCollection = cloneDeep(collection);
    const result = flattenDeep(cloneCollection.map((m) => getTail(m)));
    return Array.isArray(result) ? result : [];
    // return find(flattenDeepChildren, target);
}
export function isPromise(obj) {
    return (!!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function');
}
export function applyFieldNames(fieldNames) {
    const { label, value, children } = fieldNames || {};
    return {
        label: label || 'label',
        value: value || 'value',
        children: children || 'children',
    };
}
export function loopDataSource(dataSource, fieldNames) {
    const { label: fieldLabel, value: fieldValue, children: fieldChildren, } = applyFieldNames(fieldNames);
    const result = [];
    dataSource.forEach(item => {
        if (item[fieldChildren]) {
            const children = loopDataSource(item[fieldChildren]);
            result.push({
                ...item,
                label: item[fieldLabel],
                value: item[fieldValue],
                children,
            });
        }
        else {
            result.push({
                ...item,
                label: item[fieldLabel],
                value: item[fieldValue],
            });
        }
    });
    return result;
}
