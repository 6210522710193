import * as React from 'react';
import Tree from './view/tree';
import TreeNode from './view/tree-node';
Tree.Node = TreeNode;
// CnTree 中有 node.type !== TreeNode 的实现逻辑，暂时这样处理
const CnTreeNode = TreeNode;
CnTreeNode.displayName = 'CnTreeNode';
const CnTree = React.forwardRef((props, ref) => {
    return React.createElement(Tree, { ...props, "data-name": "CnTree", ref: ref });
});
CnTree.displayName = 'CnTree';
CnTree.Node = CnTreeNode;
export { CnTree, CnTreeNode, TreeNode };
export default CnTree;
