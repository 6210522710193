import React from 'react';
import { CnMessage } from '@/components/cn-message';
import { sendLog } from '@/components/cn-utils';
import { CnDragTransfer } from '@/components/cn-drag-transfer';
import { defaultDragZone } from '../const';
import { CnDialog } from '@/components/cn-dialog';
export default ({ onDragConfigItem, enableSaveSelected, dataSource, updateDataSource, setOverlayVisible, configInited, configValue, field, overlayVisible, gridProps, saveSelectSpan, $i18n, }) => {
    const [dragDataSource, setDragDataSource] = React.useState({});
    React.useEffect(() => {
        const newDefault = [];
        const newMore = [];
        if (enableSaveSelected) {
            newDefault.push({
                label: $i18n.get({
                    id: 'QueryCustomaryArea',
                    dm: '查询习惯区',
                    ns: 'CnFilter',
                }),
                span: saveSelectSpan || 1,
                disabled: true,
                className: 'cn-ui-filter-config-modal-query',
            });
        }
        dataSource.forEach((i) => {
            if (i.visible) {
                newDefault.push(i);
            }
            else {
                newMore.push(i);
            }
        });
        setDragDataSource({
            default: newDefault,
            more: newMore,
        });
    }, [dataSource]);
    const handleConfigOk = () => {
        sendLog({
            id: 'cn-ui.cn-filter.clickConfigOkButton',
            name: 'CnFilter查询配置面板确认点击',
        });
        let newDataSource = [];
        newDataSource = defaultDragZone().reduce((p, { key: k }) => {
            var _a;
            return ((_a = dragDataSource === null || dragDataSource === void 0 ? void 0 : dragDataSource[k]) === null || _a === void 0 ? void 0 : _a.length)
                ? p.concat(dragDataSource[k].map((i) => ({
                    ...i,
                    visible: k === 'default',
                })))
                : p;
        }, []);
        newDataSource = newDataSource.filter((i) => !i.disabled);
        updateDataSource(newDataSource);
        setOverlayVisible(false);
    };
    React.useEffect(() => {
        if (!configInited)
            return;
        field.filterChange && field.filterChange(field.getValues(), { field });
    }, [configValue]);
    return (React.createElement(CnDialog, { size: "autoLarge", className: "cn-ui-filter-config-modal", visible: overlayVisible, onClose: () => setOverlayVisible(false), title: $i18n.get({
            id: 'configPanelTitle',
            dm: '查询设置',
        }), closeMode: ['close', 'esc', 'mask'], onOk: handleConfigOk, onCancel: () => setOverlayVisible(false), footerActions: ['cancel', 'ok'] },
        React.createElement(CnMessage, { type: "notice" }, $i18n.get({
            id: 'OptionsBlocksInTheAreasWhere_84475068',
            dm: '已添加查询项、未添加查询项区域内选项块可以相互拖拽，或通过添加和移除图标按钮操作，未添加查询项将不会出现在查询区内',
            ns: 'CnFilter',
        })),
        React.createElement(CnDragTransfer, { dataSource: dragDataSource, onChange: (v, actions) => {
                sendLog({
                    id: 'cn-ui.cn-filter.changeConfigItem',
                    name: 'CnFilter查询配置项更新事件(拖拽或者点击上下)',
                });
                let newDragDataSource = v;
                if (typeof onDragConfigItem === 'function') {
                    newDragDataSource = onDragConfigItem(v, actions) || newDragDataSource;
                }
                setDragDataSource(newDragDataSource);
            }, gridProps: gridProps, dragZone: defaultDragZone() })));
};
