import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnBalloon } from '@/components/cn-balloon';
import CnDialog from '@/components/cn-dialog/view/index';
import { CnMessage } from '@/components/cn-message';
import { unpackRequest } from '@/components/cn-utils';
import omit from 'lodash/omit';
import cx from 'classnames';
import './index.scss';
const MESSAGE_TYPE = {
    alert: 'warning',
    confirm: 'help',
    's-alert': 'warning',
};
const handleRequest = async (requestConfig) => {
    const { service, ...config } = requestConfig;
    let rst = {};
    try {
        if (typeof service === 'function') {
            rst = await service();
        }
        else if (config === null || config === void 0 ? void 0 : config.url) {
            rst = await unpackRequest(config);
        }
        return Promise.resolve(rst);
    }
    catch (e) {
        return Promise.reject(e);
    }
};
const getDialogProps = (props) => {
    const mergedProps = {
        ...View.defaultProps,
        ...props,
    };
    const { dialogType, dialogTitle, dialogContent, dialogOkProps, dialogCancelProps, dialogProps, requestConfig, isConfirmSuccess, successMsg, onConfirmSuccess, onConfirmError, } = mergedProps;
    let errorMsg = mergedProps.errorMsg;
    const { width: dialogWidth, className: dialogClassName, onOk: dialogOnOk, onCancel: dialogOnCancel, ...otherDialogProps } = dialogProps || {};
    const handleDialogOk = async (e, dialog, setLoading) => {
        var _a;
        if (typeof dialogOnOk === 'function') {
            dialogOnOk(e, dialog);
            return;
        }
        let success;
        let rst;
        setLoading && setLoading(true);
        try {
            rst = await handleRequest(requestConfig);
            success = true;
            if (typeof isConfirmSuccess === 'function') {
                success = isConfirmSuccess(rst);
            }
        }
        catch (error) {
            rst = error;
            success = false;
            errorMsg = ((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.errorMsg) || errorMsg;
        }
        setLoading && setLoading(false);
        if (success) {
            if (successMsg) {
                const successFn = typeof successMsg === 'function'
                    ? successMsg
                    : () => successMsg;
                CnMessage.success(successFn());
            }
            if (typeof onConfirmSuccess === 'function') {
                onConfirmSuccess(rst);
            }
        }
        else {
            if (errorMsg) {
                const errorFn = typeof errorMsg === 'function'
                    ? errorMsg
                    : () => errorMsg;
                CnMessage.error(errorFn());
            }
            if (typeof onConfirmError === 'function') {
                onConfirmError(rst);
            }
        }
        dialog.hide();
    };
    const handleDialogCancel = (e, dialog) => {
        if (typeof dialogOnCancel === 'function') {
            dialogOnCancel(e, dialog);
            return;
        }
        dialog.hide();
    };
    return {
        dialogWidth,
        dialogClassName,
        dialogOnOk,
        dialogType,
        dialogTitle,
        dialogContent,
        dialogOkProps,
        dialogCancelProps,
        handleDialogOk,
        handleDialogCancel,
        otherDialogProps,
    };
};
const confirm = (props) => {
    const { dialogWidth, dialogClassName, dialogType, dialogTitle, dialogContent, dialogOkProps, dialogCancelProps, handleDialogOk, handleDialogCancel, otherDialogProps, } = getDialogProps(props);
    const dialog = CnDialog.show({
        v2: true,
        className: cx(dialogClassName, {
            's-alert-dialog': dialogType === 's-alert',
        }),
        type: dialogType === 'confirm' ? 'confirm' : 'alert',
        width: dialogWidth || 400,
        size: 'small',
        title: dialogTitle,
        content: dialogContent,
        okProps: {
            warning: dialogType === 's-alert',
            ...dialogOkProps,
        },
        cancelProps: dialogCancelProps,
        onOk: ((p) => {
            handleDialogOk(p, dialog);
        }),
        onCancel: ((p) => {
            handleDialogCancel(p, dialog);
        }),
        footerActions: ['cancel', 'ok'],
        centered: true,
        ...otherDialogProps,
    });
    return dialog;
};
function View(props) {
    const { onClick, btnContent, children, isBalloon, balloonProps } = props;
    const [balloonVisible, setBalloonVisible] = React.useState(false);
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleClick = (e) => {
        if (typeof onClick === 'function') {
            onClick(e);
            return;
        }
        setDialogVisible(true);
    };
    const buttonProps = omit(props, [
        'btnContent',
        'isBalloon',
        'balloonProps',
        'dialogType',
        'dialogTitle',
        'dialogContent',
        'dialogOkProps',
        'dialogCancelProps',
        'dialogProps',
        'requestConfig',
        'isConfirmSuccess',
        'successMsg',
        'errorMsg',
        'onConfirmSuccess',
        'onConfirmError',
        '$i18n',
    ]);
    const { dialogWidth, dialogClassName, dialogType, dialogTitle, dialogContent, dialogOkProps, dialogCancelProps, handleDialogOk, handleDialogCancel, otherDialogProps, } = getDialogProps(props);
    if (isBalloon) {
        return (React.createElement(CnBalloon, { v2: true, className: "cn-ui-confirm-button-balloon", triggerType: "click", trigger: React.createElement(CnButton, { ...buttonProps }, btnContent || children), closable: true, visible: balloonVisible, onVisibleChange: setBalloonVisible, ...balloonProps },
            React.createElement(CnDialog.Inner, { v2: true, className: cx(dialogClassName, {
                    'cn-ui-confirm-button-dialog': true,
                    's-alert-dialog': dialogType === 's-alert',
                }), footerActions: ['cancel', 'ok'], onOk: ((p) => {
                    handleDialogOk(p, {
                        hide: () => {
                            setBalloonVisible(false);
                        },
                    }, setLoading);
                }), onCancel: ((p) => {
                    handleDialogCancel(p, {
                        hide: () => {
                            setBalloonVisible(false);
                        },
                    });
                }), okProps: {
                    loading,
                    ...dialogOkProps,
                }, cancelProps: dialogCancelProps, onClose: () => setBalloonVisible(false), width: dialogWidth, ...otherDialogProps },
                React.createElement(CnMessage, { size: "large", shape: "addon", type: MESSAGE_TYPE[dialogType], title: dialogTitle }, dialogContent))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CnButton, { ...buttonProps, "data-name": "CnConfirmButton", ...omit(props, [
                'btnContent',
                'dialogType',
                'dialogTitle',
                'dialogContent',
                'dialogOkProps',
                'dialogCancelProps',
                'dialogProps',
                'requestConfig',
                'isConfirmSuccess',
                'successMsg',
                'errorMsg',
                'onConfirmSuccess',
                'onConfirmError',
                '$i18n',
            ]), onClick: handleClick }, btnContent || children),
        React.createElement(CnDialog, { ...{
                visible: dialogVisible,
                className: cx(dialogClassName, {
                    's-alert-dialog': dialogType === 's-alert',
                }),
                type: dialogType === 'confirm' ? 'confirm' : 'alert',
                width: dialogWidth || 400,
                size: 'small',
                okProps: {
                    warning: dialogType === 's-alert',
                    loading,
                    ...dialogOkProps,
                },
                cancelProps: dialogCancelProps,
                onOk: ((p) => {
                    handleDialogOk(p, {
                        hide: () => {
                            setDialogVisible(false);
                        },
                    }, setLoading);
                }),
                onCancel: ((p) => {
                    handleDialogCancel(p, {
                        hide: () => {
                            setDialogVisible(false);
                        },
                    });
                }),
                onClose: () => setDialogVisible(false),
                footerActions: ['cancel', 'ok'],
                centered: true,
                ...otherDialogProps,
            } },
            React.createElement(CnMessage, { size: "large", shape: "addon", type: MESSAGE_TYPE[dialogType], title: dialogTitle }, dialogContent))));
}
View.confirm = (config) => {
    if (config.isBalloon)
        return;
    confirm(config);
};
View.alert = (config) => {
    if (config.isBalloon)
        return;
    confirm({
        ...config,
        dialogType: 'alert',
    });
};
View.salert = (config) => {
    if (config.isBalloon)
        return;
    confirm({
        ...config,
        dialogType: 's-alert',
    });
};
View.defaultProps = {
    btnContent: '',
    isBalloon: false,
    balloonProps: {},
    dialogType: 'confirm',
    dialogTitle: '',
    dialogContent: '',
    dialogOkProps: {},
    dialogCancelProps: {},
    dialogProps: {},
    requestConfig: {},
    isConfirmSuccess: null,
    successMsg: () => $i18n.get({
        id: 'OperationSuccessful',
        dm: '操作成功',
        ns: 'CnConfirmButton',
    }),
    errorMsg: () => $i18n.get({
        id: 'OperationFailed',
        dm: '操作失败',
        ns: 'CnConfirmButton',
    }),
    onConfirmSuccess: () => { },
    onConfirmError: () => { },
};
export default View;
