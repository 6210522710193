import './sub-card.scss';
import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
export function CnCardSubCard({ className = '', subTitle = '', subDesc, action, children, }) {
    let actionDom = action;
    if (isFunction(action)) {
        // @ts-ignore
        actionDom = action();
    }
    return (React.createElement("div", { className: classNames(['cn-ui-card-sub-card', className]) },
        subTitle && (React.createElement("div", { className: "cn-ui-card-sub-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-card-header-header" }, subTitle),
            React.createElement("div", { className: "cn-ui-card-sub-card-header-footer" }, actionDom))),
        subDesc && (React.createElement("div", { className: "cn-ui-card-subdesc cn-ui-card-desc" }, subDesc)),
        children && (React.createElement("div", { className: "cn-ui-card-sub-card-content" }, children))));
}
CnCardSubCard.displayName = 'CnCardSubCard';
