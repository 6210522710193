import * as formilyCore from '@formily/core';
import { isEmpty, find, isNil } from 'lodash';
import { getRequest, request } from 'cn-request';
const FORMILY_HOOKS = [
    {
        label: '字段钩子',
        children: [
            {
                label: '字段初始化',
                value: 'onFieldReact',
            },
            {
                label: '字段值变化',
                value: 'onFieldValueChange',
            },
            {
                label: '输入框失焦时',
                value: 'onFieldChange_active_false',
            },
        ],
    },
    {
        label: '表单钩子',
        isFormHooks: true,
        children: [
            {
                label: '表单初始化',
                value: 'onFormInit',
            },
            {
                label: '表单值变化',
                value: 'onFormValuesChange',
            },
            {
                label: '表单字段输入',
                value: 'onFormInputChange',
            },
        ],
    },
];
const CONDITION_OPTIONS = [
    {
        label: '值等于',
        value: '$self.value==${conditionValue}',
    },
    {
        label: '值不等于',
        value: '$self.value!=${conditionValue}',
    },
    {
        label: '包含',
        value: '$self.value.includes(${conditionValue})',
    },
    {
        label: '不包含',
        value: '!$self.value.includes(${conditionValue})',
    },
    {
        label: '为空',
        value: '!$self.value',
        hiddenValue: true,
    },
    {
        label: '不为空',
        value: '!!$self.value',
        hiddenValue: true,
    },
];
export const isFormHooks = (value) => {
    var _a;
    return !isNil(find((_a = find(FORMILY_HOOKS, 'isFormHooks')) === null || _a === void 0 ? void 0 : _a.children, ['value', value]));
};
export const isNoValueCondition = (value) => {
    var _a, _b;
    return (_b = (_a = find(CONDITION_OPTIONS, ['value', value])) === null || _a === void 0 ? void 0 : _a.hiddenValue) !== null && _b !== void 0 ? _b : false;
};
const packRequest = (options) => {
    if (!getRequest()) {
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options)
        .then((result) => {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
};
const generateParams = (array, values) => {
    return Array.from(array)
        .reduce((acc, { key, value, }) => Object.assign(acc, { [key]: value === '$self.value' ? values : value }), {});
};
export const handleRequestService = (requestConfig) => {
    const { url, service, params: paramsProps, data: dataProps, searchFormat = (data) => data, method = 'GET', } = requestConfig || {};
    if (service) {
        return service;
    }
    if (url) {
        return (searchConfig) => {
            const { formValues } = searchConfig || {};
            const params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat({ ...formValues, ...paramsProps })
                : paramsProps;
            const data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat({ ...formValues, ...dataProps })
                : dataProps;
            return packRequest({
                ...requestConfig,
                params,
                data,
                url,
            });
        };
    }
    return () => Promise.resolve([]);
};
const getRealCondition = (condition, conditionValue) => `${condition.replace('${conditionValue}', `'${conditionValue}'`)}`;
export const generateEffects = (reactionsList) => {
    reactionsList.forEach((watchItem) => {
        const { watchCode, reactions, } = watchItem;
        reactions.forEach(reaction => {
            var _a, _b, _c;
            const { effects, fulfill, } = reaction;
            const { resourcePool, } = fulfill;
            const { field: rpField, state: rpState, condition: rpCondition, conditionValue: rpConditionValue, data: rpData = {}, } = resourcePool;
            const { fromDataSource, syncData, asyncData, } = rpData;
            const requestConfig = {
                url: asyncData === null || asyncData === void 0 ? void 0 : asyncData.url,
                method: asyncData === null || asyncData === void 0 ? void 0 : asyncData.method,
                formatResult: (asyncData === null || asyncData === void 0 ? void 0 : asyncData.formatResult) ? new Function(`return ${asyncData.formatResult}`)() : (res) => res,
            };
            const fieldEffectsHandle = (field, form) => {
                var _a, _b, _c;
                // 监听onFieldChange时，会存在field.value为undefined的情况；需要做容错处理
                field.value = field.value ? field.value : '';
                // new Function("$self", "$form", `${fulfill?.run}`)(field, form)
                const conditionResult = new Function('$self', `return ${getRealCondition(rpCondition, rpConditionValue)}`)(field);
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'GET') {
                    requestConfig.params = generateParams((_a = asyncData.params) === null || _a === void 0 ? void 0 : _a.array, field.value);
                }
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'POST') {
                    requestConfig.data = generateParams((_b = asyncData.params) === null || _b === void 0 ? void 0 : _b.array, field.value);
                }
                if (rpState === 'value') {
                    if (conditionResult) {
                        const requestService = handleRequestService(requestConfig);
                        requestService()
                            .then(requestRes => {
                            var _a;
                            const currentOption = fromDataSource ? (_a = find(field.getState().dataSource, ['value', field.value])) !== null && _a !== void 0 ? _a : {} : {};
                            const realResponseData = requestConfig.formatResult(requestRes);
                            const finalData = Object.assign(currentOption, new Function(`return ${syncData}`)(), realResponseData);
                            rpField.forEach(f => {
                                form.setFieldState(f, { value: finalData === null || finalData === void 0 ? void 0 : finalData[f] });
                            });
                        });
                    }
                    else {
                        rpField.forEach(f => {
                            form.setFieldState(f, { value: '' });
                        });
                    }
                }
                else if (rpState === 'dataSource') {
                    if (conditionResult) {
                        const currentOption = fromDataSource ? (_c = find(field.getState().dataSource, ['value', field.value])) !== null && _c !== void 0 ? _c : {} : {};
                        const realSyncData = new Function(`return ${syncData}`)();
                        const finalData = Object.assign(currentOption);
                        const requestService = handleRequestService(requestConfig);
                        requestService()
                            .then(requestRes => {
                            rpField.forEach(f => {
                                form.setFieldState(f, { value: '' });
                                form.setFieldState(f, (state) => {
                                    if (Array.isArray(realSyncData)) {
                                        Object.assign(finalData, { [f]: realSyncData });
                                    }
                                    const realResponseData = requestConfig.formatResult(requestRes);
                                    if (Array.isArray(realResponseData)) {
                                        Object.assign(finalData, { [f]: realResponseData });
                                    }
                                    state.dataSource = finalData === null || finalData === void 0 ? void 0 : finalData[f];
                                });
                            });
                        });
                    }
                    else {
                        rpField.forEach(f => {
                            form.setFieldState(f, (state) => {
                                state.dataSource = [];
                            });
                        });
                    }
                }
                else {
                    rpField.forEach(f => {
                        form.setFieldState(f, (state) => {
                            state[rpState] = conditionResult;
                        });
                    });
                }
            };
            const formEffectsHandle = (form) => {
                var _a, _b;
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'GET') {
                    requestConfig.params = generateParams((_a = asyncData.params) === null || _a === void 0 ? void 0 : _a.array);
                }
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'POST') {
                    requestConfig.data = generateParams((_b = asyncData.params) === null || _b === void 0 ? void 0 : _b.array);
                }
                if (rpState === 'value') {
                    const requestService = handleRequestService(requestConfig);
                    requestService()
                        .then(requestRes => {
                        const realResponseData = requestConfig.formatResult(requestRes);
                        const finalData = Object.assign({}, new Function(`return ${syncData}`)(), realResponseData);
                        rpField.forEach(f => {
                            form.setFieldState(f, (state) => {
                                state.value = finalData === null || finalData === void 0 ? void 0 : finalData[f];
                            });
                        });
                    });
                }
                else if (rpState === 'dataSource') {
                    const realSyncData = new Function(`return ${syncData}`)();
                    const finalData = {};
                    const requestService = handleRequestService(requestConfig);
                    requestService()
                        .then(requestRes => {
                        rpField.forEach(f => {
                            // 此处需要使用*(${f})写法，否则当触发CnDynamicForm中clearFormGraph、deleteValuesIn方法时，dataSource会被清空。
                            form.setFieldState(`*(${f})`, (state) => {
                                if (Array.isArray(realSyncData)) {
                                    Object.assign(finalData, { [f]: realSyncData });
                                }
                                const realResponseData = requestConfig.formatResult(requestRes);
                                if (Array.isArray(realResponseData)) {
                                    Object.assign(finalData, { [f]: realResponseData });
                                }
                                state.dataSource = finalData === null || finalData === void 0 ? void 0 : finalData[f];
                            });
                        });
                    });
                }
                else {
                    rpField.forEach(f => {
                        form.setFieldState(f, (state) => {
                            state[rpState] = true;
                        });
                    });
                }
            };
            // 判断当存在watchCode并且存在fulfill.run时，才执行
            if (!isEmpty(resourcePool)) {
                if (isFormHooks(effects[0])) {
                    (_a = formilyCore[effects[0]]) === null || _a === void 0 ? void 0 : _a.call(formilyCore, formEffectsHandle);
                }
                else {
                    if (effects[0].includes('_')) {
                        const [effectsHook, effectsWatch, watchBool] = effects[0].split('_');
                        const execuBool = new Function(`return ${watchBool}`)();
                        (_b = formilyCore[effectsHook]) === null || _b === void 0 ? void 0 : _b.call(formilyCore, watchCode, [effectsWatch], (field, form) => {
                            if (field.getState()[effectsWatch] === execuBool) {
                                fieldEffectsHandle(field, form);
                            }
                        });
                    }
                    else {
                        (_c = formilyCore[effects[0]]) === null || _c === void 0 ? void 0 : _c.call(formilyCore, watchCode, fieldEffectsHandle);
                    }
                }
            }
        });
    });
};
