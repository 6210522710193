import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-utils';
import './index.scss';
export const CnStatusTag = (props) => {
    const { type, status, size, text, children } = props;
    return withNativeProps(props, React.createElement("div", { className: cx('cn-ui-status-tag', {
            [`cn-ui-status-tag-${type}`]: Boolean(type),
            [`cn-ui-status-tag-${size}`]: Boolean(size),
            [`cn-ui-status-tag-status-${status}`]: Boolean(status),
        }) }, children || text));
};
CnStatusTag.displayName = 'CnStatusTag';
CnStatusTag.defaultProps = {
    type: 'normal',
    size: 'medium',
    status: 'info',
};
