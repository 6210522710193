import { useMemo, useState, useRef, useCallback } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
export function useSecond(callback, deps) {
    const hasBeSecond = useRef(false);
    useMemo(() => {
        if (hasBeSecond.current) {
            callback instanceof Function && callback();
        }
        else {
            hasBeSecond.current = true;
        }
    }, [...deps]);
}
export function useControlValue(props, opts) {
    var _a;
    const { defaultValue, valuePropName = 'value', changePropName = 'onChange', } = opts;
    const [data, setData] = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValue);
    const setHandler = useCallback((...args) => {
        const finalChange = get(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            finalChange(...args);
        }
        !(valuePropName in (props || {})) && setData(args[0]);
    }, [props, valuePropName, changePropName]);
    useSecond(() => {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
