import throttle from 'lodash/throttle';
/**
 * 获取ARMS追踪对象
 * @returns {Object} ARMS追踪对象
 */
function getArmsTrace() {
    return window.coneArmsTrace;
}
/**
 * 上报翻页事件
 */
export function pageChangeArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '翻页',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.pageChange',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 节流后的列拖拽宽度改变事件上报函数
 */
export const columnResizeChangeArmsReport = throttle(columnResizeChangeArmsReportBase, 3000);
/**
 * 列拖拽宽度改变事件上报函数
 */
function columnResizeChangeArmsReportBase() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列拖拽宽度改变',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnResize',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报列左侧固定事件
 */
export function columnsLeftFixedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列左侧固定',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsLeftFixed',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报列右侧固定事件
 */
export function columnsRightFixedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列右侧固定',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsRightFixed',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报列全选事件
 */
export function columnsAllCheckedArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列全选',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsAllChecked',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报列拖拽位置改变事件
 */
export function columnsDragDropArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列拖拽位置改变',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsdragDrop',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报列设置事件
 */
export function columnsSettingArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return; // 如果trace或reportHottag不存在，则直接返回
    try {
        trace.reportHottag({
            name: '列设置',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.columnsSetting',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报表格全屏事件
 */
export function tableFullScreenArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格全屏',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableFullScreen',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报表格密度选择事件
 */
export function tableDensityArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格密度选择',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableDensity',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报表格排序事件
 */
export function tableSortArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格排序',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tableSort',
        });
    }
    catch (error) {
        console.log(error);
    }
}
/**
 * 上报表格列筛选事件
 */
export function tablefilterArmsReport() {
    const trace = getArmsTrace();
    if (!trace || !trace.reportHottag)
        return;
    try {
        trace.reportHottag({
            name: '表格列筛选',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-table.tablefiltert',
        });
    }
    catch (error) {
        console.log(error);
    }
}
