import $i18n from 'panda-i18n'; /* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { rowDetailSymbol, isSysFixedCol } from '../global';
import { safeCallFunction } from '@/components/cn-utils';
import { isFunction, always, flatMap, collectNodes, safeRender, isDef, } from '../utils';
import { InlineFlexCell } from '../dep';
const getRowDetailsCols = (columns, colOpAttr) => {
    if (columns.length === 0) {
        return columns;
    }
    const { getSpanRect, judgeRender, getCellProps, multiFirstRender } = colOpAttr;
    const fixedCols = [];
    const othersCols = [];
    columns.forEach((col) => {
        (isSysFixedCol(col.symbol) ? fixedCols : othersCols).push(col);
    });
    const controlCol = {
        name: '',
        code: null,
        symbol: rowDetailSymbol,
        width: 45,
        sizeFixed: true,
    };
    if (fixedCols.length === 0 && othersCols.length) {
        return [
            {
                // 控制列 + 详情行合并列
                ...controlCol,
                getSpanRect,
                getCellProps,
                render(value, row, rowIndex) {
                    return judgeRender(controlCol, row, rowIndex);
                },
            },
            ...othersCols,
        ];
    }
    const [firstCol, ...otherFixedCols] = fixedCols;
    return [
        {
            ...(firstCol || {}),
            getSpanRect,
            getCellProps,
            render(value, row, rowIndex) {
                return multiFirstRender(firstCol, row, rowIndex);
            },
        },
        ...otherFixedCols,
        //
        {
            ...controlCol,
            render(value, row, rowIndex) {
                return judgeRender(controlCol, row, rowIndex);
            },
        },
        ...othersCols,
    ];
};
// @ts-ignore
var openStatus;
(function (openStatus) {
    openStatus["expand"] = "expand";
    openStatus["collapse"] = "collapse";
})(openStatus || (openStatus = {}));
const storePrimaryKeySymbol = Symbol('storePrimaryKeySymbol');
const sourceRowIndex = Symbol('sorceRowIndex');
export default function rowDetail(opts) {
    return function rowDetailStep(pipeline) {
        var _a, _b, _c, _d, _e, _f;
        const stateKey = 'rowDetail';
        const primaryKey = pipeline.ensurePrimaryKey('rowDetail');
        if (typeof primaryKey !== 'string') {
            throw new Error($i18n.get({
                id: 'RowDetailOnlySupportsStringsAsPrimaryKey_1306206224',
                dm: 'rowDetail 仅支持字符串作为 primaryKey',
                ns: 'CnBaseTable',
            }));
        }
        const rowDetailMetaKey = (_a = opts.rowDetailMetaKey) !== null && _a !== void 0 ? _a : rowDetailSymbol;
        const { indents } = pipeline.ctx;
        const textOffset = indents.iconIndent + indents.iconWidth + indents.iconGap;
        const getDetailKey = (_b = opts.getDetailKey) !== null && _b !== void 0 ? _b : ((row) => `${row[primaryKey]}_detail`);
        const renderDetail = (row, rowIndex) => {
            var _a;
            const sourceRenderDetails = (_a = opts.renderDetail) !== null && _a !== void 0 ? _a : fallbackRenderDetail;
            const renderRow = Object.assign({}, row);
            // @ts-ignore
            renderRow[primaryKey] = renderRow[storePrimaryKeySymbol];
            // @ts-ignore
            delete renderRow[storePrimaryKeySymbol];
            return sourceRenderDetails(renderRow, row[sourceRowIndex]);
        };
        const hasDetail = (_c = opts.hasDetail) !== null && _c !== void 0 ? _c : always(true); // 判断
        const openKeys = (_f = (_e = (_d = opts.openKeys) !== null && _d !== void 0 ? _d : pipeline.getStateAtKey(stateKey)) !== null && _e !== void 0 ? _e : (opts.defaultOpenAll
            ? pipeline
                .getDataSource()
                .filter(hasDetail)
                .map((row) => row[primaryKey])
            : opts.defaultOpenKeys)) !== null && _f !== void 0 ? _f : [];
        const onChangeOpenKeys = (nextKeys, key, status, row) => {
            const { onChangeOpenKeys } = opts;
            isFunction(onChangeOpenKeys) &&
                onChangeOpenKeys(nextKeys, key, status, row);
            pipeline.setStateAtKey(stateKey, nextKeys, { key, status });
        };
        const openKeySet = new Set(openKeys);
        const toggle = (rowKey, row) => {
            const expanded = openKeySet.has(rowKey);
            if (expanded) {
                onChangeOpenKeys(openKeys.filter((key) => key !== rowKey), rowKey, openStatus.collapse, row);
            }
            else {
                onChangeOpenKeys([...openKeys, rowKey], rowKey, openStatus.expand, row);
            }
        };
        return (pipeline
            .dataSource(
        // 核心添加展开详情的逻辑
        flatMap(pipeline.getDataSource(), (row, rowIndex) => {
            if (isDef(row) && openKeySet.has(row[primaryKey])) {
                return [
                    row,
                    {
                        [rowDetailMetaKey]: true,
                        [sourceRowIndex]: rowIndex + 1,
                        ...row,
                        [storePrimaryKeySymbol]: row[primaryKey],
                        [primaryKey]: getDetailKey(row, rowIndex),
                    },
                ];
            }
            return [row];
        }))
            // @ts-ignore
            .columns(processColumns(pipeline.getColumns()))
            .appendRowPropsGetter((row, rowIndex) => {
            if (row[rowDetailMetaKey]) {
                return { className: 'no-hover' };
            }
            return { className: '' };
        }));
        function processColumns(columns) {
            if (columns.length === 0) {
                return columns;
            }
            const columnFlatCount = collectNodes(columns, 'leaf-only').length;
            const [firstCol, ...others] = columns;
            const judgeRender = (value, row, rowIndex) => {
                var _a;
                // 行详情
                if (row[rowDetailMetaKey]) {
                    return renderDetail(row, rowIndex);
                }
                const content = safeRender(firstCol, row, rowIndex);
                const rowKey = row[primaryKey];
                const expanded = openKeySet.has(rowKey);
                const expandCls = expanded ? 'expanded' : 'collapsed';
                const isHasDetail = !hasDetail(row, rowIndex);
                const onClick = (event) => {
                    if (opts.stopClickEventPropagation) {
                        event.stopPropagation();
                    }
                    toggle(rowKey, row);
                };
                //  不包含详情 减少过度设计，这里先注释
                if (isHasDetail) {
                    return (React.createElement(InlineFlexCell, { style: { marginLeft: textOffset } }, (_a = safeCallFunction(opts === null || opts === void 0 ? void 0 : opts.controlCustomRender, content, expandCls, isHasDetail)) !== null && _a !== void 0 ? _a : React.createElement("div", null)));
                }
                // 用户自定义展开列
                if (typeof opts.controlCustomRender === 'function') {
                    return (React.createElement("div", { style: { cursor: 'pointer', textAlign: 'center' }, onClick: onClick }, opts.controlCustomRender(content, expandCls, isHasDetail)));
                }
                // 默认内容
                return (React.createElement("div", { style: { cursor: 'pointer', textAlign: 'center' }, onClick: onClick },
                    React.createElement(CnIcon, { size: opts.defaultIconSize || 'xxs', type: expanded ? 'minus' : 'add', style: opts.defaultIconStyle })));
            };
            const getCellProps = (value, row, rowIndex) => {
                var _a;
                if (row[rowDetailMetaKey]) {
                    return {
                        style: {
                            '--cell-padding': '0',
                            overflow: 'hidden',
                            ...opts.detailCellStyle,
                        },
                    };
                }
                const prevProps = (_a = firstCol.getCellProps) === null || _a === void 0 ? void 0 : _a.call(firstCol, value, row, rowIndex);
                return prevProps;
            };
            // 真正展示成行详情的逻辑
            return getRowDetailsCols(columns, 
            // newColProps
            {
                // 合并列，用于第一列
                getSpanRect(value, row, rowIndex) {
                    if (row[rowDetailMetaKey]) {
                        // detail 总是成一行， newCol 要多一列， 所以 columnFlatCount + 1
                        return {
                            top: rowIndex,
                            bottom: rowIndex + 1,
                            left: 0,
                            right: columnFlatCount + 1,
                        };
                    }
                    return {};
                },
                judgeRender,
                // @ts-ignore
                getCellProps,
                multiFirstRender(col, row, rowIndex) {
                    return row[rowDetailMetaKey]
                        ? renderDetail(row, rowIndex)
                        : safeRender(col, row, rowIndex);
                },
            });
        }
    };
}
function fallbackRenderDetail() {
    return (React.createElement("div", { style: { margin: '8px 24px' } },
        React.createElement("b", { style: { color: 'indianred' } },
            $i18n.get({ id: 'PleaseSet', dm: '请通过设置', ns: 'CnBaseTable' }),
            React.createElement("code", null, "rowDetail.renderDetail"),
            $i18n.get({
                id: 'CustomizeDetailedContent',
                dm: '来自定义详情内容',
                ns: 'CnBaseTable',
            }))));
}
