import * as React from 'react';
import { observer, RecursionField, useField, useFieldSchema } from '@formily/react';
import { CnTab, CnTabItem } from '@/components/cn-tab';
import { CnBadge } from '@/components/cn-badge';
import { CnCard } from '@/components/cn-card';
import { model, markRaw } from '@formily/reactive';
import { isValid } from '@formily/shared';
import './index.scss';
const createCnFormTab = (defaultActiveKey) => {
    const formTab = model({
        activeKey: defaultActiveKey,
        setActiveKey: (key) => {
            formTab.activeKey = key;
        },
    });
    return markRaw(formTab);
};
const useTabs = () => {
    const tabs = [];
    const fieldSchema = useFieldSchema();
    const tabsField = useField();
    fieldSchema.mapProperties((schema, name) => {
        var _a, _b;
        const field = tabsField.query(tabsField.address.concat(name)).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' || (field === null || field === void 0 ? void 0 : field.display) === 'hidden') {
            return;
        }
        if ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormTabItem')) {
            tabs.push({
                name,
                schema,
                props: {
                    key: ((_b = schema === null || schema === void 0 ? void 0 : schema['x-component-props']) === null || _b === void 0 ? void 0 : _b.key) || name,
                    ...schema === null || schema === void 0 ? void 0 : schema['x-component-props'],
                }
            });
        }
    });
    return tabs;
};
export const CnFormTab = observer((props) => {
    const tabField = useField();
    const { formTab, ...otherProps } = props;
    const _formTab = React.useMemo(() => {
        return formTab || createCnFormTab();
    }, []);
    const tabs = useTabs();
    const activeKey = props.activeKey || _formTab.activeKey;
    const badgedTab = (key, props) => {
        const errors = tabField.form.queryFeedbacks({
            type: 'error',
            address: `${tabField.address.concat(key)}.*`
        });
        if (errors.length) {
            return (React.createElement(CnBadge, { count: errors.length, className: 'cn-ui-form-tab-badge' }, props.title));
        }
        return props.title;
    };
    return (React.createElement(CnTab, { ...otherProps, ...(isValid(activeKey) && { activeKey }), onChange: k => {
            var _a, _b;
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, k);
            (_b = formTab === null || formTab === void 0 ? void 0 : formTab.setActiveKey) === null || _b === void 0 ? void 0 : _b.call(formTab, k);
        }, lazyLoad: false }, tabs.map(({ schema, name, props }, key) => {
        return (React.createElement(CnTabItem, { key: key, ...props, title: badgedTab(name, props) },
            React.createElement(CnCard, null,
                React.createElement(RecursionField, { name: name, schema: schema }))));
    })));
});
CnFormTab.createCnFormTab = createCnFormTab;
export const CnFormTabItem = ({ children, }) => {
    return (React.createElement(React.Fragment, null, children));
};
