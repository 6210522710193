/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo, useState, useRef, useCallback } from 'react';
import { isFunction, getValueByPath } from './util';
export function useSecond(callback, deps) {
    const hasBeSecond = useRef(false);
    useMemo(() => {
        if (hasBeSecond.current) {
            callback instanceof Function && callback();
        }
        else {
            hasBeSecond.current = true;
        }
    }, [...deps]);
}
export function useUnitValue(props, opts) {
    var _a;
    const { defaultValuePropName = [], valuePropName = 'value' } = opts;
    const [data, setData] = useState((_a = props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName);
    useSecond(() => {
        setData(props[valuePropName]);
    }, [props[valuePropName]]);
    return [data, setData];
}
export function useControlValue(props, opts) {
    var _a, _b;
    const { defaultValuePropName = 'defaultValue', valuePropName = 'value', changePropName = 'onChange', defaultValue, } = opts;
    const [data, setData] = useState((_b = (_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : props === null || props === void 0 ? void 0 : props[defaultValuePropName]) !== null && _b !== void 0 ? _b : defaultValue);
    const setHandler = useCallback((...args) => {
        const finalChange = getValueByPath(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            // @ts-ignore
            finalChange(...args);
        }
        // @ts-ignore
        !(valuePropName in (props || {})) && setData(...args);
    }, [props, valuePropName, changePropName]);
    useSecond(() => {
        setData(props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    // @ts-ignore
    return [data, setHandler];
}
export const useUpdate = () => {
    const [, setState] = useState({});
    return useCallback(() => setState({}), []);
};
