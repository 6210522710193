// 第三方依赖
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useControllableValue } from 'ahooks';
// 其他组件
import { safeCallFunction } from '@/components/cn-utils';
import { globalConfig } from '../config';
export const usePage = (props) => {
    var _a, _b, _c;
    const pageInfo = React.useRef({});
    const pageHasChange = React.useRef(false);
    const { defaultPageSize, defaultCurrentPage, onPageChange, pageProps = {}, } = props;
    // const dataSourceLength = React.useMemo(
    //   () => props?.dataSource?.length ?? 0,
    //   [props?.dataSource],
    // );
    // React.us
    const pageSizeListValue = React.useMemo(() => {
        if ('pageSizeList' in pageProps)
            return { value: pageProps.pageSizeList };
        if ('pageSizeList' in props)
            return { value: props.pageSizeList };
        return {};
        // return pageProps?.pageSizeList ?? props?.pageSizeList;
    }, [pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSizeList, props === null || props === void 0 ? void 0 : props.pageSizeList]);
    // const [total, setTotal] = useState(pageProps?.total ?? dataSourceLength ?? 0);
    const [total, setTotal] = useControllableValue(pick(pageProps, ['total']), {
        valuePropName: 'total',
        defaultValue: (_b = (_a = props === null || props === void 0 ? void 0 : props.dataSource) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
    });
    React.useEffect(() => {
        var _a, _b;
        if ('dataSource' in props) {
            const dataSourceLength = (_b = (_a = props === null || props === void 0 ? void 0 : props.dataSource) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            setTotal(dataSourceLength);
        }
    }, [props === null || props === void 0 ? void 0 : props.dataSource]);
    // const [total, setTotal] = useControllableValue({
    //   defaultValue: dataSourceLength ?? 0,
    //   ...(!isNil(pageProps?.total) ? { value: pageProps?.total } : {}),
    // value: pageProps?.total ?? dataSourceLength,
    // });
    const [currentPage, setCurrent] = useControllableValue({
        ...pick(pageProps, ['current']),
        onChange: (currentPage, type) => handlePageChange({ currentPage, type }),
    }, {
        defaultValue: defaultCurrentPage !== null && defaultCurrentPage !== void 0 ? defaultCurrentPage : 1,
        valuePropName: 'current',
    });
    // const [currentPage, setCurrent] = useControllableValue({
    //   defaultValue: defaultCurrentPage ?? 1,
    //   ...(!isNil(pageProps?.current) ? { value: pageProps?.current } : {}),
    //   onChange: (currentPage, type) => handlePageChange({ currentPage, type }),
    // });
    // const [pageSize, setPageSize] = useControllableValue({
    //   defaultValue:
    //     defaultPageSize ?? pageSizeListValue?.[0] ?? Config.defaultPageSize,
    //   ...(!isNil(pageProps?.pageSize) ? { value: pageProps?.pageSize } : {}),
    //   onChange: (pageSize) => handlePageChange({ pageSize }),
    // });
    // const [pageSizeList, setPageSizeList] = useControllableValue(
    //   {
    //     ...(!isNil(pageSizeListValue) ? { value: pageSizeListValue } : {}),
    //     onChange: (pageSizeList) => handlePageChange({ pageSizeList }),
    //   },
    //   {
    //     defaultValue: Config.pageSizeList,
    //   },
    // );
    const [pageSizeList, setPageSizeList] = useControllableValue({
        ...pageSizeListValue,
        onChange: (pageSizeList) => handlePageChange({ pageSizeList }),
    }, {
        defaultValue: globalConfig.pageSizeList,
    });
    const [pageSize, setPageSize] = useControllableValue({
        ...pick(pageProps, ['pageSize']),
        onChange: (pageSize) => handlePageChange({ pageSize }),
    }, {
        valuePropName: 'pageSize',
        defaultValue: (_c = defaultPageSize !== null && defaultPageSize !== void 0 ? defaultPageSize : pageSizeList[0]) !== null && _c !== void 0 ? _c : globalConfig.defaultPageSize,
    });
    const resultPageInfo = {
        pageSizeList,
        pageSize,
        total,
        currentPage,
    };
    function handlePageChange(params) {
        console.log(params.type);
        if (params.type === 'linkage')
            return;
        delete params.type;
        // 如果pagesize发生了变化把当前页重置成第一页
        if ('pageSize' in params) {
            setCurrent(1, 'linkage');
            Object.assign(params, { currentPage: 1 });
        }
        Object.assign(resultPageInfo, params);
        if (!isEqual(pageInfo.current, resultPageInfo)) {
            pageHasChange.current = true;
            safeCallFunction(onPageChange, resultPageInfo);
        }
    }
    pageInfo.current = { ...resultPageInfo };
    return {
        total,
        setTotal,
        pageSize,
        setPageSize,
        currentPage,
        setCurrent,
        pageSizeList,
        setPageSizeList,
        pageHasChange,
    };
    // const {
    //   defaultPageSize,
    //   defaultCurrentPage,
    //   pageSizeList: defaultPageSizeList,
    //   onPageChange,
    //   pageProps = {},
    // } = props;
    // const handlePageChange = () => {};
    // const [pageSizeList,]
};
