import * as React from 'react';
/**
 * 高亮文字
 */
export const highlightRender = (itemText, searchKey) => {
    if (!searchKey) {
        return itemText;
    }
    if (searchKey && searchKey.length) {
        const key = searchKey.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const reg = new RegExp(`(${key})`, 'ig');
        itemText = itemText.replace(reg, (x) => `<span class="cn-ui-select-highlight">${x}</span>`);
    }
    return React.createElement("span", { dangerouslySetInnerHTML: { __html: itemText } });
};
