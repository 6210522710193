import $i18n from 'panda-i18n';
import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import { events, friendlyTips } from '@/components/cn-utils';
import { CnDrawer } from '@/components/cn-drawer';
import { cssPrefix as _cssPrefix, MARK_READ } from '../../constant';
import JobPanel from '../job-panel';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import './index.scss';
import { CnResult } from '@/components/cn-result';
const cssPrefix = `${_cssPrefix}container`;
export const JobContainer = forwardRef((props, ref) => {
    const { visible, setVisible, importProps, exportProps, className } = props;
    const [tab, setTab] = useState(() => {
        return exportProps ? 'export' : 'import';
    });
    useImperativeHandle(ref, () => ({ setTab }));
    useEffect(() => {
        if (visible)
            events.emit(MARK_READ);
    }, [visible]);
    if (!importProps && !exportProps) {
        friendlyTips({
            message: $i18n.get({
                id: 'PleaseCheckWhetherTheComponentConfiguration_802152393',
                dm: '请检查组件配置是否正确',
                ns: 'CnAsyncJobsPro',
            }),
            component: 'CnAsyncJobsPro',
        });
    }
    return (React.createElement(CnDrawer, { visible: visible, onClose: () => setVisible(false), className: classNames(cssPrefix, className), hasMask: false, canCloseByOutSideClick: true, canCloseByEsc: false, embeddable: true },
        React.createElement("div", { className: `${cssPrefix}-close`, onClick: () => setVisible(false) },
            React.createElement(CnIcon, { type: "close", size: 16 })),
        !importProps && !exportProps ? (React.createElement(CnResult, { type: "empty", title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${cssPrefix}-header` },
                exportProps ? (React.createElement("div", { className: classNames(`${cssPrefix}-header-tab`, {
                        [`${cssPrefix}-header-tab-selected`]: tab === 'export',
                    }), onClick: () => setTab('export') }, exportProps.title ||
                    $i18n.get({
                        id: 'ExportTask',
                        dm: '导出任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null,
                importProps ? (React.createElement("div", { className: classNames(`${cssPrefix}-header-tab`, {
                        [`${cssPrefix}-header-tab-selected`]: tab === 'import',
                    }), onClick: () => setTab('import') }, importProps.title ||
                    $i18n.get({
                        id: 'ImportTask',
                        dm: '导入任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null),
            React.createElement("div", { className: `${cssPrefix}-content` },
                exportProps ? (React.createElement(JobPanel, { selected: tab === 'export', type: "export", ...exportProps, visible: visible })) : null,
                importProps ? (React.createElement(JobPanel, { selected: tab === 'import', type: "import", ...importProps, visible: visible })) : null)))));
});
JobContainer.displayName = 'JobContainer';
