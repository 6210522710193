import React from 'react';
import { useField, observer, useFieldSchema, RecursionField, } from '@formily/react';
import cls from 'classnames';
import { SortableContainer, SortableElement, } from 'react-sortable-hoc';
import { usePrefixCls } from '@/form/__builtins__';
import { ArrayBase } from '../array-base';
import './main.scss';
const SortableItem = SortableElement((props) => {
    const prefixCls = usePrefixCls('formily-array-items');
    return (React.createElement("div", { ...props, className: cls(`${prefixCls}-item`, props.className) }, props.children));
});
const SortableList = SortableContainer((props) => {
    const prefixCls = usePrefixCls('formily-array-items');
    return (React.createElement("div", { ...props, className: cls(`${prefixCls}-list`, props.className) }, props.children));
});
const isAdditionComponent = (schema) => {
    var _a;
    return ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf('Addition')) > -1;
};
const useAddition = () => {
    const schema = useFieldSchema();
    return schema.reduceProperties((addition, schema, key) => {
        if (isAdditionComponent(schema)) {
            return React.createElement(RecursionField, { schema: schema, name: key });
        }
        return addition;
    }, null);
};
export const CnArrayItems = observer((props) => {
    const field = useField();
    const prefixCls = usePrefixCls('formily-array-items');
    const schema = useFieldSchema();
    const addition = useAddition();
    const dataSource = Array.isArray(field.value) ? field.value : [];
    return (React.createElement(ArrayBase, null,
        React.createElement("div", { "data-name": "CnArrayItems", ...props, onChange: () => { }, className: cls(prefixCls, props.className) },
            React.createElement(SortableList, { useDragHandle: true, lockAxis: "y", helperClass: `${prefixCls}-sort-helper`, onSortEnd: ({ oldIndex, newIndex }) => {
                    field.move(oldIndex, newIndex);
                } }, dataSource === null || dataSource === void 0 ? void 0 : dataSource.map((item, index) => {
                const items = Array.isArray(schema.items)
                    ? schema.items[index] || schema.items[0]
                    : schema.items;
                return (React.createElement(ArrayBase.Item, { key: index, index: index, record: () => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(SortableItem, { key: `item-${index}`, index: index },
                        React.createElement("div", { className: `${prefixCls}-item-inner` },
                            React.createElement(RecursionField, { schema: items, name: index })))));
            })),
            addition)));
});
CnArrayItems.displayName = 'ArrayItems';
CnArrayItems.Item = (props) => {
    const prefixCls = usePrefixCls('formily-array-items');
    return (React.createElement("div", { ...props, onChange: () => { }, className: cls(`${prefixCls}-${props.type || 'card'}`, props.className) }, props.children));
};
ArrayBase.mixin(CnArrayItems);
