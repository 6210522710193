import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { useParentForm, observer } from '@formily/react';
export const Submit = observer(({ onSubmit, onSubmitFailed, onSubmitSuccess, ...props }) => {
    const form = useParentForm();
    return (React.createElement(CnButton, { htmlType: onSubmit ? 'button' : 'submit', type: "primary", ...props, loading: props.loading !== undefined ? props.loading : form.submitting, onClick: (e) => {
            if (props.onClick) {
                if (props.onClick(e) === false)
                    return;
            }
            if (onSubmit) {
                form.submit(onSubmit).then(onSubmitSuccess).catch(onSubmitFailed);
            }
        } }, props.children ||
        $i18n.get({ id: '31255239613091840.CNTM', dm: '提交', ns: 'CnForm' })));
}, {
    forwardRef: true,
});
