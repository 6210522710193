import * as React from 'react';
export const usePageFooterHeight = (props) => {
    const { ref } = props;
    const calcHeight = () => {
        const dom = ref.current;
        if (!dom)
            return undefined;
        return dom.getBoundingClientRect().height;
    };
    const [height, setHeight] = React.useState(calcHeight());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        setHeight(calcHeight());
    });
    React.useEffect(() => {
        if (!height)
            return;
        const dom = ref.current;
        if (!dom)
            return;
        const pageDom = dom.closest('.cn-ui-page');
        if (!pageDom)
            return;
        const placeholder = pageDom.querySelector('.cn-ui-page-footer-placeholder');
        if (!placeholder)
            return;
        placeholder.style.height = `${height}px`;
    }, [height, ref]);
    return height;
};
