import { isEmpty } from './helper';
const getKey = (storageKey) => {
    const defaultKey = `FILTER_${`${global.location.origin}_${global.location.pathname}`}|cachesearch`;
    if (isEmpty(storageKey))
        return defaultKey;
    if (typeof storageKey === 'function') {
        const key = storageKey();
        return `${defaultKey}|${key}`;
    }
    return `${defaultKey}|${storageKey}`;
};
export const getCache = (storageKey) => {
    const FILTER_SAVE_KEY = getKey(storageKey);
    const saveDataStr = sessionStorage.getItem(FILTER_SAVE_KEY) || '';
    let saveData = null;
    if (saveDataStr) {
        try {
            saveData = JSON.parse(saveDataStr);
        }
        catch (e) {
            console.error(e);
        }
    }
    return saveData;
};
export const setCache = (saveData, storageKey, cacheSearch = false) => {
    if (!cacheSearch)
        return;
    const FILTER_SAVE_KEY = getKey(storageKey);
    let saveDataStr = '';
    try {
        saveDataStr = JSON.stringify(saveData);
    }
    catch (e) {
        console.error(e);
    }
    if (saveDataStr) {
        sessionStorage.setItem(FILTER_SAVE_KEY, saveDataStr);
    }
};
export const removeCache = (storageKey) => {
    const FILTER_SAVE_KEY = getKey(storageKey);
    sessionStorage.removeItem(FILTER_SAVE_KEY);
};
