import { CnMessage } from '@/components/cn-message';
export const loading = async (title, processor) => {
    title = title || 'Loading...';
    const loadingTimeoutInstance = setTimeout(() => {
        CnMessage.loading(title);
    }, 100);
    try {
        return await processor();
    }
    finally {
        CnMessage.hide();
        clearTimeout(loadingTimeoutInstance);
    }
};
