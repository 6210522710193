import * as React from 'react';
export function cloneElementStyle(elem, newStyle) {
    var _a, _b, _c;
    if (((_a = elem === null || elem === void 0 ? void 0 : elem.props) === null || _a === void 0 ? void 0 : _a.schema) && ((_c = (_b = elem === null || elem === void 0 ? void 0 : elem.props) === null || _b === void 0 ? void 0 : _b.schema) === null || _c === void 0 ? void 0 : _c.path)) {
        // Formily compatible
        const { schema } = elem.props;
        return React.cloneElement(elem, {
            schema: {
                ...schema,
                style: newStyle,
            },
        });
    }
    else {
        return React.cloneElement(elem, {
            style: newStyle,
        });
    }
}
// 兼容源码及 Formily 的字段获取方式
export function getPropsField(elem, field) {
    var _a, _b, _c, _d, _e, _f;
    return (((_a = elem === null || elem === void 0 ? void 0 : elem.props) === null || _a === void 0 ? void 0 : _a[field]) ||
        ((_c = (_b = elem === null || elem === void 0 ? void 0 : elem.props) === null || _b === void 0 ? void 0 : _b.schema) === null || _c === void 0 ? void 0 : _c[field]) ||
        ((_f = (_e = (_d = elem === null || elem === void 0 ? void 0 : elem.props) === null || _d === void 0 ? void 0 : _d.schema) === null || _e === void 0 ? void 0 : _e['x-component-props']) === null || _f === void 0 ? void 0 : _f[field]));
}
