/**
 * 获取文件名
 * 1. 优先获取文件名
 * 2. 如果文件名不存在，通过链接拿到文件名
 * 3. 如果链接也拿不到，使用key
 */
export const getFileName = (file) => {
    try {
        let fileName = file.name || '';
        if (!fileName && file.url) {
            const { pathname } = new URL(file.url);
            fileName = pathname.split('/').pop();
        }
        if (!fileName)
            fileName = file.key;
        return String(fileName); // 兼容异常的数据格式
    }
    catch { }
    return '';
};
/** 计算文件大小 */
export const calcSize = (size) => {
    let fileSize = parseFloat(size);
    // fileSize为浮点数 用 < 0.000001 替代 === 0
    if (isNaN(fileSize) || fileSize < 0.0000001) {
        return '0';
    }
    const SIZE_SUFFIX = ['B', 'K', 'M', 'G', 'T', 'P'];
    let suffixIndex = 0;
    // 在Mac上实验发现 取1024造成显示的大小和实际大小不一致
    // 因为单位制不同 见 https://superuser.com/questions/938234/size-of-files-in-windows-os-its-kb-or-kb
    const BIT_NUMBER_SYSTEM = 1024;
    while (fileSize >= BIT_NUMBER_SYSTEM && suffixIndex < SIZE_SUFFIX.length) {
        suffixIndex++;
        fileSize /= BIT_NUMBER_SYSTEM;
    }
    const suffix = SIZE_SUFFIX[suffixIndex];
    fileSize = Number(fileSize.toFixed(2));
    return `${fileSize}${suffix}`;
};
/** 获取文件格式 */
export const getFileType = (file) => {
    var _a;
    const fileName = getFileName(file);
    return (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
};
/** 是否可以使用图片预览组件 */
export const canUseImageViewer = (file) => {
    const fileType = getFileType(file);
    return (fileType &&
        file.url &&
        ['png', 'jpg', 'jpeg', 'gif', 'svg'].includes(fileType));
};
