export function getFinalStyle(props) {
    const finalStyle = {
        // @ts-ignore
        ...((props === null || props === void 0 ? void 0 : props.style) || {}),
    };
    if (props === null || props === void 0 ? void 0 : props.maxHeight) {
        finalStyle.maxHeight = props === null || props === void 0 ? void 0 : props.maxHeight;
        finalStyle.overflow = 'auto';
    }
    return finalStyle;
}
