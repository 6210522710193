import * as React from 'react';
import { getDeptCacheByKey } from './request-cache';
import { CnEllipsis } from '@/components/cn-ellipsis';
export default function DepartmentValueItem(props) {
    const { item, mokelay, host, style } = props;
    const [departmentCache, setDepartmentCache] = React.useState(item);
    React.useEffect(() => {
        if (!item.fullName) {
            getDeptCacheByKey({ deptNo: item.value, mokelay, host }).then((res) => {
                setDepartmentCache(res);
            });
        }
        else {
            setDepartmentCache(item);
        }
    }, [item === null || item === void 0 ? void 0 : item.value]);
    return (React.createElement(CnEllipsis, { endCharCount: 6, style: style, ellipsisPosition: "middle" }, departmentCache === null || departmentCache === void 0 ? void 0 : departmentCache.fullName));
}
