import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { polyfill } from 'react-lifecycles-compat';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import Icon from '@fusion/lib/icon';
import Animate from '@fusion/lib/animate';
import { obj } from '@fusion/lib/util';
const noop = () => { };
/**
 * Message
 */
class Message extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            visible: typeof this.props.visible === 'undefined' ? this.props.defaultVisible : this.props.visible,
        };
        this.onClose = () => {
            if (!('visible' in this.props)) {
                this.setState({
                    visible: false,
                });
            }
            this.props.onClose(false);
        };
    }
    static getDerivedStateFromProps(props) {
        if ('visible' in props) {
            return {
                visible: props.visible,
            };
        }
        return {};
    }
    render() {
        /* eslint-disable no-unused-vars */
        const { prefix, pure, className, style, type, shape, size, title, children, defaultVisible, visible: propsVisible, iconType: icon, closeable, onClose, afterClose, animation, rtl, locale, } = this.props;
        const others = {
            ...obj.pickOthers(Object.keys(Message.propTypes), this.props),
        };
        /* eslint-enable */
        const { visible } = this.state;
        const messagePrefix = `${prefix}message`;
        const classes = classNames({
            [messagePrefix]: true,
            [`${prefix}message-${type}`]: type,
            [`${prefix}${shape}`]: shape,
            [`${prefix}${size}`]: size,
            [`${prefix}title-content`]: !!title,
            [`${prefix}only-content`]: !title && !!children,
            [className]: className,
        });
        const renderIcon = () => {
            if (icon === false) {
                return null;
            }
            if (icon) {
                return (React.createElement(CnIcon, { outerClassName: `${messagePrefix}-symbol`, type: icon }));
            }
            return (React.createElement(Icon, { className: `${messagePrefix}-symbol ${messagePrefix}-symbol-icon` }));
        };
        const newChildren = visible ? (React.createElement("div", { role: "alert", style: style, ...others, className: classes, dir: rtl ? 'rtl' : undefined },
            closeable ? (React.createElement("a", { role: "button", "aria-label": locale === null || locale === void 0 ? void 0 : locale.closeAriaLabel, className: `${messagePrefix}-close`, onClick: this.onClose },
                React.createElement(CnIcon, { type: "close" }))) : null,
            renderIcon(),
            title ? React.createElement("div", { className: `${messagePrefix}-title` }, title) : null,
            children ? React.createElement("div", { className: `${messagePrefix}-content` }, children) : null)) : null;
        if (animation) {
            return (React.createElement(Animate.Expand, { animationAppear: false, afterLeave: afterClose }, newChildren));
        }
        return newChildren;
    }
}
Message.propTypes = {
    prefix: PropTypes.string,
    pure: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    /**
     * 反馈类型
     */
    type: PropTypes.oneOf(['success', 'warning', 'error', 'notice', 'help', 'loading']),
    /**
     * 反馈外观
     */
    shape: PropTypes.oneOf(['inline', 'addon', 'toast']),
    /**
     * 反馈大小
     */
    size: PropTypes.oneOf(['medium', 'large']),
    /**
     * 标题
     */
    title: PropTypes.node,
    /**
     * 内容
     */
    children: PropTypes.node,
    /**
     * 默认是否显示
     */
    defaultVisible: PropTypes.bool,
    /**
     * 当前是否显示
     */
    visible: PropTypes.bool,
    /**
     * 显示的图标类型，会覆盖内部设置的IconType，传false不显示图标
     */
    iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /**
     * 显示关闭按钮
     */
    closeable: PropTypes.bool,
    /**
     * 关闭按钮的回调
     */
    onClose: PropTypes.func,
    /**
     * 关闭之后调用的函数
     */
    afterClose: PropTypes.func,
    /**
     * 是否开启展开收起动画
     */
    animation: PropTypes.bool,
    locale: PropTypes.object,
    rtl: PropTypes.bool,
};
Message.defaultProps = {
    prefix: 'cn-next-',
    pure: false,
    type: 'success',
    shape: 'inline',
    size: 'medium',
    defaultVisible: true,
    closeable: false,
    onClose: noop,
    afterClose: noop,
    animation: true,
    locale: {},
};
export default polyfill(Message);
