import * as React from 'react';
import Search from '@fusion/lib/search';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { CnTree, CnTreeNode } from '@/components/cn-tree';
import { safeCallFunction, useCnRequest } from '@/components/cn-utils';
import { normalizeToArray } from '@/components/cn-tree/view/util';
import { valueToSelectKey } from '@fusion/lib/select/util';
import { flatDataSource } from '@/components/cn-tree-select/tree-select/tree-select';
const Tree = React.forwardRef((props, ref) => {
    const isControlled = 'value' in props;
    const { value: propValue, defaultValue, treeArea, children, dataSource, checkable, checkedStrategy, onChange = () => { }, ...others } = props;
    const [value, setValue] = React.useState(normalizeToArray(isControlled ? propValue : defaultValue).map((v) => {
        return valueToSelectKey(v);
    }));
    const [, forceUpdate] = React.useState(1);
    const datasetRef = React.useRef(flatDataSource(props));
    const treeRef = React.useRef(null);
    const { _k2n, _p2n, _v2n } = datasetRef.current;
    const treeProps = {};
    React.useEffect(() => {
        if (isControlled && value !== propValue) {
            setValue(normalizeToArray(propValue).map((v) => {
                return valueToSelectKey(v);
            }));
        }
    }, [propValue]);
    React.useEffect(() => {
        datasetRef.current = flatDataSource(props);
        forceUpdate((s) => (s + 1) % 32);
    }, [dataSource, children]);
    const createNodesByData = (ds) => {
        const loop = (data, prefix = '0') => {
            const retainedNodes = [];
            data.forEach((item, index) => {
                var _a;
                const { children, ...others } = item;
                const pos = `${prefix}-${index}`;
                const key = (_a = _p2n[pos]) === null || _a === void 0 ? void 0 : _a.key;
                if (key) {
                    retainedNodes.push(React.createElement(CnTreeNode, { ...others, key: key }, children && children.length ? loop(children, pos) : null));
                }
            });
            return retainedNodes;
        };
        return loop(ds);
    };
    const getKeysByValue = (value) => {
        const { _v2n } = datasetRef.current;
        return value.reduce((ret, v) => {
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                ret.push(k);
            }
            return ret;
        }, []);
    };
    const getValueByKeys = (keys) => {
        const { _k2n } = datasetRef.current;
        return keys.map((k) => { var _a; return (_a = _k2n[k]) === null || _a === void 0 ? void 0 : _a.value; });
    };
    const getData = (value) => {
        const ret = value.reduce((ret, v) => {
            var _a;
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                // console.log(_k2n[k]);
                // const { label, pos } = _k2n[k];
                const d = {
                    value: v,
                    // label,
                    ...((_a = _k2n[k]) !== null && _a !== void 0 ? _a : {}),
                    // pos,
                };
                d.key = k;
                ret.push(d);
            }
            return ret;
        }, []);
        return ret;
    };
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            ...((_a = treeRef.current) !== null && _a !== void 0 ? _a : {}),
            getKeysByValue,
            getValueByKeys,
            datasetRef,
        };
    }, [treeRef]);
    const handleSelect = (selectedKeys, extra) => {
        if (!(extra === null || extra === void 0 ? void 0 : extra.selected)) {
            return;
        }
        const value = getValueByKeys(selectedKeys);
        if (!isControlled) {
            setValue(value);
        }
        const data = getData(value);
        if (props.multiple) {
            safeCallFunction(onChange, value, data);
        }
        else {
            safeCallFunction(onChange, value[0], data[0]);
        }
        // props.multiple ? onChange(value, data) : onChange(value[0], data[0]);
    };
    const handleCheck = (checkedKeys) => {
        const value = getValueByKeys(checkedKeys);
        if (!isControlled) {
            setValue(value);
        }
        safeCallFunction(onChange, value, getData(value));
        // onChange(value, getData(value));
    };
    if (treeArea) {
        treeProps.isLabelBlock = true;
        treeProps.labelRender = (node) => {
            var _a, _b, _c;
            // if (node.key) {
            const selfValue = getValueByKeys([node === null || node === void 0 ? void 0 : node.key]);
            node.value = Array.isArray(selfValue) ? selfValue[0] : selfValue;
            // console.log(value);
            // }
            // console.log([node.key], 'node');
            // console.log(value, 'value');
            return (React.createElement("div", { className: "cn-async-tree-label", style: {
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                } },
                React.createElement("div", null, node === null || node === void 0 ? void 0 : node.label),
                ((_a = value === null || value === void 0 ? void 0 : value.toString) === null || _a === void 0 ? void 0 : _a.call(value)) == ((_c = (_b = node === null || node === void 0 ? void 0 : node.value) === null || _b === void 0 ? void 0 : _b.toString) === null || _c === void 0 ? void 0 : _c.call(_b)) && (React.createElement("div", { className: "cn-async-tree-label-area", style: {
                        position: 'absolute',
                        right: 0,
                        backgroundColor: '#fff',
                    } }, treeArea(node === null || node === void 0 ? void 0 : node.key, node)))));
        };
    }
    const keys = getKeysByValue(value);
    if (checkable) {
        treeProps.checkable = checkable;
        treeProps.checkedStrategy = props.checkStrictly ? 'all' : checkedStrategy;
        treeProps.checkedKeys = keys;
        treeProps.onCheck = handleCheck;
    }
    else {
        treeProps.selectedKeys = keys;
        treeProps.onSelect = handleSelect;
    }
    return (React.createElement(CnTree, { ref: treeRef, ...others, ...treeProps, dataSource: dataSource }));
});
export const FormTree = React.forwardRef((props, ref) => {
    var _a, _b;
    const { requestConfig, showSearch, enableRemoteLazyLoad, searchProps = {}, filterLocal = true, ...others } = props;
    const baseTreeRef = React.useRef(null);
    const treeItemRef = React.useRef(null);
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    const [matchedKeys, setMatchedKeys] = React.useState([]);
    const [expandedKeys, setExpandedKeys] = React.useState([]);
    // const remoteLazyLoadItemRef = React.useRef<any>();
    const treeProps = {};
    // const searchProps = {};
    const getItemByPos = (pos) => {
        if (!pos)
            return false;
        return pos
            .split('-')
            .slice(1)
            .reduce((ret, num) => ret.children[num], { children: innerDataSource });
    };
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    const formatResult = (res) => {
        var _a;
        // if (isFunction(requestConfig?.formatResult)) {
        //   return requestConfig.formatResult(res);
        // }
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    };
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = formatResult;
    }
    const { runAsync, data = [], run, mutate, } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    function fetchData() {
        return runAsync().then((dataSource) => {
            console.log(dataSource);
            setInnerDataSource(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.formatResult(dataSource));
        });
    }
    if (props === null || props === void 0 ? void 0 : props.instance) {
        if (isPlainObject((_b = (_a = props === null || props === void 0 ? void 0 : props.instance) === null || _a === void 0 ? void 0 : _a.remoteOperation) === null || _b === void 0 ? void 0 : _b.current)) {
            // @ts-ignore
            props.instance.remoteOperation.current.fetchData = fetchData;
        }
    }
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) === 0 && (innerDataSource === null || innerDataSource === void 0 ? void 0 : innerDataSource.length) === 0) {
            return;
        }
        if (treeItemRef.current) {
            treeItemRef.current.children = data;
            setInnerDataSource((data) => [...data]);
            treeItemRef.current = null;
        }
        else {
            setInnerDataSource(formatResult(data));
        }
    }, [data]);
    // React.useEffect(() => {
    // run();
    // runAsync().then((dataSource) => {
    //   console.log(dataSource, '----');
    //   console.log(formatResult(dataSource));
    //   setInnerDataSource(formatResult(dataSource));
    // });
    // }, []);
    // React.useEffect(() => {
    //   if (!isRemoteDataSource) return;
    //   if (loading) return;
    //   setInnerDataSource((previousDataSource) => {
    //     if (enableRemoteLazyLoad) {
    //       if (!remoteLazyLoadItemRef.current?.value) {
    //         return data;
    //       }
    //       const valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
    //       if (valueItem) {
    //         valueItem.children = data;
    //       }
    //       return [...previousDataSource];
    //     }
    //     return data;
    //   });
    // }, [data]);
    if (isRemoteDataSource) {
        treeProps.dataSource = innerDataSource;
    }
    const enableOnSearch = showSearch && !others.onSearch;
    // const localSearch = showSearch && others.filterLocal;
    if (enableOnSearch) {
        if (filterLocal) {
            searchProps.onChange = (value) => {
                value = value.trim();
                if (!value) {
                    setMatchedKeys([]);
                    setExpandedKeys([]);
                    return;
                }
                const localMatchedKeys = [];
                const loop = (data) => data.forEach((item) => {
                    if (item.label.indexOf(value) > -1) {
                        localMatchedKeys.push(item.key);
                    }
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                });
                loop(!isRemoteDataSource && others.dataSource
                    ? others.dataSource
                    : innerDataSource);
                setMatchedKeys(localMatchedKeys);
                setExpandedKeys([...localMatchedKeys]);
                treeProps.autoExpandParent = true;
            };
            treeProps.filterTreeNode = (node) => {
                return (!isEmpty(matchedKeys) &&
                    matchedKeys.indexOf(node.props.eventKey) > -1);
            };
            treeProps.expandedKeys = expandedKeys;
            treeProps.onExpand = (keys) => {
                setExpandedKeys(keys);
                treeProps.autoExpandParent = false;
            };
            // searchProps.onChange = (value): void => {
            //   value = value.trim();
            //   if (!value) {
            //     matchedKeys.current = null;
            //     return;
            //   }
            //   matchedKeys.current = [];
            //   const loop = (data) =>
            //     data.forEach((item) => {
            //       if (item.label.indexOf(value) > -1) {
            //         matchedKeys.current.push(item.key);
            //       }
            //       if (item.children && item.children.length) {
            //         loop(item.children);
            //       }
            //     });
            //   loop(innerDataSource);
            //   this.setState({
            //     expandedKeys: [...matchedKeys],
            //     autoExpandParent: true,
            //   });
            //   this.matchedKeys = matchedKeys;
            // };
        }
        else {
            searchProps.onSearch = (inputValue) => {
                var _a;
                run({ [(_a = requestConfig.searchKey) !== null && _a !== void 0 ? _a : 'searchKey']: inputValue });
                // .then((result) => {
                //   setInnerDataSource(formatResult(result));
                // });
            };
        }
    }
    if (enableRemoteLazyLoad) {
        treeProps.loadData = (node) => {
            var _a;
            const item = getItemByPos((_a = node === null || node === void 0 ? void 0 : node.props) === null || _a === void 0 ? void 0 : _a.pos);
            if (!item)
                return false;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).then((result) => {
                // requestConfig.formatResult(result);
                if (node.props.children) {
                    return true;
                }
                treeItemRef.current = item;
                // item.children = formatResult(requestConfig.formatResult(result));
                // const dataSource = innerDataSource;
                // setTimeout(() => {
                //   setInnerDataSource(dataSource);
                // }, 0);
                // return [];
            });
            // remoteLazyLoadItemRef.current = item;
            // return runAsync({
            //   [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            // }).catch((err) => {
            //   console.log(err);
            // });
        };
    }
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sandRequest: run,
            dataSource: treeProps.dataSource,
            ...((_a = baseTreeRef === null || baseTreeRef === void 0 ? void 0 : baseTreeRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    });
    return (React.createElement(React.Fragment, null,
        showSearch && (React.createElement(Search, { shape: "simple", size: "medium", style: { margin: '10px 0', width: '100%' }, ...searchProps })),
        React.createElement(Tree, { ref: baseTreeRef, ...others, ...treeProps })));
});
FormTree.useRemote = function () {
    const ref = React.useRef({});
    const fetch = React.useCallback((...args) => {
        var _a, _b;
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    }, [ref.current]);
    const load = React.useCallback(() => {
        return fetch(false); // 不作数据刷新
    }, []);
    const getInnerData = React.useCallback(() => {
        var _a;
        // @ts-ignore
        return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.innerInfo;
    }, []);
    return {
        load,
        remoteOperation: ref,
        getInnerData,
    };
};
FormTree.createRemoteInstance = function () {
    const ref = {
        current: {},
    };
    const fetch = (...args) => {
        var _a, _b;
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    };
    const refresh = () => {
        fetch(true);
    };
    const load = () => {
        return fetch(false);
    };
    return {
        refresh,
        load,
        remoteOperation: ref,
    };
};
FormTree.defaultProps = {
    requestConfig: {},
};
