import * as React from 'react';
import { Grid as NextGrid } from '@fusion/lib';
export const Row = (props) => {
    return React.createElement(NextGrid.Row, { "data-name": "Row", ...props });
};
export const Col = (props) => {
    return React.createElement(NextGrid.Col, { "data-name": "Col", ...props });
};
Col.isNextCol = true;
export const Grid = {
    Row,
    Col,
};
export const CnGrid = {
    CnRow: Row,
    CnCol: Col,
};
