import $i18n from 'panda-i18n';
import * as React from 'react';
import cls from 'classnames';
import { useField, observer, useFieldSchema, RecursionField, } from '@formily/react';
import { CnCardSubAreaCard, } from '@/components/cn-card';
import { ArrayBase } from '../array-base';
import { usePrefixCls } from '@/form/__builtins__';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnFormGrid } from '@/components/cn-form-grid';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';
import './index.scss';
import { CnBox } from '@/components/cn-box';
const isAdditionComponent = (schema) => {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Addition')) > -1;
};
const isRemoveComponent = (schema) => {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Remove')) > -1;
};
const isCopyComponent = (schema) => {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Copy')) > -1;
};
const isMoveUpComponent = (schema) => {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveUp')) > -1;
};
const isMoveDownComponent = (schema) => {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveDown')) > -1;
};
const isOperationComponent = (schema) => {
    return (isAdditionComponent(schema) ||
        isRemoveComponent(schema) ||
        isCopyComponent(schema) ||
        isMoveDownComponent(schema) ||
        isMoveUpComponent(schema));
};
export const CnArraySubAreaCard = observer((props) => {
    var _a;
    const field = useField();
    const schema = useFieldSchema();
    const dataSource = Array.isArray(field.value) ? field.value : [];
    const prefixCls = usePrefixCls('formily-array-sub-area-card', props);
    const readOnly = (_a = field === null || field === void 0 ? void 0 : field.form) === null || _a === void 0 ? void 0 : _a.readOnly;
    const renderItems = () => {
        return dataSource === null || dataSource === void 0 ? void 0 : dataSource.map((item, index) => {
            var _a;
            const items = Array.isArray(schema.items)
                ? schema.items[index] || schema.items[0]
                : schema.items;
            const title = props.title || field.title;
            const content = (React.createElement(RecursionField, { schema: items, name: index, filterProperties: (schema) => {
                    if (isOperationComponent(schema))
                        return false;
                    return true;
                } }));
            const operations = (React.createElement(RecursionField, { schema: items, name: index, filterProperties: (schema) => {
                    if (!isOperationComponent(schema))
                        return false;
                    return true;
                }, onlyRenderProperties: true }));
            return (React.createElement(ArrayBase.Item, { key: index, index: index, record: () => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                React.createElement(CnCardSubAreaCard, { action: React.createElement(CnBox, { direction: "row", spacing: 8, align: "center" },
                        isFunction(props.titleSlot) &&
                            props.titleSlot((_a = field.value) === null || _a === void 0 ? void 0 : _a[index], index),
                        !readOnly && props.allowRemove && (React.createElement(CnButton, { text: true, className: `${prefixCls}-action-remove`, onClick: (e) => {
                                var _a;
                                e.stopPropagation();
                                (_a = field === null || field === void 0 ? void 0 : field.remove) === null || _a === void 0 ? void 0 : _a.call(field, index);
                            } },
                            React.createElement(CnIcon, { type: "delete" }),
                            $i18n.get({
                                id: '31255239516622848.CNTM',
                                dm: '删除',
                                ns: 'CnForm',
                            }))),
                        operations), ...pick(props, [
                        'title',
                        'style',
                        'className',
                        'action',
                        'children',
                    ]), className: cls(`${prefixCls}-item`, props.className), title: title }, content)));
        });
    };
    const renderAddition = () => {
        return schema.reduceProperties((addition, schema, key) => {
            if (isAdditionComponent(schema)) {
                return React.createElement(RecursionField, { schema: schema, name: key });
            }
            return addition;
        }, null);
    };
    return (React.createElement(ArrayBase, null,
        React.createElement(CnFormGrid, { ...pick(props, [
                'gridItemStyle',
                'columnGap',
                'rowGap',
                'cols',
                'slot',
                'area',
                'responsive',
                'onColumnChange',
            ]) },
            renderItems(),
            React.createElement("div", { colSpan: "full" }, renderAddition()))));
});
CnArraySubAreaCard.displayName = 'CnArraySubAreaCard';
CnArraySubAreaCard.defaultProps = {
    allowRemove: true,
    cols: 1,
};
ArrayBase.mixin(CnArraySubAreaCard);
