export default {
  'en-US': {
    momentLocale: 'en',
    Timeline: {
      expand: 'Expand',
      fold: 'Fold',
    },
    Balloon: {
      close: 'Close',
    },
    Card: {
      expand: 'Expand',
      fold: 'Fold',
    },
    Calendar: {
      today: 'Today',
      now: 'Now',
      ok: 'OK',
      clear: 'Clear',
      month: 'Month',
      year: 'Year',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      prevDecade: 'Previous Decade',
      nextDecade: 'Next Decade',
      yearSelectAriaLabel: 'Select Year',
      monthSelectAriaLabel: 'Select Month',
    },
    DatePicker: {
      placeholder: 'Select Date',
      datetimePlaceholder: 'Select Date And Time',
      monthPlaceholder: 'Select Month',
      yearPlaceholder: 'Select Year',
      weekPlaceholder: 'Select Week',
      now: 'Now',
      selectTime: 'Select Time',
      selectDate: 'Select Date',
      ok: 'OK',
      clear: 'Clear',
      startPlaceholder: 'Start Date',
      endPlaceholder: 'End Date',
      hour: 'H',
      minute: 'M',
      second: 'S',
    },
    Dialog: {
      close: 'Close',
      ok: 'OK',
      cancel: 'Cancel',
    },
    Drawer: {
      close: 'Close',
    },
    Message: {
      closeAriaLabel: 'Close',
    },
    Pagination: {
      prev: 'Previous',
      next: 'Next',
      goTo: 'Go to',
      page: 'Page',
      go: 'View',
      total: 'Page {current}, {total} pages',
      labelPrev: 'Previous page, current page {current}',
      labelNext: 'Next page, current page {current}',
      inputAriaLabel: 'Please enter what page to skip to',
      selectAriaLabel: 'Please select how many items are displayed on each page',
      pageSize: 'Items per page:',
    },
    Input: {
      clear: 'Clear',
    },
    List: {
      empty: 'No Data',
    },
    Select: {
      selectPlaceholder: 'Please Select',
      autoCompletePlaceholder: 'Please Input',
      notFoundContent: 'No Options',
      maxTagPlaceholder: 'Selected {selected}/{total} Total',
      selectAll: 'Select All',
    },
    TreeSelect: {
      maxTagPlaceholder: 'Selected {selected}/{total} Total',
      shortMaxTagPlaceholder: 'Selected {selected}',
    },
    Table: {
      empty: 'No Data',
      ok: 'OK',
      reset: 'Reset',
      asc: 'Ascending Order',
      desc: 'Descending Order',
      expanded: 'Expanded',
      folded: 'Folded',
      filter: 'Filter',
      selectAll: 'Select All',
    },
    TimePicker: {
      placeholder: 'Select Time',
      clear: 'Clear',
      hour: 'H',
      minute: 'M',
      second: 'S',
      ok: 'OK',
    },
    Transfer: {
      items: 'items',
      item: 'item',
      moveAll: 'Move All',
      searchPlaceholder: 'Please Input',
      moveToLeft: 'Uncheck Selected Elements',
      moveToRight: 'Submit Selected Elements',
    },
    Upload: {
      card: {
        cancel: 'Cancel',
        addPhoto: 'Add Picture',
        download: 'Download',
        delete: 'Delete',
      },
      drag: {
        text: 'Click or Drag the file to this area to upload',
        hint: 'Support docx, xls, PDF, rar, zip, PNG, JPG and other files upload',
      },
      upload: {
        delete: 'Delete',
      },
    },
    Search: {
      buttonText: 'Search',
    },
    Tag: {
      delete: 'Delete',
    },
    Rating: {
      description: 'Rating Options',
    },
    Switch: {
      on: 'on',
      off: 'off',
    },
    Tab: {
      closeAriaLabel: 'close',
    },
    Form: {
      Validate: {
        default: 'Validation error on field %s',
        required: '%s is required',
        format: {
          number: '%s is not a number',
          email: '%s is not a valid email',
          url: '%s is not a valid url',
          tel: '%s is not a valid phone number',
        },
        number: {
          length: '%s must be exactly %s characters',
          min: '%s cannot be less than %s',
          max: '%s cannot be greater than %s',
          minLength: '%s must be at least %s characters',
          maxLength: '%s cannot be longer than %s characters',
        },
        string: {
          length: '%s must be exactly %s characters',
          min: '%s cannot be less than %s',
          max: '%s cannot be greater than %s',
          minLength: '%s must be at least %s characters',
          maxLength: '%s cannot be longer than %s characters',
        },
        array: {
          length: '%s must be exactly %s in length',
          minLength: '%s cannot be less than %s in length',
          maxLength: '%s cannot be greater than %s in length',
        },
        pattern: '%s value %s does not match pattern %s',
      },
    },
  },
  'es-ES': {
    momentLocale: 'es',
    Upload: {
      noSnapShotError: 'No hay retorno de la instantánea',
      card: {
        download: 'Descargar',
        delete: 'Eliminar',
        addPhoto: 'Añadir imagen',
        cancel: 'Cancelar',
      },
      fileSizeNotMatch: 'El tamaño del archivo no coincide',
      fileTooLarge: 'Archivo demasiado grande, supera el límite permitido.',
      drag: {
        text: 'Haz clic o arrastra el archivo a esta área para cargar',
        hint: 'Admite docx, xls, PDF, rar, zip, PNG, JPG y otros tipos de archivos',
      },
      upload: {
        delete: 'Eliminar',
      },
    },
    DateRangePicker: {
      clear: 'Borrar',
      minute: 'M',
      datetimePlaceholder: 'Seleccionar fecha y hora',
      ok: 'Aceptar',
      startPlaceholder: 'Fecha de inicio',
      endPlaceholder: 'Fecha de finalización',
      selectDate: 'Seleccionar fecha',
      datePlaceholder: 'Seleccione fecha\n',
      hour: 'H',
      now: 'Ahora',
      second: 'S',
      monthPlaceholder: 'Seleccionar mes',
      yearPlaceholder: 'Seleccionar año',
      placeholder: 'Seleccionar fecha',
    },
    DatePicker: {
      hour: 'H',
      now: 'Ahora',
      second: 'S',
      selectDate: 'Seleccionar fecha',
      placeholder: 'Seleccionar fecha',
      monthPlaceholder: 'Seleccionar mes',
      ok: 'Aceptar',
      endPlaceholder: 'Fecha de finalización',
      minute: 'M',
      selectTime: 'Seleccionar hora',
      clear: 'Borrar',
      datetimePlaceholder: 'Seleccionar fecha y hora',
      weekPlaceholder: 'Seleccionar semana',
      yearPlaceholder: 'Seleccionar año',
      startPlaceholder: 'Fecha de inicio',
    },
    Transfer: {
      moveToLeft: 'Desmarcar los elementos seleccionados',
      moveAll: 'Mover todo',
      moveToRight: 'Enviar elementos seleccionados',
      items: 'artículos',
      item: 'artículo',
      searchPlaceholder: 'Ingresa',
    },
    Table: {
      filter: 'Filtrar',
      expanded: 'Ampliado',
      selectAll: 'Seleccionar todo',
      empty: 'Sin datos',
      ok: 'Aceptar',
      desc: 'Orden descendente',
      reset: 'Restablecer',
      folded: 'Incorporado',
      asc: 'Orden ascendente',
    },
    Calendar: {
      nextMonth: 'El próximo mes',
      nextYear: 'El próximo año',
      now: 'Ahora',
      month: 'Mes',
      nextDecade: 'La próxima década',
      clear: 'Borrar',
      yearSelectAriaLabel: 'Seleccionar año',
      prevMonth: 'El mes anterior',
      today: 'Hoy',
      monthSelectAriaLabel: 'Seleccionar mes',
      ok: 'Aceptar',
      prevDecade: 'La década anterior',
      prevYear: 'El año anterior',
      year: 'Año',
    },
    Rating: {
      description: 'Calificación',
    },
    DatePickerAdvanced: {
      recent30: 'últimos 30 días\n',
      recent1: 'Los últimos 1 día',
      month: 'Mes',
      week: 'Semana',
    },
    Card: {
      fold: 'Incorporar',
      expand: 'Ampliar',
    },
    TimePicker: {
      placeholder: 'Seleccionar hora',
      clear: 'Borrar',
      second: 'S',
      minute: 'M',
      hour: 'H',
    },
    Pagination: {
      pageSize: 'Artículos por página:',
      labelNext: 'Página siguiente, página actual {current}',
      go: 'Ver',
      goTo: 'Ir a',
      page: 'Página',
      inputAriaLabel: 'Ingresa a qué página hay que saltar',
      next: 'Siguiente',
      total: 'Página {current}, {total} páginas',
      prev: 'Anterior',
      selectAriaLabel: 'Selecciona la cantidad de elementos que se exhiben en cada página',
      labelPrev: 'Página anterior, página actual {current}',
    },
    Drawer: {
      close: 'Cerrar',
    },
    Dialog: {
      close: 'Cerrar',
      ok: 'Estás seguro',
      cancel: 'Cancelar',
    },
    Select: {
      selectPlaceholder: 'Selecciona',
      maxTagPlaceholder: '{selected}/{total} elementos seleccionados',
      autoCompletePlaceholder: 'Ingresa',
      selectAll: 'Seleccionar todo\n',
      notFoundContent: 'Sin opciones',
    },
    Tag: {
      delete: 'Eliminar',
    },
    Timeline: {
      expand: 'Ampliar',
      fold: 'Incorporar',
    },
    Balloon: {
      close: 'Cerrar',
    },
    Message: {
      closeAriaLabel: 'Cerrar',
    },
    Input: {
      clear: 'Borrar',
    },
    List: {
      empty: 'Sin datos',
    },
    Search: {
      buttonText: 'Buscar',
    },
  },
  'fr-FR': {
    momentLocale: 'fr',
    DatePicker: {
      yearPlaceholder: "Sélectionnez l'année",
      ok: 'OK',
      selectTime: "Sélectionnez l'heure",
      monthPlaceholder: 'Sélectionnez un mois',
      hour: 'H',
      startPlaceholder: 'Date de début',
      weekPlaceholder: 'Sélectionner la semaine',
      second: 'S',
      clear: 'Effacer',
      datetimePlaceholder: "Sélectionnez la date et l'heure",
      placeholder: 'Sélectionnez Date',
      endPlaceholder: 'Date de fin',
      minute: 'M',
      now: 'Maintenant',
      selectDate: 'Sélectionnez Date',
    },
    Calendar: {
      nextDecade: 'Prochaine décennie',
      month: 'Mois',
      monthSelectAriaLabel: 'Choisir un Mois',
      now: 'Maintenant',
      year: 'Année',
      prevYear: 'Année précédente',
      today: "Aujourd'hui",
      clear: 'Effacer',
      yearSelectAriaLabel: "Sélectionnez l'année",
      ok: 'OK',
      nextYear: 'L&#39;année prochaine',
      nextMonth: 'Le Mois prochain',
      prevMonth: 'Le mois précédent',
      prevDecade: 'Décennie précédente',
    },
    Dialog: {
      ok: 'OK',
      cancel: 'Annuler',
      close: 'Près',
    },
    Table: {
      folded: 'Plié',
      filter: 'Filtre',
      asc: 'Ordre croissant',
      empty: 'Aucune Donnée',
      desc: 'Ordre décroissant',
      selectAll: 'Tout sélectionner',
      ok: 'OK',
      expanded: 'Étendu',
      reset: 'Réinitialiser',
    },
    DatePickerAdvanced: {
      recent7: '7 derniers jours',
      day: 'Jour',
      month: 'Mois',
      recent30: '30 derniers jours',
      recent1: 'Le jour dernier',
      week: 'Semaine',
    },
    Upload: {
      card: {
        cancel: 'Annuler',
        delete: 'Supprimer',
        download: 'Télécharger',
        addPhoto: 'Ajouter une image',
      },
      drag: {
        text: 'Cliquez ou faites glisser le fichier dans cette zone pour le télécharger',
        hint: 'Compatible avec docx, xls, PDF, rar, zip, PNG, JPG et autres format de fichier pour téléchargements',
      },
      upload: {
        delete: 'Supprimer',
      },
      noSnapShotError: 'Il n&#39;y a pas instantané retour',
      fileSizeNotMatch: 'Taille du fichier ne correspond pas à',
      fileTooLarge: 'Fichier trop grand, Dépasser limites!',
    },
    Timeline: {
      fold: 'Plier',
      expand: 'Étendre',
    },
    DateRangePicker: {
      monthPlaceholder: 'Sélectionnez un mois',
      ok: 'OK',
      now: 'Maintenant',
      hour: 'H',
      second: 'S',
      selectDate: 'Sélectionnez Date',
      datePlaceholder: 'Sélectionnez Date',
      placeholder: 'Sélectionnez Date',
      yearPlaceholder: "Sélectionnez l'année",
      endPlaceholder: 'Date de fin',
      clear: 'Effacer',
      datetimePlaceholder: "Sélectionnez la date et l'heure",
      minute: 'M',
      startPlaceholder: 'Date de début',
    },
    Card: {
      fold: 'Plier',
      expand: 'Développer',
    },
    Select: {
      maxTagPlaceholder: 'Sélectionné {selected}/{total}',
      selectAll: 'Tout sélectionner',
      notFoundContent: 'Aucune option',
      selectPlaceholder: 'Veuillez sélectionner',
      autoCompletePlaceholder: 'Veuillez saisir',
    },
    Balloon: {
      close: 'Fermer',
    },
    Transfer: {
      moveToRight: 'Soumettre les éléments sélectionnés',
      item: 'Acticle',
      moveToLeft: 'Décocher les éléments sélectionnés',
      items: 'Articles',
      moveAll: 'Tout déplacer',
      searchPlaceholder: 'Veuillez saisir',
    },
    Pagination: {
      inputAriaLabel: 'Veuillez entrer la page vers laquelle vous souhaitez passer',
      prev: 'Précédent',
      total: 'Page actuelle {curent}, {total} pages en total',
      go: 'Voir',
      next: 'Prochaine',
      pageSize: 'Objets par page:',
      labelPrev: 'Page précédente, page actuelle est {current}',
      selectAriaLabel: "Veuillez sélectionner le nombre d'acticles affichés sur chaque page",
      page: 'Page',
      goTo: 'Aller à',
      labelNext: 'Page suivante, page actuelle {current}',
    },
    Rating: {
      description: 'Évaluation',
    },
    Input: {
      clear: 'Effacer',
    },
    Message: {
      closeAriaLabel: 'Près',
    },
    List: {
      empty: 'Aucune Donnée',
    },
    Search: {
      buttonText: 'Recherche',
    },
    TimePicker: {
      hour: 'H',
      placeholder: "Sélectionnez l'heure",
      second: 'S',
      minute: 'M',
      clear: 'Effacer',
    },
    Drawer: {
      close: 'Près',
    },
    Tag: {
      delete: 'Supprimer',
    },
  },
  'ja-JP': {
    momentLocale: 'ja',
    Timeline: {
      expand: '開く',
      fold: '閉じる',
    },
    Balloon: {
      close: '閉鎖する',
    },
    Card: {
      expand: '開く',
      fold: '閉じる',
    },
    Calendar: {
      today: '本日',
      now: '現在',
      ok: '確認',
      clear: 'クリア',
      month: '月',
      year: '年',
      prevYear: '前の年',
      nextYear: '次の年',
      prevMonth: '前の月',
      nextMonth: '来月',
      prevDecade: '前の十年',
      nextDecade: '次の十年',
      yearSelectAriaLabel: '年を選んで',
      monthSelectAriaLabel: '月を選択し',
    },
    DatePicker: {
      placeholder: '日付けの選択',
      datetimePlaceholder: '日時を選択してください',
      monthPlaceholder: '月を選択してください',
      yearPlaceholder: '年を選択してください',
      weekPlaceholder: '周を選んでください',
      now: '現在',
      selectTime: '時間の選択',
      selectDate: '日付けの選択',
      ok: '確認',
      clear: 'クリア',
      startPlaceholder: '開始日',
      endPlaceholder: '最終日',
      hour: '時',
      minute: '分',
      second: '秒',
    },
    Dialog: {
      close: '閉鎖する',
      ok: '確認',
      cancel: 'キャンセル',
    },
    Drawer: {
      close: '閉鎖する',
    },
    Message: {
      closeAriaLabel: '閉鎖する',
    },
    Pagination: {
      prev: '前のページ',
      next: '次のページ',
      goTo: '第',
      page: 'ページへ',
      go: '確認',
      total: '第{current}ページ、{total}ページ',
      labelPrev: '前のページ、当面の第{current}ページ',
      labelNext: '次のページ、当面の第{current}ページ',
      inputAriaLabel: '数ページまでジャンプしてください',
      selectAriaLabel: 'ページごとに数本表示してください',
      pageSize: 'ページサイズ：',
    },
    Input: {
      clear: 'クリア',
    },
    List: {
      empty: 'データなし',
    },
    Select: {
      selectPlaceholder: '選択',
      autoCompletePlaceholder: '入力',
      notFoundContent: '選択肢なし',
      maxTagPlaceholder: '選択済み {selected}/{total}',
      selectAll: '全選',
    },
    TreeSelect: {
      maxTagPlaceholder: '選択済み {selected}/{total}',
      shortMaxTagPlaceholder: '選択済み {selected}',
    },
    Table: {
      empty: 'データなし',
      ok: '確認',
      reset: 'リセット',
      asc: '昇順',
      desc: '降順',
      expanded: '展開している',
      folded: '収まった',
      filter: 'フィルタ',
      selectAll: '全選',
    },
    TimePicker: {
      placeholder: '時間の選択',
      clear: 'クリア',
      hour: '時',
      minute: '分',
      second: '秒',
      ok: '確認',
    },
    Transfer: {
      items: '項目',
      item: '項目',
      moveAll: '全ての移動',
      searchPlaceholder: '入力',
      moveToLeft: '選択した要素のチェックを外します',
      moveToRight: '選択した要素を送信',
    },
    Upload: {
      card: {
        cancel: 'キャンセル',
        addPhoto: '画像アップロード',
        download: 'ダウンロード',
        delete: '削除',
      },
      drag: {
        text: '枠にドラッグしてアップロード',
        hint: 'docx, xls, PDF, rar, zip, PNG, JPG 等がサポートしています',
      },
      upload: {
        delete: '削除',
      },
    },
    Search: {
      buttonText: 'サーチ',
    },
    Tag: {
      delete: 'デリート',
    },
    Rating: {
      description: '評価オプション',
    },
    Switch: {
      on: '開いています',
      off: '閉じられました',
    },
    Tab: {
      closeAriaLabel: '閉じられました',
    },
    Form: {
      Validate: {
        default: '%sのコンテントは間違いです',
        required: '%sが必要です',
        format: {
          number: '%sは番号ではありません',
          email: '%sは有効なEメールではありません',
          url: '%sは有効なURLではありません',
          tel: '%sは有効な電話番号ではありません',
        },
        number: {
          length: '%sは正確に%sキャラクターでなければなりません',
          min: '%sはmin%sが必要です',
          max: '%sはmax%sが必要です',
          minLength: '%sはmin%sキャラクターが必要です',
          maxLength: '%sはmax%sキャラクターが必要です',
        },
        string: {
          length: '%sは正確に%sキャラクターでなければなりません',
          min: '%sはmin%sが必要です',
          max: '%sはmax%sが必要です',
          minLength: '%sはmin%sキャラクターが必要です',
          maxLength: '%sはmax%sキャラクターが必要です',
        },
        array: {
          length: '%sの長さは%sでなければなりません',
          minLength: '%sの長さはmin%sが必要です',
          maxLength: '%sの長さはmax%sが必要です',
        },
        pattern: '%sの値%sはパターン%sと一致しません',
      },
    },
  },
  'pt-PT': {
    momentLocale: 'pt',
    Timeline: {
      expand: 'Expandir',
      fold: 'Comprimir',
    },
    Balloon: {
      close: 'Fechar',
    },
    Card: {
      expand: 'Expandir',
      fold: 'Comprimir',
    },
    Calendar: {
      today: 'Hoje',
      now: 'Agora',
      ok: 'OK',
      clear: 'Limpar',
      month: 'Mês',
      year: '',
      prevYear: 'Ano Passado',
      nextYear: 'Próximo ano',
      prevMonth: 'Mês Passado',
      nextMonth: 'Próximo Mês',
      prevDecade: 'Década Passada',
      nextDecade: 'Próxima Década',
      yearSelectAriaLabel: 'Selecionar Ano',
      monthSelectAriaLabel: 'Selecionar Mês',
    },
    DatePicker: {
      placeholder: 'Selecione uma Data',
      datetimePlaceholder: 'Selecione o Dia e a Hora',
      monthPlaceholder: 'Selecione o mês',
      yearPlaceholder: 'Selecione o ano',
      weekPlaceholder: 'Selecione a semana',
      now: 'Agora',
      selectTime: 'Selecionar a Hora',
      selectDate: 'Selecionar o Dia',
      ok: 'OK',
      clear: 'Limpar',
      startPlaceholder: 'Data de início',
      endPlaceholder: 'Data do Fim',
      hour: 'H',
      minute: 'M',
      second: 'S',
    },
    Dialog: {
      close: 'Fechar',
      ok: 'OK',
      cancel: 'Cancelar',
    },
    Drawer: {
      close: 'Fechar',
    },
    Message: {
      closeAriaLabel: 'Fechar',
    },
    Pagination: {
      prev: 'Voltar',
      next: 'Próximo',
      goTo: 'Ir para',
      page: 'Página',
      go: 'View',
      total: 'Página {current}, {total} Página(s)',
      labelPrev: 'Página Anterior, Página atual {current}',
      labelNext: 'Próximo Página, Página atual {current}',
      inputAriaLabel: 'Por favor, defina a página que que deseja pular',
      selectAriaLabel: 'Por favor, selecione quantos items você quer mostrar por página',
      pageSize: 'Items por página:',
    },
    Input: {
      clear: 'Limpar',
    },
    List: {
      empty: 'Vazio',
    },
    Select: {
      selectPlaceholder: 'Por favor, escolha.',
      autoCompletePlaceholder: 'Por favor, insira',
      notFoundContent: 'Sem opções',
      maxTagPlaceholder: 'Escolher {selected}/{total} Total',
      selectAll: 'Selecionar Tudo',
    },
    TreeSelect: {
      maxTagPlaceholder: 'Escolher {selected}/{total} Total',
      shortMaxTagPlaceholder: 'Escolher {selected}',
    },
    Table: {
      empty: 'Vazio',
      ok: 'OK',
      reset: 'Resetar',
      asc: 'Ordem Crescente',
      desc: 'Ordem Descendente',
      expanded: 'Expandir',
      folded: 'Comprimir',
      filter: 'Filtrar',
      selectAll: 'Selecionar Tudo',
    },
    TimePicker: {
      placeholder: 'Selecionar Hora',
      clear: 'Limpar',
      hour: 'H',
      minute: 'M',
      second: 'S',
      ok: 'OK',
    },
    Transfer: {
      items: 'items',
      item: 'item',
      moveAll: 'Mover Tudo',
      searchPlaceholder: 'Por favor, insira',
      moveToLeft: 'Desmarcar Elementos',
      moveToRight: 'Confirmar Elementos Selecionados',
    },
    Upload: {
      card: {
        cancel: 'Cancelar',
        addPhoto: 'Adicionar Imagem',
        download: 'Download',
        delete: 'Deletar',
      },
      drag: {
        text: 'Clique ou Arraste o arquivo para para área pontilhada',
        hint: 'Arquivos suportados: docx, xls, PDF, rar, zip, PNG, JPG e outros.',
      },
      upload: {
        delete: 'Deletar',
      },
    },
    Search: {
      buttonText: 'Procurar',
    },
    Tag: {
      delete: 'Deletar',
    },
    Rating: {
      description: 'Opções de classificação',
    },
    Switch: {
      on: 'Ativo',
      off: 'Inativo',
    },
    Tab: {
      closeAriaLabel: 'Fechar',
    },
    Form: {
      Validate: {
        default: 'Erro de validação no campo %s',
        required: '%s é necessário',
        format: {
          number: '%s não é um número',
          email: '%s não é um email válido',
          url: '%s não é uma url válida',
          tel: '%s não é um número de telefone válido',
        },
        number: {
          length: '%s deve ter exatamente %s caracteres',
          min: '%s não pode ser menor que %s',
          max: '%s não pode ser maior que %s',
          minLength: '%s deve ter pelo menos %s caracteres',
          maxLength: '%s não pode ter mais do que %s caracteres',
        },
        string: {
          length: '%s deve ter exatamente %s caracteres',
          min: '%s não pode ser menor que %s',
          max: '%s não pode ser maior que %s',
          minLength: '%s deve ter pelo menos %s caracteres',
          maxLength: '%s não pode ter mais do que %s caracteres',
        },
        array: {
          length: '%s deve ter exatamente %s de comprimento',
          minLength: '%s não pode ter menos que %s de comprimento',
          maxLength: '%s não pode ser maior que %s de comprimento',
        },
        pattern: '%s valor %s não corresponde ao padrão %s',
      },
    },
  },
  'ru-RU': {
    momentLocale: 'ru',
    Calendar: {
      monthSelectAriaLabel: 'Выбрать месяц',
      yearSelectAriaLabel: 'Выбрать год',
      nextDecade: 'Следующее десятилетие',
      prevDecade: 'Предыдущее десятилетие',
      clear: 'Очистить',
      today: 'Сегодня',
      month: 'Месяц',
      ok: 'ОК',
      now: 'Сейчас',
      nextYear: 'Следующий год',
      prevYear: 'Предыдущий год',
      nextMonth: 'Следующий месяц',
      year: 'Год',
      prevMonth: 'Предыдущий месяц',
      yearSelect: 'Выбрать год',
    },
    DateRangePicker: {
      minute: 'М',
      hour: 'H',
      now: 'Сейчас',
      yearPlaceholder: 'Выбрать год',
      datePlaceholder: 'Выбрать дату',
      second: 'S',
      selectDate: 'Выбрать дату',
      startPlaceholder: 'Дата начала',
      monthPlaceholder: 'Выбрать месяц',
      placeholder: 'Выбрать дату',
      ok: 'ОК',
      datetimePlaceholder: 'Выбрать дату и время',
      clear: 'Очистить',
      endPlaceholder: 'Дата окончания',
    },
    Table: {
      folded: 'Свернуто',
      asc: 'В порядке возрастания',
      selectAll: 'Выбрать все',
      desc: 'В порядке убывания',
      expanded: 'Развернуто',
      filter: 'Фильтр',
      ok: 'ОК',
      empty: 'Нет данных',
      reset: 'Сброс',
    },
    Search: {
      buttonText: 'Поиск',
    },
    Upload: {
      card: {
        download: 'скачать',
        delete: 'Удалить',
        addPhoto: 'Добавить изображение',
        cancel: 'Отменить',
      },
      drag: {
        hint: 'Для загрузки поддерживаются форматы файлов DOCX, XLS, PDF, RAR, ZIP, PNG, JPG и другие',
        text: 'Щелкните или перетащите файл в эту область, чтобы загрузить',
      },
      upload: {
        delete: 'Удалить',
      },
      noSnapShotError: 'Нет возврата снимков',
      fileTooLarge: 'Файл слишком большой, превышают пределы!',
      fileSizeNotMatch: 'Размер файла не соответствует',
    },
    Select: {
      autoCompletePlaceholder: 'Введите',
      notFoundContent: 'Нет вариантов',
      selectPlaceholder: 'Выберите',
      maxTagPlaceholder: 'Выбранный {selected}/{total}Товар',
      selectAll: 'выбрать все',
    },
    Pagination: {
      inputAriaLabel: 'Укажите, к какой странице перейти',
      next: 'Далее',
      prev: 'Назад',
      selectAriaLabel: 'Укажите, сколько элементов должно быть показано на каждой странице',
      labelPrev: 'Предыдущая страница, текущая страница {current}',
      total: 'Страница {current}, {total} стр',
      labelNext: 'Следующая страница, текущая страница {current}',
      goTo: 'Перейти',
      go: 'Просмотр',
      page: 'Страница',
      pageSize: 'Элементов на странице:',
    },
    DatePicker: {
      monthPlaceholder: 'Выбрать месяц',
      placeholder: 'Выбрать дату',
      endPlaceholder: 'Дата окончания',
      startPlaceholder: 'Дата начала',
      minute: 'М',
      hour: 'H',
      weekPlaceholder: 'выберите неделю',
      datetimePlaceholder: 'Выбрать дату и время',
      clear: 'Очистить',
      second: 'S',
      selectTime: 'Выбрать время',
      yearPlaceholder: 'Выбрать год',
      ok: 'ОК',
      now: 'Сейчас',
      selectDate: 'Выбрать дату',
    },
    Dialog: {
      cancel: 'Отменить',
      close: 'Закрыть',
      ok: 'Уверен',
    },
    DatePickerAdvanced: {
      recent30: 'последние 30 дней\n',
      month: 'В месяц',
      week: 'неделя природы',
      recent7: 'Последние 7 дней',
    },
    Timeline: {
      expand: 'Развернуть',
      fold: 'Свернуть',
    },
    List: {
      empty: 'Нет данных',
    },
    Card: {
      expand: 'Развернуть',
      fold: 'Свернуть',
    },
    TimePicker: {
      hour: 'H',
      placeholder: 'Выбрать время',
      minute: 'М',
      second: 'S',
      clear: 'Очистить',
    },
    Transfer: {
      moveAll: 'Переместить все',
      item: 'элемент',
      items: 'элем',
      moveToLeft: 'Отменить выбор элементов',
      moveToRight: 'Отправить выбранные элементы',
      searchPlaceholder: 'Введите',
    },
    Tag: {
      delete: 'Удалить',
    },
    Balloon: {
      close: 'Закрыть',
    },
    Input: {
      clear: 'Очистить',
    },
    Message: {
      closeAriaLabel: 'Закрыть',
    },
    Rating: {
      description: 'Рейтинг',
    },
    Drawer: {
      close: 'Закрыть',
    },
  },
  'zh-CN': {
    momentLocale: 'zh-cn',
    Timeline: {
      expand: '展开',
      fold: '收起',
    },
    Balloon: {
      close: '关闭',
    },
    Card: {
      expand: '展开',
      fold: '收起',
    },
    Calendar: {
      today: '今天',
      now: '此刻',
      ok: '确定',
      clear: '清除',
      month: '月',
      year: '年',
      prevYear: '上一年',
      nextYear: '下一年',
      prevMonth: '上个月',
      nextMonth: '下个月',
      prevDecade: '上十年',
      nextDecade: '后十年',
      yearSelectAriaLabel: '选择年份',
      monthSelectAriaLabel: '选择月份',
    },
    DatePicker: {
      placeholder: '请选择日期',
      datetimePlaceholder: '请选择日期和时间',
      monthPlaceholder: '请选择月',
      yearPlaceholder: '请选择年',
      weekPlaceholder: '请选择周',
      now: '此刻',
      selectTime: '选择时间',
      selectDate: '选择日期',
      ok: '确定',
      clear: '清除',
      startPlaceholder: '起始日期',
      endPlaceholder: '结束日期',
      hour: '时',
      minute: '分',
      second: '秒',
    },
    Dialog: {
      close: '关闭',
      ok: '确定',
      cancel: '取消',
    },
    Drawer: {
      close: '关闭',
    },
    Message: {
      closeAriaLabel: '关闭',
    },
    Pagination: {
      prev: '上一页',
      next: '下一页',
      goTo: '到第',
      page: '页',
      go: '确定',
      total: '第{current}页，共{total}页',
      labelPrev: '上一页，当前第{current}页',
      labelNext: '下一页，当前第{current}页',
      inputAriaLabel: '请输入跳转到第几页',
      selectAriaLabel: '请选择每页显示几条',
      pageSize: '每页显示：',
    },
    Input: {
      clear: '清除',
    },
    List: {
      empty: '没有数据',
    },
    Select: {
      selectPlaceholder: '请选择',
      autoCompletePlaceholder: '请输入',
      notFoundContent: '无选项',
      maxTagPlaceholder: '已选择 {selected}/{total} 项',
      selectAll: '全选',
    },
    TreeSelect: {
      maxTagPlaceholder: '已选择 {selected}/{total} 项',
      shortMaxTagPlaceholder: '已选择 {selected} 项',
    },
    Table: {
      empty: '没有数据',
      ok: '确认',
      reset: '重置',
      asc: '升序',
      desc: '降序',
      expanded: '已展开',
      folded: '已折叠',
      filter: '筛选',
      selectAll: '全选',
    },
    TimePicker: {
      placeholder: '请选择时间',
      clear: '清除',
      hour: '时',
      minute: '分',
      second: '秒',
      ok: '确定',
    },
    Transfer: {
      items: '项',
      item: '项',
      moveAll: '移动全部',
      searchPlaceholder: '请输入',
      moveToLeft: '撤销选中元素',
      moveToRight: '提交选中元素',
    },
    Upload: {
      card: {
        cancel: '取消',
        addPhoto: '上传图片',
        download: '下载',
        delete: '删除',
      },
      drag: {
        text: '点击或者拖动文件到虚线框内上传',
        hint: '支持 docx, xls, PDF, rar, zip, PNG, JPG 等类型的文件',
      },
      upload: {
        delete: '删除',
      },
    },
    Search: {
      buttonText: '搜索',
    },
    Tag: {
      delete: '删除',
    },
    Rating: {
      description: '评分选项',
    },
    Switch: {
      on: '已打开',
      off: '已关闭',
    },
    Tab: {
      closeAriaLabel: '关闭',
    },
    Form: {
      Validate: {
        default: '%s 校验失败',
        required: '%s 是必填字段',
        format: {
          number: '%s 不是合法的数字',
          email: '%s 不是合法的 email 地址',
          url: '%s 不是合法的 URL 地址',
          tel: '%s 不是合法的电话号码',
        },
        number: {
          length: '%s 长度必须是 %s',
          min: '%s 不得小于 %s',
          max: '%s 不得大于 %s',
          minLength: '%s 字段字符长度不得少于 %s',
          maxLength: '%s 字段字符长度不得超过 %s',
        },
        string: {
          length: '%s 长度必须是 %s',
          min: '%s 不得小于 %s',
          max: '%s 不得大于 %s',
          minLength: '%s 长度不得少于 %s',
          maxLength: '%s 长度不得超过 %s',
        },
        array: {
          length: '%s 个数必须是 %s',
          minLength: '%s 个数不得少于 %s',
          maxLength: '%s 个数不得超过 %s',
        },
        pattern: '%s 数值 %s 不匹配正则 %s',
      },
    },
  },
  'zh-HK': {
    momentLocale: 'zh-hk',
    Timeline: {
      expand: '展開',
      fold: '收起',
    },
    Balloon: {
      close: '關閉',
    },
    Card: {
      expand: '展開',
      fold: '收起',
    },
    Calendar: {
      today: '今天',
      now: '此刻',
      ok: '確定',
      clear: '清除',
      month: '月',
      year: '年',
      prevYear: '上一年',
      nextYear: '下一年',
      prevMonth: '上個月',
      nextMonth: '下個月',
      prevDecade: '上十年',
      nextDecade: '後十年',
      yearSelectAriaLabel: '選擇年份',
      monthSelectAriaLabel: '選擇月份',
    },
    DatePicker: {
      placeholder: '請選擇日期',
      datetimePlaceholder: '請選擇日期和時間',
      monthPlaceholder: '請選擇月',
      yearPlaceholder: '請選擇年',
      weekPlaceholder: '請選擇周',
      now: '此刻',
      selectTime: '選擇時間',
      selectDate: '選擇日期',
      ok: '確定',
      clear: '清除',
      startPlaceholder: '起始日期',
      endPlaceholder: '結束日期',
      hour: '時',
      minute: '分',
      second: '秒',
    },
    Dialog: {
      close: '關閉',
      ok: '確認',
      cancel: '取消',
    },
    Drawer: {
      close: '關閉',
    },
    Message: {
      closeAriaLabel: '關閉',
    },
    Pagination: {
      prev: '上一頁',
      next: '下一頁',
      goTo: '到第',
      page: '頁',
      go: '確定',
      total: '第{current}頁，共{total}頁',
      labelPrev: '上一頁，當前第{current}頁',
      labelNext: '下一頁，當前第{current}頁',
      inputAriaLabel: '請輸入跳轉到第幾頁',
      selectAriaLabel: '請選擇每頁顯示幾條',
      pageSize: '每頁顯示：',
    },
    Input: {
      clear: '清除',
    },
    List: {
      empty: '沒有數據',
    },
    Select: {
      selectPlaceholder: '請選擇',
      autoCompletePlaceholder: '請輸入',
      notFoundContent: '無選項',
      maxTagPlaceholder: '已選擇 {selected}/{total} 項',
      selectAll: '全選',
    },
    TreeSelect: {
      maxTagPlaceholder: '已選擇 {selected}/{total} 項',
      shortMaxTagPlaceholder: '已選擇 {selected} 項',
    },
    Table: {
      empty: '沒有數據',
      ok: '確認',
      reset: '重置',
      asc: '升序',
      desc: '降序',
      expanded: '已展開',
      folded: '已摺疊',
      filter: '篩選',
      selectAll: '全選',
    },
    TimePicker: {
      placeholder: '請選擇時間',
      clear: '清除',
      hour: '時',
      minute: '分',
      second: '秒',
      ok: '確定',
    },
    Transfer: {
      items: '項',
      item: '項',
      moveAll: '移動全部',
      searchPlaceholder: '請輸入',
      moveToLeft: '撤銷選中元素',
      moveToRight: '提交選中元素',
    },
    Upload: {
      card: {
        cancel: '取消',
        addPhoto: '上傳圖片',
        download: '下載',
        delete: '刪除',
      },
      drag: {
        text: '點擊或者拖動文件到虛線框內上傳',
        hint: '支持 docx, xls, PDF, rar, zip, PNG, JPG 等類型的文件',
      },
      upload: {
        delete: '刪除',
      },
    },
    Search: {
      buttonText: '檢索',
    },
    Tag: {
      delete: '删除',
    },
    Rating: {
      description: '評分選項',
    },
    Switch: {
      on: '已打開',
      off: '已關閉',
    },
    Tab: {
      closeAriaLabel: '關閉',
    },
    Form: {
      Validate: {
        default: '%s 校驗失敗',
        required: '%s 是必填字段',
        format: {
          number: '%s 不是合法的數位',
          email: '%s 不是合法的email地址',
          url: '%s 不是合法的URL地址',
          tel: '%s 不是合法的電話號碼',
        },
        number: {
          length: '%s 長度必須是 %s',
          min: '%s 不得小於 %s',
          max: '%s 不得大於 %s',
          minLength: '%s 長度不得少於 %s',
          maxLength: '%s 長度不得超過 %s',
        },
        string: {
          length: '%s 長度必須是 %s',
          min: '%s 不得小於 %s',
          max: '%s 不得大於 %s',
          minLength: '%s 長度不得少於 %s',
          maxLength: '%s 長度不得超過 %s',
        },
        array: {
          length: '%s 個數必須是 %s',
          minLength: '%s 個數不得少於 %s',
          maxLength: '%s 個數不得超過 %s',
        },
        pattern: '%s 數值 %s 不匹配正則 %s',
      },
    },
  }
};
