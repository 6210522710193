import $i18n from 'panda-i18n'; /* eslint-disable require-atomic-updates */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
import React, { Fragment } from 'react';
import { createForm, isForm, } from '@formily/core';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import { toJS } from '@formily/reactive';
import { FormProvider, Observer, observer } from '@formily/react';
import { isNum, isStr, isBool, isFn, applyMiddleware, } from '@formily/shared';
import { CnDialog } from '@/components/cn-dialog';
import { packRequest } from '@/components/cn-utils';
// local component
import { SchemaField } from '@/form/schema-field';
import { loading, createPortalRoot } from '@/form/__builtins__';
const isModalTitle = (props) => {
    return (isNum(props) || isStr(props) || isBool(props) || React.isValidElement(props));
};
const getModelProps = (props) => {
    if (isModalTitle(props)) {
        return {
            title: props,
        };
    }
    else {
        return props;
    }
};
export function cnQuickDialogForm(cnDialogProps, renderer, 
/**
 * 点击提交后的请求配置
 */
requestConfig) {
    // 全局环境属性
    const env = {
        host: document.createElement('div'),
        form: null,
        promise: null,
        components: {},
        openMiddlewares: [],
        confirmMiddlewares: [],
        cancelMiddlewares: [],
    };
    const root = createPortalRoot(env.host, 'form-dialog');
    const dialogProps = getModelProps(cnDialogProps);
    const modal = {
        ...dialogProps,
        afterClose: () => {
            var _a;
            (_a = dialogProps === null || dialogProps === void 0 ? void 0 : dialogProps.afterClose) === null || _a === void 0 ? void 0 : _a.call(dialogProps);
            root.unmount();
        },
    };
    const DialogContent = observer(() => {
        return (React.createElement(Fragment, null, isFn(renderer) ? (renderer(env.form)) : (React.createElement(SchemaField, { components: env.components, schema: renderer }))));
    });
    // 渲染 Dialog 主题
    const renderDialog = (visible, resolve, reject) => {
        return (React.createElement(Observer, null, () => {
            var _a;
            return (React.createElement(CnDialog, { size: "small", ...modal, visible: visible, onClose: (trigger, e) => {
                    var _a;
                    (_a = modal === null || modal === void 0 ? void 0 : modal.onClose) === null || _a === void 0 ? void 0 : _a.call(modal, trigger, e);
                    reject();
                }, onOk: (e) => {
                    var _a;
                    if (((_a = modal === null || modal === void 0 ? void 0 : modal.onOk) === null || _a === void 0 ? void 0 : _a.call(modal, e)) !== false) {
                        resolve();
                    }
                }, okProps: {
                    loading: (_a = env.form) === null || _a === void 0 ? void 0 : _a.submitting,
                    ...modal.okProps,
                }, onCancel: (e) => {
                    var _a;
                    if (((_a = modal === null || modal === void 0 ? void 0 : modal.onCancel) === null || _a === void 0 ? void 0 : _a.call(modal, e)) !== false) {
                        reject();
                    }
                } },
                React.createElement(FormProvider, { form: env.form },
                    React.createElement(DialogContent, null))));
        }));
    };
    document.body.appendChild(env.host);
    // 数据格式化处理
    function handleData(values) {
        if (requestConfig.formatData && isFn(requestConfig.formatData)) {
            return requestConfig.formatData(values);
        }
        else {
            return values;
        }
    }
    const formDialog = {
        registerComponents: (components) => {
            env.components = components;
            return formDialog;
        },
        forOpen: (middleware) => {
            if (isFn(middleware)) {
                env.openMiddlewares.push(middleware);
            }
            return formDialog;
        },
        forConfirm: (middleware) => {
            if (isFn(middleware)) {
                env.confirmMiddlewares.push(middleware);
            }
            return formDialog;
        },
        forCancel: (middleware) => {
            if (isFn(middleware)) {
                env.cancelMiddlewares.push(middleware);
            }
            return formDialog;
        },
        open: async (props) => {
            if (env.promise) {
                return env.promise;
            }
            env.promise = new Promise(async (resolve, reject) => {
                try {
                    // forOpen 中间件植入
                    props = await loading($i18n.get({ id: 'Loading', dm: '加载中', ns: 'CnForm' }), () => applyMiddleware(props, env.openMiddlewares));
                    if (!env.form) {
                        if (isForm(props)) {
                            env.form = props;
                        }
                        else {
                            env.form = createForm(props);
                        }
                    }
                }
                catch (e) {
                    reject(e);
                }
                root.render(() => renderDialog(true, () => {
                    env.form
                        .submit(async () => {
                        // 增加远程接口提交
                        let res;
                        if (isPlainObject(requestConfig)) {
                            res = await packRequest({
                                ...requestConfig,
                                data: toJS(handleData(env.form.values)),
                            });
                        }
                        if (isFunction(requestConfig)) {
                            res = await requestConfig(toJS(env.form.values));
                        }
                        // 点击提交的中间件
                        env.form.response = res;
                        await applyMiddleware(env.form, env.confirmMiddlewares);
                        resolve(toJS(env.form.values));
                        formDialog.close();
                    })
                        .catch(() => { });
                }, async () => {
                    await loading($i18n.get({ id: 'Loading', dm: '加载中', ns: 'CnForm' }), () => applyMiddleware(env.form, env.cancelMiddlewares));
                    formDialog.close();
                }));
            });
            return env.promise;
        },
        close: () => {
            if (!env.host)
                return;
            root.render(() => renderDialog(false));
            env.promise = null;
        },
    };
    return formDialog;
}
