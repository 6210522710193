import * as React from 'react';
import NextDatePicker from '@fusion/lib/date-picker2/picker';
import { func } from '@fusion/lib/util';
import { fmtValue, isValueChanged } from '@fusion/lib/date-picker2/util';
import ConfigProvider from '@fusion/lib/config-provider';
import { DATE_PICKER_MODE } from '@fusion/lib/date-picker2/constant';
export class NewPicker2 extends NextDatePicker {
    constructor() {
        super(...arguments);
        this.onOk = () => {
            // cn-ui 2023.02.28 日期组件format为DD/MM/YYYY HH:mm:ss，组件不可用问题修复
            // 例如选中 08/02/2023 00:00:00，checkValue会变成 02/08/2023 00:00:00
            // const { inputValue } = this.state;
            // const checkedValue = this.checkValue(inputValue);
            const { curValue } = this.state;
            const checkedValue = this.checkValue(curValue);
            func.invoke(this.props, 'onOk', this.getOutputArgs(checkedValue));
            this.setState({ value: checkedValue });
            this.handleChange(checkedValue, 'CLICK_OK');
        };
        this.handleChange = (v, eventType) => {
            const { format } = this.props;
            const { isRange, showOk, value, preValue } = this.state;
            console.log(eventType, 'eventType');
            const forceEvents = [
                'KEYDOWN_ENTER',
                'CLICK_PRESET',
                'CLICK_OK',
                'INPUT_CLEAR',
                'VISIBLE_CHANGE',
            ];
            const isTemporary = showOk && !forceEvents.includes(eventType);
            // 面板收起时候，将值设置为确认值
            v =
                eventType === 'VISIBLE_CHANGE' ? value : this.checkValue(v, !isTemporary);
            this.setState({
                curValue: v,
                inputValue: fmtValue(v, format),
            });
            if (!isTemporary) {
                this.setState({
                    value: v,
                }, () => {
                    var _a, _b, _c, _d;
                    // 判断当前选择结束，收起面板：
                    // 1. 非 Range 选择
                    // 2. 非 选择预设时间、面板收起、清空输入 操作
                    // 3. 不需要切换输入框
                    const shouldHidePanel = !isRange ||
                        ['CLICK_PRESET', 'VISIBLE_CHANGE', 'INPUT_CLEAR'].includes(eventType) ||
                        !this.shouldSwitchInput(v);
                    const isSecondValueAlter = Array.isArray(v) &&
                        v[0] &&
                        v[1] &&
                        ((_a = v[0]) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = preValue[0]) === null || _b === void 0 ? void 0 : _b.toString()) &&
                        ((_c = v[1]) === null || _c === void 0 ? void 0 : _c.toString()) !== ((_d = preValue[1]) === null || _d === void 0 ? void 0 : _d.toString());
                    if (shouldHidePanel || isSecondValueAlter) {
                        this.onVisibleChange(false);
                        if (isValueChanged(v, preValue)) {
                            this.onChange();
                        }
                    }
                });
            }
        };
    }
}
const { DATE, WEEK, MONTH, QUARTER, YEAR } = DATE_PICKER_MODE;
const MODE2FORMAT = {
    [DATE]: 'YYYY-MM-DD',
    [WEEK]: 'YYYY-wo',
    [MONTH]: 'YYYY-MM',
    [QUARTER]: 'YYYY-[Q]Q',
    [YEAR]: 'YYYY',
};
/* istanbul ignore next */
const transform = (props, deprecated) => {
    const { footerRender, onVisibleMonthChange, defaultVisibleMonth, ranges, ...newProps } = props;
    const mode = props.mode || DATE;
    if ('footerRender' in props) {
        deprecated('footerRender', 'extraFooterRender', 'DatePicker');
        newProps.extraFooterRender = footerRender;
    }
    if (onVisibleMonthChange) {
        deprecated('onVisibleMonthChange', 'onPanelChange', 'DatePicker');
        newProps.onPanelChange = onVisibleMonthChange;
    }
    if (defaultVisibleMonth) {
        deprecated('defaultVisibleMonth', 'defaultPanelValue', 'DatePicker');
        newProps.defaultPanelValue = onVisibleMonthChange;
    }
    if ('ranges' in props) {
        deprecated('ranges', 'preset: PT.oneOfType([PT.array, PT.object])', 'DatePicker');
        newProps.preset = ranges;
    }
    if ([WEEK, MONTH, QUARTER, YEAR].includes(mode)) {
        delete newProps.showTime;
    }
    else if (typeof props.showTime === 'object') {
        deprecated('showTime: object', 'showTime && timePanelProps', 'DatePicker');
        newProps.timePanelProps = props.showTime;
        newProps.showTime = true;
    }
    if (!newProps.format) {
        newProps.format =
            MODE2FORMAT[mode] + (newProps.showTime ? ' HH:mm:ss' : '');
    }
    return newProps;
};
const ConfigPicker = ConfigProvider.config(NewPicker2, {
    componentName: 'DatePicker2',
    transform,
});
const generatePicker = (mode) => React.forwardRef((props, ref) => (React.createElement(ConfigPicker, { ref: ref, ...props, mode: mode })));
const DatePicker2 = generatePicker();
DatePicker2.displayName = 'DatePicker2';
DatePicker2.MonthPicker = generatePicker(MONTH);
DatePicker2.MonthPicker.displayName = 'MonthPicker2';
DatePicker2.YearPicker = generatePicker(YEAR);
DatePicker2.YearPicker.displayName = 'YearPicker2';
DatePicker2.WeekPicker = generatePicker(WEEK);
DatePicker2.WeekPicker.displayName = 'WeekPicker2';
DatePicker2.QuarterPicker = generatePicker(QUARTER);
DatePicker2.QuarterPicker.displayName = 'QuarterPicker2';
DatePicker2.RangePicker = React.forwardRef((props, ref) => (React.createElement(ConfigPicker, { ref: ref, ...props, type: "range" })));
DatePicker2.RangePicker.displayName = 'RangePicker2';
export default DatePicker2;
