import { connect, mapProps } from '@formily/react';
import { CnInput as UiInput, CnInputTextArea as UiTextArea } from '@/components/cn-input';
import { mapSize, mapStatus } from '@/form/__builtins__';
export const CnInput = connect(UiInput, mapProps((props) => {
    if (props.readOnly)
        return {};
    return mapSize(props);
}, (props, field) => {
    if (props.readOnly)
        return {};
    return mapStatus(props, field);
}));
export const CnInputTextArea = connect(UiTextArea, mapProps(mapSize, mapStatus));
