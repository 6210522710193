import CNRequest from 'cn-request';
const request = CNRequest({ timeout: 10000 }, {
    // 此处为业务的 trace
    armsTrace: window.trace,
    noGlobal: true,
});
request.interceptors.request.use((config) => {
    if (window.REQUEST_ENV === 'local' && config.isCommonRequest) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        // config.url = `https://cn-x-gateway.cainiao.com/${config.url}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
export default request;
