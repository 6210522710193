import * as React from 'react';
import cx from 'classnames';
import { CnGrid } from '@/components/cn-grid';
import { CnIcon } from '@/components/cn-icon';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { normalizeCol } from './utils';
import './cn-form-item.scss';
const { CnRow, CnCol } = CnGrid;
export class CnFormItem extends React.Component {
    constructor() {
        super(...arguments);
        // 显示 Label title 解释信息
        this.renderTip = () => {
            const { tip, cnTooltipProps } = this.props;
            return (React.createElement(CnTooltip, { className: "cn-ui-form-item-explanation", trigger: React.createElement(CnIcon, { outerClassName: "cn-ui-form-item-label-help-icon", role: "cn-form-item-tip", type: "help-color", size: 14 }), ...cnTooltipProps }, tip));
        };
    }
    renderLabel(labelMode) {
        var _a;
        const { required, title, labelCol, tip, asterisk, readOnly } = this.props;
        if (!title)
            return null;
        const labelTextAlign = (_a = this.props.labelTextAlign) !== null && _a !== void 0 ? _a : (labelMode === 'inline' ? 'right' : 'left');
        const cls = cx({
            'cn-ui-form-item-header': true,
            'cn-ui-left': labelMode === 'inline',
            [`cn-ui-form-label-align-${labelTextAlign}`]: true,
            'cn-ui-form-item-readonly': !!readOnly,
        });
        let requiredStatus = false;
        if (required || asterisk) {
            requiredStatus = true;
        }
        if (readOnly) {
            requiredStatus = false;
        }
        const ele = (React.createElement("div", { className: "cn-ui-form-item-label" },
            requiredStatus && (React.createElement("span", { className: "cn-ui-form-item-required" }, '* ')),
            React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, title),
            tip && this.renderTip()));
        if (labelMode === 'inline') {
            return (React.createElement(CnCol, { ...normalizeCol(labelCol), className: cls }, ele));
        }
        return React.createElement("div", { className: cls }, ele);
    }
    renderWrapper(labelMode) {
        const { errors, wrapperCol, extra, children, rightExtra = null, } = this.props;
        const componentArray = [
            React.createElement("div", { key: "control", className: "cn-ui-form-item-control" },
                children,
                rightExtra),
        ];
        if (Array.isArray(errors) && errors.length > 0) {
            componentArray.push(React.createElement("div", { key: "error", className: "cn-ui-form-item-error" }, errors.map((errorItem, idx) => (React.createElement("div", { className: "error-item", key: `error-${idx}` }, errorItem)))));
        }
        if (extra) {
            componentArray.push(React.createElement("div", { key: "extra", className: "cn-ui-form-item-extra" },
                React.createElement("span", null, extra)));
        }
        if (labelMode === 'inline') {
            return (React.createElement(CnCol, { ...normalizeCol(wrapperCol), className: "cn-ui-form-item-footer" }, componentArray));
        }
        return React.createElement("div", { className: "cn-ui-form-item-footer" }, componentArray);
    }
    render() {
        const { className, wrapperCol, labelCol, style, id, size } = this.props;
        const labelMode = wrapperCol || labelCol ? 'inline' : 'block';
        const classes = cx({
            'cn-ui-form-item': true,
            [`cn-ui-form-item-${labelMode}`]: true,
            [`cn-ui-form-item-${size}`]: true,
            [className]: !!className,
        });
        const Comp = labelMode === 'inline' ? CnRow : 'div';
        return (React.createElement(Comp, { "data-name": "CnFormItem", style: style, className: classes, id: id, gutter: 0, role: "cn-form-item" },
            this.renderLabel(labelMode),
            this.renderWrapper(labelMode)));
    }
}
CnFormItem.displayName = 'CnFormItem';
CnFormItem.defaultProps = {
// labelTextAlign: 'left',
};
