export default function formatColumns(columns) {
    return columns.map((item) => {
        var _a, _b, _c;
        return {
            ...item,
            // 如果 item.dataIndex 为 null 或 undefined，则使用 item.key 或 item.code，否则使用空字符串
            dataIndex: (_c = (_b = (_a = item.dataIndex) !== null && _a !== void 0 ? _a : item.key) !== null && _b !== void 0 ? _b : item.code) !== null && _c !== void 0 ? _c : '',
        };
    });
}
