import * as React from 'react';
import NextStep from './view/step';
import NextStepItem from './view/step-item';
import { CnPageFooter } from '@/components/cn-page-footer';
export const CnStep = React.forwardRef((props, ref) => {
    const { hasCnPageFooter, cnPageFooterProps } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(NextStep, { "data-name": "CnStep", ...props, ref: ref }),
        hasCnPageFooter && React.createElement(CnPageFooter, { ...cnPageFooterProps })));
});
CnStep.displayName = 'CnStep';
export const CnStepItem = NextStepItem;
/**
 * @deprecated
 * 建议使用 CnStepItem
 */
CnStep.Item = NextStepItem;
/**
 * @deprecated
 * 建议使用 CnStepItem
 */
export const StepItem = NextStepItem;
StepItem.displayName = 'StepItem';
