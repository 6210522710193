import * as React from 'react';
import isString from 'lodash/isString';
import { createFromIconfontCN } from './icon-font';
import './index.scss';
import classNames from 'classnames';
const scriptUrl = '//at.alicdn.com/t/c/font_3267196_vq8hlj9pj5b.js';
const script = document.createElement('script');
script.setAttribute('src', scriptUrl);
script.setAttribute('data-namespace', scriptUrl);
script.defer = true;
document.body.appendChild(script);
const CustomIcon = createFromIconfontCN({
    scriptUrl,
});
export const CnIcon = (props) => {
    const { type, outerClassName, ...otherProps } = props;
    let realType = type || '';
    if (isString(type) && !type.startsWith('icon-')) {
        realType = `icon-${type}`;
    }
    return (React.createElement(CustomIcon, { outerClassName: classNames(outerClassName, 'cn-ui-icon'), type: realType, ...otherProps }));
};
CnIcon.defaultProps = {
    size: 'medium',
};
CnIcon.createFromIconfontCN = createFromIconfontCN;
