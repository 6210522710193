// 第三方依赖
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import { getCellFormat } from './index';
/**
 * 这个函数的作用是对传入的表格列进行格式化处理。
 * 具体来说，它会遍历传入的 columns 数组，对每个元素进行处理。
 * 如果该元素中有 render 或 cell 函数，则直接返回该元素；
 * 否则，它会获取当前列的格式化方式，获取格式化函数，并根据格式化函数的类型执行相应的操作。
 * 如果格式化函数是一个函数，则执行该函数并返回结果；如果格式化函数是一个对象，则将该对象合并到 item 中并返回；
 * 如果格式化函数不存在，则直接返回 item
 */
export function cellFormat(columns, format) {
    // 如果传入的 columns 不是数组，直接返回
    if (!Array.isArray(columns)) {
        return columns;
    }
    // 获取格式化函数映射表
    const formatMap = getCellFormat(format);
    // 遍历 columns 数组，对每个元素进行处理
    return columns.map((item) => {
        var _a;
        // 处理表头嵌套的情况
        if (Array.isArray(item.children)) {
            item.children = cellFormat(item.children, format);
        }
        // 防止奇怪的用户传入字符串
        if (!isFunction(item.render)) {
            item.render = null;
        }
        if (!isFunction(item.cell)) {
            item.cell = null;
        }
        // 如果 item 中有 render 或 cell 函数，则直接返回
        if (isFunction(item.render) || isFunction(item.cell)) {
            return item;
        }
        // 获取当前列的格式化方式
        const itemFormat = (_a = item === null || item === void 0 ? void 0 : item.format) !== null && _a !== void 0 ? _a : 'text';
        // 获取格式化函数
        const configFormatMatch = isPlainObject(formatMap)
            ? formatMap[itemFormat]
            : null;
        // 如果格式化函数是一个函数，则执行该函数并返回结果
        if (configFormatMatch instanceof Function) {
            return {
                ...(item !== null && item !== void 0 ? item : {}),
                ...configFormatMatch(item),
            };
        }
        // 如果格式化函数是一个对象，则将该对象合并到 item 中并返回
        if (isPlainObject(configFormatMatch)) {
            return {
                ...(item !== null && item !== void 0 ? item : {}),
                ...configFormatMatch,
            };
        }
        // 如果格式化函数不存在，则直接返回 item
        return item;
    });
}
