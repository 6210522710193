import { useEffect, // 处理副作用
useRef, // 获取DOM元素或者缓存变量
useCallback, // 缓存函数
 } from 'react';
import useDebounceFn from 'ahooks/lib/useDebounceFn';
import useMount from 'ahooks/lib/useMount';
import useUpdate from 'ahooks/lib/useUpdate';
/**
 * 屏幕刷新的时候重新获取表格dom宽度, 并提供表格正确的ref
 * @param tableSourceRef
 * @returns
 */
export function useTableWidthCalWhenScreenResize(tableSourceRef) {
    const update = useUpdate();
    const tableWidth = useRef();
    const totalWidthCalByColumnRef = useRef();
    const newRef = useRef();
    const midRef = tableSourceRef || newRef;
    const calTableWidth = useCallback(() => {
        var _a, _b;
        const tableWrapper = (_b = (_a = midRef === null || midRef === void 0 ? void 0 : midRef.current) === null || _a === void 0 ? void 0 : _a.artTableWrapperRef) === null || _b === void 0 ? void 0 : _b.current;
        const tableContainerWidth = tableWrapper === null || tableWrapper === void 0 ? void 0 : tableWrapper.clientWidth;
        if (tableContainerWidth > 0) {
            tableWidth.current = tableContainerWidth;
            const totalWidthCalByColumn = totalWidthCalByColumnRef.current;
            if (totalWidthCalByColumn > 0 &&
                totalWidthCalByColumn < tableContainerWidth) {
                update();
            }
        }
    }, []);
    useEffect(() => {
        calTableWidth();
    }, []);
    const { run } = useDebounceFn(() => {
        calTableWidth();
    }, {
        wait: 100,
    });
    // if (isFunction(ResizeObserver)) {
    //   const resizeObserver = new ResizeObserver((entries) => {
    //     // entries.wid
    //     if (tableWidth.current !== entries?.[0]?.contentRect?.width) {
    //       run();
    //     }
    //   });
    //   if (midRef?.current?.artTableWrapperRef?.current) {
    //     resizeObserver.observe(midRef?.current?.artTableWrapperRef?.current);
    //   }
    // }
    // useEffect(() => {
    //   run();
    // }, [midRef?.current?.artTableWrapperRef?.current?.clientWidth]);
    useMount(() => {
        window.onresize = () => {
            run();
        };
    });
    return {
        midRef,
        totalWidthCalByColumnRef,
        tableWidth, // 重新获取表格dom宽度
    };
}
