import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
export const isInvisible = (el) => {
    var _a;
    return ['none', 'hidden'].indexOf(el === null || el === void 0 ? void 0 : el['x-display']) > -1
        || ((_a = el === null || el === void 0 ? void 0 : el['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.hidden)
        || (el === null || el === void 0 ? void 0 : el['x-hidden'])
        || ((el === null || el === void 0 ? void 0 : el['x-display']) === 'none')
        || ((el === null || el === void 0 ? void 0 : el['x-display']) === 'hidden')
        || ((el === null || el === void 0 ? void 0 : el['x-visible']) === false);
};
/**
 * 将Filter的children节点，最多保留maxRow行
 */
export const calcLayout = (maxRow, columns, properties, enableSaveSelected, saveSelectSpan, filterRefContext) => {
    var _a, _b, _c, _d;
    let idx = 0;
    let currentGroupSpan = 0;
    let groupCnt = 0;
    const arrangedProperties = [...properties];
    const showChildren = [];
    if (enableSaveSelected) {
        arrangedProperties.unshift({
            key: 'saveSelected',
            value: {
                colSpan: saveSelectSpan,
            },
        });
    }
    while (idx < arrangedProperties.length) {
        const child = arrangedProperties[idx].value;
        const formInstance = (_a = filterRefContext.current) === null || _a === void 0 ? void 0 : _a.formInstance;
        const item = (_b = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query) === null || _b === void 0 ? void 0 : _b.call(formInstance, arrangedProperties[idx].key).take();
        if (isInvisible(child) || (item === null || item === void 0 ? void 0 : item.display) === 'none' || (item === null || item === void 0 ? void 0 : item.display) === 'hidden') {
            // 排版中跳过 但仍然参与渲染
            showChildren.push(arrangedProperties[idx]);
            idx += 1;
            continue;
        }
        const span = Number(child === null || child === void 0 ? void 0 : child.colSpan) || Number((_c = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.colSpan) || 1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(arrangedProperties[idx]);
        idx += 1;
    }
    const hideChildren = arrangedProperties.slice(idx);
    if (enableSaveSelected && ((_d = showChildren === null || showChildren === void 0 ? void 0 : showChildren[0]) === null || _d === void 0 ? void 0 : _d.key) === 'saveSelected') {
        showChildren.shift();
    }
    return {
        showChildren,
        hideChildren,
    };
};
export const calcLayoutWithButton = (maxRow, columns, buttonSpan, properties, enableSaveSelected, saveSelectSpan, filterRefContext) => {
    var _a, _b, _c, _d;
    let idx = 0;
    let currentGroupSpan = 0;
    let groupCnt = 0;
    const btnSpan = Math.min(buttonSpan, columns);
    const arrangedProperties = [...properties];
    const showChildren = [];
    if (enableSaveSelected) {
        arrangedProperties.unshift({
            key: 'saveSelected',
            value: {
                colSpan: saveSelectSpan,
            },
        });
    }
    while (idx < arrangedProperties.length) {
        const child = arrangedProperties[idx].value;
        const formInstance = (_a = filterRefContext.current) === null || _a === void 0 ? void 0 : _a.formInstance;
        const item = (_b = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query) === null || _b === void 0 ? void 0 : _b.call(formInstance, arrangedProperties[idx].key).take();
        if (isInvisible(child) || (item === null || item === void 0 ? void 0 : item.display) === 'none' || (item === null || item === void 0 ? void 0 : item.display) === 'hidden') {
            // 排版中跳过 但仍然参与渲染
            showChildren.push(arrangedProperties[idx]);
            idx += 1;
            continue;
        }
        const span = Number(child === null || child === void 0 ? void 0 : child.colSpan) ||
            Number((_c = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.colSpan) ||
            1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span + btnSpan > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(arrangedProperties[idx]);
        idx += 1;
    }
    // btn前置空白
    const spaceSpan = columns - currentGroupSpan - btnSpan;
    if (spaceSpan < 0) {
        // 此行已放不下btn，需要换行
        ++groupCnt;
        showChildren.push({
            key: 'cn-filter-right-button-span',
            value: {
                colSpan: columns - btnSpan,
            }
        });
    }
    else {
        if (spaceSpan > 0) {
            showChildren.push({
                key: 'cn-filter-right-button-space-span',
                value: {
                    colSpan: spaceSpan,
                }
            });
        }
        ++groupCnt;
    }
    const hideChildren = arrangedProperties.slice(idx);
    if (enableSaveSelected && ((_d = showChildren === null || showChildren === void 0 ? void 0 : showChildren[0]) === null || _d === void 0 ? void 0 : _d.key) === 'saveSelected') {
        showChildren.shift();
    }
    return {
        showChildren,
        hideChildren,
    };
};
export const defaultDateFormat = {
    time: 'HH:mm:ss',
    date: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    week: 'GGGG-wo',
    year: 'YYYY',
    quarter: 'YYYY-[Q]Q',
};
export const isEmpty = (value) => {
    if (['boolean', 'number'].includes(typeof value))
        return false;
    return !value;
};
export const normalizeChildren = (value) => {
    return React.Children.toArray(value);
};
export function normalizeArray(value) {
    if (isEmpty(value))
        return [];
    const _value = Array.isArray(value) ? value : [value];
    return _value.filter((val) => !isEmpty(val));
}
const _childrenToDataSource = (dataSource, children, targetDisplayName, normalizeFn, ignoreChildren) => {
    React.Children.forEach(children, (child) => {
        var _a;
        if (!targetDisplayName ||
            normalizeDisplayName(((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.displayName) || '') === targetDisplayName) {
            dataSource.push(normalizeFn(child));
            if (ignoreChildren)
                return;
        }
        if (child === null || child === void 0 ? void 0 : child.props.children) {
            _childrenToDataSource(dataSource, child === null || child === void 0 ? void 0 : child.props.children, targetDisplayName, normalizeFn);
        }
    });
};
export const childrenToDataSource = (children, targetDisplayName, normalizeFn, ignoreChildren) => {
    const dataSource = [];
    _childrenToDataSource(dataSource, children, normalizeDisplayName(targetDisplayName), normalizeFn, ignoreChildren);
    return dataSource;
};
const _valueToDataSourceItem = (payload, value, dataSourceNode) => {
    if (payload.count === value.length)
        return;
    if (['boolean', 'number', 'string'].includes(typeof dataSourceNode)) {
        const idx = value.indexOf(dataSourceNode);
        if (idx >= 0) {
            payload.items[idx] = {
                value: dataSourceNode,
                label: dataSourceNode,
            };
            payload.count += 1;
            return;
        }
    }
    if (!isEmpty(dataSourceNode.value)) {
        const idx = value.indexOf(dataSourceNode.value);
        if (idx >= 0) {
            payload.items[idx] = dataSourceNode;
            payload.count += 1;
        }
    }
    if (Array.isArray(dataSourceNode.children)) {
        dataSourceNode.children.forEach((childNode) => {
            _valueToDataSourceItem(payload, value, childNode);
        });
    }
};
export const valueToDataSourceItem = (value, dataSource) => {
    const normalizedValue = normalizeArray(value);
    const payload = {
        count: 0,
        items: normalizedValue.slice(),
    };
    _valueToDataSourceItem(payload, normalizedValue, {
        children: dataSource,
    });
    return payload.items;
};
export const genId = (ids) => {
    return Math.max(...(ids || []), 0) + 1;
};
export function formatValue(value, formatter) {
    if (Array.isArray(value)) {
        return value.map(formatter);
    }
    return formatter(value);
}
export const normalizeDisplayName = (displayName) => {
    return (displayName || '').replace(/Config\(/g, '').replace(/\)/g, '');
};
export const getNormalizedDisplayName = (type) => {
    return normalizeDisplayName((type === null || type === void 0 ? void 0 : type.displayName) || '');
};
const componentsWithoutOverlay = [
    'Checkbox', 'Input', 'NumberPicker', 'Radio', 'Range', 'Rating', 'Switch',
    'CnCheckbox', 'CnInput', 'CnNumberPicker', 'CnRangeNumberPicker', 'CnNumberPickerState', 'CnRadio', 'CnRange', 'CnRating', 'CnSwitch',
];
export const getItemStyleInjectProps = (componentName, props) => {
    var _a;
    if (componentsWithoutOverlay.indexOf(componentName) >= 0) {
        return null;
    }
    // 使用 v2 的组件自己覆盖 container 设置
    const popupContainer = (e) => {
        var _a, _b;
        return (_b = (_a = e === null || e === void 0 ? void 0 : e.closest('.cn-ui-filter-pro')) !== null && _a !== void 0 ? _a : e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) !== null && _b !== void 0 ? _b : document.body;
    };
    return {
        popupContainer: (_a = props === null || props === void 0 ? void 0 : props.popupContainer) !== null && _a !== void 0 ? _a : popupContainer,
        popupProps: {
            container: popupContainer,
            ...((props === null || props === void 0 ? void 0 : props.popupProps) || {}),
        },
    };
};
const formatConfig = {
    moment: {
        componentNames: [
            'DatePicker',
            'RangePicker',
            'WeekPicker',
            'MonthPicker',
            'YearPicker',
            'TimePicker',
        ],
        getValueFormatter: (val) => formatValue(val, (v) => (moment.isMoment(v) ? v.valueOf() : v)),
        setValueFormatter: (val) => formatValue(val, (v) => (typeof v === 'number' ? moment(v) : v)),
    },
    dayjs: {
        componentNames: [
            'DatePicker2',
            'RangePicker2',
            'WeekPicker2',
            'MonthPicker2',
            'QuarterPicker2',
            'YearPicker2',
            'TimePicker2',
        ],
        getValueFormatter: (val) => formatValue(val, (v) => (dayjs.isDayjs(v) ? v.valueOf() : v)),
        setValueFormatter: (val) => formatValue(val, (v) => (typeof v === 'number' ? dayjs(v) : v)),
    },
};
export const getValueFormatterInjectProps = (componentName) => {
    const typeName = Object.keys(formatConfig).find((key) => {
        var _a;
        return (_a = formatConfig[key]) === null || _a === void 0 ? void 0 : _a.componentNames.includes(componentName);
    });
    return typeName
        ? {
            _formattertypename: typeName,
        }
        : null;
};
