import * as React from 'react';
import { CnAsyncSelect } from '@/components/cn-async-select';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { highlightRender } from './utils';
import currencyMap from './currency-map';
export class CurrencySelect extends React.Component {
    constructor() {
        super(...arguments);
        this.renderCountryIcon = (currencyDetail) => {
            if (currencyDetail.country) {
                return (React.createElement("img", { className: "cn-ui-currency-select-country-icon", src: `//caijing-resource.oss-accelerate.aliyuncs.com/country/${currencyDetail.country}.svg`, width: 18 }));
            }
            // 未命中任何情况，兜底
            return (React.createElement("div", { className: "cn-ui-currency-select-country-icon", style: { width: '18px' } }));
        };
        this.currencyItemRender = (item, searchKey) => {
            const currencyDetail = currencyMap[item.value] || {};
            return (React.createElement("div", { className: "cn-ui-currency-select-menu-item" },
                React.createElement("div", { className: "cn-ui-currency-select-menu-item-header" },
                    this.renderCountryIcon(currencyDetail),
                    React.createElement("div", { className: "currency-value" }, highlightRender(currencyDetail.value || item.value, searchKey))),
                React.createElement(CnEllipsis, { className: "cn-ui-currency-select-menu-item-footer" }, currencyDetail.label)));
        };
        this.currencyValueRender = (retValue) => {
            const currencyDetail = currencyMap[retValue.value] || {};
            return (React.createElement("div", { className: "cn-ui-currency-select-value" },
                this.renderCountryIcon(currencyDetail),
                React.createElement("div", { className: "currency-value" }, currencyDetail.value)));
        };
    }
    render() {
        const { readOnly, dataSource, ...otherProps } = this.props;
        if (readOnly) {
            return (React.createElement("span", { className: "cn-ui-currency-select-readonly" }, otherProps.value));
        }
        return (React.createElement(CnAsyncSelect, { "data-name": "CnCurrencySelect", className: "cn-ui-currency-select", popupClassName: "cn-ui-currency-select-popup", itemRender: this.currencyItemRender, valueRender: this.currencyValueRender, dataSource: readOnly ? undefined : dataSource, readOnly: readOnly, ...otherProps }));
    }
}
