import $i18n from 'panda-i18n';
import * as React from 'react';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        console.log(error);
        // 更新 state 使下一次渲染能够显示降级后的 UI
        return { hasError: true };
    }
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // 你可以自定义降级后的 UI 并渲染
            return (React.createElement("td", null, $i18n.get({
                id: 'FromCntableAnExceptionHasOccurred_162716565',
                dm: '【from cn-table】单元格内容发生了异常，请检查渲染内容是否包含对象',
                ns: 'CnBaseTable',
            })));
        }
        return children;
    }
}
ErrorBoundary.state = {
    hasError: false,
};
