import React, { forwardRef, useState, useCallback, useRef, cloneElement, useImperativeHandle, } from 'react';
import { Form } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import omit from 'lodash/omit';
import classnames from 'classnames';
import useGap from './hooks/use-gap';
import ResponsiveLayout from './responsive-layout';
import FilterSelectedTags from './filter-selected-tags';
import useSaveSelected from './hooks/use-save-selected';
import useSaveConfig from './hooks/use-save-config';
import OptBtns from './more-btns';
import { injectFieldInit } from './value-formatter';
import { responsiveSizeV, responsiveSizeH } from './const';
import { sendLog } from '@/components/cn-utils';
import { setCache } from './cache';
import './filter.scss';
/**
 * Filter组件
 */
const FilterComponent = forwardRef((props, ref) => {
    const { children, className, style, field, extendButtons, extendButtonsPosition, expandButtonUseIcon, resetButtonUseIcon, 
    // layout,
    columns, responsiveSize, isRightButton, buttonSpan, hideButton, columnGap: _columnGap, rowGap: _rowGap, onColumnChange, enableExpand, maxVisibleRow, 
    // mode,
    defaultExpand, expand, onExpandChange, showSelected, showFolder, showBottomLine, storageKey, onGetStorage, onSetStorage, enableConfig, defaultConfigValue, configValue, configMeta, onConfigValueChange, enableSaveSelected, saveSelectSpan, beforeGetSaveSelected, beforeSetSaveSelected, buttonType, onChange, onSearch, onSubmit, onReset, fullWidth, labelAlign, labelTextAlign, size, labelCol: labelColProps, wrapperCol, $i18n, moreFilters, useLabelForErrorMessage, onDragConfigItem, disableEnterSubmit, cacheSearch, removeEmptyLabel, ...rest } = props;
    const labelCol = labelAlign === 'left' ? labelColProps !== null && labelColProps !== void 0 ? labelColProps : { fixedSpan: 5 } : undefined;
    const [, forceUpdate] = useState(1);
    const [fold, setFold] = useState(false);
    const defaultValuesRef = useRef((field && field.getValues()) || {});
    const searchValuesRef = useRef((field && field.getValues()) || {});
    const filterPopupContainerRef = useRef(null);
    const innerValues = field.getValues();
    const [innerColumns, setInnerColumns] = useState(8);
    const rowGap = useGap(size, _rowGap);
    const columnGap = useGap(size, _columnGap);
    const handleSearch = () => {
        sendLog({
            id: 'cn-ui.cn-filter.onSearch',
            name: 'CnFilter查询触发',
        });
        field.validatePromise().then(({ errors, values }) => {
            if (errors)
                return;
            // const values = field && field.getValues<Obj>();
            searchValuesRef.current = values;
            setCache(values, storageKey, cacheSearch);
            onSearch && onSearch(values);
            // searchValuesRef更新了, 需要触发tags渲染
            forceUpdate((s) => (s + 1) % 32);
        });
    };
    const handleSubmit = (e) => {
        sendLog({
            id: 'cn-ui.cn-filter.onSubmit',
            name: 'CnFilter提交触发',
        });
        e && e.preventDefault();
        const values = field && field.getValues();
        onSubmit && onSubmit(values);
        handleSearch();
    };
    const handleReset = () => {
        sendLog({
            id: 'cn-ui.cn-filter.onReset',
            name: 'CnFilter重置按钮点击',
        });
        field && field.reset();
        field &&
            (defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current) &&
            field.setValues(defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current);
        searchValuesRef.current = field.getValues();
        if (onReset) {
            return onReset(field);
        }
        handleChange(field.getValues(), { field });
        // reset 调用之后立即调用 validate 不能生效
        setTimeout(() => {
            handleSearch();
        });
    };
    const handleRemove = (key) => {
        field && field.setValue(key, undefined);
        searchValuesRef.current = field.getValues();
        forceUpdate((s) => (s + 1) % 32);
        handleChange(field.getValues(), {
            name: key,
            field,
        });
        handleSearch();
    };
    const handleChange = (values, change) => {
        onChange && onChange(values, change);
    };
    const handleColumnChange = (c) => {
        setInnerColumns(c);
        onColumnChange && onColumnChange(c);
    };
    const getSearchValues = useCallback(() => searchValuesRef.current, []);
    if (field) {
        injectFieldInit(field);
        field.filterSubmit = handleSubmit;
        field.filterReset = handleReset;
        field.filterSearch = handleSearch;
        field.filterChange = handleChange;
        field.getSearchValues = getSearchValues;
    }
    let fullChildren = React.Children.toArray(children);
    if (moreFilters) {
        fullChildren = fullChildren.concat(React.Children.map(moreFilters, (i) => cloneElement(i, {
            _cnfilter_ismorefilter: true,
        })));
    }
    const normalizedChildren = React.Children.map(fullChildren, (child) => {
        // if (!layout) return child as ReactElement;
        return cloneElement(child, {
            size,
            fullWidth,
            labelAlign,
            labelTextAlign,
            labelCol,
            wrapperCol,
            useLabelForErrorMessage,
            removeEmptyLabel,
            ...child.props,
        });
    });
    const { renderConfigButton, renderConfigModal, children: arrangedChildren, } = useSaveConfig({
        enableConfig,
        storageKey,
        children: normalizedChildren,
        defaultConfigValue,
        configValue,
        configMeta,
        onConfigValueChange,
        onGetStorage,
        onSetStorage,
        filterPopupContainerRef,
        field,
        enableSaveSelected,
        saveSelectSpan,
        gridProps: {
            columns: innerColumns,
            gap: [8, 8],
        },
        onDragConfigItem,
    });
    const { renderSelector, renderInputSaveOverlay, clearSelected } = useSaveSelected({
        enableSaveSelected,
        values: searchValuesRef.current,
        field,
        storageKey,
        beforeGetSaveSelected,
        beforeSetSaveSelected,
        onGetStorage,
        onSetStorage,
        filterPopupContainerRef,
    });
    if (enableSaveSelected) {
        arrangedChildren.unshift(renderSelector({
            key: 'cnfilter-saved-selector',
            span: saveSelectSpan,
            size,
            fullWidth,
            labelAlign,
            labelTextAlign,
            labelCol,
            wrapperCol,
            locale: {},
        }));
    }
    useImperativeHandle(ref, () => ({
        getValues: () => {
            return field && field.getValues();
        },
        getField: () => field,
        filterPopupContainerRef,
    }));
    const btns = [];
    // if (enableSaveSelected) {
    //   btns.push(renderSaveButtonProps({ size }));
    // }
    btns.push({
        icon: 'refresh',
        onClick: handleReset,
        title: $i18n.get({ id: 'reset', dm: '重置' }),
        useIcon: resetButtonUseIcon,
    });
    // if (enableConfig) {
    //   btns.push(renderConfigButtonProps({ size }));
    // }
    return (React.createElement("div", { "data-name": "CnFilter", 
        // id="filter-popup-container"
        className: classnames('cn-ui-filter', className, `cn-ui-filter-${size}`), style: style },
        React.createElement(Form, { field: field, 
            // fullWidth
            onChange: (values, item) => {
                field.isOnchange = true;
                field.stateValues = values;
                handleChange(values, item);
                clearSelected();
            }, onSubmit: handleSubmit, 
            // onReset={handleReset}
            labelCol: labelCol, style: {
                display: fold ? 'none' : 'inherit',
            }, ...omit(rest, [
                'value',
                'defaultValue',
                'labelCol',
                'showFolder',
                'showBottomLine',
            ]) },
            renderConfigModal(),
            renderInputSaveOverlay(),
            React.createElement("div", null,
                React.createElement(ResponsiveLayout, { buttons: React.createElement(React.Fragment, null,
                        React.createElement(CnButton, { size: size, type: "primary", htmlType: disableEnterSubmit ? 'button' : 'submit', onClick: (e) => {
                                if (!disableEnterSubmit)
                                    return;
                                handleSubmit(e);
                            }, className: `cn-ui-filter-search-btn cn-ui-filter-btn-${buttonType || size}` }, $i18n.get({
                            id: 'search',
                            dm: '查询',
                            ns: 'CnFilter',
                        })),
                        React.createElement(OptBtns, { maxShowNum: 2, dataSource: btns, size: size })), ...{
                        mode: 'expand',
                        extendButtons,
                        extendButtonsPosition,
                        expandButtonUseIcon,
                        columnGap,
                        rowGap,
                        columns,
                        onColumnChange: handleColumnChange,
                        maxVisibleRow,
                        isRightButton,
                        buttonSpan,
                        hideButton,
                        enableExpand,
                        defaultExpand,
                        enableConfig,
                        renderConfigButton: () => renderConfigButton({ size }),
                        expand,
                        onExpandChange,
                        size,
                        fullWidth,
                        labelAlign,
                        responsiveSize: responsiveSize ||
                            (labelAlign === 'left' ? responsiveSizeH : responsiveSizeV),
                        $i18n,
                    } }, arrangedChildren))),
        showSelected ? (React.createElement(FilterSelectedTags, { values: searchValuesRef.current, innerValues: innerValues, onRemove: handleRemove })) : null,
        showFolder ? (React.createElement("div", { className: "cn-ui-filter-foldline" },
            React.createElement("div", { className: "cn-ui-filter-foldline-btn", onClick: () => {
                    sendLog({
                        id: 'cn-ui.cn-filter.toggleFold',
                        name: 'CnFilter展开收起按钮点击',
                    });
                    setFold(!fold);
                } },
                React.createElement(CnIcon, { className: "cn-ui-filter-foldline-btn-icon", type: fold ? 'icon-arrow-down' : 'icon-arrow-up', size: "small" }),
                fold
                    ? $i18n.get({ id: 'Expand', dm: '展开', ns: 'CnFilter' })
                    : $i18n.get({ id: 'PutItAway', dm: '收起', ns: 'CnFilter' })))) : null,
        !showFolder && showBottomLine ? (React.createElement("div", { className: "cn-ui-filter-foldline" })) : null,
        !showSelected && !showFolder && !showBottomLine && React.createElement("div", { className: "cn-ui-filter-marginbottom" }),
        React.createElement("div", { "data-role": "filter-popup-container", ref: filterPopupContainerRef })));
});
FilterComponent.defaultProps = {
    extendButtonsPosition: 'end',
    expandButtonUseIcon: false,
    resetButtonUseIcon: false,
    buttonSpan: 1,
    isRightButton: false,
    enableExpand: true,
    maxVisibleRow: 2,
    // mode: 'expand',
    showSelected: false,
    showFolder: true,
    showBottomLine: false,
    enableConfig: false,
    enableSaveSelected: false,
    saveSelectSpan: 1,
    size: 'medium',
    useLabelForErrorMessage: true,
};
export default FilterComponent;
