import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
export default class TreeNodeIndent extends Component {
    render() {
        const { prefix, level, isLastChild, showLine } = this.props;
        const indents = [];
        for (let i = 1; i < level; i++) {
            const classNames = cx(`${prefix}tree-node-indent-unit`, {
                [`${prefix}line`]: !isLastChild[i - 1] && showLine,
            });
            indents.push(React.createElement("span", { className: classNames, key: i - 1 }));
        }
        return indents;
    }
}
TreeNodeIndent.propTypes = {
    prefix: PropTypes.string,
    level: PropTypes.number,
    isLastChild: PropTypes.arrayOf(PropTypes.bool),
    showLine: PropTypes.bool,
};
TreeNodeIndent.defaultProps = {
    isLastChild: [],
};
