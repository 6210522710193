import $i18n from 'panda-i18n';
import React, { Fragment } from 'react';
import { isFn } from '@formily/shared';
import { useField } from '@formily/react';
import { Button } from '@fusion/lib';
import { CnButton } from '@/components/cn-button';
import cloneDeep from 'lodash/cloneDeep';
import { usePaginationContext } from './pagination-context';
import { ArrayBase } from '../array-base';
import { CnArrayTable } from './cn-array-table';
import './index.scss';
export { CnArrayTable };
export const CnArrayTableIndex = (props) => {
    const index = ArrayBase.useIndex();
    return (React.createElement("span", { ...props, className: 'cn-ui-array-table-index' }, index + 1));
};
export const CnArrayTableAddition = (props) => {
    const array = ArrayBase.useArray();
    const { totalPage = 0, pageSize = 10, changePage } = usePaginationContext();
    return (React.createElement(ArrayBase.Addition, { ...props, onClick: (e) => {
            var _a, _b;
            // 如果添加数据后将超过当前页，则自动切换到下一页
            const total = ((_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.value.length) || 0;
            if (total === totalPage * pageSize + 1 && isFn(changePage)) {
                changePage(totalPage + 1);
            }
            (_b = props.onClick) === null || _b === void 0 ? void 0 : _b.call(props, e);
        } }));
};
export const CnArrayTableColumn = () => {
    return React.createElement(Fragment, null);
};
export const CnArrayTableRemove = React.forwardRef((props, ref) => {
    var _a;
    const index = ArrayBase.useIndex(props.index);
    const self = useField();
    const array = ArrayBase.useArray();
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, { text: true, type: "primary", className: "cn-ui-array-table-remove", warning: true, ...props, ref: ref, children: props.children ||
            props.title ||
            self.title ||
            $i18n.get({
                id: '31255239516622848.CNTM',
                dm: '删除',
                ns: 'CnForm',
            }), onClick: (e) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            (_b = (_a = array.field) === null || _a === void 0 ? void 0 : _a.remove) === null || _b === void 0 ? void 0 : _b.call(_a, index);
            (_c = array.field) === null || _c === void 0 ? void 0 : _c.form.setValuesIn((_e = (_d = array.field) === null || _d === void 0 ? void 0 : _d.path) === null || _e === void 0 ? void 0 : _e.segments, cloneDeep((_f = array.field) === null || _f === void 0 ? void 0 : _f.value));
            (_h = (_g = array.props) === null || _g === void 0 ? void 0 : _g.onRemove) === null || _h === void 0 ? void 0 : _h.call(_g, index);
            if (props.onClick) {
                props.onClick(e);
            }
        } }));
});
export const CnArrayTableMoveUp = React.forwardRef((props, ref) => {
    var _a;
    const { disabled } = props;
    const index = ArrayBase.useIndex(props.index);
    const self = useField();
    const array = ArrayBase.useArray();
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, { type: "primary", text: true, className: "cn-ui-array-table-move-up", ...props, disabled: disabled || index === 0, ref: ref, onClick: (e) => {
            var _a, _b, _c;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            (_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.moveUp(index);
            (_c = (_b = array === null || array === void 0 ? void 0 : array.props) === null || _b === void 0 ? void 0 : _b.onMoveUp) === null || _c === void 0 ? void 0 : _c.call(_b, index);
            if (props.onClick) {
                props.onClick(e);
            }
        } }, $i18n.get({ id: 'MoveUp', dm: '上移', ns: 'CnForm' })));
});
export const CnArrayTableMoveDown = React.forwardRef((props, ref) => {
    var _a, _b, _c;
    const { disabled } = props;
    const index = ArrayBase.useIndex(props.index);
    const self = useField();
    const array = ArrayBase.useArray();
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    const arrayLength = (_c = (_b = array === null || array === void 0 ? void 0 : array.field) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.length;
    const isLast = index === arrayLength - 1;
    return (React.createElement(Button, { type: "primary", text: true, className: "cn-ui-array-table-move-down", ...props, disabled: disabled || isLast, ref: ref, onClick: (e) => {
            var _a, _b, _c;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            (_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.moveDown(index);
            (_c = (_b = array === null || array === void 0 ? void 0 : array.props) === null || _b === void 0 ? void 0 : _b.onMoveDown) === null || _c === void 0 ? void 0 : _c.call(_b, index);
            if (props.onClick) {
                props.onClick(e);
            }
        } }, $i18n.get({ id: 'MoveDown', dm: '下移', ns: 'CnForm' })));
});
export const CnArrayTableSortHandle = (props) => {
    return (React.createElement(ArrayBase.SortHandle, { ...props }));
};
