import * as React from 'react';
import cx from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import ResizeObserver from 'resize-observer-polyfill';
export const CnTextEllipsis = ({ children, style, line, className, hasTooltip, showFolder, toolTipProps, }) => {
    const textRef = React.useRef(null);
    const [isOverflow, setIsOverflow] = React.useState(false);
    const [showAll, setShowAll] = React.useState(false);
    React.useEffect(() => {
        if (!(textRef === null || textRef === void 0 ? void 0 : textRef.current))
            return;
        const resizeObserver = new ResizeObserver(() => {
            var _a, _b;
            if (((_a = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) > ((_b = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight)) {
                setIsOverflow(true);
            }
        });
        resizeObserver.observe(textRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    const handleToggleFold = () => {
        setShowAll(!showAll);
    };
    const content = (React.createElement("div", { "data-name": "CnEllipsis", className: cx('cn-ui-text-clip', {
            fold: showFolder && isOverflow,
        }) },
        React.createElement("div", { ref: textRef, className: cx([
                className,
                {
                    'cn-ui-text-clip-inner': !showAll,
                },
            ]), style: { ...style, WebkitLineClamp: line } }, children),
        showFolder && isOverflow ? (React.createElement("span", { className: "cn-ui-text-clip-fold", onClick: handleToggleFold },
            React.createElement(CnIcon, { type: showAll ? 'triangle-up-fill' : 'triangle-down-fill' }))) : null));
    if (hasTooltip && isOverflow) {
        return (React.createElement(CnTooltip, { className: cx({
                'cn-ui-text-ellipsis-tooltip': true,
                [toolTipProps === null || toolTipProps === void 0 ? void 0 : toolTipProps.className]: !!(toolTipProps === null || toolTipProps === void 0 ? void 0 : toolTipProps.className),
            }), trigger: content, ...toolTipProps }, children));
    }
    return content;
};
CnTextEllipsis.defaultProps = {
    line: 2,
    hasTooltip: true,
    showFolder: false,
};
CnTextEllipsis.displayName = 'CnTextEllipsis';
