/**
 * 附加参数数据到CnRequestConfig中
 * 目前只处理了get和post请求
 */
export function requestAttachData(config, extraData) {
    var _a;
    const method = ((_a = config.method) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || 'get';
    if (method === 'get') {
        const params = { ...config.params, ...extraData };
        return {
            ...config,
            params,
        };
    }
    if (method === 'post') {
        const data = { ...config.data, ...extraData };
        return {
            ...config,
            data,
        };
    }
    return config;
}
