import $i18n from 'panda-i18n';
import React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
export const UploadDropZone = (props) => {
    const { children, limitExceeded, disabled } = props;
    const [isDropOver, setIsDropOver] = React.useState(false);
    let title = $i18n.get({
        id: 'ClickUploadFileOrDragFile_62060765',
        dm: '点击上传文件或拖拽文件到这里',
        ns: 'CnUpload',
    });
    if (limitExceeded) {
        title = $i18n.get({
            id: 'ExceededQuantityLimit',
            dm: '超出数量限制',
            ns: 'CnUpload',
        });
    }
    else if (isDropOver) {
        title = $i18n.get({
            id: 'LetGoAndStartUploading',
            dm: '松手开始上传',
            ns: 'CnUpload',
        });
    }
    const activeZone = disabled ? undefined : () => setIsDropOver(true);
    const inactiveZone = disabled
        ? (e) => e.preventDefault()
        : () => setIsDropOver(false);
    return (React.createElement("div", { className: cx('cn-ui-upload-drop-zone', {
            disabled,
            'cn-ui-upload-drop-zone-active': !disabled && isDropOver,
        }), onDragEnter: activeZone, onDragLeave: inactiveZone, onDragOver: inactiveZone, onDrop: inactiveZone },
        React.createElement(CnIcon, { className: "cn-ui-upload-drop-zone-icon", size: "large", type: "upload" }),
        React.createElement("div", { className: "cn-ui-upload-drop-zone-title" }, title),
        React.createElement("div", { className: "cn-ui-upload-drop-zone-description" }, children)));
};
