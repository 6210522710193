import { isFunction, mergeCellProps, makeRecursiveMapper } from '../utils';
// 定义 cellPropsPipeline 函数
export default function cellPropsPipeline(cellProps) {
    // 返回一个处理函数
    return function (pipeline) {
        // 获取源列
        const sourceColumns = pipeline.getColumns();
        // 初始化列索引
        let colIndex = 0;
        // 判断 cellProps 是否为函数
        if (cellProps instanceof Function) {
            // 创建递归映射处理器
            const traverseHandler = makeRecursiveMapper((col, { isLeaf }) => {
                // 如果列不存在，直接返回
                if (!col) {
                    return col;
                }
                // 保存当前列索引
                const nowColIndex = colIndex;
                // 如果是叶子节点
                if (isLeaf) {
                    // 获取源单元格属性
                    const sourceGetCellProps = col.getCellProps;
                    // 重写 getCellProps 方法
                    col.getCellProps = (value, rowData, rowIndex) => {
                        // 获取源单元格属性
                        const sourceCellProps = isFunction(sourceGetCellProps)
                            ? sourceGetCellProps(value, rowData, rowIndex)
                            : {};
                        // 获取通用单元格属性
                        const commonCellProps = cellProps(rowIndex, nowColIndex, col.code, rowData);
                        // 合并单元格属性，优先级 cellProps > getCellProps > pipeline内部getCellProps
                        return mergeCellProps(sourceCellProps, commonCellProps);
                    };
                    // 更新列索引
                    colIndex += 1;
                }
                // 返回处理后的列
                return col;
            });
            // 使用处理器遍历源列
            traverseHandler(sourceColumns);
        }
        // 返回处理后的列
        return pipeline.columns(sourceColumns);
    };
}
