import React, { Fragment } from 'react';
import cls from 'classnames';
import { CnStep as Steps, StepItem as StepsItem, } from '@/components/cn-step';
import { connect, useField, observer, useFieldSchema, RecursionField, } from '@formily/react';
import { usePrefixCls } from '@/form/__builtins__';
import './cn-form-step.scss';
const parseSteps = (schema) => {
    const steps = [];
    schema.mapProperties((schema, name) => {
        var _a;
        if ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormStepItem')) {
            steps.push({
                name,
                props: schema['x-component-props'],
                schema,
            });
        }
    });
    return steps;
};
export const CnFormStep = connect(observer(({ cnFormStepInstance, className, ...props }) => {
    var _a;
    const field = useField();
    const prefixCls = usePrefixCls('formily-step', props);
    const schema = useFieldSchema();
    const steps = parseSteps(schema);
    const current = props.current || (cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.current) || 0;
    (_a = cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.connect) === null || _a === void 0 ? void 0 : _a.call(cnFormStepInstance, steps, field);
    return (React.createElement("div", { className: cls(prefixCls, className) },
        React.createElement(Steps, { ...props, current: current }, steps.map(({ props }, key) => {
            return React.createElement(StepsItem, { ...props, key: key });
        })),
        steps.map(({ name, schema }, key) => {
            if (key !== current)
                return;
            return React.createElement(RecursionField, { key: key, name: name, schema: schema });
        })));
}));
export const CnFormStepItem = ({ children }) => {
    return React.createElement(Fragment, null, children);
};
