import $i18n from 'panda-i18n';
export default (value) => {
    if (value) {
        return $i18n.get({ id: 'Yes', dm: '是', ns: 'CnReadOnly' });
    }
    return $i18n.get({
        id: '31255237809541120.CNTM',
        dm: '否',
        ns: 'CnReadOnly',
    });
};
