import React, { createContext, useCallback, useEffect, useMemo, useState, } from 'react';
import { configResponsive, useResponsive, useUpdateEffect } from 'ahooks';
import { useBreadcrumbDataSource } from './hooks/use-breadcrumb-data-source';
import { getMenuKeysWitchHasChildren, getParentKeysBySelected, } from './side-bar/utils';
import { useLatest } from '../cn-scroll-bar/hooks';
export const ShellStateCtx = createContext({});
configResponsive({
    small: 0,
    middle: 1280,
    large: 1920,
});
export const ShellContext = (props) => {
    const { children, shellProps } = props;
    const { theme, headerVisible, headerProps = {}, sideBarVisible, sideBarProps = {}, } = shellProps;
    const { menu = [], defaultExpandAll, selectedMenuKey, menuCollapse, onToggleMenuCollapse, } = sideBarProps || {};
    const menuRef = useLatest(menu);
    const defaultExpandAllRef = useLatest(defaultExpandAll);
    const onToggleMenuCollapseRef = useLatest(onToggleMenuCollapse);
    const responsive = useResponsive();
    // 默认是否折叠
    const initIsSideBarFold = !responsive.middle;
    // 是否是受控模式(menuCollapse由外部控制)
    const isSideBarFoldControlled = 'menuCollapse' in sideBarProps;
    const [isSideBarFold, setIsSideBarFold] = useState(isSideBarFoldControlled ? menuCollapse : initIsSideBarFold);
    const [isSideBarPreview, setIsSideBarPreview] = useState(false);
    /** 通过响应式控制侧边栏展开和折叠逻辑 */
    useUpdateEffect(() => {
        var _a;
        setIsSideBarFold(initIsSideBarFold);
        (_a = onToggleMenuCollapseRef.current) === null || _a === void 0 ? void 0 : _a.call(onToggleMenuCollapseRef, initIsSideBarFold);
    }, [initIsSideBarFold, onToggleMenuCollapseRef]);
    useUpdateEffect(() => {
        setIsSideBarFold(menuCollapse);
    }, [menuCollapse]);
    const getAllKeys = useCallback(() => {
        if (defaultExpandAllRef.current) {
            return getMenuKeysWitchHasChildren(menuRef.current);
        }
        if (selectedMenuKey) {
            return getParentKeysBySelected(menu, selectedMenuKey);
        }
        return [];
    }, [defaultExpandAllRef, menu, menuRef, selectedMenuKey]);
    const [sideBarExpandKeys, setSideBarExpandKeys] = useState(getAllKeys());
    useEffect(() => {
        setSideBarExpandKeys(getAllKeys());
    }, [getAllKeys]);
    useBreadcrumbDataSource({
        selectedKey: sideBarProps.selectedMenuKey,
        headerMenu: headerProps.menu,
        sideBarMenu: sideBarProps.menu,
    });
    const output = useMemo(() => {
        return {
            isSideBarPreview,
            setIsSideBarPreview,
            isSideBarFold,
            setIsSideBarFold,
            sideBarVisible,
            sideBarProps,
            sideBarExpandKeys,
            setSideBarExpandKeys,
            theme,
            headerVisible,
            headerProps,
        };
    }, [
        isSideBarPreview,
        isSideBarFold,
        sideBarVisible,
        sideBarProps,
        sideBarExpandKeys,
        theme,
        headerVisible,
        headerProps,
    ]);
    return (React.createElement(ShellStateCtx.Provider, { value: output }, children));
};
