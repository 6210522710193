import $i18n from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import { Dropdown } from '@/components/fusion';
import { CnInput, CnInputTextArea } from '@/components/cn-input';
import { CnIcon } from '@/components/cn-icon';
import { useControllableValue } from 'ahooks';
import isEqual from 'lodash/isEqual';
import { i18nRegister } from '@/components/cn-utils';
import './index.scss';
const formatValue = (value) => value.replace(/(,)+/g, ',').replace(/(\n)+/g, '\n');
const processInputValue = (inputVal) => {
    if (typeof inputVal !== 'string')
        return inputVal;
    return formatValue(inputVal.replace(/,/g, '\n')).replace(/\n$/, '');
};
const processTextareaValue = (textareaVal) => {
    if (typeof textareaVal !== 'string')
        return textareaVal;
    return formatValue(textareaVal.replace(/\n/g, ',')).replace(/,$/, '');
};
export const CnBatchInput = (props) => {
    const { className, onChange, value, defaultValue, state, size, inputProps, textAreaProps, readOnly, } = props;
    const inputRef = React.useRef(null);
    const wrapperRef = React.useRef(null);
    const [dropDownVisible, setDropDownVisible] = React.useState(false);
    const [inputValue, setInputValue] = useControllableValue(props);
    const [textareaValue, setTextareaValue] = React.useState(processInputValue(inputValue));
    React.useEffect(() => {
        const newValue = processTextareaValue(textareaValue);
        if (!isEqual(newValue, inputValue)) {
            setInputValue(newValue);
        }
    }, [textareaValue]);
    React.useEffect(() => {
        const newValue = processInputValue(inputValue);
        if (!isEqual(newValue, textareaValue)) {
            setTimeout(() => {
                setTextareaValue(newValue);
            });
        }
    }, [inputValue]);
    return (React.createElement("div", { className: cx({
            'cn-ui-batch-input': true,
            [className]: !!className,
        }), ref: wrapperRef },
        React.createElement(Dropdown, { visible: dropDownVisible, onVisibleChange: (visible, type) => {
                if (type === 'fromTrigger') {
                    setDropDownVisible(true);
                }
                else {
                    setDropDownVisible(visible);
                }
            }, container: (e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body, wrapperClassName: "cn-ui-batch-input-popup", triggerType: "click", target: inputRef.current, autoFocus: false, trigger: React.createElement(CnInput, { ref: inputRef, hint: React.createElement(CnIcon, { type: "icon-bill-more", className: "add-icon", outerClassName: "cn-next-input-hint" }), state: state, size: size, readOnly: readOnly, ...inputProps, value: inputValue, onChange: setInputValue }) },
            React.createElement("div", { className: "popup-wrapper" },
                React.createElement("div", { className: "popup-title" },
                    React.createElement("span", null, $i18n.get({
                        id: 'BatchAddition',
                        dm: '批量添加',
                        ns: 'CnBatchInput',
                    })),
                    React.createElement("span", { className: "tips" }, $i18n.get({
                        id: 'EachDataEnterKeyWrap',
                        dm: '（每条数据回车键换行）',
                        ns: 'CnBatchInput',
                    }))),
                React.createElement(CnInputTextArea, { hasBorder: false, rows: 8, ...textAreaProps, className: cx({
                        'popup-textarea': true,
                        [textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className]: !!(textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className),
                    }), value: textareaValue, onChange: setTextareaValue })))));
};
CnBatchInput.displayName = 'CnBatchInput';
i18nRegister(CnBatchInput);
