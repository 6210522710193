import * as React from 'react';
import { BaseTable } from './base';
import { useTablePipeline } from './pipeline';
import { rowDetailsPipeLine, rowAttachPipeline, columnsResizePipeline, columnsSortPipeline, columnsMultiSelectPipeline, singleSelectPipeline, cellPropsPipeline, crossLinePipeline, zebraPipeline, reOrderColumnsPipeline, serialNumberPipeline, operateColumnPipeline, treeModePipeline, AsyncDragLine, useColumnsResize, getSelectedByPipeline, getFooterDataSource, useAttach, useSort, columnsFilterPipeline, copyText, resetColumnsWidthByTableWidthPipeline, useAutoWidth, headerCellPropsMergeStylePipeline, columnsTitlePipeline, } from '../pipelines';
import { normalizeColumns } from '../utils';
import { useTableWidthCalWhenScreenResize } from '../hooks';
import { stylegen, rowPropsgen, classNamegen, } from './config';
import './index.scss';
export { copyText };
function HighPerformanceTable(props) {
    var _a;
    const { primaryKey, dataSource, columns: inputColumns, rowSelection, 
    // 列拖拽
    columnResize, columnsAutoWidth = false, columnsAutoWidthOpt = {}, operateColumn, rowDetail, filter, showIndex, index, size = 'medium', footerRule, colAttach, onSort, sortCustom, treeConfig, tree = false, cellProps, className, crossline, crosslineConfig = {}, isZebra, zebraColor, tableRef, ...restProps } = props;
    const { midRef, totalWidthCalByColumnRef, tableWidth } = useTableWidthCalWhenScreenResize(tableRef);
    const sortStatus = useSort(sortCustom || {});
    const attachState = useAttach(inputColumns);
    const resize = useColumnsResize(props);
    const pipeline = useTablePipeline({})
        .input({
        dataSource,
        // 前置转换，请后代维护者关注一下
        columns: normalizeColumns(inputColumns),
    })
        .primaryKey(primaryKey);
    /** 添加列start */
    rowSelection &&
        pipeline.use((rowSelection.type === 'single'
            ? singleSelectPipeline
            : columnsMultiSelectPipeline)(rowSelection));
    (showIndex || index) && pipeline.use(serialNumberPipeline);
    operateColumn &&
        pipeline.use(operateColumnPipeline(operateColumn, resize === null || resize === void 0 ? void 0 : resize.calOpereateButtonItemWithNoRule));
    /**  添加列end */
    // 列顺序重置
    pipeline.use(reOrderColumnsPipeline);
    // 自动计算列
    useAutoWidth({
        ...props,
        allColumns: pipeline.getColumns(),
        resize,
    });
    resize && pipeline.use(columnsResizePipeline(resize));
    /** 列操作start */
    (sortCustom || onSort) &&
        pipeline.use(columnsSortPipeline(onSort, sortStatus, sortCustom || {}));
    filter && pipeline.use(columnsFilterPipeline(filter));
    colAttach && pipeline.use(rowAttachPipeline(colAttach, attachState));
    /** 列操作end */
    /** 行设置start */
    rowDetail && pipeline.use(rowDetailsPipeLine(rowDetail));
    isZebra && pipeline.use(zebraPipeline(zebraColor));
    (treeConfig || tree) && pipeline.use(treeModePipeline(treeConfig || {}));
    /** 行设置end */
    // 定制单元格能力
    crossline && pipeline.use(crossLinePipeline(crosslineConfig));
    // 放这里就是为了将优先级调整到最高
    cellProps instanceof Function && pipeline.use(cellPropsPipeline(cellProps));
    // 重新计算应有宽度
    pipeline.use(resetColumnsWidthByTableWidthPipeline(tableWidth, totalWidthCalByColumnRef, midRef));
    // headerCellProps style 合并
    pipeline.use(headerCellPropsMergeStylePipeline());
    // title 提示信息 tooltip
    pipeline.use(columnsTitlePipeline());
    const selected = getSelectedByPipeline(pipeline);
    const footerDataSource = (_a = props === null || props === void 0 ? void 0 : props.footerDataSource) !== null && _a !== void 0 ? _a : getFooterDataSource(pipeline, footerRule);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseTable, { "data-name": "CnBaseTable", ...restProps, footerDataSource: footerDataSource, ref: midRef, className: classNamegen(props), style: stylegen(props), ...pipeline.getProps(), getRowProps: rowPropsgen(props, selected) }),
        React.createElement(AsyncDragLine, { resize: resize, tableRef: midRef })));
}
export default HighPerformanceTable;
