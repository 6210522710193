import React, { Fragment, useEffect, useState } from 'react';
import { Pagination } from '@fusion/lib';
import { PaginationContext } from './pagination-context';
import isFunction from 'lodash/isFunction';
export const CnArrayTablePagination = ({ children, dataSource, ...props }) => {
    const [current, setCurrent] = useState(1);
    const prefixCls = 'cn-ui-array-table';
    const pageSize = props.pageSize || 10;
    const size = props.size || 'medium';
    const sources = dataSource || [];
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize - 1;
    const total = (sources === null || sources === void 0 ? void 0 : sources.length) || 0;
    const totalPage = Math.ceil(total / pageSize);
    const handleChange = (newCurrent) => {
        setCurrent(newCurrent);
        if (isFunction(props.onChange)) {
            props.onChange(newCurrent);
        }
    };
    useEffect(() => {
        if (totalPage > 0 && totalPage < current) {
            handleChange(totalPage);
        }
    }, [totalPage, current]);
    const renderPagination = () => {
        if (totalPage <= 1)
            return;
        return (React.createElement("div", { className: `${prefixCls}-pagination` },
            React.createElement(Pagination, { ...props, pageSize: pageSize, current: current, total: dataSource.length, size: size, pageSizeSelector: false, onChange: handleChange })));
    };
    return (React.createElement(Fragment, null,
        React.createElement(PaginationContext.Provider, { value: { totalPage, pageSize, changePage: handleChange } }, children === null || children === void 0 ? void 0 : children(dataSource === null || dataSource === void 0 ? void 0 : dataSource.slice(startIndex, endIndex + 1), renderPagination()))));
};
