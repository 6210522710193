// import $i18n from 'panda-i18n';
// import { Message } from '@/components/fusion';
import request from './request';
export const getSubDeptByKey = (params) => {
    const { mokelay, host = '', deptNo } = params;
    const result = mokelay
        ? request({
            url: `${host}/cmdp/hysmab/getSubDepartmentInfo`,
            headers: {
                mokelay: true,
            },
            method: 'post',
            withCredentials: true,
            data: {
                departmentCode: deptNo || '',
                isCommonRequest: true,
            },
        })
        : request({
            url: `${host}/cn-common-proxy/post`,
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/getSubDepartmentInfo',
                params: { departmentCode: deptNo || '', isCommonRequest: true },
            },
        });
    return result.then((res) => {
        var _a, _b;
        const resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        return resultData
            .filter((item) => (item === null || item === void 0 ? void 0 : item.enabled) !== false)
            .map((item) => ({
            ...item,
            label: item.name,
            value: item.code,
            deptName: item.name,
            deptNo: item.code,
            departmentFullName: item.fullName || item.name,
            // fullName: item.fullName || item.name,
            fullName: `${item.fullName || item.name}`.replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''),
            isLeaf: !item.hasSubDepartment,
        }));
    });
};
export const getDeptByKey = (params) => {
    const { mokelay, host = '' } = params;
    let deptNo = '';
    if (Array.isArray(params.deptNo)) {
        deptNo = params.deptNo.join(',');
    }
    else {
        deptNo = params.deptNo;
    }
    const result = mokelay
        ? request({
            url: `${host}/cmdp/hysmab/getDepartmentInfo`,
            headers: {
                mokelay: true,
            },
            withCredentials: true,
            method: 'POST',
            data: {
                departmentCode: deptNo,
                isCommonRequest: true,
            },
        })
        : request({
            url: `${host}/cn-common-proxy/post`,
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/getDepartmentInfo',
                params: { departmentCode: deptNo, isCommonRequest: true },
            },
        });
    return result.then((res) => {
        var _a, _b;
        const resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        if (!Array.isArray(resultData))
            return [];
        return resultData
            .filter((item) => (item === null || item === void 0 ? void 0 : item.enabled) !== false)
            .map((item) => ({
            ...item,
            label: item.name,
            value: item.code,
            deptName: item.name,
            deptNo: item.code,
            departmentFullName: item.fullName || item.name,
            // fullName: item.fullName || item.name,
            fullName: `${item.fullName || item.name}`.replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''),
            isLeaf: !item.hasSubDepartment,
        }));
    });
};
export const getDepartmentsByKeyword = (params) => {
    const { searchKey = '', mokelay, host = '' } = params;
    const result = mokelay
        ? request({
            url: `${host}/cmdp/hysmab/searchDepartmentInfo`,
            headers: {
                mokelay: true,
            },
            method: 'POST',
            withCredentials: true,
            data: {
                searchKey,
                isCommonRequest: true,
            },
        })
        : request({
            url: `${host}/cn-common-proxy/post`,
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/searchDepartmentInfo',
                params: { searchKey, isCommonRequest: true },
            },
        });
    return result.then((res) => {
        var _a, _b;
        const resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        return resultData
            .filter((item) => (item === null || item === void 0 ? void 0 : item.enabled) !== false)
            .map((item) => ({
            ...item,
            label: item.name,
            value: item.code,
            deptName: item.name,
            deptNo: item.code,
            departmentFullName: item.fullName || item.name,
            fullName: `${item.fullName || item.name}`.replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''),
            isLeaf: !item.hasSubDepartment,
        }));
    });
};
