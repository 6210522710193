export const useShowDownload = (params) => {
    const { file, onDownload } = params;
    if (typeof params.showDownload === 'function') {
        return params.showDownload(file);
    }
    if (!params.showDownload)
        return false;
    const isUploading = ['idle', 'uploading'].includes(file.status);
    if (isUploading)
        return false;
    return Boolean(file.downloadUrl || file.url || onDownload);
};
