import isPlainObject from 'lodash/isPlainObject';
export default (value, dataSource) => {
    const getSelected = () => {
        if (isPlainObject(value)) {
            return {
                label: value.label || value.value,
                value: value.value,
            };
        }
        return {
            label: value,
            value,
        };
    };
    const getLabel = (target) => {
        var _a;
        return (((_a = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find((item) => item.value === (target === null || target === void 0 ? void 0 : target.value))) === null || _a === void 0 ? void 0 : _a.label) ||
            target.label);
    };
    return getLabel(getSelected());
};
