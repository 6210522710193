// 第三方依赖
import React, { useContext } from 'react';
import $i18n from 'panda-i18n';
// 当前目录的相对依赖
import { ColumnItem } from './column-item';
import { leftFixMaxNum } from './const';
import { ColumnsStateContext } from './context';
/**
 * @description 固定在左侧的列
 * @function ColumnsFixLeft
 * @returns {JSX.Element} 返回固定在左侧的列
 */
export default function ColumnsFixLeft() {
    const { isDialog, fixLeftColumns } = useContext(ColumnsStateContext);
    return (React.createElement(React.Fragment, null,
        isDialog || fixLeftColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title cn-table-column-setting-wrapper-title-left" }, $i18n.get({
            id: 'FixedOnTheLeftDatasourcelethleftFixMaxNum',
            dm: '固定在左侧({dataSourceLength}/{leftFixMaxNum})',
            ns: 'CnTable',
        }, {
            dataSourceLength: fixLeftColumns.length,
            leftFixMaxNum,
        }))) : null,
        fixLeftColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, fixLeftColumns.map((dataItem, index) => (React.createElement(ColumnItem, { key: dataItem.dataIndex, dataItem: dataItem, index: index, position: "left" }))))) : null));
}
