import $i18n from 'panda-i18n'; /* eslint-disable */
import * as React from 'react';
import CnListFilter from '../../cn-list-filter';
import classnames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { safeCallFunction } from '@/components/cn-utils';
import { tablefilterArmsReport } from '@/components/cn-table/view/arms';
import { isDef, isFunction, isPlainObject, makeRecursiveMapper, } from '../utils';
import { FilterIcon, InlineFlexCell, DropDown2 } from '../dep';
import { CnButton } from '@/components/cn-button';
function FilterComponent(props) {
    const { list, labelKey, valueKey, onChange, popContainerStyle, columns } = props;
    // const allValue = list.map((item) => item && item[labelKey]);
    // const handleEmptyList=list.filter((item,index)=>);
    const { handleEmptyList } = list.reduce((pre, cur) => {
        if (cur[labelKey]) {
            pre.handleEmptyList.push(cur);
        }
        else if (isEmpty(cur[labelKey]) && pre.first) {
            pre.handleEmptyList.push(cur);
            pre.first = false;
        }
        return pre;
    }, { handleEmptyList: [], first: true });
    const noTreeList = handleEmptyList === null || handleEmptyList === void 0 ? void 0 : handleEmptyList.map((item) => {
        delete item.children;
        return item;
    });
    // const allValue = noTreeList?.map((item) => item && item[labelKey]);
    // const defaultValue = props?.value?.length === 0 ? allValue : props.value;
    const [value, setValue] = React.useState(props.value);
    return (React.createElement(CnListFilter, { list: noTreeList, value: value, column: columns, style: { padding: '12px 2px', ...(popContainerStyle || {}) }, searchStyle: { margin: '0px 14px', width: 'calc(100% - 28px)' }, onChange: (val) => {
            isFunction(onChange) && onChange(val);
            setValue(val);
        }, labelKey: labelKey, valueKey: valueKey }));
}
function Filter(props) {
    var _a;
    const { dataSource: dataSourceProps, labelKey, valueKey, onChange, keysMap, primaryKey, clear, code, popContainerStyle, iconSize, columns, } = props;
    const selectKeys = React.useRef([]);
    const dataSource = uniqBy(dataSourceProps.map((item) => {
        if (isPlainObject(item[valueKey])) {
            return {
                ...item,
                [valueKey]: JSON.stringify(item[valueKey]),
            };
        }
        return item;
    }), valueKey);
    if (!isDef(labelKey)) {
        console.warn($i18n.get({
            id: 'FilterMissingLabelConfiguration',
            dm: 'filter， 缺少label配置',
            ns: 'CnBaseTable',
        }));
    }
    const onMidChange = (val) => {
        selectKeys.current = val;
    };
    const confirm = () => {
        isFunction(onChange) && onChange(selectKeys.current);
        DropDown2.hide();
    };
    const reset = () => {
        // isFunction(clear) && clear(selectKeys.current);
        DropDown2.hide();
    };
    // console.log(keysMap?.[code]?.length);
    return (React.createElement(FilterIcon, { size: iconSize, className: classnames('cnc-table-icon', {
            'cnc-table-icon-highlight': ((_a = keysMap === null || keysMap === void 0 ? void 0 : keysMap[code]) === null || _a === void 0 ? void 0 : _a.length) > 0,
        }), onClick: (event) => {
            // const mergedKeys = isPlainObject(keysMap)
            //   ? Object.keys(keysMap).reduce((ret, nextKey) => {
            //       const nextMergedKeys = keysMap[nextKey];
            var _a;
            //       if (ret.length === 0) {
            //         return nextMergedKeys ?? [];
            //       }
            //       return getMergeArr(ret, nextMergedKeys as any[]);
            //     }, [])
            //   : [];
            // const list = getFilterList(dataSource, mergedKeys, {
            //   labelKey,
            //   valueKey: primaryKey,
            //   transform: (item: Obj) => {
            //     return {
            //       ...item,
            //       value: item[primaryKey], // 设置好value
            //     };
            //   },
            //   isInclude: true,
            // });
            DropDown2.show(event, React.createElement(FilterComponent, { list: dataSource, value: isPlainObject(keysMap) ? (_a = keysMap[code]) !== null && _a !== void 0 ? _a : [] : [], columns: columns, onChange: onMidChange, labelKey: labelKey, valueKey: valueKey, popContainerStyle: popContainerStyle }), {
                Container: FilterContainer,
                footer: (React.createElement("div", { className: "cnc-table-filter-footer" },
                    React.createElement(CnButton, { type: "primary", onClick: confirm }, $i18n.get({ id: 'Confirm', dm: '确认', ns: 'CnBaseTable' })),
                    React.createElement(CnButton, { onClick: reset }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnBaseTable' })))),
            });
            tablefilterArmsReport();
        } }));
}
const selectKeysName = 'filterSelectKeys';
export default function columnsFilterPipeline(opt) {
    const { labelKey, popContainerStyle, iconSize = 16, onFilter, } = isPlainObject(opt)
        ? opt
        : { labelKey: undefined, popContainerStyle: {} };
    return function (pipeline) {
        const primaryKey = pipeline.ensurePrimaryKey('filter');
        if (!primaryKey) {
            return pipeline;
        }
        // 先简单考虑，不考虑嵌套的filter功能， 嵌套filter场景应用较少，后期有业务线需要使用再考虑
        const columns = pipeline.getColumns();
        const dataSource = pipeline.getDataSource();
        const keysMap = pipeline.getStateAtKey(selectKeysName);
        let allLen = 0;
        // const mergedKeys = isPlainObject(keysMap)
        //   ? Object.keys(keysMap).reduce((ret, nextKey) => {
        //       const nextMergedKeys = Array.isArray(keysMap[nextKey])
        //         ? keysMap[nextKey]
        //         : [];
        //       allLen += nextMergedKeys.length;
        //       if (ret.length !== 0 && nextMergedKeys.length !== 0) {
        //         return getMergeArr(ret, nextMergedKeys as any[]);
        //       }
        //       return ret.concat(nextMergedKeys);
        //     }, [])
        //   : [];
        function getFilterDataSource(dataSource, keysMap) {
            if (Object.keys(keysMap).length === 0) {
                return dataSource;
            }
            // keysMap;
            return Object.keys(keysMap).reduce((pre, cur) => {
                var _a;
                if (((_a = keysMap[cur]) === null || _a === void 0 ? void 0 : _a.length) === 0)
                    return pre;
                return pre.filter((item) => {
                    var _a, _b, _c;
                    const ItemValue = isPlainObject(item[cur])
                        ? JSON.stringify(item[cur])
                        : item[cur];
                    const keysMapHasEmpty = ((_b = (_a = keysMap[cur]) === null || _a === void 0 ? void 0 : _a.filter((item) => !item)) === null || _b === void 0 ? void 0 : _b.length) !== 0;
                    if (isEmpty(item[cur]) && keysMapHasEmpty) {
                        return true;
                    }
                    return (_c = keysMap[cur]) === null || _c === void 0 ? void 0 : _c.includes(ItemValue);
                });
            }, dataSource);
        }
        const filterDataSource = getFilterDataSource(dataSource, keysMap || {});
        const setColKeys = (code, val) => {
            const ret = keysMap !== null && keysMap !== void 0 ? keysMap : {};
            ret[code] = val !== null && val !== void 0 ? val : [];
            pipeline.setStateAtKey(selectKeysName, {
                ...ret,
            });
        };
        makeRecursiveMapper((col, range) => {
            var _a, _b, _c, _d, _e;
            if ((range === null || range === void 0 ? void 0 : range.isLeaf) && (col === null || col === void 0 ? void 0 : col.filter)) {
                const sourceTitle = (col === null || col === void 0 ? void 0 : col.title) || (col === null || col === void 0 ? void 0 : col.name);
                col.title = (React.createElement(InlineFlexCell, null,
                    sourceTitle,
                    React.createElement(Filter, { dataSource: dataSource, iconSize: iconSize, code: col.code, labelKey: (_c = (_b = (_a = col.filter) === null || _a === void 0 ? void 0 : _a.labelKey) !== null && _b !== void 0 ? _b : labelKey) !== null && _c !== void 0 ? _c : col.code, columns: col, popContainerStyle: (_e = (_d = col.filter) === null || _d === void 0 ? void 0 : _d.popContainerStyle) !== null && _e !== void 0 ? _e : popContainerStyle, keysMap: keysMap, valueKey: col.code, 
                        // valueKey={primaryKey}
                        clear: () => {
                            setColKeys(col.code, []);
                        }, onChange: (keys) => {
                            setColKeys(col.code, keys);
                        } })));
                if (col.width > 0) {
                    col.width += iconSize !== null && iconSize !== void 0 ? iconSize : 16;
                }
            }
            return col;
        })(columns);
        safeCallFunction(onFilter, keysMap, filterDataSource);
        return pipeline.columns(columns).dataSource(filterDataSource);
    };
}
function FilterContainer(props) {
    const { children, className, footer, ...rest } = props;
    const classNames = classnames(className, 'cn-table-filter-container');
    return (React.createElement("div", { className: classNames, ...rest },
        children,
        footer));
}
