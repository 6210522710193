import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CnCard } from '@/components/cn-card';
import { PageHeader } from './page-header';
import { useBreadcrumbDataSource } from './hooks/use-breadcrumb-data-source';
export function CnPageContent(props) {
    const { noCard, title, contentFill, children, embeddable, noPadding, flexDirection, action, cnBreadcrumbDataSource, cnBreadcrumbProps = {}, } = props;
    const isChildrenHasCard = useMemo(() => {
        var _a;
        if (noCard)
            return true;
        let types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map((item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            // @ts-ignore 节点类型无法被识别
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    }, [children, noCard]);
    const breadcrumbDataSource = useBreadcrumbDataSource({
        cnBreadcrumbDataSource,
        cnBreadcrumbProps,
    });
    return (React.createElement("div", { id: "cn-ui-page-scroll-container", className: classNames({
            'cn-ui-page-content-wrapper': true,
            'cn-ui-page-content-wrapper-no-padding': Boolean(noPadding),
            'cn-ui-page-flex-direction': flexDirection,
            'has-title': Boolean(title || (breadcrumbDataSource === null || breadcrumbDataSource === void 0 ? void 0 : breadcrumbDataSource.length)),
        }) },
        React.createElement(PageHeader, { title: title, action: action, cnBreadcrumbProps: {
                ...cnBreadcrumbProps,
                dataSource: breadcrumbDataSource,
            } }),
        React.createElement("div", { className: "cn-ui-page-content" }, isChildrenHasCard ? (children) : (React.createElement(CnCard, { flexDirection: flexDirection, className: classNames({
                'cn-ui-page-embeddable': Boolean(embeddable),
                'cn-ui-page-content-fill': contentFill,
            }) }, children)))));
}
