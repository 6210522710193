import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { CnIcon } from '@/components/cn-icon';
import { support, events, dom, obj } from '@fusion/lib/util';
import { CnProgress } from '@/components/cn-progress';
/** Step.Item */
class StepItem extends Component {
    constructor(props) {
        super(props);
        this.onClick = () => {
            const { index, disabled, readOnly, animation } = this.props;
            if (disabled || readOnly) {
                return false;
            }
            if (animation && this.stepNode) {
                dom.hasClass(this.stepNode, 'clicked')
                    ? dom.removeClass(this.stepNode, 'clicked')
                    : dom.addClass(this.stepNode, 'clicked');
            }
            this.props.onClick(index);
        };
        this.removeClickedCls = this.removeClickedCls.bind(this);
        this._refHandlerCreator = this._refHandlerCreator.bind(this);
        this.resize = this.resize.bind(this);
        this.ro = new ResizeObserver(() => {
            const { shape, direction, onResize } = this.props;
            if (!this.body || shape === 'arrow') {
                return;
            }
            if (direction === 'vertical' || direction === 'ver') {
                this.resize();
            }
            else {
                onResize && onResize();
            }
        });
    }
    componentDidMount() {
        const { shape, direction, labelPlacement, index, total, stretch } = this.props;
        this.body && this.ro.observe(ReactDOM.findDOMNode(this.body));
        if (shape === 'arrow') {
            return;
        }
        if (direction === 'vertical' || direction === 'ver') {
            this.resize();
            this.forceUpdate(); // 解决Step嵌套的情况下，嵌套节点宽度为0的问题
            this.eventHandler = events.on(window, 'resize', this.resize); // 调整垂直Step
        }
        else if ((direction === 'horizontal' || direction === 'hoz') &&
            (labelPlacement === 'horizontal' || labelPlacement === 'hoz') &&
            index !== total - 1) {
            // 调整横向Content
            this.adjustTail();
        }
        if (stretch && (direction === 'horizontal' || direction === 'hoz')) {
            this.adjustItemWidth();
        }
    }
    componentDidUpdate() {
        const { shape, direction, labelPlacement, index, total, rtl, stretch } = this.props;
        if (shape === 'arrow') {
            return;
        }
        const resetTailStyle = () => {
            dom.setStyle(this.tail, {
                width: '',
                top: '',
                height: '',
            });
        };
        if (direction === 'vertical' || direction === 'ver') {
            this.resize();
        }
        else if (direction === 'horizontal' || direction === 'hoz') {
            const pos = rtl ? 'right' : 'left';
            dom.setStyle(this.body, {
                width: '',
                [pos]: '',
            });
            if (shape === 'circle' &&
                (labelPlacement === 'horizontal' || labelPlacement === 'hoz') &&
                index !== total - 1) {
                // 调整横向Content
                this.adjustTail();
            }
            else {
                resetTailStyle();
            }
            if (stretch) {
                this.adjustItemWidth();
            }
        }
        else if (index !== total - 1) {
            resetTailStyle();
        }
    }
    componentWillUnmount() {
        this.eventHandler && this.eventHandler.off();
    }
    adjustItemWidth() {
        const { index, total, labelPlacement } = this.props;
        const lastNodeWidth = labelPlacement === 'horizontal' || labelPlacement === 'hoz'
            ? this.container.offsetWidth + this.body.offsetWidth
            : this.title.offsetWidth;
        const width = total - 1 !== index
            ? `calc((100% - ${lastNodeWidth}px)/${total - 1})`
            : 'auto';
        dom.setStyle(this.step, {
            width,
        });
    }
    adjustTail() {
        const width = this.container.offsetWidth + this.title.offsetWidth;
        dom.setStyle(this.tail, {
            width: `calc(100% - ${width}px)`,
            top: `${dom.getStyle(this.container, 'height') / 2}px`,
        });
    }
    resize() {
        const { direction } = this.props;
        if (direction === 'vertical' || direction === 'ver') {
            const stepWidth = dom.getStyle(this.step, 'width');
            const stepHozWhitespace = dom.getNodeHozWhitespace(this.step.parentNode);
            const stepBodyHozWhitespace = dom.getNodeHozWhitespace(this.body);
            const { rtl } = this.props;
            rtl
                ? (this.body.style.right = `${stepWidth}px`)
                : (this.body.style.left = `${stepWidth}px`);
            dom.setStyle(this.body, {
                width: dom.getStyle(this.step.parentNode.parentNode, 'width') -
                    stepWidth -
                    stepHozWhitespace -
                    stepBodyHozWhitespace,
            });
            dom.setStyle(this.tail, 'height', dom.getStyle(this.body, 'height') -
                dom.getStyle(this.container, 'height'));
        }
    }
    _getNode() {
        const { prefix, index, status, icon, shape, percent, itemRender } = this.props;
        let nodeElement = icon;
        if (shape === 'dot') {
            nodeElement = icon ? (React.createElement(CnIcon, { type: icon })) : (React.createElement("div", { className: `${prefix}step-item-node-dot` }, " "));
        }
        else if (shape === 'circle' && percent) {
            nodeElement = (React.createElement(CnProgress, { shape: "circle", percent: percent, className: `${prefix}step-item-progress` }));
        }
        else if (shape === 'circle' &&
            !!itemRender &&
            typeof itemRender === 'function') {
            nodeElement = null; // 如果是需要自定义节点，则不处理，返回空
        }
        else {
            nodeElement = (React.createElement("div", { className: `${prefix}step-item-node-circle` }, icon ? React.createElement(CnIcon, { type: icon }) : this._itemRender(index, status)));
        }
        return nodeElement;
    }
    getNode(args) {
        const { prefix, itemRender, index, status, title, content, shape } = this.props;
        const { others, stepCls, overlayCls } = args;
        const nodeElement = this._getNode();
        const containerStyle = (shape === 'dot' && { fontSize: 'initial' }) || {};
        let finalNodeElement = (React.createElement("div", { className: `${prefix}step-item-container`, style: containerStyle, ref: this._refHandlerCreator('container') },
            React.createElement("div", { className: `${prefix}step-item-node-placeholder`, onClick: this.onClick },
                React.createElement("div", { className: `${prefix}step-item-node`, ref: this._refHandlerCreator('stepNode'), onTransitionEnd: this.removeClickedCls }, nodeElement))));
        if (!nodeElement) {
            // 需要自定义子节点
            finalNodeElement = (React.createElement("div", { className: `${prefix}step-item-container`, style: containerStyle, ref: this._refHandlerCreator('container') },
                React.createElement("div", { className: `${prefix}step-item-node-placeholder`, onClick: this.onClick }, itemRender(index, status, title, content))));
        }
        if (shape !== 'arrow') {
            delete others.tabIndex;
            delete others['aria-current'];
        }
        return (React.createElement("li", { ...others, style: this.getStyle(), className: stepCls, ref: this._refHandlerCreator('step') },
            finalNodeElement,
            React.createElement("div", { className: `${prefix}step-item-body`, ref: this._refHandlerCreator('body'), tabIndex: this.props.tabIndex, "aria-current": this.props['aria-current'] },
                React.createElement("div", { className: `${prefix}step-item-title`, ref: this._refHandlerCreator('title') }, title),
                React.createElement("div", { className: `${prefix}step-item-content` }, content)),
            React.createElement("div", { className: `${prefix}step-item-tail`, ref: this._refHandlerCreator('tail') },
                React.createElement("div", { className: `${prefix}step-item-tail-underlay` },
                    React.createElement("div", { className: `${prefix}step-item-tail-overlay`, style: overlayCls })))));
    }
    getStyle() {
        const { parentWidth, parentHeight, direction, total, index, shape } = this.props;
        let width = 'auto';
        if (Number(parentWidth) && Number(parentHeight)) {
            if (!support.flex && shape === 'arrow') {
                width = Math.floor(parentWidth / total - parentHeight / 2 - parentHeight / 8);
            }
        }
        if (shape !== 'arrow' &&
            (direction === 'horizontal' || direction === 'hoz')) {
            width = total - 1 !== index ? `${Math.floor(100 / total)}%` : 'auto';
        }
        return {
            width: width,
        };
    }
    removeClickedCls() {
        const { animation } = this.props;
        if (animation && this.stepNode && dom.hasClass(this.stepNode, 'clicked')) {
            dom.removeClass(this.stepNode, 'clicked');
        }
    }
    // 节点的渲染方法
    _itemRender(index, status) {
        const { itemRender } = this.props;
        if (itemRender) {
            return itemRender(index, status);
        }
        return status === 'finish' ? React.createElement(CnIcon, { type: "complete" }) : index + 1;
    }
    _refHandlerCreator(refName) {
        const self = this;
        return function (ref) {
            self[refName] = ref;
        };
    }
    render() {
        // eslint-disable-next-line
        const { prefix, locale, className, status, title, icon, index, total, shape, content, direction, disabled, onClick, readOnly, animation, parentHeight, itemRender, parentWidth, labelPlacement, rtl, onResize, } = this.props;
        const others = obj.pickOthers(StepItem.propTypes, this.props);
        const stepCls = classNames({
            [`${prefix}step-item`]: true,
            [`${prefix}step-item-${status}`]: status,
            [`${prefix}step-item-first`]: index === 0,
            [`${prefix}step-item-last`]: index === total - 1,
            [`${prefix}step-item-disabled`]: disabled,
            [`${prefix}step-item-read-only`]: readOnly,
            [className]: className,
        });
        const overlayCls = status === 'finish' ? { width: '100%' } : null;
        const arrowElement = (React.createElement("li", { ...others, style: this.getStyle(), className: stepCls, onClick: this.onClick },
            React.createElement("div", { className: `${prefix}step-item-container` },
                React.createElement("div", { className: `${prefix}step-item-title` }, title))));
        const otherElement = this.getNode({ others, stepCls, overlayCls });
        return shape === 'arrow' ? arrowElement : otherElement;
    }
}
StepItem.propTypes = {
    prefix: PropTypes.string,
    rtl: PropTypes.bool,
    /**
     * 步骤的状态，如不传，会根据外层的 Step 的 current 属性生成，可选值为 `wait`, `process`, `finish`
     */
    status: PropTypes.oneOf(['wait', 'process', 'finish']),
    /**
     * 标题
     */
    title: PropTypes.node,
    direction: PropTypes.oneOf(['hoz', 'ver']),
    labelPlacement: PropTypes.oneOf(['hoz', 'ver']),
    shape: PropTypes.oneOf(['circle', 'arrow', 'dot']),
    /**
     * 图标
     */
    icon: PropTypes.string,
    /**
     * 内容填充, shape为 arrow 时无效
     */
    content: PropTypes.node,
    /**
     * StepItem 的自定义渲染, 会覆盖父节点设置的itemRender
     * @param {Number} index   节点索引
     * @param {String} status  节点状态
     * @returns {Node} 节点的渲染结果
     */
    itemRender: PropTypes.func,
    /**
     * 百分比
     */
    percent: PropTypes.number,
    index: PropTypes.number,
    total: PropTypes.number,
    animation: PropTypes.bool,
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    parentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 点击步骤时的回调
     * @param {Number} index 节点索引
     */
    onClick: PropTypes.func,
    /**
     * 自定义样式
     */
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    onResize: PropTypes.func,
    stretch: PropTypes.bool,
};
StepItem.defaultProps = {
    shape: 'circle',
    index: 0,
    total: 1,
    onClick: () => { },
    stretch: false,
};
export default StepItem;
