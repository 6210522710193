import React from 'react';
import cx from 'classnames';
import { Overlay } from '@fusion/lib';
import { UploadListItem } from '../upload-list-item';
import { StaticFileIcon } from '../upload-list-item/icons';
import { CnTag } from '@/components/cn-tag';
import './index.scss';
export function MiniUploadList(props) {
    const { popupProps, value = [], readOnly, showRemove = true, onRemove, showDownload, onDownload, showPreview, onPreview, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = props;
    if (readOnly && !value.length)
        return null;
    return (React.createElement(Overlay.Popup, { trigger: React.createElement("div", { className: "cn-ui-upload-list-table-trigger" },
            value.slice(0, 3).map((item) => (React.createElement(StaticFileIcon, { key: item.uid || item.name || item.url, file: item, className: "table-inline-icon" }))),
            value.length > 3 ? (React.createElement(CnTag, { key: "item-tag", size: "small", type: "primary", className: "table-inline-more" },
                value.length - 3,
                " +")) : null), className: "cn-ui-upload-list-table-popup", ...popupProps },
        React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-mini') }, value.map((item) => (React.createElement(UploadListItem, { key: item.uid || item.name || item.url, file: item, showDownload: showDownload, onDownload: onDownload, showPreview: showPreview, onPreview: onPreview, showRemove: !readOnly && showRemove, onRemove: onRemove, readOnly: readOnly, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig }))))));
}
