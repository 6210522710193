import * as React from 'react';
import cx from 'classnames';
import $i18n from 'panda-i18n';
import pickBy from 'lodash/pickBy';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { isVoidField } from '@formily/core';
import { CnFormItem } from '@/components/cn-form-item';
import { connect, mapProps, observer, useField } from '@formily/react';
import { useItemCollection } from '../filter-selected-tags';
import { getNormalizedDisplayName, getItemStyleInjectProps, getValueFormatterInjectProps, } from '../../utils';
import { useFormLayout } from '@/form/cn-form-layout';
import { useFilterRefsContext } from '../../context';
const useFormItemLayout = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const layout = useFormLayout();
    const labelAlign = (_a = props.labelAlign) !== null && _a !== void 0 ? _a : layout.labelAlign;
    const labelCol = (_b = props.labelCol) !== null && _b !== void 0 ? _b : layout.labelCol;
    return {
        ...props,
        labelAlign,
        labelCol: labelAlign === 'left' ? (labelCol !== null && labelCol !== void 0 ? labelCol : { fixedSpan: 5 }) : undefined,
        wrapperCol: ((_c = props.wrapperCol) !== null && _c !== void 0 ? _c : layout.wrapperCol),
        size: ((_d = props.size) !== null && _d !== void 0 ? _d : layout.size),
        asterisk: props.asterisk,
        labelTextAlign: (_f = (_e = props.labelTextAlign) !== null && _e !== void 0 ? _e : layout.labelTextAlign) !== null && _f !== void 0 ? _f : (labelAlign === 'left' ? 'right' : 'left'),
        removeEmptyLabel: (_g = props.removeEmptyLabel) !== null && _g !== void 0 ? _g : layout.removeEmptyLabel,
    };
};
export const CnFilterProItem = connect(observer((props, ref) => {
    const { children, renderSelected, className, title, tip, _shouldInsertProps = true, ...rest } = props;
    const field = useField();
    const formLayout = useFormItemLayout(rest);
    const filterContext = useFilterRefsContext();
    const { setCollection, getCollectionInjectPorps } = useItemCollection({
        renderSelected,
        label: title,
    });
    let existName = false;
    const renderChildren = React.Children.map(children, (child, idx) => {
        const childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        const style = {
            width: '100%',
            ...childProps === null || childProps === void 0 ? void 0 : childProps.style,
        };
        const initName = field === null || field === void 0 ? void 0 : field.path.toString();
        if (_shouldInsertProps && initName && !existName) {
            existName = true;
            const displayName = getNormalizedDisplayName(child === null || child === void 0 ? void 0 : child.type);
            setCollection(initName, displayName);
            const collectionInjectProps = getCollectionInjectPorps(displayName);
            const valueFromatterInjectProps = getValueFormatterInjectProps(displayName);
            const styleInjectProps = getItemStyleInjectProps(displayName, childProps);
            return React.cloneElement(child, pickBy({
                style,
                ...collectionInjectProps,
                ...valueFromatterInjectProps,
                ...styleInjectProps,
            }));
        }
        return React.cloneElement(child, {
            style,
        });
    });
    const renderTitle = () => {
        if ((formLayout === null || formLayout === void 0 ? void 0 : formLayout.removeEmptyLabel) && !title) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "label-text" },
                React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, title)),
            tip && (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { outerClassName: "cn-ui-filter-pro-item-label-help-icon", type: "help-color", size: 14 }), align: "t" }, tip))));
    };
    return (React.createElement(CnFormItem, { className: cx('cn-ui-filter-pro-item', !formLayout.labelAlign || formLayout.labelAlign === 'top' ? 'label-top' : '', className), ...formLayout, title: renderTitle() }, renderChildren));
}), mapProps((props, field) => {
    if (isVoidField(field)) {
        return {
            readOnly: field.readOnly,
            title: field.title || props.title,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    const takeFeedbackStatus = () => {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    // 获取错误信息
    const takeMessage = () => {
        const split = (messages) => {
            return messages.reduce((buf, text) => {
                if (!text)
                    return buf;
                return buf.concat(text);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                const requiredErrorsIndex = field.selfErrors.indexOf($i18n.get({
                    id: 'ThisFieldIsRequired',
                    dm: '该字段是必填字段',
                    ns: 'CnForm',
                }));
                if (requiredErrorsIndex > -1) {
                    field.selfErrors.splice(requiredErrorsIndex, 1, props.requiredMessage);
                }
            }
            return split(field.selfErrors);
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    // 获取是否有 required mark
    const takeAsterisk = () => {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        title: props.title || field.title,
        validateState: takeFeedbackStatus(),
        errors: takeMessage(),
        help: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
        readOnly: field.readOnly,
    };
}));
