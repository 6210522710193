export default function dfs(list, handlerCallback) {
    Array.isArray(list) &&
        list.forEach((item) => {
            var _a;
            handlerCallback instanceof Function && handlerCallback(item);
            if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dfs(item === null || item === void 0 ? void 0 : item.children, handlerCallback);
            }
        });
}
