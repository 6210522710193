import * as React from 'react';
import classnames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
function SortIcon(props) {
    const { size = 32, style, className, order, ...rest } = props;
    return (React.createElement("div", { className: classnames('cn-table-sort-icon', className), ...rest },
        order === 'none' && React.createElement(CnIcon, { type: "triangle-sort", size: "small" }),
        order === 'asc' && (React.createElement(CnIcon, { type: "icon-triangle-sort-descending", size: "small" })),
        order === 'desc' && (React.createElement(CnIcon, { type: "icon-triangle-sort-ascending", size: "small" }))));
}
export default function SortHeaderCell(props) {
    const { column, sortOrder, onToggle, children } = props;
    const justifyContent = column.align === 'right'
        ? 'flex-end'
        : column.align === 'center'
            ? 'center'
            : 'flex-start';
    return (React.createElement("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent,
        } },
        children,
        React.createElement(SortIcon, { style: { marginLeft: 2, flexShrink: 0 }, size: 16, order: sortOrder, onClick: onToggle })));
}
