import $i18n from 'panda-i18n';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import flattenDeep from 'lodash/flattenDeep';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
// import { CascaderSelect } from '@/components/fusion';
import { CnCascaderSelect as CascaderSelect, } from '@/components/cn-cascader-select';
// import { CnCascaderSelectProps as CascaderSelectProps } from '@/components/cn-cascader-select';
// import { CascaderSelectProps } from '@fusion/types/cascader-select';
import { getSubDeptCacheByKey } from './request-cache';
import { getDepartmentsByKeyword } from './service';
import DepartmentValueItem from './department-value-item';
import { useSizeChange } from '@/components/cn-utils';
const DepartmentSelect = (props) => {
    const { readOnly, dataSource, style, mokelay = true, host, popupContainer, itemPropsRef, ...otherProps } = props;
    const timer = React.useRef();
    const [searchValue, onSearchValueChange] = React.useState('');
    const [departmentList, setDepartmentList] = React.useState([]);
    const [departmentSearchResult, setDepartmentSearchResult] = React.useState([]);
    if (itemPropsRef) {
        itemPropsRef.current = {
            renderSelected: (key, value, itemLabel) => {
                if (Array.isArray(value)) {
                    return (React.createElement("div", { style: { display: 'flex', width: 200 } },
                        itemLabel,
                        ":",
                        value.map((valueItem) => {
                            return (React.createElement(DepartmentValueItem, { style: { width: 100 }, item: { value: valueItem }, host: host, mokelay: mokelay }));
                        })));
                }
                return (React.createElement("div", { style: { display: 'flex', width: 200, overflow: 'hidden' } },
                    itemLabel,
                    ":",
                    React.createElement(DepartmentValueItem, { style: { width: 100 }, item: { value }, host: host, mokelay: mokelay }),
                    ";"));
            },
        };
    }
    // 初始数据
    React.useEffect(() => {
        async function init() {
            const initialData = await getSubDeptCacheByKey({
                deptNo: '22187',
                mokelay,
                host,
            });
            setDepartmentList(initialData);
        }
        init();
    }, []);
    const getServiceAndSetMap = (requestData) => {
        const clonedepartmentList = cloneDeep(departmentList);
        const getTail = (item) => {
            if ((item === null || item === void 0 ? void 0 : item.children) && (item === null || item === void 0 ? void 0 : item.children.length) > 0) {
                return item === null || item === void 0 ? void 0 : item.children.map((m) => getTail(m));
            }
            else {
                return [item];
            }
        };
        const childrenDepartment = find(flattenDeep(clonedepartmentList.map((m) => getTail(m))), { code: requestData.code });
        return getSubDeptCacheByKey({
            deptNo: requestData.deptNo,
            mokelay,
            host,
        }).then((resData) => {
            childrenDepartment.children = resData;
            setDepartmentList(clonedepartmentList);
        });
    };
    const onSearch = (newSearchValue) => {
        onSearchValueChange(newSearchValue);
        if (!isNil(newSearchValue)) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                getDepartmentsByKeyword({
                    host,
                    searchKey: newSearchValue,
                    mokelay,
                }).then((departmentList) => {
                    setDepartmentSearchResult(departmentList);
                });
            }, 300);
        }
    };
    const valueRender = (item) => {
        return React.createElement(DepartmentValueItem, { item: item, mokelay: mokelay, host: host });
    };
    const resultRender = (searchValue, path) => {
        const parts = [];
        path === null || path === void 0 ? void 0 : path.forEach((item, i) => {
            var _a, _b;
            const reExp = searchValue.replace(/[-.+*?^$()[\]{}|\\]/g, (v) => `\\${v}`);
            const re = new RegExp(reExp, 'gi');
            const others = (_a = item === null || item === void 0 ? void 0 : item.fullName) === null || _a === void 0 ? void 0 : _a.split(re);
            const matches = (_b = item === null || item === void 0 ? void 0 : item.fullName) === null || _b === void 0 ? void 0 : _b.match(re);
            others === null || others === void 0 ? void 0 : others.forEach((other, j) => {
                if (other) {
                    parts.push(other);
                }
                if (j < others.length - 1) {
                    parts.push(React.createElement("em", { key: `${i}-${j}` }, matches[j]));
                }
            });
            if (i < path.length - 1) {
                parts.push(' / ');
            }
        });
        return React.createElement("span", null, parts);
    };
    const { size } = useSizeChange(props);
    const selectProps = {
        placeholder: $i18n.get({
            id: 'PleaseSelectADepartment',
            dm: '请选择部门',
            ns: 'CnDepartmentSelect',
        }),
        dataSource: [...departmentSearchResult, ...departmentList],
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        loadData: getServiceAndSetMap,
        isPreview: readOnly,
        searchValue,
        style: { width: '100%', ...style },
        onSearch,
        changeOnSelect: true,
        onSearchClear: () => onSearch(''),
        resultRender,
        resultAutoWidth: false,
        valueRender,
        popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body),
        ...otherProps,
        size,
    };
    return React.createElement(CascaderSelect, { ...selectProps });
};
export default DepartmentSelect;
