import React, { useState, useMemo, useCallback, useRef, useEffect, cloneElement, useContext, } from 'react';
import { ResponsiveGrid, Button } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import classNames from 'classnames';
import { useSize } from 'ahooks';
import FilterItem from './filter-item';
import { CnIcon } from '@/components/cn-icon';
import map from 'lodash/map';
import { sendLog } from '@/components/cn-utils';
import FilterContext from './filter-context';
const { Cell } = ResponsiveGrid;
const isInvisible = (el) => { var _a, _b; return ((_b = (_a = el === null || el === void 0 ? void 0 : el.props) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.display) === 'none'; };
/**
 * 将Filter的children节点，最多保留maxRow行
 */
const calcLayout = (maxRow, columns, children) => {
    var _a;
    let idx = 0;
    let currentGroupSpan = 0;
    let groupCnt = 0;
    const showChildren = [];
    while (idx < children.length) {
        const child = children[idx];
        if (isInvisible(child)) {
            idx += 1;
            continue;
        }
        const span = Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.colSpan) || 1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(child);
        idx += 1;
    }
    // // btn前置空白
    // const spaceSpan = columns - currentGroupSpan - btnSpan;
    // if (spaceSpan < 0) {
    //   // 此行已放不下btn，需要换行
    //   ++groupCnt;
    //   showChildren.push(<Cell key="space-cell" colSpan={columns - btnSpan} />);
    // } else {
    //   if (spaceSpan > 0) {
    //     showChildren.push(<Cell key="space-cell" colSpan={spaceSpan} />);
    //   }
    //   ++groupCnt;
    // }
    const hideChildren = children.slice(idx);
    return {
        showChildren,
        hideChildren,
    };
};
const calcLayoutWithButton = (maxRow, columns, buttonSpan, children) => {
    var _a;
    let idx = 0;
    let currentGroupSpan = 0;
    let groupCnt = 0;
    const btnSpan = Math.min(buttonSpan, columns);
    const showChildren = [];
    while (idx < children.length) {
        const child = children[idx];
        if (isInvisible(child)) {
            idx += 1;
            continue;
        }
        const span = Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.colSpan) || 1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span + btnSpan > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(child);
        idx += 1;
    }
    // btn前置空白
    const spaceSpan = columns - currentGroupSpan - btnSpan;
    if (spaceSpan < 0) {
        // 此行已放不下btn，需要换行
        ++groupCnt;
        showChildren.push(React.createElement(Cell, { key: "space-cell", colSpan: columns - btnSpan }));
    }
    else {
        if (spaceSpan > 0) {
            showChildren.push(React.createElement(Cell, { key: "space-cell", colSpan: spaceSpan }));
        }
        ++groupCnt;
    }
    const hideChildren = children.slice(idx);
    return {
        showChildren,
        hideChildren,
    };
};
const renderExpandButton = ({ size, expand, onClick, ref, $i18n, iconOnly = true, showButton, enableConfig, renderConfigButton = () => null, }) => {
    let expandButton = (React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { size: size, onClick: onClick, className: classNames('cn-ui-filter-icon-btn', `cn-ui-filter-icon-btn-${size}`) },
            React.createElement("span", { ref: ref },
                React.createElement(CnIcon, { size: size, type: expand ? 'arrow-up' : 'arrow-down' }))) }, expand
        ? $i18n.get({ id: 'hide', dm: '折叠' })
        : $i18n.get({ id: 'more', dm: '展开' })));
    if (!iconOnly) {
        expandButton = (React.createElement(CnButton, { size: size, 
            // text
            onClick: onClick, className: classNames(
            // 'cn-ui-filter-icon-btn',
            // `cn-ui-filter-icon-btn-${size}`,
            'cn-ui-filter-expand-btn-with-text') },
            React.createElement("span", { ref: ref, className: "cn-ui-filter-expand-btn-with-text-content" },
                React.createElement(CnIcon, { size: size, className: "cn-ui-filter-expand-btn-with-text-icon", type: expand ? 'arrow-up' : 'arrow-down' }),
                expand
                    ? $i18n.get({ id: 'hide', dm: '更多' })
                    : $i18n.get({ id: 'more', dm: '更多' }))));
    }
    const configButton = renderConfigButton();
    if (showButton && enableConfig) {
        return (React.createElement(Button.Group, { className: "cn-ui-filter-btn-group", size: size },
            expandButton,
            configButton));
    }
    if (showButton) {
        return expandButton;
    }
    if (enableConfig) {
        return configButton;
    }
    return null;
};
const AutoColumnLayout = (props) => {
    const { size, mode, columns, maxVisibleRow, columnGap, rowGap, buttons, extendButtons, extendButtonsPosition, isRightButton, buttonSpan = 1, hideButton, enableExpand = true, defaultExpand, enableConfig = false, renderConfigButton, expand: propsExpand, onExpandChange, children, responsiveSize, onColumnChange, expandButtonUseIcon, labelAlign, $i18n, ...rest } = props;
    const overlayRef = useRef(null);
    const resizeRef = useRef(null);
    const [expand, setExpand] = useState(defaultExpand || false);
    const filterContext = useContext(FilterContext);
    useEffect(() => {
        if (propsExpand !== undefined && propsExpand !== expand) {
            setExpand(propsExpand);
        }
    }, [expand, propsExpand]);
    const { width } = useSize(resizeRef) || {};
    const _columnGap = (typeof columnGap === 'function' ? columnGap(size) : columnGap) || 16;
    const _rowGap = (typeof rowGap === 'function' ? rowGap(size) : rowGap) || 12;
    const innerColunms = useMemo(() => {
        if (columns)
            return columns;
        const _responsive = responsiveSize;
        let matched = responsiveSize.length; // 默认8个
        if (!width)
            return matched;
        _responsive.some((v, idx) => {
            if (width < v) {
                matched = idx;
                return true;
            }
            return false;
        });
        const minCols = labelAlign === 'left' ? 2 : 3;
        // 最小值3
        return matched > minCols ? matched : minCols;
    }, [columns, responsiveSize, width, labelAlign]);
    useEffect(() => {
        onColumnChange && onColumnChange(Number(innerColunms));
    }, [innerColunms, onColumnChange]);
    const handleToggleMore = useCallback(() => {
        sendLog({
            id: 'cn-ui.cn-filter.toggleExpand',
            name: 'CnFilter更多查询项点击',
        });
        onExpandChange && onExpandChange(!expand);
        if (propsExpand === undefined) {
            setExpand(!expand);
        }
    }, [propsExpand, expand, onExpandChange]);
    useEffect(() => {
        // 展开时需要刷新select从而重新计算宽度
        if (expand && (filterContext === null || filterContext === void 0 ? void 0 : filterContext.itemCollection)) {
            map(Object.keys(filterContext.itemCollection), (i) => {
                var _a, _b, _c, _d;
                if ([
                    'CnAsyncSelect',
                    'Select',
                    'CnEmployeeSelect',
                    'CnDepartmentSelect',
                    'CnTreeSelect',
                ].indexOf(filterContext.itemCollection[i].displayName) > -1) {
                    (_d = (_c = (_b = (_a = filterContext.itemCollection[i]) === null || _a === void 0 ? void 0 : _a.defaultChildRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.forceUpdate) === null || _d === void 0 ? void 0 : _d.call(_c);
                }
            });
        }
    }, [expand]);
    // const handleHideMore = useCallback(() => {
    //   onExpandChange && onExpandChange(false);
    //   if (propsExpand === undefined) {
    //     setExpand(false);
    //   }
    // }, [propsExpand, onExpandChange]);
    const normalizedChildren = useMemo(() => React.Children.map(children, (_child, idx) => {
        var _a, _b, _c;
        const child = _child;
        const span = Math.min(Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.span) || 1, Number(innerColunms));
        let style = isInvisible(child) ? { display: 'none' } : undefined;
        if (((_b = child === null || child === void 0 ? void 0 : child.props) === null || _b === void 0 ? void 0 : _b.display) === 'none') {
            style = { display: 'none' };
        }
        else if (((_c = child === null || child === void 0 ? void 0 : child.props) === null || _c === void 0 ? void 0 : _c.display) === 'hidden') {
            style = { visibility: 'hidden' };
        }
        return (React.createElement(Cell, { key: idx, colSpan: span, style: style }, child));
    }), [children, innerColunms]);
    const showAll = !enableExpand || (mode === 'expand' && expand);
    const { showChildren, hideChildren } = useMemo(() => isRightButton
        ? calcLayoutWithButton(showAll ? normalizedChildren.length : Number(maxVisibleRow) || 2, Number(innerColunms), Number(buttonSpan) || 1, normalizedChildren)
        : calcLayout(showAll ? normalizedChildren.length : Number(maxVisibleRow) || 2, Number(innerColunms), normalizedChildren), [showAll, innerColunms, maxVisibleRow, normalizedChildren]);
    const showButton = enableExpand &&
        (showAll ||
            (hideChildren &&
                hideChildren.filter((child) => !isInvisible(child)).length > 0));
    // console.log('show cn-cn-button', hideChildren, showChildren);
    const renderChildren = [
        ...showChildren,
        ...hideChildren.map((child) => cloneElement(child, { style: { display: 'none' } })),
    ];
    const renderButton = () => {
        if (hideButton) {
            return null;
        }
        return (React.createElement(FilterItem, { size: size, className: classNames('filter-item-btns', labelAlign === 'left' ? 'left-label' : '', isRightButton ? 'right-btn' : ''), label: React.createElement("div", null), labelAlign: labelAlign, wrapperCol: labelAlign === 'left'
                ? {
                    span: 24,
                }
                : undefined, ...rest },
            React.createElement("div", { className: "cn-ui-filter-btns" },
                extendButtonsPosition === 'left' ? extendButtons : undefined,
                buttons,
                extendButtonsPosition === 'right' ? extendButtons : undefined,
                renderExpandButton({
                    size,
                    expand,
                    onClick: handleToggleMore,
                    ref: overlayRef,
                    $i18n,
                    iconOnly: expandButtonUseIcon,
                    showButton,
                    enableConfig,
                    renderConfigButton,
                }),
                extendButtonsPosition === 'end' ? extendButtons : undefined)));
    };
    if (isRightButton) {
        return (React.createElement("div", { ref: resizeRef },
            React.createElement(ResponsiveGrid, { columns: innerColunms, gap: [Number(_rowGap), Number(_columnGap)] },
                renderChildren,
                React.createElement(Cell, { colSpan: Number(buttonSpan) || 1 }, renderButton()))));
    }
    return (React.createElement("div", { ref: resizeRef },
        React.createElement(ResponsiveGrid, { columns: innerColunms, gap: [Number(_rowGap), Number(_columnGap)] }, renderChildren),
        renderButton()));
};
export default AutoColumnLayout;
