import * as React from 'react';
import { TimePicker2 as NextTimePicker2 } from '@/components/fusion';
import omit from 'lodash/omit';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import compact from 'lodash/compact';
import { CnReadOnly } from '@/components/cn-read-only';
import { initDayjsLocale, useValueState } from '@/components/cn-utils';
import { formatValue, getValueFromDayjs } from '../cn-date-picker-pro/util';
import cx from 'classnames';
import './index.scss';
initDayjsLocale();
dayjs.extend(customParseFormat);
const { RangePicker } = NextTimePicker2;
const renderPreview = (value, readOnlyProps, format) => {
    if (value === undefined)
        return null;
    let showValue;
    if (Array.isArray(value)) {
        showValue = compact(value);
        showValue = showValue.map((i) => {
            const d = dayjs(i, format || 'HH:mm:ss');
            return d.isValid() ? d.format(format || 'HH:mm:ss') : '';
        });
    }
    else {
        const d = dayjs(value, format || 'HH:mm:ss');
        showValue = d.isValid() ? d.format(format || 'HH:mm:ss') : '';
    }
    return (React.createElement(CnReadOnly, { value: showValue, valueSeparator: " - ", type: "string", ...readOnlyProps }));
};
/**
 * fusion timepicker2.rangepicker对时间戳处理的问题
 */
function fixTimeRangeValue(value) {
    if (value && typeof value === 'number') {
        return dayjs(value);
    }
    return value;
}
function nextCompMap(Comp, displayName) {
    const Component = React.forwardRef((props, ref) => {
        const { readOnly, readOnlyProps, popupContainer, className, outputFormat, state, ...otherProps } = props;
        const [value, setValue, isControlled] = useValueState(props, undefined, {
            formatter: (v) => Array.isArray(v)
                ? v.map((i) => fixTimeRangeValue(i))
                : fixTimeRangeValue(v),
        });
        const onChange = (newValue, strVal) => {
            if (!isControlled) {
                setValue(newValue);
            }
            let v = getValueFromDayjs(newValue);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onChange && props.onChange(v, strVal);
        };
        const onOk = (newValue, strVal) => {
            if (!isControlled) {
                setValue(value);
            }
            let v = getValueFromDayjs(newValue);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onOk && props.onOk(v, strVal);
        };
        if (props.readOnly) {
            return renderPreview(value, readOnlyProps, props.format);
        }
        let coverProps = {
            value,
            onChange,
            onOk,
            disabledHours: (index) => {
                return (props.disabledHours && props.disabledHours(index, value));
            },
            disabledMinutes: (index) => {
                return (props.disabledMinutes && props.disabledMinutes(index, value));
            },
            disabledSeconds: (index) => {
                return (props.disabledSeconds && props.disabledSeconds(index, value));
            },
        };
        if (displayName === 'CnTimePicker2') {
            coverProps = omit(coverProps, 'onOk');
        }
        return (React.createElement(Comp, { "data-name": "CnTimePickerPro", className: cx({
                'cn-ui-time-picker-pro': true,
                [`cn-ui-time-picker-pro-${state}`]: true,
            }, className), ref: ref, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), state: state, ...otherProps, ...coverProps }));
    });
    Component.displayName = displayName;
    return Component;
}
/**
 * @deprecated
 * 使用 CnTimePickerPro
 */
export const CnTimePicker2 = nextCompMap(NextTimePicker2, 'CnTimePicker2');
/**
 * @deprecated
 * 使用 CnRangeTimePickerPro
 */
export const CnRangeTimePicker2 = nextCompMap(RangePicker, 'CnRangeTimePicker2');
export const CnTimePickerPro = CnTimePicker2;
export const CnRangeTimePickerPro = CnRangeTimePicker2;
