import * as React from 'react';
import { CnFormGrid as PureCnFormGrid } from '@/components/cn-form-grid';
import { observer, useFieldSchema, useField, RecursionField, } from '@formily/react';
export const useGrids = () => {
    const schema = useFieldSchema();
    // useField https://core.formilyjs.org/zh-CN/api/models/field
    const gridField = useField();
    const grids = [];
    schema.mapProperties((schemaItem, name) => {
        var _a, _b, _c, _d;
        // 获取 子组件 formPath。 formPath 操作 https://core.formilyjs.org/zh-CN/api/entry/form-path
        const childFormPath = gridField.address.concat(name);
        // 查询子组件 field。  query https://core.formilyjs.org/zh-CN/api/models/query#take
        const field = gridField.query(childFormPath).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' || (field === null || field === void 0 ? void 0 : field.display) === 'hidden')
            return;
        grids.push({
            name,
            props: {
                colSpan: (_b = (_a = schemaItem === null || schemaItem === void 0 ? void 0 : schemaItem['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : schemaItem.colSpan,
                rowSpan: (_d = (_c = schemaItem === null || schemaItem === void 0 ? void 0 : schemaItem['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.rowSpan) !== null && _d !== void 0 ? _d : schemaItem.rowSpan,
            },
            schema: schemaItem,
        });
    });
    return grids;
};
export const CnFormGrid = observer((props) => {
    const { children, ...otherProps } = props;
    const grids = useGrids();
    return (React.createElement(PureCnFormGrid, { "data-name": "CnFormGrid", ...otherProps }, grids.map(({ props: containerProps, schema, name }, key) => (React.createElement("div", { key: name, ...containerProps },
        React.createElement(RecursionField, { schema: schema, name: name }))))));
});
