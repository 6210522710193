import React, { createContext, useContext } from 'react';
import { useResponsiveFormLayout } from './useResponsiveFormLayout';
import { usePrefixCls } from '@/form/__builtins__/hooks/usePrefixCls';
import cls from 'classnames';
export const FormLayoutDeepContext = createContext(null);
export const FormLayoutShallowContext = createContext(null);
export const useFormDeepLayout = () => useContext(FormLayoutDeepContext);
export const useFormShallowLayout = () => useContext(FormLayoutShallowContext);
export const useFormLayout = () => ({
    ...useFormDeepLayout(),
    ...useFormShallowLayout(),
});
export const CnFormLayout = ({ shallow, children, prefix, className, style, ...otherProps }) => {
    const { ref, props } = useResponsiveFormLayout(otherProps);
    const deepLayout = useFormDeepLayout();
    const formPrefixCls = usePrefixCls('form', { prefix });
    const layoutPrefixCls = usePrefixCls('layout', { prefix });
    const layoutClassName = cls(layoutPrefixCls, {
        [`${formPrefixCls}-${props.layout}`]: props.layout,
        [`${formPrefixCls}-rtl`]: props.direction === 'rtl',
        [`${formPrefixCls}-${props.size}`]: props.size,
    }, className);
    const renderChildren = () => {
        const newDeepLayout = {
            ...deepLayout,
        };
        if (!shallow) {
            Object.assign(newDeepLayout, props);
        }
        else {
            if (props.size) {
                newDeepLayout.size = props.size;
            }
            if (props.colon) {
                newDeepLayout.colon = props.colon;
            }
        }
        return (React.createElement(FormLayoutDeepContext.Provider, { value: newDeepLayout },
            React.createElement(FormLayoutShallowContext.Provider, { value: shallow ? props : undefined }, children)));
    };
    return (React.createElement("div", { "data-name": "CnFormLayout", ref: ref, className: layoutClassName, style: style }, renderChildren()));
};
CnFormLayout.defaultProps = {
    shallow: true,
};
CnFormLayout.useFormDeepLayout = useFormDeepLayout;
CnFormLayout.useFormShallowLayout = useFormShallowLayout;
CnFormLayout.useFormLayout = useFormLayout;
