import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useField } from '@formily/react';
export function connect(target, ...args) {
    const Target = args.reduce((target, mapper) => {
        return mapper(target);
    }, target);
    const Destination = React.forwardRef((props, ref) => {
        const field = useField();
        return React.createElement(Target, {
            ...props,
            key: field.path.toString(),
            ref,
        });
    });
    if (target)
        hoistNonReactStatics(Destination, target);
    return Destination;
}
