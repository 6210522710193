/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/unbound-method */
import { isLeafNode, makeRecursiveMapper, mergeCellProps, } from '../../view/utils';
export default function crossLinePipeline(opts = {}) {
    const stateKey = 'columnHover';
    return (pipeline) => {
        var _a, _b, _c, _d;
        const hoverColor = (_a = opts.hoverColor) !== null && _a !== void 0 ? _a : 'var(--hover-bgcolor)';
        const hoverColIndex = (_d = (_c = (_b = opts.hoverColIndex) !== null && _b !== void 0 ? _b : pipeline.getStateAtKey(stateKey)) !== null && _c !== void 0 ? _c : opts.defaultHoverColIndex) !== null && _d !== void 0 ? _d : -1;
        const onChangeHoverColIndex = (nextColIndex) => {
            var _a;
            pipeline.setStateAtKey(stateKey, nextColIndex);
            (_a = opts.onChangeHoverColIndex) === null || _a === void 0 ? void 0 : _a.call(opts, nextColIndex);
        };
        return pipeline.mapColumns(makeRecursiveMapper((col, { startIndex, endIndex }) => {
            const range = { start: startIndex, end: endIndex };
            if (!isLeafNode(col)) {
                return col;
            }
            const colIndexMatched = range.start <= hoverColIndex && hoverColIndex < range.end;
            const prevGetCellProps = col.getCellProps;
            return {
                ...col,
                getCellProps(value, record, rowIndex) {
                    const prevCellProps = prevGetCellProps === null || prevGetCellProps === void 0 ? void 0 : prevGetCellProps(value, record, rowIndex);
                    // @ts-ignore
                    return mergeCellProps(prevCellProps, {
                        // 默认cell 的 background 都是 bgcolor，所以只需要改变当前的 bgcolor 就ok了。
                        style: {
                            '--bgcolor': colIndexMatched ? hoverColor : undefined,
                            '--row-bgcolor': colIndexMatched ? hoverColor : undefined, // 兼容老版本
                        },
                        onMouseEnter() {
                            onChangeHoverColIndex(range.start);
                        },
                        onMouseLeave() {
                            onChangeHoverColIndex(-1);
                        },
                    });
                },
            };
        }));
    };
}
