import $i18n, { pandaEvent, langToShortLang } from 'panda-i18n';
import loadDayjsLocale from '@/locales/dayjs';
import { datejs } from '@fusion/lib/util/index';
import once from 'lodash/once';
/** 注入dayjs的翻译文案 */
export const injectDayjs = once((dayjs, lang) => {
    loadDayjsLocale(dayjs);
    // 注入文案后，需要设置一次语种
    const currentShortLang = langToShortLang(lang || $i18n.getLang());
    dayjs.locale(currentShortLang);
    pandaEvent.on('CHANGE_LANG', (_lang) => {
        dayjs.locale(langToShortLang(_lang));
    });
    return dayjs;
});
/** 初始化cn-next提供的datejs的处理多语言文案 */
export const initDayjsLocale = () => {
    injectDayjs(datejs);
};
