/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ReactDOM from 'react-dom';
import * as React from 'react';
import styled from 'styled-components';
import { Balloon } from '@/components/fusion';
import { isFunction } from '../utils';
const DropDownContainer = styled.div `
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
`;
const DropDown = 'cn-table-drapdown';
const DropDownOperate = 'cn-table-drapdown-operate';
const getContainer = (containerType = 'drapdown') => {
    const DropDownId = containerType === 'drapdown' ? DropDown : DropDownOperate;
    let container = document.getElementById(DropDownId);
    if (!container) {
        container = document.createElement('div');
        container.id = DropDownId;
        document.body.append(container);
    }
    return container;
};
let lock = false;
const show = (event, content, opts) => {
    var _a, _b;
    if (!content) {
        return;
    }
    // lock = true;
    const container = getContainer(opts === null || opts === void 0 ? void 0 : opts.containerType);
    const { offset, props, accordTarget = true, triangleHeight = 10, zIndex = 100, footer, hasMask = true, transform, target, closeOnClick = true, onMaskClick, } = opts || {};
    const { clientX, clientY } = event;
    const targetDom = target !== null && target !== void 0 ? target : event === null || event === void 0 ? void 0 : event.target;
    // @ts-ignore
    const { height, left, top } = isFunction(targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect)
        ? targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect()
        : {};
    let posTop = 0;
    let posLeft = 0;
    const { innerHeight } = window;
    const isTopHalf = top < innerHeight / 2;
    const addVerOffset = (_a = offset === null || offset === void 0 ? void 0 : offset[0]) !== null && _a !== void 0 ? _a : 0;
    const addHozOffset = (_b = offset === null || offset === void 0 ? void 0 : offset[1]) !== null && _b !== void 0 ? _b : 0;
    posTop = isTopHalf ? top + height + triangleHeight : top - triangleHeight;
    posLeft = accordTarget ? left : clientX;
    // @ts-ignore
    const Container = (opts === null || opts === void 0 ? void 0 : opts.Container) || Balloon.Inner;
    const maskClick = () => {
        closeOnClick && hide(opts === null || opts === void 0 ? void 0 : opts.containerType);
        onMaskClick instanceof Function && onMaskClick();
    };
    ReactDOM.render(React.createElement(React.Fragment, null,
        React.createElement(Container, { onClick: (event) => {
                event.stopPropagation();
            }, closable: false, align: isTopHalf ? 'b' : 't', ...(props || {}), style: {
                position: 'fixed',
                top: isTopHalf ? posTop + addVerOffset : posTop - addVerOffset,
                left: posLeft + addHozOffset,
                zIndex,
                transform: isFunction(transform)
                    ? transform({
                        isTopHalf,
                        accordTarget,
                    })
                    : `translate(${accordTarget ? 0 : -50}%, ${isTopHalf ? 0 : '-100'}%)`,
                ...((props === null || props === void 0 ? void 0 : props.style) || {}),
            } },
            content,
            footer),
        hasMask && (React.createElement(DropDownContainer, { onClick: maskClick, style: { zIndex: zIndex - 1 } }))), container);
};
function hide(containerType) {
    // if (!lock) {
    //   return;
    // }
    // lock = false;
    const container = getContainer(containerType);
    ReactDOM.unmountComponentAtNode(container);
}
export const Dropdown = {
    show,
    hide,
};
