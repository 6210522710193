import * as React from 'react';
import classnames from 'classnames';
export class CnPageSeparator extends React.Component {
    render() {
        const { className } = this.props;
        const cls = classnames('cn-ui-page-separator', className);
        return React.createElement("div", { className: cls });
    }
}
CnPageSeparator.displayName = 'CnPageSeparator';
