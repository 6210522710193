import { ActionTypes } from '../actions/save-selected';
export const Reducers = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.SetOptions:
            return {
                ...state,
                options: payload,
            };
        case ActionTypes.SetSelected:
            return {
                ...state,
                selected: payload,
            };
        case ActionTypes.SetOverlayVisible:
            return {
                ...state,
                overlayVisible: payload,
            };
        default:
            return {
                ...state,
            };
    }
};
