import { ActionTypes } from '../actions/save-config';
export const Reducers = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.SetConfigValue:
            return {
                ...state,
                configValue: payload,
            };
        case ActionTypes.SetConfigInited:
            return {
                ...state,
                configInited: payload,
            };
        case ActionTypes.SetItemsMeta:
            return {
                ...state,
                itemsMeta: payload,
            };
        case ActionTypes.SetDataSource:
            return {
                ...state,
                dataSource: payload,
            };
        case ActionTypes.SetArrangedChildren:
            return {
                ...state,
                arrangedChildren: payload,
            };
        case ActionTypes.SetOverlayVisible:
            return {
                ...state,
                overlayVisible: payload,
            };
        default:
            return {
                ...state,
            };
    }
};
