import $i18n from 'panda-i18n';
import merge from 'lodash/merge';
import defaultFormat from './cn-table-cell-format';
let cacheFormat = {};
const defaultRemoteTransform = (res) => {
    var _a;
    return (_a = res === null || res === void 0 ? void 0 : res.data) !== null && _a !== void 0 ? _a : res;
};
/**
 * 全局常量表格配置
 */
export let globalConfig = {
    request: null,
    responseTransform: defaultRemoteTransform,
    defaultParamsTransform: null,
    pageSizeList: [10, 20, 50],
    sizeList: () => [
        {
            label: $i18n.get({ id: 'CompactTable', dm: '紧凑表格', ns: 'CnTable' }),
            value: 'small',
        },
        {
            label: $i18n.get({ id: 'NormalForm', dm: '正常表格', ns: 'CnTable' }),
            value: 'medium',
        },
        {
            label: $i18n.get({ id: 'LooseForm', dm: '宽松表格', ns: 'CnTable' }),
            value: 'large',
        },
    ],
    format: {},
    defaultPageSize: 10,
};
export function getCellFormat(format) {
    return {
        ...defaultFormat,
        ...cacheFormat,
        ...format,
    };
}
export function registerFormat(format) {
    cacheFormat = merge(cacheFormat, format);
}
export function config(props) {
    cacheFormat = Object.assign({}, cacheFormat, props === null || props === void 0 ? void 0 : props.format);
    globalConfig = Object.assign(globalConfig, props);
}
export { cellFormat } from './cell-format';
