import { useEffect } from 'react';
export default function useInitPolling(props) {
    const { startTaskLoop, stopTaskLoop } = props;
    useEffect(() => {
        startTaskLoop();
        return () => {
            stopTaskLoop();
        };
    }, [startTaskLoop, stopTaskLoop]);
}
