import * as React from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import numbro from 'numbro';
import NextNumberPicker from './number-picker';
import './cn-number-picker.scss';
export class CnNumberPicker extends React.Component {
    constructor(props) {
        super(props);
        this.saveNumberPickerRef = (ref) => {
            this.numberPickerRef = ref;
        };
        this.handleNumberPaste = (e) => {
            e.preventDefault();
            const formatNumber = e.clipboardData.getData('text');
            const val = numbro.unformat(formatNumber);
            if (val) {
                this.onChange(val, e);
            }
            if (!('value' in this.props)) {
                this.setState({
                    value: val,
                });
            }
        };
        this.onChange = (newValue, e) => {
            const { onChange } = this.props;
            if (!('value' in this.props)) {
                this.setState({
                    value: newValue,
                });
            }
            onChange && onChange(newValue, e);
        };
        let value;
        if ('value' in props) {
            value = props.value;
        }
        else {
            value = props.defaultValue;
        }
        this.state = {
            value: value === undefined || value === null ? undefined : value,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            const { value } = nextProps;
            return {
                value: value === undefined || value === null ? '' : value,
            };
        }
        return null;
    }
    focus() {
        this.numberPickerRef.focus();
    }
    render() {
        const { className, readOnly, ...otherProps } = this.props;
        const classes = cx({
            'cn-ui-number-picker': true,
            'cn-ui-number-picker-readonly': !!readOnly,
            [className]: !!className,
        });
        if (readOnly) {
            const bigNumberValue = new BigNumber(this.state.value);
            let displayValue = bigNumberValue.toString();
            if (!bigNumberValue.isNaN()) {
                // if (!isNaN(otherProps.precision)) {
                //   displayValue = bigNumberValue.toFixed(Number(otherProps.precision));
                // }
                if (otherProps.format) {
                    displayValue = otherProps.format(displayValue);
                }
            }
            else {
                displayValue = '-';
            }
            return (React.createElement("div", { className: classes },
                otherProps.innerBefore,
                otherProps.addonTextBefore,
                displayValue,
                otherProps.addonTextAfter,
                otherProps.innerAfter));
        }
        return (React.createElement(NextNumberPicker, { "data-name": "CnNumberPicker", ref: this.saveNumberPickerRef, ...otherProps, prefix: "cn-next-", className: classes, value: this.state.value, onChange: this.onChange, onPaste: this.handleNumberPaste }));
    }
}
CnNumberPicker.displayName = 'CnNumberPicker';
CnNumberPicker.defaultProps = {
    // size: 'medium',
    type: 'normal',
    maxLength: 17,
    precision: 0,
};
