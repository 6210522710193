import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { domStyleMap } from '@/components/cn-utils';
export default function headerCellPropsMergeStylePipeline() {
    return function (pipeline) {
        const columns = pipeline.getColumns();
        const mergeHeaderCellPropsColumns = columns.map((column) => {
            column = headerCellPropsStyleFilter(column);
            return column;
        });
        return pipeline.columns(mergeHeaderCellPropsColumns);
    };
}
function headerCellPropsStyleFilter(column) {
    const { headerCellProps } = column;
    if (isEmpty(column) || isNil(headerCellProps)) {
        return column;
    }
    if (Array.isArray(column.children)) {
        column.children = column.children.map((element) => {
            return headerCellPropsStyleFilter(element);
        });
    }
    const styleAttribute = pick(headerCellProps, Object.keys(domStyleMap));
    // const styleAttribute = Object.keys(headerCellProps).reduce((pre, cur) => {
    //   if (cur in domStyleMap) {
    //     pre[cur] = headerCellProps[cur];
    //   }
    //   return pre;
    // }, {});
    if (headerCellProps.style) {
        headerCellProps.style = {
            ...headerCellProps.style,
            ...styleAttribute,
        };
    }
    else {
        headerCellProps.style = styleAttribute;
    }
    column.headerCellProps = headerCellProps;
    return column;
}
