import * as React from 'react';
import cx from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
export class CnEllipsis extends React.Component {
    constructor(props) {
        super(props);
        this.onMouseEnter = () => {
            if (this.textRef && this.textRef.scrollWidth > this.textRef.offsetWidth) {
                this.setState({
                    isOverflow: true,
                });
            }
            else {
                this.setState({
                    isOverflow: false,
                });
            }
        };
        this.saveTextRef = (ref) => {
            this.textRef = ref;
        };
        this.state = {
            isOverflow: false,
        };
    }
    componentDidMount() {
        if (this.props.ellipsisPosition === 'middle') {
            if (this.textRef && this.textRef.scrollWidth > this.textRef.offsetWidth) {
                this.setState({
                    isOverflow: true,
                });
            }
        }
    }
    render() {
        const { isOverflow } = this.state;
        const { children, className, ellipsisPosition, endCharCount, hasTooltip, cnTooltipProps, ...otherProps } = this.props;
        const classes = cx({
            'cn-ui-ellipsis': true,
            [className]: !!className,
        });
        // 默认
        let trigger = (React.createElement("div", { onMouseEnter: this.onMouseEnter, className: classes, ...otherProps },
            React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header" }, children)));
        // 省略号出现在中间
        if (isOverflow && ellipsisPosition === 'middle') {
            const childrenStr = `${children}`;
            const startChildren = childrenStr.substr(0, childrenStr.length - endCharCount);
            const endChildren = childrenStr.substr(-endCharCount);
            trigger = (React.createElement("div", { className: classes, ...otherProps },
                React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header" }, startChildren),
                React.createElement("div", { className: "cn-ui-ellipsis-footer" }, endChildren)));
        }
        // 出现 Balloon
        if (isOverflow && hasTooltip) {
            return (React.createElement(CnTooltip, { v2: true, trigger: trigger, ...cnTooltipProps }, children));
        }
        return trigger;
    }
}
CnEllipsis.displayName = 'CnEllipsis';
CnEllipsis.defaultProps = {
    ellipsisPosition: 'end',
    endCharCount: 8,
    hasTooltip: true,
};
